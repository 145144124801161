/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';

import DateSelection from '../../../../../Shared/Components/Common/DatePicker/DateSelection';
import { CancellationOptions } from '../AllJobs.Model';

import { buttonStyles } from '../../../../Jobs/Components/UpdateShiftDate/UpdateShiftDate.Style';
import { DatePickerContainer } from '../../../../Jobs/Components/JobDetails/OneTimeJobDetails.Style';
import theme from '../../../../../Shared/Themes/theme';

interface CancelShift {
  isDataLoading?: boolean;
  handleOnConfirm: (data: any) => void;
  selectedDate?: string;
}
const RenderCancelShiftBodyContent = ({ isDataLoading, handleOnConfirm, selectedDate }: CancelShift): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState('');

  const { t } = useTranslation(['jobManagement', 'calendar', 'vendor']);

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      lastDateOfService: selectedDate ?? new Date().toISOString(),
      closureStartDate: selectedDate ?? new Date().toISOString(),
      shiftRestartDate: selectedDate ?? new Date().toISOString(),
      shiftCancellationDate: selectedDate ?? new Date().toISOString(),
    },
  });

  const { confirmButton, buttonText, radioButton, radioButtonText } = buttonStyles;
  const handleOptionChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSelectedOption(event.target.value);
  };

  const disableUpdateButton = () => {
    const { lastDateOfService, closureStartDate, shiftRestartDate, shiftCancellationDate } = getValues();
    return !lastDateOfService || !(closureStartDate && shiftRestartDate) || !shiftCancellationDate;
  };

  const handleOnPressConfirm = (data: any) => {
    handleOnConfirm({ ...data, type: selectedOption });
  };

  return (
    <Box>
      <Box sx={{ paddingBottom: 2, marginBottom: '1rem' }}>
        <FormControl component="fieldset">
          <Typography
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontSize: theme.typography.h3?.fontSize,
              fontWeight: theme.typography.fontWeightBold,
            }}>
            {t('jobManagement:selectCancellationOption')}
          </Typography>
          <RadioGroup value={selectedOption} onChange={handleOptionChange}>
            <Box sx={{ marginLeft: '1rem', marginTop: '1rem' }}>
              <FormControlLabel
                value="permanent"
                control={
                  <Radio
                    sx={{
                      ...radioButton,
                    }}
                  />
                }
                label={t('jobManagement:permanentClosure')}
                sx={{
                  ...radioButtonText,
                }}
              />
            </Box>
            <Box sx={{ marginLeft: '1rem', marginTop: '1rem' }}>
              <FormControlLabel
                value="temporary"
                control={
                  <Radio
                    sx={{
                      ...radioButton,
                    }}
                  />
                }
                label={t('jobManagement:temporaryClosure')}
                sx={{
                  ...radioButtonText,
                }}
              />
            </Box>
            <Box sx={{ marginLeft: '1rem', marginTop: '1rem' }}>
              <FormControlLabel
                value="shiftCancellation"
                control={
                  <Radio
                    sx={{
                      ...radioButton,
                    }}
                  />
                }
                label={t('jobManagement:shiftCancellation')}
                sx={{
                  ...radioButtonText,
                }}
              />
            </Box>
          </RadioGroup>
        </FormControl>
      </Box>

      {(selectedOption as CancellationOptions) === CancellationOptions.PermanentClosure && (
        <DatePickerContainer style={{ marginBottom: '1rem' }}>
          <DateSelection
            requiredText={t('calendar:dateFieldIsRequired')}
            control={control}
            fieldName={'lastDateOfService'}
            label={t('jobManagement:lastDateOfService')}
            isExcludePastDate={false}
            isExcludeFutureDate={false}
            textFieldStyles={{
              '& .MuiOutlinedInput-root': {
                '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
                  borderWidth: 1,
                  borderColor: theme.palette.primary.dark,
                  borderRadius: '1rem',
                },
              },
              '& .MuiInputLabel-root': {
                color: theme.palette.primary.dark,
                '&.Mui-focused': {
                  color: theme.palette.primary.dark,
                },
              },
            }}
          />
        </DatePickerContainer>
      )}

      {(selectedOption as CancellationOptions) === CancellationOptions.TemporaryClosure && (
        <DatePickerContainer style={{ marginBottom: '1rem' }}>
          <DateSelection
            requiredText={t('calendar:dateFieldIsRequired')}
            control={control}
            fieldName={'closureStartDate'}
            label={t('calendar:startDate')}
            isExcludePastDate={false}
            isExcludeFutureDate={false}
            textFieldStyles={{
              '& .MuiOutlinedInput-root': {
                '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
                  borderWidth: 1,
                  borderColor: theme.palette.primary.dark,
                  borderRadius: '1rem',
                },
              },
              '& .MuiInputLabel-root': {
                color: theme.palette.primary.dark,
                '&.Mui-focused': {
                  color: theme.palette.primary.dark,
                },
              },
            }}
          />
          <DateSelection
            requiredText={t('calendar:dateFieldIsRequired')}
            control={control}
            fieldName={'shiftRestartDate'}
            label={t('calendar:endDate')}
            isExcludePastDate={false}
            isExcludeFutureDate={false}
            textFieldStyles={{
              '& .MuiOutlinedInput-root': {
                '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
                  borderWidth: 1,
                  borderColor: theme.palette.primary.dark,
                  borderRadius: '1rem',
                },
              },
              '& .MuiInputLabel-root': {
                color: theme.palette.primary.dark,
                '&.Mui-focused': {
                  color: theme.palette.primary.dark,
                },
              },
            }}
          />
        </DatePickerContainer>
      )}

      {(selectedOption as CancellationOptions) === CancellationOptions.ShiftCancellation && (
        <DatePickerContainer style={{ marginBottom: '1rem' }}>
          <DateSelection
            requiredText={t('calendar:dateFieldIsRequired')}
            control={control}
            fieldName={'shiftCancellationDate'}
            label={t('jobManagement:cancellationDate')}
            isExcludePastDate={false}
            isExcludeFutureDate={false}
            textFieldStyles={{
              '& .MuiOutlinedInput-root': {
                '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
                  borderWidth: 1,
                  borderColor: theme.palette.primary.dark,
                  borderRadius: '1rem',
                },
              },
              '& .MuiInputLabel-root': {
                color: theme.palette.primary.dark,
                '&.Mui-focused': {
                  color: theme.palette.primary.dark,
                },
              },
            }}
          />
        </DatePickerContainer>
      )}

      {selectedOption && (
        <Button
          sx={{
            ...confirmButton,
            opacity: isDataLoading || disableUpdateButton() ? 0.5 : 1,
          }}
          disabled={isDataLoading || disableUpdateButton()}
          onClick={handleSubmit(handleOnPressConfirm)}>
          {isDataLoading ? (
            <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
          ) : (
            <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>{t('vendor:confirm')}</Typography>
          )}
        </Button>
      )}
    </Box>
  );
};

export default RenderCancelShiftBodyContent;
