/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import {
  convertToObject,
  getAuthData,
  getAuthDetails,
  getPlaceholderText,
  getStoredCustomerDetails,
} from '../../../../Shared/Utilities/utils';
import { isEmpty } from 'lodash';
import { UserType } from '../../../../Shared/Constants/App';
import {
  ConversationsContainer,
  ConversationsContainerTwo,
  CustomerOuterContainer,
} from '../../../../Shared/Components/Conversations/DashboardConversations.Style';
import MessageScreen from '../../../../Shared/Components/Conversations/MessagesScreen/MessageScreen';
import { ConversationType, useOTJCommHelper } from 'otj-client-lib';
import { DirectChat, Meta, MetaData } from '../../../../Shared/Components/Conversations/Conversations.model';

interface Conversation {
  adminIds: string[];
  createdAt: string;
  description: string | null;
  endCursor: number;
  id: string;
  image: string | null;
  isDeleted: boolean;
  isPinned: boolean;
  meta: Record<string, unknown>;
  metaData: MetaData;
  mode: string;
  name: string | null;
  startCursor: number;
  updatedAt: string;
  userIds: string[];
}

export interface CustomerVendorDetails {
  customerId: string;
  customerUserName: string;
  customerName: string;
  vendorId: string;
  vendorUserName: string;
  vendorName: string;
}

export interface IConversationContext {
  userType: UserType;
  userId1: string;
  userId1Name: string;
  userId1UserName: string;
  userId2: string;
  userId2Name: string;
  userId2UserName: string;
  jobId: string;
  userAndJobDetails: IUserAndJobDetails;
  primaryContactName: string;
  primaryContactToBeShownInViewDetails?: string;
}

export interface IUserAndJobDetails {
  jobName: string;
  vendorId: string;
  jobId: string;
  vendorUserName: string;
  vendorCompanyName: string;
  userType: UserType;
  facilityName: string;
  conversationGroupName: string;
  associateId: string;
  associateName: string;
  customerOrDmSubVendorId: string;
  customerOrDmOrSubVendorUserName: string;
  customerOrDmOrSubVendorCompanyName: string;
}

const PostJobAwardConversations = (): JSX.Element => {
  const location = useLocation();
  const [selectedConversation, setSelectedConversation] = useState<any>({});
  const [replyMsgContent, setReplyMsgContent] = useState('');
  const [allConversationsData, setAllConversationsData] = useState<any>([]);

  const [currentUserConversationId, setCurrentUserConversationId] = useState(
    !isEmpty(location.state?.selectedConversationId) ? location.state?.selectedConversationId : ''
  );

  const [placeHolder, setPlaceHolder] = useState<string>('');

  const [conversationDetails, setConversationDetails] = useState<any>();

  const { role } = getAuthData();

  const { isDistrictManager } = getAuthData() || {};

  const { chatHooks } = useOTJCommHelper();
  const { useConversations, createConversation } = chatHooks;

  const { data, isSuccess } = useConversations();

  const { organizationName } = getStoredCustomerDetails();

  const storedAuthDetails = getAuthDetails();

  const loggedInUserType = storedAuthDetails['custom:Role'] as UserType;
  const loggedInUserInternalId = storedAuthDetails['custom:UserId'] || storedAuthDetails['preferred_username'];
  const loggedInUserName = storedAuthDetails['name'];

  const extractMeta = useCallback(
    (meta: Meta) => ({
      associateId: meta?.associateId || '',
      associateName: meta?.associateName || '',
      customerOrDmOrSubVendorCompanyName: meta?.customerOrDmOrSubVendorCompanyName || '',
      customerOrDmOrSubVendorUserName: meta?.customerOrDmOrSubVendorUserName || '',
      customerOrDmSubVendorId: meta?.customerOrDmSubVendorId || '',
      vendorCompanyName: meta?.vendorCompanyName || '',
      vendorId: meta?.vendorId || '',
      vendorUserName: meta?.vendorUserName || '',
    }),
    []
  );

  const determineVendorDetails = useCallback((meta: Meta) => {
    const {
      vendorId,
      vendorCompanyName,
      vendorUserName,
      customerOrDmSubVendorId,
      customerOrDmOrSubVendorCompanyName,
      customerOrDmOrSubVendorUserName,
      associateId,
      associateName,
    } = convertToObject(meta);

    if (vendorId === loggedInUserInternalId) {
      return {
        userId1: vendorId,
        userId1Name: vendorCompanyName,
        userId1UserName: vendorUserName,
        userId2: customerOrDmSubVendorId || associateId,
        userId2Name: customerOrDmOrSubVendorCompanyName || associateName,
        userId2UserName: customerOrDmOrSubVendorUserName || '',
      };
    }

    return {
      userId1: customerOrDmSubVendorId,
      userId1Name: customerOrDmOrSubVendorCompanyName,
      userId1UserName: customerOrDmOrSubVendorUserName,
      userId2: vendorId || associateId,
      userId2Name: vendorCompanyName || associateName,
      userId2UserName: vendorUserName || '',
    };
  }, []);

  const getDisplayNameForDirectChat = useCallback((meta: any, isPlaceholderTextRequired: boolean = false) => {
    if (isPlaceholderTextRequired) {
      const senderInformation =
        meta?.userInformation?.find((item: { id: any }) => item?.id !== loggedInUserInternalId) ?? {};
      const senderName = senderInformation?.name ?? '';
      const senderCompanyName = senderInformation?.companyName ?? '';
      return getPlaceholderText(senderName, senderCompanyName);
    }
    return meta?.userInformation?.find((item: { id: any }) => item?.id !== loggedInUserInternalId)?.name ?? '';
  }, []);

  const getVendorPlaceholder = useCallback((meta: Meta, details: any, conversation: DirectChat) => {
    const { userId2UserName, userId2Name } = details;
    const { associateName } = convertToObject(meta);

    if (userId2UserName || userId2Name || associateName) {
      return getPlaceholderText(userId2UserName || '', userId2Name || associateName);
    }

    return getDisplayNameForDirectChat(conversation?.meta, true) || '';
  }, []);

  const handleVendorConversation = (meta: Meta, conversation: DirectChat) => {
    const conversationDetails = determineVendorDetails(meta);

    setConversationDetails(conversationDetails);

    setPlaceHolder(getVendorPlaceholder(meta, conversationDetails, conversation));
  };

  const handleCustomerConversation = useCallback((meta: any) => {
    const {
      vendorId,
      vendorCompanyName,
      vendorUserName,
      customerOrDmSubVendorId,
      customerOrDmOrSubVendorCompanyName,
      customerOrDmOrSubVendorUserName,
    } = convertToObject(meta);
    const conversationDetails = {
      userType: UserType.Customer,
      userId2: !isEmpty(customerOrDmSubVendorId) ? customerOrDmSubVendorId : '',
      userId2Name: !isEmpty(customerOrDmOrSubVendorCompanyName) ? customerOrDmOrSubVendorCompanyName : '',
      userId2UserName: !isEmpty(customerOrDmOrSubVendorUserName) ? customerOrDmOrSubVendorUserName : '',
      userId1: !isEmpty(vendorId) ? vendorId : '',
      userId1Name: !isEmpty(vendorCompanyName) ? vendorCompanyName : '',
      userId1UserName: !isEmpty(vendorUserName) ? vendorUserName : '',
    };
    setConversationDetails(conversationDetails);

    setPlaceHolder(
      !isEmpty(vendorUserName) || !isEmpty(vendorCompanyName)
        ? getPlaceholderText(
            !isEmpty(vendorUserName) ? vendorUserName : '',
            !isEmpty(vendorCompanyName) ? vendorCompanyName : ''
          )
        : getDisplayNameForDirectChat(meta, true) ?? ''
    );
  }, []);

  const onSelectConversationCard = useCallback(
    (conversation: any): void => {
      setSelectedConversation(conversation);
      const meta = extractMeta(convertToObject(conversation?.meta));

      if (loggedInUserType === UserType.Vendor) {
        handleVendorConversation(meta, conversation);
      } else if (loggedInUserType === UserType.Customer) {
        handleCustomerConversation(meta);
      }
    },
    [allConversationsData]
  );

  useEffect(() => {
    if (allConversationsData) {
      onSelectConversationCard(allConversationsData);
    }
  }, [allConversationsData]);

  const handleOnCreateConversationForUser = useCallback(async (allConversationsData: any) => {
    try {
      const { userId1, userId1UserName, userId2, userId2UserName, jobId } = allConversationsData ?? '';

      const userIds = [userId2, userId1];
      !isEmpty(jobId) && userIds?.push(jobId);
      return (await createConversation?.({
        userIds,
        adminIds: [loggedInUserInternalId ?? ''],
        mode: 'GROUP',
        meta: { ...allConversationsData?.meta },
        userData: [
          { id: userId1 ?? '', fullName: userId1UserName ?? '' },
          { id: userId2 ?? '', fullName: userId2UserName ?? '' },
          { id: jobId ?? '', fullName: '' },
        ],
      })) as ConversationType;
    } catch (e: any) {
      console.log('error', e);
    }
  }, []);

  const handleConversationFound = useCallback(async () => {
    let conversationFound: Conversation | undefined;
    if (!isEmpty(location.state?.conversationContext?.jobId)) {
      const { userId1, userId2 } = location.state?.conversationContext ?? '';
      const conversationDataArray: Conversation[] = data;

      conversationFound = conversationDataArray?.find(
        ({ userIds }) =>
          userIds.includes(userId2) &&
          userIds.includes(userId1) &&
          userIds.includes(location.state?.conversationContext?.jobId)
      );
      setCurrentUserConversationId(isEmpty(conversationFound?.id ?? '') ? '' : conversationFound?.id ?? '');

      if (!isEmpty(conversationFound)) {
        setAllConversationsData(conversationFound);
      } else {
        const conversationData = {
          ...location.state?.conversationContext,
          meta: location.state?.conversationContext?.userAndJobDetails,
          mode: 'GROUP',
        };

        const response = await handleOnCreateConversationForUser(conversationData);
        setAllConversationsData(response ?? '');
      }
    }
  }, [location.state?.conversationContext, data]);

  const initializeConversation = useCallback(async () => {
    if (isSuccess && isEmpty(currentUserConversationId)) {
      if (isEmpty(data)) {
        setCurrentUserConversationId('');
        const conversationData = {
          ...location.state?.conversationContext,
          meta: location.state?.conversationContext?.userAndJobDetails,
          mode: 'GROUP',
        };

        const response = await handleOnCreateConversationForUser(conversationData);
        setAllConversationsData(response ?? '');
      } else if (role === UserType.Vendor || role === UserType.Associate || role === UserType.Customer) {
        await handleConversationFound();
      }
    } else if (!isEmpty(currentUserConversationId) && !isEmpty(data)) {
      const conversationDataArray: Conversation[] = data;

      const conversationFound = conversationDataArray?.find(
        (conversation) => conversation.id === currentUserConversationId
      );

      if (!isEmpty(conversationFound)) {
        setAllConversationsData(conversationFound);
      }
    }
  }, [isSuccess, data, currentUserConversationId, location.state?.conversationContext]);

  useEffect(() => {
    initializeConversation();
  }, [isSuccess, data, currentUserConversationId]);

  return (
    <>
      <PageTitle title={location.state?.jobName || location.state?.name} />
      <CustomerOuterContainer>
        <ConversationsContainer>
          <ConversationsContainerTwo
            style={{
              flexBasis: '100%',
              borderRadius: '10px',
              borderTopRightRadius: '20px',
              borderBottomRightRadius: '10px',
            }}>
            <MessageScreen
              selectedConversation={selectedConversation}
              placeHolder={placeHolder}
              conversationDetails={conversationDetails}
              userName={loggedInUserName}
              companyName={organizationName}
              loggedInUserInternalId={loggedInUserInternalId}
              replyMsgContent={replyMsgContent}
              setReplyMsgContent={setReplyMsgContent}
              hideConversationTab={true}
              allConversationsData={allConversationsData}
              isDistrictManager={isDistrictManager}
              userAndJobDetails={allConversationsData?.meta}
            />
          </ConversationsContainerTwo>
        </ConversationsContainer>
      </CustomerOuterContainer>
    </>
  );
};

export default PostJobAwardConversations;
