/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertColor, IconButton, Snackbar } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { isEmpty } from 'lodash';

import PageTitle from '../../../../../Shared/Components/Common/PageTitle/PageTitle';
import DataGrid from '../../../../../Shared/Components/Common/DataGrid/DataGrid';
import Button, { ButtonType } from '../../../../../Shared/Components/Common/Buttons/Button';
import DateSelection from '../../../../../Shared/Components/Common/DatePicker/DateSelection';
import ModifyPopUp from '../../../../Jobs/Components/JobDetails/ModifyPopUp';
import Modal from '../../../../Jobs/Utilities/Shared/Components/Modal';
import RenderCancelShiftBodyContent from './RenderCancellationOptions';
import SnackbarMessage from '../../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';

import WorkOrdersApiService from '../../../../Jobs/Services/WorkOrdersService';

import { CustomerContainer } from '../../../../../Shared/Components/Layout/styles';
import { SnackBarWrapper } from '../../../../Jobs/Components/JobDetailsOverView/JobDetailsOverview.styles';
import { DropdownWrapper } from '../../../../Jobs/Components/WorkTasks/JobDetailsTasks.styles';
import { DatePickerWrapper } from '../../../../../Shared/Components/Common/GoogleMap/GoogleMapChips.Style';
import { ActionsColumn } from '../../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import WhiteTooltip from '../../../../../Shared/Components/Common/CommonStyle/Tooltip.Style';
import { HeadingText } from '../../../../Jobs/Components/JobDetails/OneTimeJobDetails.Style';
import theme from '../../../../../Shared/Themes/theme';
import { Icons } from '../../../../../Shared/Constants/Icons';
import ScheduleColumns from './ScheduleColumns';
import { CancellationOptions, DropdownOptions } from '../AllJobs.Model';

interface ShiftData {
  lastDateOfService: string;
  closureStartDate: string;
  shiftRestartDate: string;
  shiftCancellationDate: string;
  type: string;
}

const CancelShift = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCancelShiftLoading, setIsCancelShiftLoading] = useState<boolean>(false);
  const [schedules, setSchedules] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const [selectedMultipleShifts, setIsSelectedMultipleShifts] = useState(false);
  const [isSelectOptionsOpen, setIsSelectOptionsOpen] = useState(false);
  const [isConfirmPopUpOpen, setIsConfirmPopUp] = useState(false);

  const [shiftData, setShiftData] = useState<ShiftData>();
  const [workOrderScheduleIds, setWorkOrderScheduleIds] = useState<string>();

  const [selectedShiftData, setSelectedShiftData] = useState<any>({});
  const [selectedMultipleShiftsData, setSelectedMultipleShiftsData] = useState<any>([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');

  const { t } = useTranslation([
    'jobManagement',
    'messages',
    'noData',
    'tableHeader',
    'calendar',
    'taskRejectionAndRework',
  ]);

  const location = useLocation();

  const { control } = useForm({
    defaultValues: {
      specificDate: new Date().toISOString(),
    },
  });

  const specificDate = useWatch({
    control,
    name: 'specificDate',
  });

  const fetchSchedulesData = useCallback(
    async (workOrderScheduleIds: string) => {
      setIsLoading(true);
      try {
        const formattedDate = new Date(specificDate).toISOString().split('T')[0];

        const schedulesByDateResponse = await WorkOrdersApiService.getSchedulesByDate(
          formattedDate,
          '',
          workOrderScheduleIds
        );

        setSchedules(schedulesByDateResponse?.data);
      } catch (error) {
        console.error(t('messages:errorFetchingJobs'), error);
      } finally {
        setIsLoading(false);
      }
    },
    [specificDate, workOrderScheduleIds]
  );

  const renderActionsCell = (row: any) => {
    const { dataStatus } = row.original;
    const isEditable = dataStatus === 'A';
    return (
      <ActionsColumn>
        <IconButton
          size="large"
          sx={{
            '&:hover': {
              background: isEditable ? theme.palette.primary.main : theme.palette.common.white,
            },
          }}
          onClick={() => {
            if (isEditable) {
              setIsSelectOptionsOpen(true);
              setSelectedShiftData(row.original);
              setSelectedMultipleShiftsData([]);
            }
          }}>
          <WhiteTooltip title={t('jobManagement:cancelShift')}>
            <img
              src={Icons.CancelIcon}
              alt={t('jobManagement:cancelShift')}
              style={{ cursor: isEditable ? 'pointer' : 'not-allowed', opacity: isEditable ? 1 : 0.3 }}
            />
          </WhiteTooltip>
        </IconButton>
      </ActionsColumn>
    );
  };

  const columns = ScheduleColumns({
    type: DropdownOptions.CancelShift,
    renderActionsCell: renderActionsCell,
  });

  const handleCloseCancelShift = () => {
    setIsSelectedMultipleShifts(!selectedMultipleShifts);
  };

  const handleConfirmPopup = () => {
    setIsConfirmPopUp(!isConfirmPopUpOpen);
  };

  const handleContinueButton = () => {
    setIsSelectOptionsOpen(true);
  };

  const cancelShiftsPayload = (selectedShiftsData: any[], shiftData: ShiftData | undefined) => {
    const result: any[] = [];

    selectedShiftsData.forEach((schedule) => {
      const { workOrderId, workOrderScheduleId, woScheduleMasterId } = schedule;
      let workOrder = result.find((item) => item.workOrderId === workOrderId);

      if (!workOrder) {
        workOrder = {
          workOrderId,
          cancelWorkOrderScheduleInput: [],
        };
        result.push(workOrder);
      }

      if (shiftData?.type === CancellationOptions.PermanentClosure && shiftData?.lastDateOfService) {
        const originalDate = new Date(shiftData.lastDateOfService);

        const updatedDate = new Date(
          Date.UTC(originalDate.getUTCFullYear(), originalDate.getUTCMonth(), originalDate.getUTCDate())
        );

        const lastDateOfService = updatedDate;
        workOrder.cancelWorkOrderScheduleInput.push({
          type: shiftData?.type,
          lastDateOfService: lastDateOfService ?? '',
          workOrderScheduleId,
          woScheduleMasterId,
        });
      }

      if (
        shiftData?.type === CancellationOptions.TemporaryClosure &&
        shiftData?.closureStartDate &&
        shiftData?.shiftRestartDate
      ) {
        const originalClosureStartDate = new Date(shiftData.closureStartDate);
        const originalShiftRestartDate = new Date(shiftData.shiftRestartDate);

        const updatedClosureStartDate = new Date(
          Date.UTC(
            originalClosureStartDate.getUTCFullYear(),
            originalClosureStartDate.getUTCMonth(),
            originalClosureStartDate.getUTCDate()
          )
        );

        const updatedShiftRestartDate = new Date(
          Date.UTC(
            originalShiftRestartDate.getUTCFullYear(),
            originalShiftRestartDate.getUTCMonth(),
            originalShiftRestartDate.getUTCDate()
          )
        );

        workOrder.cancelWorkOrderScheduleInput.push({
          type: shiftData?.type,
          closureStartDate: updatedClosureStartDate ?? '',
          shiftRestartDate: updatedShiftRestartDate ?? '',
          workOrderScheduleId,
          woScheduleMasterId,
        });
      }

      if (shiftData?.type === CancellationOptions.ShiftCancellation && shiftData?.shiftCancellationDate) {
        const originalDate = new Date(shiftData.shiftCancellationDate);

        const updatedDate = new Date(
          Date.UTC(originalDate.getUTCFullYear(), originalDate.getUTCMonth(), originalDate.getUTCDate())
        );

        const shiftCancellationDate = updatedDate;
        workOrder.cancelWorkOrderScheduleInput.push({
          type: shiftData?.type,
          shiftCancellationDate: shiftCancellationDate ?? '',
          workOrderScheduleId,
          woScheduleMasterId,
        });
      }
    });

    return result;
  };

  const showSnackbar = (severity: AlertColor, message: string) => {
    setSnackbarOpen(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  };

  const handleConfirmButton = async () => {
    try {
      setIsCancelShiftLoading(true);

      let payload;
      if (!isEmpty(selectedMultipleShiftsData)) {
        payload = cancelShiftsPayload(selectedMultipleShiftsData, shiftData);
      } else {
        payload = cancelShiftsPayload([selectedShiftData], shiftData);
      }
      const cancelSchedulesResponse = await WorkOrdersApiService.cancelSchedules(payload);

      if (cancelSchedulesResponse?.data) {
        const allFailedEmpty = cancelSchedulesResponse?.data?.cancelSchedules.every(
          (schedule: { cancelWorkOrderSchedules: { failed: string | any[] } }) =>
            schedule.cancelWorkOrderSchedules.failed.length === 0
        );

        const message = allFailedEmpty
          ? isEmpty(selectedMultipleShiftsData)
            ? 'jobManagement:shiftCancelledSuccessfully'
            : 'jobManagement:shiftsCancelledSuccessfully'
          : isEmpty(selectedMultipleShiftsData)
            ? 'jobManagement:shiftCancellationFailed'
            : 'jobManagement:shiftsCancellationFailed';

        const messageType = allFailedEmpty ? 'success' : 'error';
        showSnackbar(messageType, t(message));
      } else {
        showSnackbar('error', cancelSchedulesResponse?.errors?.[0]?.message);
      }
      setIsCancelShiftLoading(false);
      setIsConfirmPopUp(false);
      setIsSelectOptionsOpen(false);
      setIsSelectedMultipleShifts(false);
      fetchSchedulesData(workOrderScheduleIds as string);
    } catch (error) {
      console.error(t('jobManagement:cancelShiftError'), error);
      showSnackbar('error', t('jobManagement:cancelShiftError'));
    }
  };

  useEffect(() => {
    const workOrderScheduleIds: string = location.state?.selectedRows
      ?.map((row: { workOrderScheduleId: any }) => row.workOrderScheduleId)
      ?.join(',');
    setWorkOrderScheduleIds(workOrderScheduleIds);
    fetchSchedulesData(workOrderScheduleIds);
  }, [specificDate]);

  const renderCancelShiftHeaderContent = () => {
    return <HeadingText sx={{ fontSize: theme.typography.h2?.fontSize }}>{t('jobManagement:cancelShift')}</HeadingText>;
  };

  const handleOnConfirm = (data: any) => {
    setIsConfirmPopUp(true);
    setShiftData(data);
  };

  const renderCancelShiftBodyContent = () => {
    return (
      <>
        <RenderCancelShiftBodyContent handleOnConfirm={handleOnConfirm} selectedDate={specificDate} />
      </>
    );
  };
  return (
    <>
      <PageTitle title={t('jobManagement:cancelShift')} />
      <CustomerContainer>
        <DropdownWrapper sx={{ justifyContent: 'flex-end', marginTop: '2rem', marginBottom: '2rem' }}>
          <DatePickerWrapper
            sx={{
              maxWidth: '13rem',
              '& .MuiOutlinedInput-root': {
                '& .MuiInputBase-input': {
                  padding: '0.5625rem 0 0.5625rem 1rem',
                  color: theme.palette.primary.dark,
                },
              },
            }}>
            <DateSelection
              requiredText={t('calendar:dateFieldIsRequired')}
              control={control}
              fieldName={'specificDate'}
              label={t('taskRejectionAndRework:selectDate')}
              isExcludePastDate={false}
              textFieldStyles={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
                    borderWidth: 1,
                    borderColor: theme.palette.primary.dark,
                    borderRadius: '1rem',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: theme.palette.primary.dark,
                  '&.Mui-focused': {
                    color: theme.palette.primary.dark,
                  },
                },
              }}
            />
          </DatePickerWrapper>
        </DropdownWrapper>
        <DataGrid
          columns={columns}
          data={schedules}
          enableRowSelect={true}
          enableColumnPinning={false}
          loader={isLoading}
          errorMessageTitle={t('noData:noDataYet')}
          errorMessageDescription={t('noData:comeBackLater')}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          isRescheduleShift={true}
        />
        {selectedRows?.length > 0 && (
          <SnackBarWrapper>
            <Snackbar
              open
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              action={
                <Button
                  type={ButtonType.Primary}
                  label={t('jobManagement:cancelShift')}
                  onClick={() => {
                    if (selectedRows?.length <= 1) {
                      setIsSelectOptionsOpen(true);
                      setSelectedShiftData({});
                      setSelectedMultipleShiftsData(selectedRows);
                    } else {
                      setIsSelectedMultipleShifts(true);
                      setSelectedShiftData({});
                      setSelectedMultipleShiftsData(selectedRows);
                    }
                  }}
                />
              }
            />
          </SnackBarWrapper>
        )}

        <ModifyPopUp
          open={selectedMultipleShifts}
          handleClose={handleCloseCancelShift}
          handleOnCloseJob={handleContinueButton}
          heading={t('jobManagement:cancelShift')}
          label={t('jobManagement:continue')}
          text={t('jobManagement:confirmCancelText')}
        />
        <ModifyPopUp
          open={isConfirmPopUpOpen}
          handleClose={handleConfirmPopup}
          handleOnCloseJob={handleConfirmButton}
          heading={t('jobManagement:cancelShift')}
          text={t('jobManagement:cancelShiftPopupText')}
          loading={isCancelShiftLoading}
        />
        <Modal
          open={isSelectOptionsOpen}
          onClose={() => {
            setIsSelectOptionsOpen(false);
            setIsSelectedMultipleShifts(false);
          }}
          renderHeader={renderCancelShiftHeaderContent()}
          renderBody={renderCancelShiftBodyContent()}
        />
        <SnackbarMessage
          open={snackbarOpen}
          successMessage={snackbarMessage}
          errorMessage={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        />
      </CustomerContainer>
    </>
  );
};

export default CancelShift;
