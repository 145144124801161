/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { Box, Divider } from '@mui/material';
import moment from 'moment';
import { isEmpty } from 'lodash';

import theme from '../../../Themes/theme';
import { LogoWrapper } from '../../../../Modules/Jobs/Components/Conversations/ConversationsPage.Style';
import {
  dividerStyles,
  MessageCardContainer,
  MessageCardOuterDiv,
  MessageCardStyles,
  NoMessagesAvailable,
} from './MessagesScreen.Style';
import { ConversationsDiv, LastSeenText, MessageTextNew } from '../ConversationsScreen/Conversations.Style';

const MessageCard = ({ messages, chatBoxRef, getAcronym, hasSameOrganizationId }: any): JSX.Element => {
  const { divider } = dividerStyles;

  return (
    <>
      {isEmpty(messages) && <NoMessagesAvailable>There are no messages available</NoMessagesAvailable>}
      <MessageCardContainer ref={chatBoxRef}>
        {messages?.map((message: any, id: number) => {
          const isSameOrg = hasSameOrganizationId(message?.from || message?.organizationId);

          const updatedAt = moment
            .utc(message?.UpdatedAt || message?.modifiedOn)
            .local()
            .format('MMM DD, YYYY hh:mm A');
          const { individualUserName, username } = message;

          return (
            <>
              <MessageCardOuterDiv
                style={{
                  justifyContent: isSameOrg ? 'flex-end' : 'flex-start',
                }}>
                <MessageCardStyles>
                  <Box style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    {!isSameOrg && (
                      <LogoWrapper
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: '50%',
                        }}>
                        <Box>{getAcronym(!isEmpty(username) ? username : individualUserName).toUpperCase()}</Box>
                      </LogoWrapper>
                    )}
                    <ConversationsDiv style={{ display: 'flex', flexDirection: 'column' }}>
                      <MessageTextNew
                        style={{
                          wordBreak: 'break-word',
                        }}>
                        {message.text || message.message}
                      </MessageTextNew>
                      <LastSeenText>{updatedAt}</LastSeenText>
                    </ConversationsDiv>
                  </Box>
                </MessageCardStyles>
              </MessageCardOuterDiv>
              {id < messages?.length - 1 && <Divider sx={divider} />}
            </>
          );
        })}
      </MessageCardContainer>
    </>
  );
};

export default MessageCard;
