/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import theme from '../../../Themes/theme';

export const TabPanel = styled('div')(() => ({
  height: '100%',
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 9rem)',
  padding: '0 1rem',
}));

export const ConversationCardOuterDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  overflowY: 'auto',
  marginBottom: '8px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  '&.selected': {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  },
  '&.last-card.selected': {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  },
}));

export const ConversationCardDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexShrink: 1,
  height: 'auto',
  alignItems: 'center',
  padding: '1rem 1.5rem',
}));

export const ConversationsDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const ConversationName = styled('div')(() => ({
  color: '#292F4D',
  letterSpacing: '0.25px',
  fontSize: theme.typography.h5?.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  fontFamily: theme.typography.fontFamily,
}));

export const SubText = styled('div')(() => ({
  opacity: 0.6,
  fontSize: theme.typography.h6?.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  fontFamily: theme.typography.fontFamily,
}));

export const MessageText = styled('div')(() => ({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  color: '#292F4D',
  letterSpacing: '0.4px',
  fontSize: theme.typography.h6?.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  fontFamily: theme.typography.fontFamily,
}));

export const MessageTextNew = styled('div')(() => ({
  whiteSpace: 'pre-wrap',
  color: '#292F4D',
  letterSpacing: '0.4px',
  fontSize: theme.typography.h6?.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  fontFamily: theme.typography.fontFamily,
}));

export const LastSeenText = styled('div')(() => ({
  whiteSpace: 'pre-wrap',
  color: '#646B87',
  fontSize: theme.typography.customSize2?.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  fontFamily: theme.typography.fontFamily,
}));

export const IndividualUnreadContainer = styled('div')(() => ({
  width: '20px',
  height: '20px',
  borderRadius: '40px',
  display: 'flex',
  background: theme.palette.primary.dark,
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '0.8125rem',
  position: 'absolute',
  right: '20px',
  [theme.breakpoints.down('laptop')]: {
    right: '10px',
  },
}));

export const UnreadCount = styled('span')(() => ({
  color: theme.palette.common.white,
  whiteSpace: 'nowrap',
}));
