/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import {
  DialogWrapper,
  PopupHeadingText,
  DeleteWithdrawText,
  CloseIconStyle,
} from '../../../Vendor/Components/CreateBid.Style';
import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';
import { Icons } from '../../../../Shared/Constants/Icons';

interface ConfirmationDialogProps {
  open: boolean;
  handleClose: () => void;
  handleOnCloseJob: () => void;
  loading?: boolean;
  heading: string;
  text: string;
  label?: string;
}

const ModifyQuotePopup: React.FC<ConfirmationDialogProps> = ({
  open,
  handleClose,
  handleOnCloseJob,
  loading,
  heading,
  text,
  label,
}) => {
  const { t } = useTranslation(['assignJob']);
  return (
    <DialogWrapper open={open} onClose={handleClose}>
      <CloseIconStyle onClick={handleClose}>
        <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
      </CloseIconStyle>
      <PopupHeadingText>{heading}</PopupHeadingText>
      <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '2rem' }}>
        <DeleteWithdrawText style={{ whiteSpace: 'pre-line', width: label && '41.25rem', flexWrap: 'wrap' }}>
          {text}
        </DeleteWithdrawText>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '1.5rem',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
        }}>
        <Button type={ButtonType.Secondary} label={t('assignJob:no')} onClick={handleClose} />

        <Button
          type={ButtonType.Primary}
          label={label ? label : t('assignJob:yes')}
          onClick={handleOnCloseJob}
          disabled={loading}
          isLoading={loading}
        />
      </Box>
    </DialogWrapper>
  );
};

export default ModifyQuotePopup;
