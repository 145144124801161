/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { CssBaseline } from '@mui/material';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Amplify } from 'aws-amplify';
import { PermissionProvider } from '@abrightlab/client-rbac';

import App from './App';
import { store } from './Shared/Store/store';
import reportWebVitals from './report-web-vitals';

import awsConfig from './Configuration/environment-config';
import i18n from './Bootstrap/i18n';
import { I18nextProvider } from 'react-i18next';
import { OTJCommHelperProvider } from 'otj-client-lib';
import { getChatUrls, getStoredCustomerDetails } from './Shared/Utilities/utils';
import { Environment } from './Shared/Constants/App';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

const [localRedirectSignIn] = awsConfig.oauth.redirectSignIn.split(',');
const [localRedirectSignOut] = awsConfig.oauth.redirectSignOut.split(',');

Amplify.configure({
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : '',
    redirectSignOut: isLocalhost ? localRedirectSignOut : '',
  },
});

const [apiBaseUrl, mqttClusterBaseUrl] = getChatUrls(process.env.REACT_APP_ENVIRONMENT as Environment);

const { userId = '' } = getStoredCustomerDetails() || {};

const root = createRoot(document.querySelector('#root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <OTJCommHelperProvider
    apiBaseUrl={apiBaseUrl}
    env={process.env.REACT_APP_ENVIRONMENT as undefined}
    mqttClusterBaseUrl={mqttClusterBaseUrl}
    userId={userId}>
    <Provider store={store}>
      <CssBaseline />
      <PermissionProvider>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </PermissionProvider>
    </Provider>
  </OTJCommHelperProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
