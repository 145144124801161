/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, CircularProgress, IconButton, Typography } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';
import { isEmpty } from 'lodash';

import {
  convertToReadableFormat,
  uploadDocumentToS3Bucket,
  getAuthData,
  getStoredCustomerDetails,
  getRescheduleShiftErrorMessage,
  formatDate,
} from '../../../../Shared/Utilities/utils';

import { IJobOverview } from '../JobsListView/JobsListView';
import { IJobs } from '../JobView/JobsView';
import ApproveDialog from '../TaskDetails/ApproveDialog';
import RejectDialog from '../TaskDetails/RejectDialog';
import SnackBar from '../../../../Shared/Components/Common/SnackBar/SnackBar';
import { useAuth } from '../../../../Configuration/AuthContext';
import { ISchedules, IServices, Task } from '../../../../Shared/Models/WorkTask.model';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import { ErrorMessage } from '../../../../Shared/Components/Common/ErrorMessage/ErrorMessage';
import DateSelection from '../../../../Shared/Components/Common/DatePicker/DateSelection';
import {
  AttachmentStatus,
  CreateTaskAttachmentInput,
  mdAttachmentType,
  mdFileContext,
  UpdateTaskInput,
  WorkOrderYesNoType,
  TaskStatus,
  ApprovalType,
  CreateTaskApprovalInput,
  ApprovalStatus,
  TaskScheduleType,
  RescheduleWorkDayInput,
  AllPunchInOutByWorkDayQuery,
} from '../../../../API';

import { DatePickerWrapper } from '../../../../Shared/Components/Common/GoogleMap/GoogleMapChips.Style';
import {
  ActionIcon,
  ActionsColumn,
  Linking,
  Menus,
  MenuWrapper,
} from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import {
  buttonStyles,
  DropdownWrapper,
  NoDataTag,
  NoPendingTaskContainer,
  Services,
  TitleWrapper,
} from './JobDetailsTasks.styles';
import { Loader } from '../JobView/JobsView.styles';

import { Icons } from '../../../../Shared/Constants/Icons';
import theme from '../../../../Shared/Themes/theme';
import { ApproverType } from '../../../../Shared/Models';
import { IBidList } from '../JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import { VendorType } from '../../../../Shared/Models/Vendors.Models';
import { IProofsProps, DocumentType, UserType } from '../../../../Shared/Constants/App';
import RejectWorkTask from '../TaskRejectionAndRework/RejectWorkTask';
import RequestOrScheduleRework from '../TaskRejectionAndRework/RequestOrScheduleRework';
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useNavigate } from 'react-router-dom';
import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';
import UpdateShiftDateCard from '../UpdateShiftDate/UpdateShiftDate';
import WorkTasksAccordion, {
  ScreenName,
  WhiteTooltip,
} from '../../../../Shared/Components/Common/Accordion/WorkTasksAccordion';
import moment from 'moment';
import { ISupervisorAssociate } from '../../../Vendor/Components/AllJobs/AllJobs.Model';

export interface IEachOptionsProps {
  completedBy?: string;
  floorNumber?: number | string;
  id: string;
  isProofOfCompletionRequired?: WorkOrderYesNoType;
  isTaskCompleted?: boolean;
  proof?: boolean;
  quantity?: number;
  quantityName?: string;
  status?: string;
  taskId?: string;
  taskScheduleId?: string;
  timeTaken?: string;
  title: string;
  workDayId?: string;
}

export interface IDataProps {
  category?: string;
  categoryTitle?: string;
  id?: string;
  options?: IEachOptionsProps[];
  serviceImage?: string;
  totalCompletedTasks?: number;
  totalTasks?: number;
}

export interface TaskData {
  no: number;
  taskName: string;
  floor: string;
  timeTaken: string;
  status: string;
  comments: string;
  proof: string;
  date: string;
  taskId: string;
  totalTasks?: number;
  approvedByCustomer?: number;
  proofTypes: {
    photo: number;
    audio: number;
    video: number;
  };
  sortingFn?: any;
  jobId?: string;
  executionType?: string;
  customerId?: string;
  supervisors?: ISupervisorAssociate[];
  associates?: ISupervisorAssociate[];
  dataStatus?: string;
  mdScheduleType?: string;
  parentWorkOrderId?: string;
}

export interface SchedulePunchInRecords {
  workDayPunchInRecords?: AllPunchInOutByWorkDayQuery | null;
  workOrderScheduleId: string;
  workOrderId?: string | null;
  woScheduleMasterId: string;
  scheduleDate?: string | null;
  scheduleStartTime?: string | null;
  scheduleEndTime?: string | null;
  scheduleType?: string | null;
  dataStatus?: string | null;
}

interface ICategoryList {
  id: number;
  serviceName: string;
  mdServiceId: string;
  iconUrl: string;
  shiftType: string;
  shiftTiming: string;
}
[];

interface IJobData {
  address: any;
  id: number;
  facilityName: string;
  facilityId: string;
  jobId: string;
  location: string;
  FacilityIcon: string;
  buildingName: string;
  Jobname: string;
  startTime: string;
  endTime: string;
  vendorId: string;
}

interface IJobDetailsTasksProps {
  servicesList: ICategoryList[];
  selectedJob: string;
  selectedFacility: IJobData;
  vendorTypes: any;
  newDate: any;
  specificDate: any;
  jobStartDate: string;
  jobEndDate: string;
  control: any;
}

interface ISchedulesByDate {
  workOrderScheduleId: string;
  workOrderId?: string | null;
  woScheduleMasterId: string;
  scheduleDate?: string | null;
  scheduleStartTime?: string | null;
  scheduleEndTime?: string | null;
  scheduleType?: string | null;
  dataStatus?: string | null;
  services: any[];
}

enum ShiftType {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
}

enum TaskType {
  ReworkTask = 'ReworkTask',
  SagaTask = 'SagaTask',
}

function getNextDay(dateString: string): string {
  // Parse the input date string
  const date = new Date(dateString);

  // Add one day (24 hours) to the date
  date.setDate(date.getDate() + 1);

  // Format the new date to the desired string format (ISO 8601)
  return date.toISOString();
}

const getTaskDuration = (durationInMinutes: number): string => {
  if (durationInMinutes < 60) {
    return `${durationInMinutes} min`;
  } else {
    const hours = Math?.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    return minutes > 0 ? `${hours} ${hours > 1 ? 'hrs' : 'hr'} ${minutes} min` : `${hours} ${hours > 1 ? 'hrs' : 'hr'}`;
  }
};

/**
 * @returns JobDetailsTasks Page
 */
const JobDetailsTasks = ({
  selectedJob,
  selectedFacility,
  vendorTypes,
  specificDate,
  jobStartDate,
  jobEndDate,
  control,
}: IJobDetailsTasksProps): JSX.Element => {
  const { t } = useTranslation(['altTexts', 'taskRejectionAndRework', 'rescheduleShiftDate', 'tableHeader']);
  const { updateShiftDateButton, buttonText } = buttonStyles;
  const [expanded, setExpanded] = useState<number | false>(0);
  const schedule = 'all';
  const [serviceList, setServiceList] = useState<IServices[]>([]);
  const [scheduleList] = useState<ISchedules[]>([]);
  const originalServices: IServices[] = [];
  const [isOpenApprove, setIsOpenApprove] = useState(false);
  const [isOpenReject, setIsOpenReject] = useState(false);
  const [isOpenApproveAll, setIsOpenApproveAll] = useState(false);
  const [isOpenRejectAll, setIsOpenRejectAll] = useState(false);
  const [rowInfo, setRowInfo] = useState<any>({});
  const [isAPIError, setIsAPIError] = useState<boolean>(false);
  const [dailyTaskData, setDailyTaskData] = useState<any[]>([]);
  const [reworkTaskData, setReworkTaskData] = useState<any[]>([]);
  const [isViewLoading, setIsViewLoading] = useState<boolean>(true);
  const [approveByRole, setApproveByRole] = useState(ApproverType.Supervisor);
  const [accordionID, setSelectedAccordionID] = useState('');
  const [isApproving, setIsApproving] = useState(false);
  const [isOtjJob, setIsOtjJob] = useState<boolean>(false);
  const [rejectionComment, setRejectionComment] = useState<string>('');
  const [isPartiallyCompleted, setIsPartiallyCompleted] = useState<boolean>(false);
  const [openRejectTask, setOpenRejectTask] = useState<boolean>(false);
  const [proofs, setProofs] = useState<IProofsProps[]>([]);
  const [openRequestRework, setOpenRequestRework] = useState<boolean>(false);
  const [reworkShift, setReworkShift] = useState<TaskScheduleType | null>(null);
  const [reworkScheduleDate, setReworkScheduleDate] = useState<string>('');
  const [workOrderEndDate, setWorkOrderEndDate] = useState<any>(null);
  const [isReworkLoading, setIsReworkLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [selectedTaskType, setSelectedTaskType] = useState<TaskType>(TaskType.SagaTask);
  const [sendingForApproval, setSendingForApproval] = useState<boolean>(false);
  const [openUpdateShiftDate, setOpenUpdateShiftDate] = useState<boolean>(false);
  const [isShowUpdateShiftDateLoading, setIsShowUpdateShiftDateLoading] = useState<boolean>(false);
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false);
  const [subWorkOrderId, setSubWorkOrderId] = useState<string | null>(null);
  const [workOrderId, setWorkOrderId] = useState<string | null>(null);
  const [isShiftDetailsLoading, setIsShiftDetailsLoading] = useState<boolean>(false);
  const [jobCustodian, setJobCustodian] = useState<string | null>(null);
  const [scheduleId, setScheduleId] = useState<string>('');
  const [shiftData, setShiftData] = useState<any[]>([]);
  const [schedulePunchInResponse, setSchedulePunchInResponse] = useState<SchedulePunchInRecords[]>([]);
  const [schedules, setSchedules] = useState<ISchedulesByDate[]>([]);
  const [isStartTimeSelectOpen, setStartTimeSelectOpen] = useState(false);
  const [isEndTimeSelectOpen, setEndTimeSelectOpen] = useState(false);

  const currentDate = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(currentDate.getDate() + 1);
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const authValue = localStorage.getItem('auth');
  let role: UserType;
  if (authValue !== null) {
    const authData = JSON.parse(authValue);
    role = authData?.attributes?.['custom:Role'];
  } else {
    role = UserType.NoUser;
  }
  const { isDistrictManager } = getAuthData();

  const {
    handleApprovalAll,
    handleRejectAll,
    handleReject,
    handleApprove,
    setSelectedTaskId,
    selectedTaskId,
    setSnackBarStatus,
    snackBarStatus,
    isApproveLoading,
  } = useAuth();

  const noDataImageStyles: React.CSSProperties = {
    width: '20rem',
  };

  const getTypeCount = (proofs: any) => {
    let photo = 0,
      video = 0,
      audio = 0;
    proofs?.forEach((dataItem: any) => {
      if (
        dataItem?.mdAttachmentType === mdAttachmentType.Photo ||
        dataItem?.mdAttachmentType === mdAttachmentType.Image
      ) {
        photo++;
      } else if (dataItem?.mdAttachmentType === mdAttachmentType.Video) {
        video++;
      } else if (dataItem?.mdAttachmentType === mdAttachmentType.Audio) {
        audio++;
      }
    });
    return {
      photo: photo,
      audio: audio,
      video: video,
    };
  };

  const getOtjJobTaskStatus = (status: TaskStatus) => {
    if (vendorTypes?.[0]?.mdVendorType === VendorType.SelfPerform && status === TaskStatus.Complete) {
      return TaskStatus.PendingPrimeVendorApproval;
    } else if (
      vendorTypes?.[0]?.mdVendorType === VendorType.SelfPerform &&
      status === TaskStatus.ApprovedByPrimeVendor
    ) {
      return TaskStatus.ApprovedByPrimeVendor;
    } else if (
      vendorTypes?.[0]?.mdVendorType !== VendorType.SelfPerform &&
      status === TaskStatus.ApprovedByPrimeVendor
    ) {
      return TaskStatus.ApprovedByPrimeVendor;
    } else if (vendorTypes?.[0]?.mdVendorType !== VendorType.SelfPerform && status === TaskStatus.Complete) {
      return 'PendingSupervisorApproval'; //TaskStatus.PendingPrimeVendorApproval;
    } else if (
      vendorTypes[0]?.mdVendorType !== VendorType.SelfPerform &&
      status === TaskStatus.PendingPrimeVendorApproval
    ) {
      return TaskStatus.PendingPrimeVendorApproval;
    }
    return status;
  };

  const getTaskStatus = (isOtjJob: boolean, status: TaskStatus, jobCustodian: string | null) => {
    if (isOtjJob || jobCustodian) {
      return getOtjJobTaskStatus(status);
    } else {
      if (status === TaskStatus.Complete) {
        return 'PendingSupervisorApproval'; // TaskStatus.PendingSupervisorApproval;
      }
    }
    return status;
  };

  const getCompletedTasksCount = (tasks: any, reworkTaskIdCheck: boolean) => {
    return tasks.filter(
      (task: any) =>
        task.markedAsDone === WorkOrderYesNoType.Yes &&
        task.status !== TaskStatus.ReworkRequestedByCustomer &&
        task.status !== TaskStatus.Rework &&
        task.status !== TaskStatus.Scheduled &&
        task.status !== TaskStatus.InProgress &&
        task.status !== TaskStatus.OnHold &&
        task.status !== TaskStatus.Rejected &&
        task.status !== TaskStatus.RejectedByCustomer &&
        task.status !== TaskStatus.RejectedByPrimeVendor &&
        task.status !== TaskStatus.RejectedBySupervisor &&
        (reworkTaskIdCheck ? !!task.reworkTaskId : !task.reworkTaskId)
    ).length;
  };

  const getTotalTasksPerService = (tasks: any, reworkTaskCheck: boolean) => {
    return tasks.filter(
      (task: any) => task.status !== TaskStatus.Rework && (reworkTaskCheck ? !!task.reworkTaskId : !task.reworkTaskId)
    ).length;
  };

  const getTasksDetails = (
    tasksV2Response: any,
    reworkTaskCheck: boolean,
    jobCustodian: string | null,
    isOtjJob: boolean
  ) => {
    const taskDetails = tasksV2Response.map((service: any) => {
      return {
        category: service?.categoryDescription,
        categoryTitle: service?.serviceCategoryName,
        id: service?.mdServiceId,
        serviceImage: service?.categoryImageUrl,
        taskCompleted: reworkTaskCheck
          ? getCompletedTasksCount(service?.tasks, true)
          : getCompletedTasksCount(service?.tasks, false),
        totalScheduledTasks: service?.tasks?.length,
        totalTasks: getTotalTasksPerService(service?.tasks, reworkTaskCheck),
        options: service?.tasks
          .filter((task: any) =>
            reworkTaskCheck
              ? !!task?.reworkTaskId && task?.status !== TaskStatus.Rework
              : !task?.reworkTaskId && task?.status !== TaskStatus.Rework
          )
          .map((task: any, taskIndex: number) => {
            return {
              ...task,
              actualEndTime: task?.actualEndTime,
              actualStartTime: task?.actualStartTime,
              associateId: task?.associateId,
              comments: '',
              floor: '',
              isTaskCompleted:
                task.markedAsDone === WorkOrderYesNoType.Yes &&
                task.status !== TaskStatus.ReworkRequestedByCustomer &&
                task.status !== TaskStatus.Rework &&
                (reworkTaskCheck ? !!task?.reworkTaskId : !task?.reworkTaskId),
              no: taskIndex + 1,
              proof: task?.taskAttachments?.length,
              proofTypes: task?.taskAttachments?.length
                ? getTypeCount(task?.taskAttachments)
                : { photo: 0, audio: 0, video: 0 },
              proofattachments: task?.taskAttachments,
              status: getTaskStatus(isOtjJob, task?.status, jobCustodian),
              taskId: task?.taskId,
              taskName: task?.taskName,
              timeTaken: getTaskDuration(task?.duration ?? 0),
              workDayId: task?.workDayId,
            };
          }),
      };
    });
    return taskDetails.filter((service: any) => !isEmpty(service.options));
  };

  const getRoletoApprove = (parentId: string, hasChild: boolean, jobCustodian: string | null) => {
    const { customerId } = getStoredCustomerDetails();
    // if (parentId || hasChild) {
    //   setApproveByRole(ApproverType.PrimeVendor);
    // }
    if (jobCustodian && jobCustodian === customerId) {
      setApproveByRole(ApproverType.PrimeVendor);
    }

    return null;
  };

  const fetchTasks = useCallback(
    async (date: any, workorderResponse: any, schedulesByDateResponse: any) => {
      try {
        setIsViewLoading(true);
        if (workorderResponse?.data?.length) {
          setWorkOrderEndDate(new Date(workorderResponse?.data?.[0]?.actualEndDate));
          const workOrderId = workorderResponse?.data?.[0]?.workOrderId;
          const subWorkOrderId = workorderResponse?.data?.[0]?.subWorkOrders.length
            ? workorderResponse?.data?.[0]?.subWorkOrders?.[0]?.workOrderId
            : null;
          const { parentWorkOrderId, hasChild } = workorderResponse?.data?.[0] || {};
          setIsOtjJob(
            !!workorderResponse?.data?.[0]?.jobCustodian && workorderResponse?.data?.[0]?.jobType != 'Recurring'
          );
          setJobCustodian(workorderResponse?.data?.[0]?.jobCustodian);
          getRoletoApprove(parentWorkOrderId, hasChild, workorderResponse?.data?.[0]?.jobCustodian);

          const isOtjJob =
            !!workorderResponse?.data?.[0]?.jobCustodian && workorderResponse?.data?.[0]?.jobType != 'Recurring';

          const scheduleTaskResponse = await Promise.all(
            schedulesByDateResponse?.data.map(async (schedule: any) => {
              const tasksV2Response = await WorkOrdersApiService.getTasksV2(
                {
                  workOrderId: subWorkOrderId || workOrderId,
                  workDayId: schedule?.workOrderScheduleId,
                },
                false
              );
              if (tasksV2Response.data && isEmpty(tasksV2Response.errors)) {
                const taskDetails = getTasksDetails(
                  tasksV2Response?.data,
                  false,
                  workorderResponse?.data?.[0]?.jobCustodian,
                  isOtjJob
                );
                return {
                  ...schedule,
                  services: [...taskDetails],
                };
              }
            })
          );
          if (scheduleTaskResponse[0]?.services?.length) {
            setSelectedAccordionID(scheduleTaskResponse?.[0]?.woScheduleMasterId);
          }
          setSchedules(scheduleTaskResponse);
        }
        setIsViewLoading(false);
      } catch (error) {
        console.error('Error', error);
        setIsAPIError(true);
        setIsViewLoading(false);
      }
    },
    [vendorTypes]
  );

  const updateShiftDetailsOptions = async (
    fromDate: string,
    workorderResponse: any,
    schedulesByDateResponse: any
  ): Promise<void> => {
    setIsShiftDetailsLoading(true);
    try {
      if (workorderResponse.data && !isEmpty(workorderResponse.data)) {
        const workOrderId = workorderResponse?.data?.[0]?.workOrderId;
        const subWorkOrderId = workorderResponse?.data?.[0]?.subWorkOrders.length
          ? workorderResponse?.data?.[0].subWorkOrders[0]?.workOrderId
          : null;
        setWorkOrderId(workOrderId);
        setSubWorkOrderId(subWorkOrderId);
        const { hasChild } = workorderResponse?.data?.[0] || {};
        setIsViewOnly(hasChild);

        const schedulePunchInResponse = await Promise.all(
          schedulesByDateResponse?.data.map(async (schedule: any) => {
            if (schedule?.workOrderScheduleId) {
              const workDayPunchInRecords = await WorkOrdersApiService.getAllPunchInOutByWorkDay({
                userId: 'all',
                workDayId: schedule?.workOrderScheduleId ?? '',
              });
              return {
                ...schedule,
                workDayPunchInRecords: workDayPunchInRecords?.data,
              };
            }
          })
        );
        setSchedulePunchInResponse(schedulePunchInResponse);
      }
    } catch (e: any) {
      console.log(e);
    }
    setIsShiftDetailsLoading(false);
  };

  const handleRescheduleWorkDayResponse = (rescheduleWorkDaysResponse: any): void => {
    if (
      !isEmpty(rescheduleWorkDaysResponse?.data?.rescheduleFailure ?? []) &&
      isEmpty(rescheduleWorkDaysResponse?.errors ?? [])
    ) {
      const errorMsg = `${t('rescheduleShiftDate:failedToRescheduleShift')}. ${t(getRescheduleShiftErrorMessage(rescheduleWorkDaysResponse?.data?.rescheduleFailure?.[0]?.errorCode ?? ''))}`;
      setSnackbarMessage(errorMsg);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (
      !isEmpty(rescheduleWorkDaysResponse?.data?.rescheduleSuccess ?? []) &&
      isEmpty(rescheduleWorkDaysResponse?.data?.rescheduleFailure ?? []) &&
      isEmpty(rescheduleWorkDaysResponse?.errors ?? [])
    ) {
      setSnackbarMessage(t('rescheduleShiftDate:shiftReschedulingSuccessMsg'));
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      const formattedDate = new Date(specificDate).toISOString().split('T')[0];
      fetchShiftAndTaskDetails(formattedDate);
    } else if (!isEmpty(rescheduleWorkDaysResponse?.errors ?? [])) {
      setSnackbarMessage(rescheduleWorkDaysResponse?.errors[0]?.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleOnUpdateShiftDate = async (duration: any): Promise<void> => {
    setIsShowUpdateShiftDateLoading(true);
    try {
      let newDate = moment(duration?.shiftDate).format('YYYY-MM-DDT00:00:00.000');
      newDate = moment(newDate).format('YYYY-MM-DD');

      const rescheduleWorkDayInput: RescheduleWorkDayInput = {
        newDate: newDate,
        workDayId: scheduleId ? scheduleId : '',
        startTime: !isEmpty(duration?.startTime) ? `${duration?.startTime?.hours}:${duration?.startTime?.minutes}` : '',
        endTime: !isEmpty(duration?.endTime) ? `${duration?.endTime?.hours}:${duration?.endTime?.minutes}` : '',
        endDateOffset: Number(duration?.offsetDays),
      };

      const mSubWorkOrderId = subWorkOrderId ?? '';
      const mWorkOrderId = workOrderId ?? '';
      const rescheduleWorkDaysResponse = await WorkOrdersApiService.rescheduleWorkDays(
        isViewOnly ? mSubWorkOrderId : mWorkOrderId,
        [rescheduleWorkDayInput]
      );
      handleRescheduleWorkDayResponse(rescheduleWorkDaysResponse);
      setOpenUpdateShiftDate(false);
    } catch (e: any) {
      console.log(e);
      setOpenUpdateShiftDate(false);
    } finally {
      setIsShowUpdateShiftDateLoading(false);
      setOpenUpdateShiftDate(false);
    }
  };

  const dataFiltering = useCallback(() => {
    let filteredData: IServices[] = [];
    if (schedule === 'all') {
      filteredData = originalServices;
    } else {
      filteredData = originalServices.filter((dataItem) => {
        if (schedule === 'daily') {
          return (dataItem?.shiftTiming as ShiftType) === ShiftType.Daily;
        } else if (schedule === 'weekly') {
          return (dataItem?.shiftTiming as ShiftType) === ShiftType.Weekly;
        } else if (schedule === 'monthly') {
          return (dataItem?.shiftTiming as ShiftType) === ShiftType.Monthly;
        }
        return false;
      });
    }

    return filteredData;
  }, [schedule, originalServices, expanded]);

  const handleAccordion = (panel: number, id: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setSelectedAccordionID(id);
    setExpanded(newExpanded ? panel : false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const showApproveOrRejectButtons = (status: string): boolean => {
    return (
      (role === UserType.Customer && (status as TaskStatus) === TaskStatus.PendingCustomerReview) ||
      (role === UserType.Vendor &&
        isDistrictManager &&
        (status as TaskStatus) === TaskStatus.PendingPrimeVendorApproval) ||
      (role === UserType.Vendor && !isDistrictManager && status === 'PendingSupervisorApproval') //TaskStatus.PendingSupervisorApproval))
    );
  };

  const getTaskType = (reworkTaskId: any): TaskType => {
    return reworkTaskId ? TaskType.ReworkTask : TaskType.SagaTask;
  };

  const isOtjSelfPerformJob = (): boolean => {
    return isOtjJob && vendorTypes[0]?.mdVendorType === VendorType.SelfPerform;
  };

  const renderActionsCellContent = (row: any): JSX.Element => {
    const status = row?.original?.status;
    const taskId = row?.original?.taskId;

    return (
      <ActionsColumn>
        {showApproveOrRejectButtons(status) ? (
          <>
            {taskId === rowInfo?.taskId && isApproving ? (
              <Loader>
                <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
              </Loader>
            ) : (
              <ActionIcon
                onClick={() => {
                  setIsOpenApprove(true);
                  setRowInfo(row?.original);
                  const taskType = getTaskType(row?.original?.reworkTaskId);
                  setSelectedTaskType(taskType);
                }}
                src={Icons.TickIcon}
                alt={t('altTexts:approve')}
              />
            )}
            {/* {renderRejectButton(taskId, row)} */}
          </>
        ) : null}
        {role === UserType.Vendor || role === UserType.Customer ? (
          <>
            <IconButton
              sx={{ padding: '0px' }}
              onClick={(e) => {
                handleClick(e);
                setRowInfo(row.original);
              }}
              size="large"
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}>
              <MoreVertIcon></MoreVertIcon>
            </IconButton>
            <MenuWrapper anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose} onClick={handleClose}>
              <Linking to="/task-details" state={{ taskRecord: rowInfo, vendorTypes, approveByRole }}>
                <Menus>
                  <img src={Icons.EyeIcon} alt={t('altTexts:viewDetails')} /> View details
                </Menus>
              </Linking>
              {/* <Menus onClick={() => setIsOpenApprove(true)}>
                <img src={Icons.TickIcon} alt={t('altTexts:approve')} /> Approve
              </Menus>
              <Menus onClick={() => setIsOpenReject(true)}>
                <img src={Icons.CancelIcon} alt={t('altTexts:reject')} /> Reject
              </Menus> */}
            </MenuWrapper>
          </>
        ) : null}
      </ActionsColumn>
    );
  };

  const columns: MRT_ColumnDef<TaskData | IJobOverview | IJobs | IBidList | IProfileCardProps>[] = [
    {
      accessorKey: 'no',
      header: 'No.',
      enablePinning: false, //disable column pinning for this column
      enableSorting: false,
      size: 10,
      enableColumnFilter: false,
      enableColumnActions: false,
    },
    {
      accessorKey: 'categoryTitle',
      header: t('tableHeader:serviceName'),
      enableSorting: true,
      enableColumnFilter: false,
      size: 140,
      Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : '-'),
      enableColumnActions: true,
    },
    {
      accessorKey: 'taskName',
      header: t('tableHeader:task'),
      enableSorting: true,
      enableColumnFilter: false,
      size: 140,
      Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : '-'),
      enableColumnActions: true,
    },
    {
      accessorKey: 'status',
      header: t('tableHeader:status'),
      enableSorting: true,
      enableColumnFilter: false,
      size: 162,
      Cell: ({ renderedCellValue }) => {
        let selectedColor = theme.palette.text.secondary;
        if (
          renderedCellValue === 'Issue raised' ||
          renderedCellValue === 'RejectedBySupervisor' ||
          renderedCellValue === 'RejectedByCustomer'
        ) {
          selectedColor = theme.palette.error.main;
        } else if (renderedCellValue === 'ApprovedByCustomer') {
          selectedColor = theme.palette.success.main;
        }
        if (renderedCellValue) {
          return <span style={{ color: selectedColor }}>{convertToReadableFormat(renderedCellValue)}</span>;
        } else {
          return '-';
        }
      },
    },
    {
      accessorKey: 'proof',
      header: t('tableHeader:proof'),
      enableSorting: false,
      enableColumnFilter: false,
      size: 100,
      Cell: ({ renderedCellValue }) =>
        renderedCellValue || renderedCellValue === 0 ? (
          <span style={{ color: theme.palette.primary.dark }}>{renderedCellValue}</span>
        ) : (
          '-'
        ),
      enableColumnActions: false,
    },
    {
      header: t('tableHeader:actions'),
      enableColumnActions: false,
      enablePinning: false,
      size: 130,
      Cell: ({ row }) => renderActionsCellContent(row),
    },
  ];

  const onChangeRow = useCallback(
    (rowSelection: any, data: TaskData[], service: IServices) => {
      const allRowSelected = Object.keys(rowSelection);
      const taskIds: any = data.filter(
        (dataItem, index) => allRowSelected?.includes(index.toString()) && dataItem.taskId
      );
      //Adding timeout to avoid re rendering because fraction of second allRowSelected and taskIds become empty
      // and because of that approve and reject button gives jerk
      setTimeout(() => {
        setSelectedTaskId(taskIds);
        const isShow = allRowSelected.length && data.length && allRowSelected.length === data.length ? true : false;
        const updatedServiceList = serviceList.map((dataItem) => {
          if (service.id === dataItem.id) {
            return {
              ...dataItem,
              isShowButton: isShow,
              selectedRowCount: taskIds.length,
            };
          }
          return dataItem;
        });
        setServiceList(updatedServiceList);
      }, 0);
    },
    [serviceList]
  );

  const getApproveStatusByRole = (status: TaskStatus) => {
    if (status === TaskStatus.PendingPrimeVendorApproval) {
      return TaskStatus.ApprovedByPrimeVendor;
    } else if (status === TaskStatus.PendingCustomerReview) {
      return TaskStatus.ApprovedByCustomer;
    } else {
      return TaskStatus.ApprovedBySupervisor;
    }
  };

  const getRejectStatusByRole = (
    status: TaskStatus
  ): TaskStatus.RejectedBySupervisor | TaskStatus.RejectedByCustomer | TaskStatus.RejectedByPrimeVendor => {
    if (status === TaskStatus.PendingPrimeVendorApproval) {
      return TaskStatus.RejectedByPrimeVendor;
    } else if (status === TaskStatus.PendingCustomerReview) {
      return TaskStatus.RejectedByCustomer;
    } else {
      return TaskStatus.RejectedBySupervisor;
    }
  };

  const handleRejectTaskClose = (): void => {
    setOpenRejectTask(false);
    setRejectionComment('');
    setProofs([]);
    setIsPartiallyCompleted(false);
  };

  const handleRejectTask = (): void => {
    setOpenRejectTask(false);
    if (rowInfo.isCurrentShiftAvailable) {
      setReworkShift(TaskScheduleType.CurrentShift);
      setReworkScheduleDate(new Date().toISOString());
    } else if (rowInfo.nextAvailableWorkDay && !rowInfo.isCurrentShiftAvailable) {
      setReworkShift(TaskScheduleType.NextWorkDay);
      setReworkScheduleDate(rowInfo?.nextAvailableWorkDay?.workDate);
    } else {
      setReworkShift(TaskScheduleType.SpecificDay);
      setReworkScheduleDate(tomorrow.toISOString());
    }
    setOpenRequestRework(true);
  };

  const handleDeleteProofs = (proofId: string): void => {
    setProofs(proofs.filter((proof: IProofsProps) => proof.id !== proofId));
  };

  const handleRequestReworkClose = (): void => {
    setOpenRequestRework(false);
    setRejectionComment('');
    setProofs([]);
    setIsPartiallyCompleted(false);
  };

  const storeAttachmentsToS3Cloud = async (proofs: IProofsProps[] = []) => {
    try {
      const failedAttachmentIds: any = [];
      const successAttachments: any = [];
      await Promise.allSettled(
        proofs.map(async (fileItem: IProofsProps, index: number) => {
          const uniqueAttachmentId = uuidv4();
          const uploadDocumentResponse = await uploadDocumentToS3Bucket(
            navigate,
            `fulfillment/workOrders/${rowInfo?.workOrderId}/${rowInfo?.workDayId}/${rowInfo?.taskId}/attachment-${
              index + 1
            }-${uniqueAttachmentId}.${fileItem?.fileExtension}`,
            fileItem.url ?? '',
            {
              level: 'public',
            },
            fileItem?.fileExtension
          );
          successAttachments.push({ key: uploadDocumentResponse?.file?.key || '', id: fileItem?.id });
        })
      );
      return { successAttachments, failedAttachmentIds };
    } catch (e) {
      console.log('upload attachments error: ', e);
      return { successAttachments: [], failedAttachmentIds: [] };
    }
  };

  const getProofAttachmnetFiles = (successAttachments: any): CreateTaskAttachmentInput[] => {
    let files: CreateTaskAttachmentInput[] = proofs.map((fileItem: IProofsProps) => {
      const s3BucketUrl = successAttachments?.find((file: any) => file?.id === fileItem?.id) ?? '';

      return {
        attachmentStatus: role === UserType.Customer ? AttachmentStatus.ReworkRequested : AttachmentStatus.Rework,
        mdAttachmentType: fileItem.mediaType === DocumentType.Video ? mdAttachmentType.Video : mdAttachmentType.Image,
        fileName: fileItem?.fileName ?? '',
        mdFileContext: role === UserType.Customer ? mdFileContext.ProofOfReworkRequested : mdFileContext.ProofOfRework,
        fileExtension: fileItem?.fileExtension as string,
        url: s3BucketUrl?.key ?? '',
        comments: fileItem?.comment,
      };
    });
    files = files.filter((attachment) => !isEmpty(attachment?.url));
    return files;
  };

  const handleUpdateTaskResponse = (updateTasksResposne: { data: { errorCode: string }[] }): void => {
    if (
      updateTasksResposne.data.some(
        (task: { errorCode: string }) => task.errorCode && task.errorCode === 'TASK_ALREADY_RESCHEDULED'
      )
    ) {
      setSnackbarMessage('selected task is already rescheduled for rework');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else {
      const successMsg =
        role === UserType.Customer
          ? t('taskRejectionAndRework:reworkRequestedSuccessMsg')
          : t('taskRejectionAndRework:reworkScheduledSuccessMsg');
      setSnackbarMessage(successMsg);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  };

  const handleRequestRework = async (): Promise<void> => {
    setIsReworkLoading(true);
    try {
      if (proofs.length) {
        const s3BucketUploadedResponse = await storeAttachmentsToS3Cloud(proofs);
        const { successAttachments = [] } = s3BucketUploadedResponse || {
          successAttachments: [],
          failedAttachmentIds: [],
        };

        const files = getProofAttachmnetFiles(successAttachments);

        if (!isEmpty(rowInfo.taskAttachments)) {
          rowInfo.taskAttachments.forEach((attachmet: any) => {
            files.push({
              attachmentStatus: attachmet.attachmentStatus,
              mdAttachmentType: attachmet.mdAttachmentType,
              fileName: attachmet.fileName,
              mdFileContext: attachmet.mdFileContext,
              fileExtension: attachmet.fileExtension,
              url: attachmet.url,
              comments: attachmet.comments,
            });
          });
        }

        const addTaskAttachmentsResponse = await WorkOrdersApiService.addTaskAttachments(rowInfo.taskId, files);
        if (!isEmpty(addTaskAttachmentsResponse.errors)) {
          console.log('error while uploading the attachments: ', addTaskAttachmentsResponse.errors);
          const errorMsg = t('taskRejectionAndRework:addTaskAttachmentsErrorMsg');
          setSnackbarMessage(errorMsg);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      }

      const updateTaskPayload: UpdateTaskInput = getUpdateTaskPayload();

      const updateTasksResposne = await WorkOrdersApiService.updateMultipleTasksAtOnce([{ ...updateTaskPayload }]);
      if (updateTasksResposne.data && isEmpty(updateTasksResposne.errors)) {
        handleUpdateTaskResponse(updateTasksResposne);
      }
    } catch (error) {
      console.log('error occured while requesting or scheduling task for rework: ', error);
      const errorMsg =
        role === UserType.Customer
          ? t('taskRejectionAndRework:reworkRequestedErrorMsg')
          : t('taskRejectionAndRework:reworkScheduledErrorMsg');
      setSnackbarMessage(errorMsg);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    setOpenRequestRework(false);
    setRejectionComment('');
    setProofs([]);
    setIsPartiallyCompleted(false);
    setIsReworkLoading(false);
    const formattedDate = new Date(specificDate).toISOString().split('T')[0];
    fetchShiftAndTaskDetails(formattedDate);
  };

  const getUpdateTaskPayload = (): UpdateTaskInput => {
    const taskApprovalpayload: CreateTaskApprovalInput = {
      approvalName: ApprovalType.TaskApproval,
      status: role === UserType.Customer ? TaskStatus.ReworkRequestedByCustomer : TaskStatus.Rework,
      approvalStatus: role === UserType.Customer ? ApprovalStatus.ReworkRequested : ApprovalStatus.Rework,
      mdApproverType: role === UserType.Customer ? ApproverType.Customer : ApproverType.Vendor,
    };
    if (!isEmpty(rejectionComment)) {
      taskApprovalpayload.reason = rejectionComment;
    }

    const updateTaskPayload: UpdateTaskInput = {
      taskId: rowInfo.taskId,
      associateId: rowInfo.associateId,
      isPartiallyCompleted: isPartiallyCompleted,
      taskApprovalInput: [{ ...taskApprovalpayload }],
    };
    if (role === UserType.Customer) {
      updateTaskPayload.suggestedReworkDate = reworkScheduleDate;
      updateTaskPayload.taskStatus = TaskStatus.ReworkRequestedByCustomer;
      updateTaskPayload.suggestedScheduleType = reworkShift;
    } else {
      updateTaskPayload.reworkDate = reworkScheduleDate;
      updateTaskPayload.taskStatus = TaskStatus.Rework;
    }

    return updateTaskPayload;
  };

  const handleCustomerApproval = async (tasks: Task[]): Promise<void> => {
    setSendingForApproval(true);
    try {
      const updateTaskInputPayload: UpdateTaskInput[] = [];
      tasks?.forEach((task: Task) => {
        updateTaskInputPayload.push({
          taskId: task.taskId,
          taskStatus: isOtjJob ? TaskStatus.PendingPrimeVendorApproval : TaskStatus.PendingCustomerReview,
        });
      });

      const updateTasksResponse = await WorkOrdersApiService.updateMultipleTasksAtOnce(updateTaskInputPayload);
      if (isEmpty(updateTasksResponse.errors)) {
        const successMsg = isOtjJob
          ? t('dashboard:sendPrimeVendorApprovalSuccessMsg')
          : t('dashboard:sendCustomerApprovalSuccessMsg');
        setSnackbarMessage(successMsg);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else {
        const errorMsg = isOtjJob
          ? t('dashboard:sendPrimeVendorApprovalErrorMsg')
          : t('dashboard:sendCustomerApprovalErrorMsg');
        setSnackbarMessage(errorMsg);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error(error);
    }
    setSendingForApproval(false);
    const formattedDate = new Date(specificDate).toISOString().split('T')[0];
    fetchShiftAndTaskDetails(formattedDate);
  };

  const calculateTaskCompleted = (schedules: any[]) => {
    return schedules.map((schedule) => {
      let totalTasksCount = 0;
      let taskCompletedCount = 0;
      schedule?.services?.forEach((service: { totalTasks: any; taskCompleted: any }) => {
        totalTasksCount = totalTasksCount + service?.totalTasks;
        taskCompletedCount = taskCompletedCount + service?.taskCompleted;
      });
      return {
        ...schedule,
        totalTasks: totalTasksCount,
        taskCompleted: taskCompletedCount,
      };
    });
  };

  const updatedSchedules = calculateTaskCompleted(schedules);

  const renderOptionsData = (services: any[]) => {
    let taskCounter = 1;
    return services?.flatMap((category) =>
      category?.options?.map((option: any) => ({
        ...option,
        categoryTitle: category?.categoryTitle,
        no: taskCounter++,
      }))
    );
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderTasksData = (reworkTaskCheck: boolean) => {
    const schedules = updatedSchedules;
    let index = 0;
    return (
      <>
        <TitleWrapper sx={{ marginTop: reworkTaskCheck ? '2rem' : '0rem' }}>
          <Services>{t(`taskRejectionAndRework:${reworkTaskCheck ? 'reworkTasks' : 'services'}`)}</Services>
        </TitleWrapper>
        {isViewLoading ? (
          <Loader>
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
          </Loader>
        ) : schedules?.length ? (
          schedules?.map((dataItem) => {
            index = index + 1;
            const sectionTitle = !isEmpty(dataItem?.scheduleName) ? dataItem?.scheduleName : `Schedule #${index}`;
            const scheduleSubName = !isEmpty(dataItem?.scheduleSubName) ? dataItem?.scheduleSubName : '';
            return (
              <WorkTasksAccordion
                vendorTypes={vendorTypes}
                scheduleList={scheduleList}
                key={dataItem?.woScheduleMasterId}
                columns={columns}
                expanded={accordionID === dataItem?.woScheduleMasterId && expanded}
                handleAccordion={(e) => handleAccordion(e, dataItem?.woScheduleMasterId)}
                onChangeRow={onChangeRow}
                accordionObjNew={dataItem}
                accordionObj={dataItem?.services?.[0]}
                optionsData={renderOptionsData(dataItem?.services)}
                screenName={ScreenName.JobDetails}
                handleApproveAll={() => {
                  setIsOpenApproveAll(true);
                  setSnackBarStatus({ ...snackBarStatus, isOpen: false });
                }}
                handleRejectAll={() => {
                  setIsOpenRejectAll(true);
                  setSnackBarStatus({ ...snackBarStatus, isOpen: false });
                }}
                handleCustomerApproval={handleCustomerApproval}
                sendingForApproval={sendingForApproval}
                isOtjJob={isOtjJob}
                showUpdateShiftDateButton={showUpdateShiftDateButton}
                renderUpdateShiftDateButton={renderUpdateShiftDateButton}
                sectionTitle={sectionTitle}
                scheduleSubName={scheduleSubName}
              />
            );
          })
        ) : isAPIError ? (
          <ErrorMessage />
        ) : (
          <NoDataTag>{t('taskRejectionAndRework:thereAreNoSchedules')}</NoDataTag>
        )}
      </>
    );
  };

  const getUpatedTaskData = (allTasks: any[]): void => {
    if (selectedTaskType === TaskType.SagaTask) {
      setDailyTaskData(allTasks);
    } else {
      setReworkTaskData(allTasks);
    }
  };

  const renderUpdateShiftDateButton = (schedule: any): JSX.Element => {
    const showUpdateShiftDateResponse = schedulePunchInResponse?.find(
      (scheduleItem) => scheduleItem?.woScheduleMasterId === schedule?.woScheduleMasterId
    );
    const showUpdateShiftDate = showUpdateShiftDateResponse?.workDayPunchInRecords;
    return (
      <div>
        {isShiftDetailsLoading ? (
          <Loader sx={{ marginRight: '5rem' }}>
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
          </Loader>
        ) : (
          <>
            {isEmpty(showUpdateShiftDate) && (
              <>
                <WhiteTooltip
                  title={
                    schedule?.isRescheduled &&
                    `${t('rescheduleShiftDate:shiftAlreadyRescheduled')}
                  ${schedule?.rescheduledDate ? formatDate(schedule?.rescheduledDate) : ''}`
                  }
                  disableInteractive>
                  <Button
                    sx={{ ...updateShiftDateButton, opacity: schedule?.isRescheduled ? 0.5 : 1 }}
                    onClick={() => {
                      if (!schedule?.isRescheduled) {
                        setOpenUpdateShiftDate(true);
                        setScheduleId(schedule?.workOrderScheduleId);
                        setShiftData(schedule);
                      }
                    }}>
                    <Typography sx={{ ...buttonText }}>
                      {schedule?.isRescheduled
                        ? `${t('rescheduleShiftDate:alreadyRescheduled')}`
                        : t('rescheduleShiftDate:rescheduleShift')}
                    </Typography>
                  </Button>
                </WhiteTooltip>
              </>
            )}
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (reworkShift === TaskScheduleType.SpecificDay) {
      if (rowInfo.isCurrentShiftAvailable && !rowInfo.nextAvailableWorkDay) {
        setReworkScheduleDate(new Date(tomorrow).toISOString());
      } else if (rowInfo.nextAvailableWorkDay && rowInfo.nextAvailableWorkDay.workDate) {
        setReworkScheduleDate(getNextDay(rowInfo.nextAvailableWorkDay.workDate));
      } else if (!rowInfo.isCurrentShiftAvailable && !rowInfo.nextAvailableWorkDay) {
        setReworkScheduleDate(new Date(tomorrow).toISOString());
      }
    }
  }, [reworkShift]);

  useEffect(() => {
    if (originalServices.length) {
      const filteredData = dataFiltering();
      setServiceList(filteredData);
    }
  }, [schedule, originalServices, expanded]);

  const fetchShiftAndTaskDetails = async (formattedDate: string) => {
    const workorderResponse = await WorkOrdersApiService.getAllWorkOrders({ jobId: selectedJob });

    if (workorderResponse?.data?.length) {
      const workOrderId = workorderResponse?.data?.[0]?.workOrderId;
      const subWorkOrderId = workorderResponse?.data?.[0]?.subWorkOrders.length
        ? workorderResponse?.data?.[0].subWorkOrders[0]?.workOrderId
        : null;

      const workOrderScheduleId = location?.state?.isJobManagement ? location?.state?.jobData?.workOrderScheduleId : '';
      const schedulesByDateResponse = await WorkOrdersApiService.getSchedulesByDate(
        formattedDate,
        workOrderId || subWorkOrderId,
        workOrderScheduleId
      );
      updateShiftDetailsOptions(formattedDate, workorderResponse, schedulesByDateResponse);
      fetchTasks(formattedDate, workorderResponse, schedulesByDateResponse);
    }
  };

  useEffect(() => {
    const formattedDate = new Date(specificDate).toISOString().split('T')[0];
    if (vendorTypes?.length) {
      fetchShiftAndTaskDetails(formattedDate);
    }
  }, [specificDate, selectedJob, selectedFacility, isOpenRejectAll, isOpenApproveAll, handleApprove, vendorTypes]);

  useEffect(() => {
    if (snackBarStatus.isOpen) {
      const taskData = selectedTaskType === TaskType.SagaTask ? [...dailyTaskData] : [...reworkTaskData];
      const allTasks = [...taskData].map((serviceCategory) => {
        const allOptions = serviceCategory.options.map((optionItem: any) => {
          if (optionItem.taskId === rowInfo.taskId && snackBarStatus.isApproved) {
            return {
              ...optionItem,
              status: getApproveStatusByRole(optionItem.status),
            };
          }
          if (optionItem.taskId === rowInfo.taskId && !snackBarStatus.isApproved) {
            return {
              ...optionItem,
              status: getRejectStatusByRole(optionItem.status),
            };
          }
          return {
            ...optionItem,
          };
        });
        return {
          ...serviceCategory,
          options: allOptions,
        };
      });
      setIsApproving(false);
      getUpatedTaskData(allTasks);
    }
  }, [snackBarStatus.isOpen]);

  const showUpdateShiftDateButton = () => {
    const { customerId } = getStoredCustomerDetails();
    const otjSecondFlowCheck =
      isOtjJob &&
      jobCustodian &&
      (vendorTypes[0]?.mdVendorType === VendorType.All || vendorTypes[0]?.mdVendorType === VendorType.SearchVendors) &&
      jobCustodian !== customerId;
    const otjThirdFlowCheck =
      isOtjJob &&
      jobCustodian &&
      vendorTypes?.[0]?.mdVendorType === VendorType.SubVendor &&
      jobCustodian === customerId;

    return (
      role !== UserType.Customer && (!isOtjJob || (!isOtjSelfPerformJob() && (otjSecondFlowCheck || otjThirdFlowCheck)))
    );
  };

  return (
    <div>
      <DropdownWrapper sx={{ justifyContent: 'flex-end', marginTop: '2rem' }}>
        <DatePickerWrapper
          sx={{
            maxWidth: '13rem',
            '& .MuiOutlinedInput-root': {
              '& .MuiInputBase-input': {
                padding: '0.5625rem 0 0.5625rem 1rem',
                color: theme.palette.primary.dark,
              },
            },
          }}>
          <DateSelection
            requiredText="Date is mandatory"
            control={control}
            fieldName={'specificDate'}
            label={t('taskRejectionAndRework:selectDate')}
            isExcludePastDate={false}
            showSpecificRangeDates={{
              from: new Date(jobStartDate),
              to: new Date(jobEndDate),
            }}
            textFieldStyles={{
              '& .MuiOutlinedInput-root': {
                '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
                  borderWidth: 1,
                  borderColor: theme.palette.primary.dark,
                  borderRadius: '1rem',
                },
              },
              '& .MuiInputLabel-root': {
                color: theme.palette.primary.dark,
                '&.Mui-focused': {
                  color: theme.palette.primary.dark,
                },
              },
            }}
          />
        </DatePickerWrapper>
      </DropdownWrapper>
      {isViewLoading ? (
        <Loader>
          <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
        </Loader>
      ) : (
        <>
          {!isEmpty(schedules) ? (
            renderTasksData(false)
          ) : (
            <NoPendingTaskContainer>
              <img src={Icons.ErrorCautionImg} style={{ ...noDataImageStyles }} />
              <NoDataTag>{t('taskRejectionAndRework:thereAreNoSchedules')}</NoDataTag>
            </NoPendingTaskContainer>
          )}
        </>
      )}
      <ApproveDialog
        isApproveWindowOpen={isOpenApprove || isOpenApproveAll}
        isApproveLoading={isApproveLoading}
        handleCloseApproveWindow={() => {
          setIsOpenApprove(false);
          setIsOpenApproveAll(false);
          setIsApproving(false);
        }}
        handleApprove={() => {
          if (isOpenApproveAll) {
            handleApprovalAll();
          } else {
            if (Object.keys(rowInfo).length > 0) handleApprove(rowInfo?.taskId, approveByRole, vendorTypes);
          }
          setIsOpenApprove(false);
          setIsOpenApproveAll(false);
          setIsApproving(true);
        }}
        rowDetails={rowInfo}
        isShowApproveAll={isOpenApproveAll && selectedTaskId.length > 1}
      />
      <RejectDialog
        isRejectWindowOpen={isOpenReject || isOpenRejectAll}
        handleCloseRejectWindow={() => {
          setIsOpenReject(false);
          setIsOpenRejectAll(false);
        }}
        handleReject={(vendorMsg: string) => {
          if (isOpenRejectAll) {
            handleRejectAll(vendorMsg);
          } else {
            if (Object.keys(rowInfo).length > 0) handleReject(rowInfo?.taskId, vendorMsg, approveByRole);
          }
          setIsOpenReject(false);
          setIsOpenRejectAll(false);
        }}
        isShowRejectAll={isOpenRejectAll && selectedTaskId.length > 1}
      />
      {openRejectTask && (
        <RejectWorkTask
          openRejectTask={openRejectTask}
          handleRejectTaskClose={handleRejectTaskClose}
          taskName={`${rowInfo.taskName}-Floor ${rowInfo.floor ? rowInfo.floor : 0}`}
          rejectionComment={rejectionComment}
          setRejectionComment={setRejectionComment}
          isPartiallyCompleted={isPartiallyCompleted}
          setIsPartiallyCompleted={setIsPartiallyCompleted}
          proofs={proofs}
          setProofs={setProofs}
          handleDeleteProofs={handleDeleteProofs}
          handleRequestRework={handleRejectTask}
        />
      )}
      {openRequestRework && (
        <RequestOrScheduleRework
          openRequestRework={openRequestRework}
          handleRequestReworkClose={handleRequestReworkClose}
          handleRequestRework={handleRequestRework}
          reworkShift={reworkShift}
          setReworkShift={setReworkShift}
          isCurrentShiftAvailable={rowInfo.isCurrentShiftAvailable}
          nextAvailableWorkDay={rowInfo.nextAvailableWorkDay}
          tomorrowDate={tomorrow}
          workOrderEndDate={workOrderEndDate}
          reworkScheduleDate={reworkScheduleDate}
          setReworkScheduleDate={setReworkScheduleDate}
          isDataLoading={isReworkLoading}
          proofs={proofs}
        />
      )}
      {snackBarStatus.isOpen ? (
        <SnackBar
          isApproved={snackBarStatus?.isApproved}
          taskCount={selectedTaskId?.length ? selectedTaskId?.length : 1}
          handleOnClose={() =>
            setSnackBarStatus({
              isOpen: false,
              isApproved: false,
            })
          }
        />
      ) : null}
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
      <UpdateShiftDateCard
        openUpdateShift={openUpdateShiftDate}
        handleShiftDateClose={() => setOpenUpdateShiftDate(false)}
        isDataLoading={isShowUpdateShiftDateLoading}
        handleOnConfirm={handleOnUpdateShiftDate}
        shiftData={shiftData}
        setStartTimeSelectOpen={setStartTimeSelectOpen}
        setEndTimeSelectOpen={setEndTimeSelectOpen}
        isStartTimeSelectOpen={isStartTimeSelectOpen}
        isEndTimeSelectOpen={isEndTimeSelectOpen}
      />
    </div>
  );
};

export default JobDetailsTasks;
