/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Stack, Typography } from '@mui/material';
import { MRT_SortingState } from 'material-react-table';
import { isEmpty } from 'lodash';

import AllJobsApiService from '../../../Jobs/Services/AllJobsService';
import AnalyticsApiService from '../../../../Shared/Services/AnalyticsService';
import { getStoredCustomerDetails, itemsPerPage } from '../../../../Shared/Utilities/utils';
import { CurrencySymbol, FiltersOfAllJobs, FormFieldType, JobStatuses } from '../../../../Shared/Constants/App';

import DataGrid from '../../../../Shared/Components/Common/DataGrid/DataGrid';
import { getAllJobsColumns } from './AllJobsColumns';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';

import { Icons } from '../../../../Shared/Constants/Icons';
import theme from '../../../../Shared/Themes/theme';

import { StyledPagination } from '../../../../Shared/Components/Common/CommonStyle/Pagination.Style';
import { PaginationStyle } from './AllJobs.Style';
import { SearchFieldContainer, SearchInput, CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import { ActionsColumn, Linking } from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import WhiteTooltip from '../../../../Shared/Components/Common/CommonStyle/Tooltip.Style';

const AllJobs = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [jobs, setJobs] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterValues, setFilterValues] = useState<any[]>([]);
  const [selectedJobName, setSelectedJobName] = useState<string>('');
  const [selectedJobNumber, setSelectedJobNumber] = useState<string>('');
  const [selectedLocation, setSelectedLocation] = useState<string>('');
  const [isFiltersLoading, setIsFiltersLoading] = useState(false);
  const [sortBy, setSortBy] = useState('jobName');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [newSort, setNewSort] = useState<boolean>();
  const [newSortOrder, setNewSortOrder] = useState('');
  const [searchText, setSearchText] = useState('');
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const { t } = useTranslation(['messages']);
  const { userId } = getStoredCustomerDetails() || {};

  const renderCell = useCallback((renderedCellValue: React.ReactNode): string | number => {
    // If renderedCellValue is a string or number, return it. Otherwise, return a fallback '-'
    if (typeof renderedCellValue === 'string' || typeof renderedCellValue === 'number') {
      return renderedCellValue ? renderedCellValue : '-';
    }
    if (renderedCellValue && Array.isArray(renderedCellValue)) {
      return renderedCellValue[0]?.finalQuote || renderedCellValue[0]?.finalQuote === 0
        ? `${CurrencySymbol.Dollar}${Number(renderedCellValue[0]?.finalQuote).toFixed(2)}`
        : '-';
    }
    return '-';
  }, []);

  const transformJobsData = (jobs: any[]): any[] => {
    return jobs.flatMap((job) =>
      job.schedules.map((schedule: any) => ({
        jobNumber: job.jobNumber,
        jobName: job.jobName,
        jobAddress: job.jobAddress,
        jobStatus: job.jobStatus,
        scheduleName: schedule.scheduleName,
        scheduleDays: schedule.scheduleDays,
        scheduleStartTime: schedule.scheduleStartTime,
        scheduleEndTime: schedule.scheduleEndTime,
        scheduleStartDate: schedule.scheduleStartDate,
        scheduleEndDate: schedule.scheduleEndDate,
        supervisors: schedule.supervisors,
        associates: schedule.associates,
        vendorName: job.vendorName,
        vendorAdminEmail: job.vendorAdminEmail,
        vendorAdminName: job.vendorAdminName,
        jobStartDate: job.jobStartDate,
        jobEndDate: job.jobEndDate,
        workTicketNumber: job.workTicketNumber,
        createdOn: job.createdOn,
        workOrderId: job?.workOrderId,
        workOrderScheduleId: schedule?.workOrderScheduleId,
        woScheduleMasterId: schedule.woScheduleMasterId,
        mdScheduleType: schedule.mdScheduleType,
        mdShiftType: schedule.mdShiftType,
        jobId: job.jobId,
        facilityId: job.facilityId,
        facilityName: job.buildingName,
        customerId: job.customerId,
        jobCustodian: job?.jobCustodian,
        jobType: job?.jobType,
        mdVendorType: job?.mdVendorType,
        parentWorkOrderId: job?.parentWorkOrderId,
      }))
    );
  };
  const fetchData = useCallback(
    async (
      currentPage: number,
      jobId?: string,
      jobNumber?: string,
      jobAddress?: string,
      sortBy?: any,
      sortOrder?: any,
      searchText?: string
    ) => {
      setIsLoading(true);
      try {
        let sortOrderString = sortOrder;
        if (sortOrder) {
          sortOrderString = 'desc';
        } else {
          if (sortBy) sortOrderString = 'asc';
        }

        const allJobsResponse = await AllJobsApiService.allJobs(
          currentPage,
          itemsPerPage,
          userId,
          jobId,
          jobNumber,
          jobAddress,
          sortBy || '',
          sortOrder !== undefined ? sortOrderString : '',
          searchText || ''
        );

        if (allJobsResponse?.data?.data) {
          const transformedData = transformJobsData(allJobsResponse.data.data);
          setJobs(transformedData);
          setTotalCount(allJobsResponse?.data?.metadata?.totalCount);
        } else {
          console.error(t('messages:failedToFetchJobs'));
        }
      } catch (error) {
        console.error(t('messages:errorFetchingJobs'), error);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const fetchFilterValues = useCallback(async () => {
    try {
      setIsFiltersLoading(true);
      const filterValuesResponse = await AnalyticsApiService.getFilterValues(userId);
      if (!isEmpty(filterValuesResponse?.data)) {
        setFilterValues(filterValuesResponse?.data);
      }
    } catch (error) {
      console.error(t('messages:errorFetchingFilterValues'), error);
    }
    setIsFiltersLoading(false);
  }, []);

  const handleChange = (event: any, value: number): void => {
    setCurrentPage(value);
    fetchData(value, selectedJobName, selectedJobNumber, selectedLocation, sortBy, newSort, searchText);
  };
  const myCustomSortingFn = (): void => {
    setSortBy('');
    setNewSortOrder('');
  };
  const handleSort = async (sortingObj: MRT_SortingState): Promise<void> => {
    setSorting(sortingObj);
    try {
      if (sortingObj.length) {
        const { id, desc } = sortingObj[0];
        setNewSortOrder(id);
        setNewSort(desc);
        await fetchData(currentPage, selectedJobName, selectedJobNumber, selectedLocation, id, desc, searchText);
      } else {
        setNewSortOrder('');
        setNewSort(undefined);
        await fetchData(currentPage, selectedJobName, selectedJobNumber, selectedLocation, '', '', searchText);
      }
    } catch (error) {
      console.error(t('messages:errorFetchingData'));
    }
  };

  const renderActionsCell = (row: any): JSX.Element => {
    const { facilityId, facilityName, jobId, jobAddress, customerId, workOrderScheduleId } = row.original;
    const goToDetailsPath =
      row.original.jobStatus === JobStatuses.Completed ? `/onetimejobdetails?id=${jobId}` : `/job-detail/worktasks`;
    const goToDetailsState =
      row.original.jobStatus === JobStatuses.Completed
        ? {
            facilityItem: {
              ...row.original,
            },
            jobStatus: row.original.jobStatus,
            facility: {
              ...row.original.facilityName,
              address: row.original.jobAddress,
            },
            job: {
              ...row.original,
              customerId,
            },
          }
        : {
            facilityItem: {
              facilityId,
              facilityName,
              workOrderScheduleId,
              jobAddress,
            },
            jobData: jobs[row.index],
            customerId,
            jobId,
            isJobManagement: true,
          };

    return (
      <ActionsColumn>
        <Linking to={goToDetailsPath} state={goToDetailsState} key={3}>
          <IconButton
            size="large"
            sx={{
              '&:hover': {
                background: theme.palette.primary.main,
              },
            }}>
            <WhiteTooltip title={t('homePage:viewDetails')}>
              <img src={Icons.EyeIcon} alt={t('altTexts:viewDetails')} />
            </WhiteTooltip>
          </IconButton>
        </Linking>
      </ActionsColumn>
    );
  };

  const filters = [
    {
      title: 'Job name',
      type: FormFieldType.DropDown,
      name: FiltersOfAllJobs.JobName,
      options: Array?.from(
        new Map(
          filterValues
            ?.filter((job) => job?.jobName && job?.jobId)
            ?.map((job) => [job?.jobId, { label: job?.jobName, value: job?.jobId }])
        )?.values()
      ),
    },
    {
      title: 'Job number',
      type: FormFieldType.DropDown,
      name: FiltersOfAllJobs.JobNumber,
      options: Array?.from(
        new Map(
          filterValues
            ?.filter((job) => job?.jobNumber)
            ?.map((job) => [job?.jobNumber, { label: job?.jobNumber, value: job?.jobNumber }])
        )?.values()
      ),
    },
    {
      title: 'Location',
      type: FormFieldType.DropDown,
      name: FiltersOfAllJobs.Location,
      options: Array?.from(
        new Map(
          filterValues
            ?.filter((job) => job?.jobAddress)
            ?.map((job) => [job?.jobAddress, { label: job?.jobAddress, value: job?.jobAddress }])
        )?.values()
      ),
    },
    {
      title: 'Customer',
      type: FormFieldType.DropDown,
      name: FiltersOfAllJobs.Customer,
      options: Array?.from(
        new Map(
          filterValues
            ?.filter((job) => job?.customerName && job?.customerId)
            ?.map((job) => [job.customerId, { label: job?.customerName, value: job?.customerId }])
        )?.values()
      ),
    },
  ];

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData(currentPage, selectedJobName, selectedJobNumber, selectedLocation, newSortOrder, newSort, searchText);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [currentPage, newSortOrder, newSort, searchText]);

  const handleJobNumberChanges = (filterValue: string) => {
    const value = filterValue;
    setSelectedJobNumber(value);
    fetchData(currentPage, selectedJobName, value, selectedLocation, newSortOrder, newSort, searchText);
  };

  const handleJobNameChanges = (filterValue: string) => {
    const value = filterValue;
    setSelectedJobName(value);
    fetchData(currentPage, value, selectedJobNumber, selectedLocation, newSortOrder, newSort, searchText);
  };

  const handleLocationChanges = (filterValue: string, event?: React.MouseEvent<HTMLInputElement>) => {
    const value = filterValue;
    setSelectedLocation(value);
    if (filterValue) {
      event?.preventDefault();
      (event?.target as HTMLInputElement)?.blur();
    }
    fetchData(currentPage, selectedJobName, selectedJobNumber, value, newSortOrder, newSort, searchText);
  };

  const columns = useMemo(
    () =>
      getAllJobsColumns(
        renderCell,
        renderActionsCell,
        filters,
        handleJobNumberChanges,
        isFiltersLoading,
        handleJobNameChanges,
        handleLocationChanges
      ),
    [jobs, renderCell, renderActionsCell, fetchData]
  );
  useEffect(() => {
    fetchFilterValues();
  }, []);

  return (
    <>
      <PageTitle title={t('vendor:allJobs')} />
      <CustomerContainer>
        <SearchFieldContainer sx={{ marginBottom: '1rem' }}>
          <SearchInput
            placeholder={t('oneTimeJob:search')}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <img src={Icons.SearchIcon} alt={t('altTexts:search')} />
        </SearchFieldContainer>

        <DataGrid
          columns={columns}
          data={jobs}
          enableRowSelect={true}
          enableColumnPinning={false}
          loader={isLoading}
          errorMessageTitle={t('noData:noDataYet')}
          errorMessageDescription={t('noData:comeBackLater')}
          configureColumns={true}
          sortingFns={{
            myCustomSortingFn: myCustomSortingFn,
          }}
          sortingObj={sorting}
          sort={handleSort}
          selectActions={true}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
        />
        {!isLoading && totalCount > 0 && (
          <PaginationStyle>
            <Stack spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
              <StyledPagination
                count={Math.ceil(totalCount / itemsPerPage)}
                page={currentPage}
                onChange={(event, value) => {
                  handleChange(event, value);
                  if (newSortOrder) {
                    fetchData(value, '', '', '', newSortOrder, newSort);
                  } else {
                    fetchData(value);
                  }
                }}
                color="primary"
              />
            </Stack>
            <Typography>
              {t('dashboard:totalItems')}: {totalCount}
            </Typography>
          </PaginationStyle>
        )}
      </CustomerContainer>
    </>
  );
};

export default AllJobs;
