/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useCallback, useEffect, useState } from 'react';
import { ConversationType, useOTJCommHelper } from 'otj-client-lib';
import { isEmpty } from 'lodash';

import { getAuthData, getPlaceholderText, NUMBER_OF_ITEMS_PER_PAGE } from '../../../Utilities/utils';

import { ConversationTabs, Role, UserType } from '../../../Constants/App';

import Modal from '../../../../Modules/Jobs/Utilities/Shared/Components/Modal';
import VendorApiService from '../../../Services/VendorService';
import { OrganizationService } from '../../../Services/OrganizationService';

import CreateConversationCards from './CreateConversationCards';
import { HeadingText } from './CreateConversations.Style';
import { useNavigate } from 'react-router-dom';

export type UsersListType = {
  name: string;
  id: string;
  role: string;
};

const CreateConversations = (props: any): JSX.Element => {
  const {
    loggedInUserInternalId,
    loggedInUserType,
    customerId,
    createNewConversation,
    setCreateConversation,
    conversationMode,
    organizationName,
    loggedInUserName,
    onNewConversation,
    conversationData,
    setPlaceHolder,
    setConversationName,
    users,
    jobId,
    jobName,
    isSupervisorGroup,
    isAssociateGroup,
    isAssociateGroupCreating,
  } = props;

  const [teamData, setTeamData] = useState<UsersListType[]>([]);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [checkedMembers, setCheckedMembers] = useState<UsersListType[]>([]);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [groupName, setGroupName] = useState(jobName ?? '');
  const [isCreateConversationLoading, setIsCreateConversationLoading] = useState<boolean>(false);

  const [originalTeamData, setOriginalTeamData] = useState<UsersListType[]>();
  const [isGroupAlreadyCreated, setIsGroupAlreadyCreated] = useState<boolean>(false);
  const [alreadyExistingGroupConversation, setAlreadyExistingGroupConversation] = useState<UsersListType | null>(null);
  const [previouslySelectedUsersForGroupChat, setPreviouslySelectedUsersForGroupChat] = useState<UsersListType[]>([]);
  const [addAssociateLoader, setAddAssociateLoader] = useState<boolean>(false);

  const { chatHooks } = useOTJCommHelper();

  const { createConversation, useConversations, AddUserToConversation } = chatHooks;
  const { data } = useConversations();

  const { role, isDistrictManager } = getAuthData();

  const dataExists = true;

  const navigate = useNavigate();

  const renderCreateConversationHeaderContent = useCallback(() => {
    let heading;
    if (conversationMode === ConversationTabs.DIRECT_CHAT) {
      heading = 'Select User';
    } else if (conversationMode === ConversationTabs.GROUP_CHAT) {
      heading = 'Select Users';
    }
    return <HeadingText>{heading}</HeadingText>;
  }, []);

  const AddNewMemberToAlreadyCreatedGroup = useCallback(async () => {
    try {
      setAddAssociateLoader(true);
      const alreadySelectedMembersUserIds = previouslySelectedUsersForGroupChat?.map((item) => {
        return item?.id ?? '';
      });
      const newlySelectedMembers =
        checkedMembers?.filter((item) => {
          return !alreadySelectedMembersUserIds?.includes(item?.id);
        }) ?? [];
      const userData = newlySelectedMembers?.map((checkedMember) => {
        return { id: checkedMember?.id ?? '', fullName: checkedMember?.name ?? '' };
      });
      const userIds = newlySelectedMembers?.map((item) => item?.id) ?? [];
      await AddUserToConversation?.({
        userIds,
        conversationId: alreadyExistingGroupConversation?.id as string,
        userData,
      });
      setAddAssociateLoader(false);
    } catch (e) {
      setAddAssociateLoader(false);
      console.log('error', e);
    }
  }, [checkedMembers, alreadyExistingGroupConversation, previouslySelectedUsersForGroupChat]);

  const handleOnPressConversation = useCallback(
    (conversationId?: string) => {
      const conversationDetails = {
        userId1: '',
        userId1Name: '',
        userId1UserName: '',
        userId2: '',
        userId2Name: '',
        userId2UserName: '',
      };
      const queryParams = new URLSearchParams({
        jobId: jobId,
      });
      navigate(`/post-job-award-conversations?${queryParams.toString()}`, {
        state: {
          userType: UserType.Vendor,
          ...conversationDetails,
          jobId: !isEmpty(jobId) ? jobId : '',
          jobName: jobName,
          placeholder: getPlaceholderText(''),
          selectedConversationId: conversationId,
        },
      });
    },
    [jobId]
  );

  const chatWithExistingGroup = useCallback(() => {
    handleOnPressConversation(alreadyExistingGroupConversation?.id);
  }, [alreadyExistingGroupConversation]);

  const renderCreateConversationBodyContent = useCallback(() => {
    const usersData = teamData.length > 0 ? teamData : users;

    return (
      <>
        <CreateConversationCards
          users={usersData}
          isDataLoading={isDataLoading}
          checkedMembers={checkedMembers}
          setCheckedMembers={setCheckedMembers}
          handleOnPressCreateConversation={handleOnPressCreateConversation}
          createGroup={createGroup}
          isButtonLoading={isButtonLoading}
          conversationMode={conversationMode}
          setGroupName={setGroupName}
          groupName={groupName}
          isCreateConversationLoading={isCreateConversationLoading}
          isGroupAlreadyCreated={isGroupAlreadyCreated}
          previouslySelectedUsersForGroupChat={previouslySelectedUsersForGroupChat}
          addAssociateLoader={addAssociateLoader}
          AddNewMemberToAlreadyCreatedGroup={AddNewMemberToAlreadyCreatedGroup}
          chatWithExistingGroup={chatWithExistingGroup}
          loggedInUserInternalId={loggedInUserInternalId}
        />
      </>
    );
  }, [
    teamData,
    isDataLoading,
    checkedMembers,
    isButtonLoading,
    groupName,
    isCreateConversationLoading,
    isGroupAlreadyCreated,
    previouslySelectedUsersForGroupChat,
    addAssociateLoader,
    loggedInUserInternalId,
  ]);

  const fetchUsers = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async (searchedText?: string, pageNumber?: number) => {
      setIsDataLoading(true);
      let fetchAllUsersResponse;
      if (role === UserType.Vendor) {
        if (isDistrictManager) {
          fetchAllUsersResponse = await VendorApiService.getVendorTeam(
            loggedInUserInternalId ?? '',
            '',
            '',
            searchedText ?? '',
            pageNumber,
            NUMBER_OF_ITEMS_PER_PAGE,
            '',
            '',
            true
          );
          if (fetchAllUsersResponse?.metadata?.totalCount >= NUMBER_OF_ITEMS_PER_PAGE) {
            fetchAllUsersResponse = await VendorApiService.getVendorTeam(
              loggedInUserInternalId ?? '',
              '',
              '',
              searchedText ?? '',
              pageNumber,
              fetchAllUsersResponse?.metadata?.totalCount,
              '',
              '',
              true
            );
          }
        } else {
          fetchAllUsersResponse = await OrganizationService.getAllUsers(
            customerId ?? '',
            '',
            '',
            pageNumber,
            NUMBER_OF_ITEMS_PER_PAGE,
            '',
            true,
            searchedText,
            ''
          );
          if (fetchAllUsersResponse?.metadata?.totalCount >= NUMBER_OF_ITEMS_PER_PAGE) {
            fetchAllUsersResponse = await OrganizationService.getAllUsers(
              customerId ?? '',
              '',
              '',
              pageNumber,
              fetchAllUsersResponse?.metadata?.totalCount,
              '',
              true,
              searchedText,
              ''
            );
          }
        }

        const modifiedUsersResponse = fetchAllUsersResponse?.data?.map(
          (user: { userId: any; username: any; roles: { roleName: any }[] }) => {
            return {
              id: user?.userId,
              name: user?.username,
              role: user?.roles?.[0]?.roleName,
            };
          }
        );

        setTeamData(modifiedUsersResponse);
      }
      if (loggedInUserType === UserType.Customer) {
        fetchAllUsersResponse = await OrganizationService.getAllUsers(
          customerId ?? '',
          '',
          '',
          pageNumber,
          NUMBER_OF_ITEMS_PER_PAGE,
          '',
          true,
          searchedText,
          ''
        );
        if (fetchAllUsersResponse?.metadata?.totalCount >= NUMBER_OF_ITEMS_PER_PAGE) {
          fetchAllUsersResponse = fetchAllUsersResponse = await OrganizationService.getAllUsers(
            customerId ?? '',
            '',
            '',
            pageNumber,
            fetchAllUsersResponse?.metadata?.totalCount,
            '',
            true,
            searchedText,
            ''
          );
        }
        const modifiedUsersResponse = fetchAllUsersResponse?.data?.map(
          (user: { userId: any; username: any; roles: { roleName: any }[] }) => {
            return {
              id: user?.userId,
              name: user?.username,
              role: user?.roles?.[0]?.roleName,
            };
          }
        );

        setTeamData(modifiedUsersResponse);
      }
      setIsDataLoading(false);
    },
    [loggedInUserInternalId]
  );

  useEffect(() => {
    if (isEmpty(users) && !isSupervisorGroup && !isAssociateGroup) {
      fetchUsers();
    } else {
      setOriginalTeamData(users);
    }
  }, []);

  const handleCloseAddButton = useCallback(() => {
    setCreateConversation(false);
  }, []);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleOnPressCreateConversation = useCallback(async () => {
    try {
      if (conversationMode === ConversationTabs.DIRECT_CHAT) {
        setIsButtonLoading(true);
        const userIds = [loggedInUserInternalId ?? '', checkedMembers?.[0]?.id ?? ''];
        const existingConversation = getExistingConversationIdIfAlreadyExists(userIds);
        let response;
        let userCompanyName;
        if (loggedInUserType === UserType.Vendor && (checkedMembers?.[0]?.role as Role) !== Role.Associate) {
          userCompanyName = organizationName;
        }
        if (loggedInUserType === UserType.Customer) {
          userCompanyName = organizationName;
        }

        if (isEmpty(existingConversation?.id)) {
          response = (await createConversation?.({
            userIds,
            adminIds: [loggedInUserInternalId ?? ''],
            mode: 'NORMAL',
            meta: {
              userInformation: [
                { id: loggedInUserInternalId, name: loggedInUserName, companyName: userCompanyName ?? '' },
                { id: checkedMembers?.[0]?.id, name: checkedMembers?.[0]?.name, companyName: userCompanyName ?? '' },
              ],
            },
          })) as ConversationType;
        }

        if (response && onNewConversation) {
          onNewConversation(response, false);
          setConversationName(response?.name ?? checkedMembers?.[0]?.name);
        }
        if (existingConversation?.id) {
          const matchedConversation = conversationData.find((conv: { id: any }) => conv.id === existingConversation.id);

          if (matchedConversation && onNewConversation) {
            onNewConversation(matchedConversation, true);
          }
        }
        setPlaceHolder(getPlaceholderText(checkedMembers?.[0]?.name, userCompanyName) ?? '');

        setCreateConversation(false);
      }
    } catch (e) {
      console.log('error', e);
    }
  }, [loggedInUserType, checkedMembers]);

  const getExistingConversationIdIfAlreadyExists = useCallback(
    (userIdsForConversation: string[]) => {
      const conversation = data?.find(
        ({ mode, userIds }: { mode: string; userIds: string[] }) =>
          mode === 'NORMAL' && userIdsForConversation?.every((item) => userIds?.includes(item))
      );

      return { id: conversation?.id ?? '', name: conversation?.name ?? '' };
    },
    [data]
  );

  const createGroup = useCallback(
    async (conversationName: string) => {
      try {
        setIsCreateConversationLoading(true);
        const userIds: string[] = checkedMembers?.map((checkedMember) => {
          return checkedMember?.id ?? '';
        });
        userIds?.push(loggedInUserInternalId ?? '');
        const userData = checkedMembers?.map((checkedMember) => {
          return { id: checkedMember?.id ?? '', fullName: checkedMember?.name ?? '' };
        });

        const meta = {
          jobName: jobName ?? '',
          isFromJob: true,
          currentJobId: jobId ?? '',
          isSupervisorGroup,
          isAssociateGroup,
        };

        const response = (await createConversation?.({
          userIds,
          adminIds: [loggedInUserInternalId ?? ''],
          mode: 'GROUP',
          name: conversationName,
          meta,
          userData,
        })) as ConversationType;

        onNewConversation(response);
        setConversationName(response?.name ?? groupName);

        setPlaceHolder('');

        setCreateConversation(false);
        setIsCreateConversationLoading(false);
      } catch (e) {
        console.log('error', e);
      }
    },
    [checkedMembers]
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (dataExists) {
      const createdGroup = data?.filter((conversation: any) => {
        let metaObject;
        if (typeof conversation?.meta === 'string') {
          metaObject = JSON.parse(conversation?.meta);
        } else {
          metaObject = conversation?.meta;
        }
        if (metaObject?.currentJobId === jobId && metaObject?.isFromJob === true && conversation?.mode === 'GROUP') {
          return isAssociateGroupCreating ? metaObject?.isAssociateGroup : metaObject?.isSupervisorGroup;
        }
      });

      if (!isEmpty(createdGroup)) {
        setAlreadyExistingGroupConversation(createdGroup?.[0] ?? {});

        const associatesAlreadyExistingInGroup: UsersListType[] =
          originalTeamData?.filter((user) => createdGroup?.[0]?.userIds?.includes(user?.id)) ?? [];

        const uniqueUserIds = new Set(createdGroup?.[0]?.userIds);
        const uniqueUserIdsList = Array.from(uniqueUserIds);

        setIsGroupAlreadyCreated(
          !isEmpty(associatesAlreadyExistingInGroup) &&
            ((uniqueUserIdsList?.length === 1 && uniqueUserIdsList?.[0] !== loggedInUserInternalId) ||
              uniqueUserIdsList?.length > 1)
        );
        if (
          associatesAlreadyExistingInGroup &&
          ((uniqueUserIdsList?.[0] !== loggedInUserInternalId && uniqueUserIdsList?.length === 1) ||
            uniqueUserIdsList?.length > 1)
        ) {
          setPreviouslySelectedUsersForGroupChat(associatesAlreadyExistingInGroup);
          setCheckedMembers(associatesAlreadyExistingInGroup);
        }
      }
    }
  }, [data, originalTeamData]);

  return (
    <>
      <Modal
        open={createNewConversation}
        onClose={() => handleCloseAddButton()}
        renderHeader={renderCreateConversationHeaderContent()}
        renderBody={renderCreateConversationBodyContent()}
        styles={{
          '& .MuiPaper-root': {
            width: '32rem',
          },
        }}
      />
    </>
  );
};

export default CreateConversations;
