/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import theme from '../../../Themes/theme';
import { StyleConstants } from '../../../Constants/Style.Constants';

export const SelectConversation = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1rem',
  color: 'gray',
  height: '100%',
  fontWeight: theme.typography.fontWeightRegular,
  fontFamily: theme.typography.fontFamily,
}));

export const HeaderText = styled('div')(() => ({
  display: 'flex',
  background: 'white',
  height: '4.5rem',
  [theme.breakpoints.down('laptop')]: {
    height: '4.25rem',
  },
}));

export const MessageBody = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  maxHeight: 'calc(100vh - 20.5rem)',
  '@media (max-width: 69rem)': {
    maxHeight: 'calc(100vh - 18.3rem)',
  },
}));

export const MessagesContainer = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexGrow: 1,
}));

export const MessageFooter = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const NoMessagesAvailable = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1rem',
  color: 'gray',
  height: '100%',
  width: '100%',
  fontWeight: theme.typography.fontWeightRegular,
  fontFamily: theme.typography.fontFamily,
}));

export const MessageCardContainer = styled('div')(() => ({
  marginBottom: '1rem',
  width: '100%',
  padding: '0',
  overflow: 'auto',
  maxHeight: 'calc(100vh - 20.5rem)',
  '@media (max-width: 69rem)': {
    maxHeight: 'calc(100vh - 18.3rem)',
  },
}));

export const MessageCardOuterDiv = styled('div')(() => ({
  display: 'flex',
  padding: '1rem 1.5rem',
  width: '100%',
}));

export const MessageCardStyles = styled('div')(() => ({
  backgroundColor: theme.palette.common.white,
  padding: '1rem 1.5rem',
  width: 'fit-content',
  maxWidth: '50%',
  borderRadius: '12px',
  boxShadow: '0 0.625rem 1.25rem 0.375rem rgba(121, 87, 0, 0.10);',
}));

export const dividerStyles = {
  divider: {
    marginLeft: '1.5rem',
    marginRight: '1.5rem',
    BorderColor: '#B0D1D1',
    borderStyle: 'dashed ',
  },
};

export const buttonStyles = {
  sendButton: {
    background: theme.palette.primary.dark,
    borderRadius: '1.25rem',
    maxWidth: '11.25rem',
    padding: '16px 24px',
    color: theme.palette.primary.dark,
    textTransform: 'capitalize',
    height: '3.5rem',
    width: '8rem',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    '@media (max-width: 64rem)': {
      padding: '0.75rem 2rem',
    },
  },
  buttonText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4?.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1.5rem',
    letterSpacing: StyleConstants.LetterSpacingTight,
    color: theme.palette.common.white,
    '@media (max-width: 64rem)': {
      fontSize: theme.typography.h5?.fontSize,
      lineHeight: '1.375rem',
      letterSpacing: '0.0125rem',
    },
  },
};

export const BottomBarMessageContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  background: 'white',
  paddingLeft: '24px',
  paddingRight: '24px',
  paddingTop: '16px',
  paddingBottom: '16px',
  boxShadow: `0px -5px 10px rgba(84, 75, 42, 0.08)`,
  filter: `drop-shadow(0px -5px 10px rgba(84, 75, 42, 0.08))`,
}));

export const MessageTextField = styled('textarea')(({ theme }) => ({
  paddingTop: '15px',
  paddingLeft: '15px',
  border: `0.0625rem solid ${theme.palette.secondary.light}`,
  borderRadius: '10px',
  width: '100%',
  maxWidth: '100%',
  marginRight: '10px',
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.text.primary,
  letterSpacing: '0.25px',
  fontFamily: theme.typography.fontFamily,
  resize: 'none',
  '&:focus': {
    outline: 'none',
    borderColor: theme.palette.primary.dark,
  },
}));
