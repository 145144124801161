/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { Icons } from '../../../../Shared/Constants/Icons';
import {
  convertToReadableFormat,
  formatDate,
  getAcronymName,
  getAuthDetails,
  getStoredCustomerDetails,
} from '../../../../Shared/Utilities/utils';
import theme from '../../../../Shared/Themes/theme';
import DateSelection from '../../../../Shared/Components/Common/DatePicker/DateSelection';
import { FieldNames } from '../../Models/AddEditOneTimeJob.Model';
import { useForm } from 'react-hook-form';
import { ITeamMemberData } from '../JobDetails/JobAssignmentCard';
import {
  AssignTeamDiv,
  buttonStyles,
  CardContainerWrapper,
  DatePickerContainer,
  LogoWrapper,
  TeamCard,
  TeamContainer,
} from '../JobDetails/OneTimeJobDetails.Style';
import { Role, UserType } from '../../../../Shared/Constants/App';
import ModifyPopUp from '../JobDetails/ModifyPopUp';
import { AssociateShiftDateProps } from './SupervisorPunchIn';
import { AllShiftDetailsQuery } from './AssociateCard';
import { IConversationContext } from '../PostJobAwardConversations/PostJobAwardConversations';
import { useNavigate } from 'react-router-dom';
import VendorApiService from '../../../../Shared/Services/VendorService';
import { isEmpty } from 'lodash';

interface AddTeam {
  teamMembers: ITeamMemberData[];
  setTeamMembers: (data: ITeamMemberData[]) => void;
  originalTeam: ITeamMemberData[];
  setOriginalTeam: (data: ITeamMemberData[]) => void;
  selectedTeamMembers: ITeamMemberData[];
  setSelectedTeamMembers: (data: ITeamMemberData[]) => void;
  isDataLoading: boolean;
  onClose: (type: Role) => void;
  assignmentType: Role;
  workOrderData: any;
  handleAddTeamMember: (startDate: string, endDate: string) => void;
  isButtonLoading: boolean;
  deleteTeamMember: boolean;
  editTeamMember: boolean;
  handleDeleteTeamMember: (deleteMember: ITeamMemberData | undefined) => void;
  isDeleteModelOpen: boolean;
  setDeleteModalOpen: (data: boolean) => void;
  isDeleteButtonLoading: boolean;
  handleCloseDeleteModal: () => void;
  editAssociateShiftDate?: {
    associateStartDate: string;
    associateEndDate: string;
    userId: string;
  };
  setEditAssociateShiftDate: ({ associateStartDate, associateEndDate, userId }: AssociateShiftDateProps) => void;
  shiftData: AllShiftDetailsQuery[];
}

export const checkAlreadySelected = (data: ITeamMemberData[], memberId: string): boolean => {
  return data.some((prevMember) => prevMember.id === memberId);
};

const AddTeamMember = ({
  teamMembers,
  setTeamMembers,
  originalTeam,
  setOriginalTeam,
  selectedTeamMembers,
  setSelectedTeamMembers,
  isDataLoading,
  onClose,
  assignmentType,
  workOrderData,
  handleAddTeamMember,
  isButtonLoading,
  deleteTeamMember,
  editTeamMember,
  handleDeleteTeamMember,
  isDeleteButtonLoading,
  isDeleteModelOpen,
  setDeleteModalOpen,
  handleCloseDeleteModal,
  setEditAssociateShiftDate,
  editAssociateShiftDate,
  shiftData,
}: AddTeam) => {
  const [isTeamCardVisible, setTeamCardVisibility] = useState(false);
  const [isAssociateButtonDisabled, setIsAssociateButtonDisabled] = useState(true);
  const [isSupervisorButtonDisabled, setIsSupervisorButtonDisabled] = useState(true);
  const [removedTeamMember, setRemovedTeamMember] = useState<ITeamMemberData>();
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);

  const [conversationContext, setConversationContext] = useState<IConversationContext>({} as IConversationContext);

  const [loadingMembers, setLoadingMembers] = useState<Record<string, boolean>>({});

  const { t } = useTranslation(['assignJob', 'oneTimeJob', 'calendar']);
  const { buttonText, createJobButton, cancelButton } = buttonStyles;
  const { control, getValues, reset, watch } = useForm({
    defaultValues: {
      startDate: shiftData?.[0]?.scheduleStartDate
        ? formatDate(shiftData[0].scheduleStartDate)
        : shiftData[0]?.shiftStartDateTime
          ? formatDate(shiftData[0].shiftStartDateTime)
          : null,

      endDate: shiftData?.[0]?.scheduleEndDate
        ? formatDate(shiftData[0].scheduleEndDate)
        : shiftData[0]?.shiftEndDateTime
          ? formatDate(shiftData[0].shiftEndDateTime)
          : null,
    },
  });

  const watchFields = watch();

  const storedAuthDetails = getAuthDetails();

  const loggedInUserInternalId = storedAuthDetails['custom:UserId'];
  const loggedInUserName = storedAuthDetails['name'];

  const navigate = useNavigate();

  const handleOpenModal = (member: ITeamMemberData) => {
    setRemovedTeamMember(member);
    setDeleteModalOpen(true);
  };

  const handleSelectMember = useCallback((member: any) => {
    if (!member?.isAlreadyChecked) {
      handleSelectTeamMember(member?.id);
    }
  }, []);

  const handleSelectTeamMember = (memberId: string) => {
    const teamMembersData = [...teamMembers];
    const originalTeamMembersData = [...originalTeam];
    const selectedMember = teamMembersData.find((member: ITeamMemberData) => member.id === memberId);
    if (selectedMember) {
      selectedMember.isChecked = !selectedMember?.isChecked;
      const selectedMemberIndex = teamMembersData.findIndex((member: ITeamMemberData) => member.id === memberId);
      const originalSelectedMemberIndex = originalTeamMembersData.findIndex(
        (member: ITeamMemberData) => member.id === memberId
      );

      if (selectedMemberIndex !== -1) {
        teamMembersData[selectedMemberIndex] = selectedMember;
        setTeamMembers(teamMembersData);
      }
      if (originalSelectedMemberIndex !== -1) {
        originalTeamMembersData[originalSelectedMemberIndex] = selectedMember;
        setOriginalTeam(originalTeamMembersData);
      }
      const selectedTeamList = originalTeamMembersData.filter((member: ITeamMemberData) => member.isChecked);
      setSelectedTeamMembers(selectedTeamList);
    }
  };

  const handleNextButton = useCallback(() => {
    if (assignmentType === Role.Associate) {
      setTeamCardVisibility(true);
      setEditAssociateShiftDate({
        userId: '',
        associateStartDate: '',
        associateEndDate: '',
      });
    } else {
      const startDate = getValues(FieldNames.startDate);
      const endDate = getValues(FieldNames.endDate);
      handleAddTeamMember(startDate as string, endDate as string);
    }
  }, [selectedTeamMembers]);

  const handleConversationIconClick = useCallback(() => {
    const queryParams = new URLSearchParams({
      jobId: workOrderData?.jobId,
    });

    navigate(`/post-job-award-conversations?${queryParams?.toString()}`, {
      state: {
        ...workOrderData,
        conversationContext: conversationContext,
      },
    });
  }, [conversationContext, workOrderData]);

  const setDataForChat = useCallback(
    async (selectedChat: ITeamMemberData) => {
      if (!selectedChat.id) {
        return;
      }
      try {
        setLoadingMembers((prev) => ({
          ...prev,
          [selectedChat.id as string]: true,
        }));
        const { customerId = '' } = getStoredCustomerDetails() || {};
        const response = await VendorApiService.getVendorDetails(customerId);

        setConversationContext((prevConversationContext) => {
          return {
            ...prevConversationContext,
            userType: UserType.Vendor,
            userId1: loggedInUserInternalId ?? '',
            userId1Name: response?.data?.doingBusinessAs ?? '',
            userId1UserName: loggedInUserName ?? '',
            userId2: selectedChat?.id ?? '',
            userId2Name: selectedChat?.name ?? '',
            userId2UserName: '',
            jobId: workOrderData?.jobId,

            userAndJobDetails: {
              jobName: workOrderData?.name ?? workOrderData?.jobName ?? '',
              vendorId: loggedInUserInternalId ?? '',
              jobId: workOrderData?.jobId,
              vendorUserName: loggedInUserName ?? '',
              vendorCompanyName: response?.data?.doingBusinessAs ?? '',
              userType: UserType.Vendor,
              facilityName: '',
              conversationGroupName: '',
              associateId: selectedChat?.id ?? '',
              associateName: selectedChat?.name ?? '',
              customerOrDmSubVendorId: '',
              customerOrDmOrSubVendorUserName: '',
              customerOrDmOrSubVendorCompanyName: '',
            },
            primaryContactName: t('common:chatWithDM'),
          };
        });
        setLoadingMembers((prev) => ({
          ...prev,
          [selectedChat.id as string]: false,
        }));
      } catch (error) {
        console.error('Error fetching vendor details or setting conversation context:', error);
      }
    },
    [workOrderData]
  );

  const renderChatIcon = (member: ITeamMemberData) => {
    if (loggedInUserInternalId === member?.id || !member.id) return null;

    return loadingMembers[member.id] ? (
      <CircularProgress size={24} sx={{ color: theme.palette.primary.dark }} />
    ) : (
      <img
        src={Icons.GreenConversationIcon}
        alt={t('altTexts:conversation')}
        style={{ cursor: 'pointer', height: '1.5rem', width: '1.5rem' }}
        onClick={() => setDataForChat(member)}
      />
    );
  };

  const handleEditIcon = useCallback((member: ITeamMemberData) => {
    setTeamCardVisibility(true);
    setEditAssociateShiftDate({
      userId: member?.id ?? '',
      associateStartDate: member?.associateStartDate ?? '',
      associateEndDate: member?.associateEndDate ?? '',
    });
  }, []);

  const renderEditIcon = (member: ITeamMemberData) => (
    <img
      src={Icons.EditBlackIcon}
      alt={t('altTexts:edit')}
      style={{ cursor: 'pointer', height: '1.5rem', width: '1.5rem' }}
      onClick={() => handleEditIcon(member)}
    />
  );

  const renderDeleteIcon = (member: ITeamMemberData) => (
    <img
      src={Icons.DeleteIcon}
      alt={t('altTexts:delete')}
      style={{ cursor: 'pointer', height: '1.5rem', width: '1.5rem' }}
      onClick={() => handleOpenModal(member)}
    />
  );

  const renderActionIcons = (member: ITeamMemberData) => {
    if (member.isAlreadyChecked) {
      return (
        <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '0.5rem' }}>
          {renderChatIcon(member)}
          {editTeamMember && renderEditIcon(member)}
          {deleteTeamMember && renderDeleteIcon(member)}
        </Box>
      );
    }

    return member.isChecked ? (
      <img
        src={Icons.CheckedIcon}
        alt={t('altTexts:checked')}
        style={{
          opacity: member?.isAlreadyChecked ? '0.5' : '1',
          height: '1.5rem',
          width: '1.5rem',
        }}
      />
    ) : (
      <img src={Icons.Unchecked} alt={t('altTexts:unchecked')} style={{ cursor: 'pointer' }} />
    );
  };

  const renderTeamContent = (teamMembers: ITeamMemberData[]) => {
    return (
      <>
        {teamMembers?.length > 0 && (
          <AssignTeamDiv>
            {teamMembers?.map((member: ITeamMemberData) => {
              return (
                <TeamCard key={member.id} onClick={() => handleSelectMember(member)}>
                  <Box sx={{ display: 'flex' }}>
                    <LogoWrapper>
                      <Box>{getAcronymName(member.name as string).toUpperCase()}</Box>
                    </LogoWrapper>
                    <TeamContainer>
                      <Box className="Title">{member.name}</Box>
                      <Box className="Position">{convertToReadableFormat(member.role)}</Box>
                    </TeamContainer>
                  </Box>
                  <Box>
                    <Box>{renderActionIcons(member)}</Box>
                  </Box>
                </TeamCard>
              );
            })}
          </AssignTeamDiv>
        )}
      </>
    );
  };

  const disableAssignButton = () => {
    if (assignmentType === Role.Associate) {
      return isAssociateButtonDisabled || isDataLoading;
    } else {
      return isSupervisorButtonDisabled || isDataLoading;
    }
  };

  const disableHandleButton = () => {
    const startDate = getValues(FieldNames.startDate);
    const endDate = getValues(FieldNames.endDate);
    setDisableConfirmButton(!(startDate && endDate));
  };

  const renderAssignTeamButton = () => (
    <Box style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Button
        disabled={disableAssignButton()}
        sx={{
          ...createJobButton,
          opacity: disableAssignButton() ? '0.5' : '1',
          marginTop: '2em',
          width: '100%',
          maxWidth: '350px',
          textAlign: 'center',
        }}
        onClick={handleNextButton}>
        {isButtonLoading ? (
          <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
        ) : (
          <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
            {assignmentType === Role.Associate ? t('assignJob:assignAssociates') : t('assignJob:assignSupervisors')}
          </Typography>
        )}
      </Button>
    </Box>
  );

  const handleConfirmButton = useCallback(() => {
    const startDate = getValues(FieldNames.startDate);
    const endDate = getValues(FieldNames.endDate);

    handleAddTeamMember(startDate as string, endDate as string);
  }, [selectedTeamMembers]);

  const renderConfirmButton = () => (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        disabled={disableAssignButton()}
        sx={{ ...cancelButton, cursor: 'pointer', maxWidth: '350px', marginTop: '2em', width: '100%' }}
        onClick={() => onClose(assignmentType)}>
        <Typography sx={{ ...buttonText, color: theme.palette.primary.dark }}>{t('assignJob:cancel')}</Typography>
      </Button>
      <Button
        disabled={disableConfirmButton}
        sx={{
          ...createJobButton,
          maxWidth: '350px',
          marginTop: '2em',
          width: '100%',
          opacity: disableConfirmButton ? '0.5' : '1',
        }}
        onClick={handleConfirmButton}>
        {isButtonLoading ? (
          <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
        ) : (
          <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>{t('assignJob:confirm')}</Typography>
        )}
      </Button>
    </Box>
  );

  const renderDatePickers = () => (
    <Box
      sx={{
        display: 'flex',
        marginBottom: '0.5rem',
      }}>
      <DatePickerContainer
        sx={{
          margin: 0,
        }}>
        <DateSelection
          requiredText={t('oneTimeJob:startDateFieldReq')}
          control={control}
          fieldName={FieldNames.startDate}
          label={t('calendar:startDate')}
          iconColor="black"
          isExcludePastDate={false}
          showSpecificRangeDates={{
            from:
              workOrderData.actualStartDate &&
              (formatDate(shiftData?.[0]?.scheduleStartDate) || formatDate(shiftData[0]?.shiftStartDateTime)),
            to:
              workOrderData.actualEndDate &&
              (formatDate(shiftData?.[0]?.scheduleEndDate) || formatDate(shiftData[0]?.shiftEndDateTime)),
          }}
        />
        <DateSelection
          requiredText={t('oneTimeJob:endDateFieldReq')}
          control={control}
          fieldName={FieldNames.endDate}
          label={t('calendar:endDate')}
          iconColor="black"
          isExcludePastDate={false}
          showSpecificRangeDates={{
            from:
              workOrderData.actualStartDate &&
              (formatDate(shiftData?.[0]?.scheduleStartDate) || formatDate(shiftData[0]?.shiftStartDateTime)),
            to:
              workOrderData.actualEndDate &&
              (formatDate(shiftData?.[0]?.scheduleEndDate) || formatDate(shiftData[0]?.shiftEndDateTime)),
          }}
        />
      </DatePickerContainer>
    </Box>
  );

  useEffect(() => {
    disableHandleButton();
  }, [watchFields]);

  useEffect(() => {
    disableAssignButton();
  }, [isTeamCardVisible, selectedTeamMembers]);

  useEffect(() => {
    const disablingAssociateButton = selectedTeamMembers.every((associate) => {
      return !(!associate.isAlreadyChecked && associate.isChecked);
    });
    setIsAssociateButtonDisabled(disablingAssociateButton);
  }, [selectedTeamMembers]);

  useEffect(() => {
    const disablingSupervisorButton = selectedTeamMembers.every((supervisor) => {
      return !(!supervisor.isAlreadyChecked && supervisor.isChecked);
    });
    setIsSupervisorButtonDisabled(disablingSupervisorButton);
  }, [selectedTeamMembers]);

  useEffect(() => {
    if (editAssociateShiftDate?.associateStartDate || editAssociateShiftDate?.associateEndDate) {
      reset({
        startDate: editAssociateShiftDate?.associateStartDate
          ? formatDate(editAssociateShiftDate.associateStartDate)
          : formatDate(workOrderData.actualStartDate),
        endDate: editAssociateShiftDate?.associateEndDate
          ? formatDate(editAssociateShiftDate.associateEndDate)
          : formatDate(workOrderData.actualEndDate),
      });
    }
  }, [editAssociateShiftDate, reset, workOrderData]);

  useEffect(() => {
    if (!isEmpty(conversationContext)) {
      handleConversationIconClick();
    }
  }, [conversationContext]);

  return (
    <>
      {!isTeamCardVisible ? (
        <CardContainerWrapper>
          {isDataLoading ? (
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark, marginLeft: '45%' }} />
          ) : teamMembers.length > 0 ? (
            renderTeamContent(teamMembers)
          ) : (
            <Typography sx={{ display: 'flex', justifyContent: 'center' }}>{t('assignJob:noResultsFound')}</Typography>
          )}
        </CardContainerWrapper>
      ) : (
        renderDatePickers()
      )}
      <>{!isTeamCardVisible ? renderAssignTeamButton() : renderConfirmButton()}</>
      <ModifyPopUp
        open={isDeleteModelOpen}
        handleClose={handleCloseDeleteModal}
        handleOnCloseJob={() => handleDeleteTeamMember(removedTeamMember)}
        loading={isDeleteButtonLoading}
        heading={t('assignJob:removeData')}
        text={t('assignJob:removeTeamDataText')}
      />
    </>
  );
};

export default AddTeamMember;
