/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogActions, TextField, Box, InputAdornment, IconButton } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';

import { getTimeFormat, hours } from '../../../../Shared/Utilities/utils';
import { FieldNames } from '../../../Jobs/Models/AddEditOneTimeJob.Model';
import { ScheduleTypes, ShiftTypes } from '../../../../Shared/Constants/App';
import { IModifySchedulePopupProps } from './AllJobs.Model';
import DeletePopup from '../../../../Shared/Components/Common/DeletePopup/DeletePopup';
import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';

import { Icons } from '../../../../Shared/Constants/Icons';
import theme from '../../../../Shared/Themes/theme';

import {
  buttonStyles,
  DateFieldWrapper,
  DialogWrapper,
  ErrorMsg,
} from '../../../Jobs/Components/AddEditOneTimeJob/AddEditOneTimeJob.Style';
import {
  EditableOptionContainer,
  ModifyScheduleText,
  ScheduleDaysContainer,
  ScheduleDaysText,
  wrapperStyles,
} from './AllJobs.Style';
import { Options } from '../../../../Shared/Components/Common/Dropdown/Dropdown.styles';

const ModifySchedulePopup: React.FC<IModifySchedulePopupProps> = ({ open, onClose, onConfirm, selectedJobData }) => {
  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      startTime: selectedJobData[0]?.scheduleStartTime || '',
      endTime: selectedJobData[0]?.scheduleEndTime || '',
      woScheduleMasterId: selectedJobData[0]?.woScheduleMasterId,
      scheduleDays: selectedJobData[0]?.scheduleDays,
    },
  });
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [hoursList, setHoursList] = useState<any[]>([]);
  const [isStartTimeSelectOpen, setStartTimeSelectOpen] = useState(false);
  const [isEndTimeSelectOpen, setEndTimeSelectOpen] = useState(false);
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { closeIconStyle, textField } = buttonStyles;
  const { daysStyle, frequencyOptions } = wrapperStyles;
  const { t } = useTranslation(['jobManagement', 'messages', 'homePage']);

  const disableConfirmButton = !selectedJobData[0]?.scheduleStartTime || !selectedJobData[0]?.scheduleEndTime;
  const numericToDayMap = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

  const handleFormSubmit = async (data: {
    woScheduleMasterId: string;
    scheduleDays: string;
    startTime: string;
    endTime: string;
  }) => {
    let formattedScheduleDays = '';
    if (selectedJobData[0]?.mdScheduleType === ScheduleTypes.Weekly) {
      formattedScheduleDays = selectedDays
        .map((day) => numericToDayMap.indexOf(day))
        .filter((day) => day >= 0)
        .join(',');
    } else if (selectedJobData[0]?.mdScheduleType === ScheduleTypes.Monthly) {
      formattedScheduleDays = selectedDays.filter((day) => !!day).join(',');
    }
    setIsLoading(true);
    try {
      await new Promise<void>(() => {
        onConfirm({
          woScheduleMasterId: data.woScheduleMasterId,
          scheduleDays: formattedScheduleDays,
          startTime: data.startTime,
          endTime: data.endTime,
        });
      });
    } catch (error) {
      console.error(t('messages:errorOccurred'), error);
    } finally {
      setIsLoading(false);
    }
    reset();
    onClose();
  };

  const handleDayClick = (day: string) => {
    setSelectedDays((prev) => (prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]));
  };

  useEffect(() => {
    if (selectedJobData && selectedJobData.length > 0) {
      const { scheduleStartTime, scheduleEndTime, woScheduleMasterId, scheduleDays } = selectedJobData[0];
      setValue('startTime', scheduleStartTime || '');
      setValue('endTime', scheduleEndTime || '');
      setValue('woScheduleMasterId', woScheduleMasterId || '');
      setValue('scheduleDays', scheduleDays || '');
    }
  }, [selectedJobData, setValue]);

  useEffect(() => {
    if (selectedJobData[0]?.mdScheduleType === ScheduleTypes.Weekly && selectedJobData[0]?.scheduleDays) {
      const daysFromData = selectedJobData[0]?.scheduleDays.map((day: string) => {
        return numericToDayMap[parseInt(day)];
      });
      setSelectedDays(daysFromData);
    } else if (selectedJobData[0]?.mdScheduleType === ScheduleTypes.Monthly) {
      setSelectedDays(selectedJobData[0]?.scheduleDays || []);
    }
  }, [selectedJobData]);

  useEffect(() => {
    setHoursList(
      hours.map((hour) => {
        return {
          label: getTimeFormat(hour),
          value: hour,
        };
      })
    );
  }, []);

  return (
    <DialogWrapper open={open} onClose={onClose} fullWidth>
      <Box sx={closeIconStyle} onClick={onClose}>
        <img src={Icons.InfoWindowCloseIcon} />
      </Box>
      <ModifyScheduleText>{t('jobManagement:modifySchedule')}</ModifyScheduleText>
      <Box>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          {/* Type Selection */}
          <ScheduleDaysText>{t('jobManagement:frequency')}</ScheduleDaysText>
          <EditableOptionContainer>
            {[ScheduleTypes.Daily, ScheduleTypes.Weekly, ScheduleTypes.Monthly].map((type) => {
              const isDisabled = selectedJobData[0]?.mdScheduleType !== type;
              return (
                <Box
                  key={type}
                  onClick={() => !isDisabled}
                  bgcolor={selectedJobData[0]?.mdScheduleType === type ? theme.palette.secondary.dark : ''}
                  fontWeight={
                    selectedJobData[0]?.mdScheduleType === type
                      ? theme.typography.fontWeightBold
                      : theme.typography.fontWeightLight
                  }
                  sx={{
                    ...frequencyOptions,
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    opacity: isDisabled ? 0.5 : 1,
                  }}>
                  {type}
                </Box>
              );
            })}
          </EditableOptionContainer>
          {/* Conditional Sections */}
          {selectedJobData[0]?.mdScheduleType === ScheduleTypes.Weekly && (
            <>
              <ScheduleDaysText>{t('jobManagement:serviceDays')}</ScheduleDaysText>
              <ScheduleDaysContainer>
                {numericToDayMap.map((day) => (
                  <Box
                    key={day}
                    onClick={() => handleDayClick(day)}
                    bgcolor={selectedDays.includes(day) ? theme.palette.secondary.dark : ''}
                    fontWeight={
                      selectedDays.includes(day) ? theme.typography.fontWeightBold : theme.typography.fontWeightLight
                    }
                    sx={daysStyle}>
                    {day}
                  </Box>
                ))}
              </ScheduleDaysContainer>
            </>
          )}

          {selectedJobData[0]?.mdScheduleType === ScheduleTypes.Monthly && (
            <>
              <ScheduleDaysText>{t('jobManagement:serviceDates')}</ScheduleDaysText>
              <ScheduleDaysContainer>
                {Array.from({ length: 31 }, (_, i) => i + 1).map((date) => (
                  <Box
                    key={date}
                    onClick={() => handleDayClick(date.toString())}
                    bgcolor={selectedDays.includes(date.toString()) ? theme.palette.secondary.dark : ''}
                    fontWeight={
                      selectedDays.includes(date.toString())
                        ? theme.typography.fontWeightBold
                        : theme.typography.fontWeightLight
                    }
                    sx={daysStyle}>
                    {date}
                  </Box>
                ))}
              </ScheduleDaysContainer>
            </>
          )}
          {/* Shift Type Selection */}
          <ScheduleDaysText>{t('jobManagement:shiftType')}</ScheduleDaysText>
          <EditableOptionContainer>
            {[ShiftTypes.Day, ShiftTypes.Night, ShiftTypes.DayAndNight].map((shiftType) => {
              const isDisabled = selectedJobData[0]?.mdShiftType !== shiftType;
              return (
                <Box
                  key={shiftType}
                  onClick={() => !isDisabled}
                  bgcolor={selectedJobData[0]?.mdShiftType === shiftType ? theme.palette.secondary.dark : ''}
                  fontWeight={
                    selectedJobData[0]?.mdShiftType === shiftType
                      ? theme.typography.fontWeightBold
                      : theme.typography.fontWeightLight
                  }
                  sx={{
                    ...frequencyOptions,
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    opacity: isDisabled ? 0.5 : 1,
                  }}>
                  {shiftType}
                </Box>
              );
            })}
          </EditableOptionContainer>
          {/* Time Selection */}
          <ScheduleDaysText>{t('jobManagement:shiftTime')}</ScheduleDaysText>
          <EditableOptionContainer>
            <Controller
              control={control}
              name={FieldNames.startTime}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <DateFieldWrapper>
                  <TextField
                    select
                    sx={textField}
                    label={
                      <span>
                        {t('jobManagement:startTime')}
                        <span className="mandatory-field">*</span>
                      </span>
                    }
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setStartTimeSelectOpen(!isStartTimeSelectOpen)}
                            style={{ padding: 0 }}>
                            <img src={Icons.DropdownUpIcon} alt="up" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    SelectProps={{
                      open: isStartTimeSelectOpen,
                      IconComponent: () => null,
                      onOpen: () => setStartTimeSelectOpen(true),
                      onClose: () => setStartTimeSelectOpen(false),
                      MenuProps: {
                        PaperProps: {
                          style: {
                            boxShadow: '0px 16px 20px 0px rgba(58, 42, 0, 0.12)',
                          },
                        },
                      },
                    }}>
                    {hoursList.map((item) => (
                      <Options key={'startTime' + item.value} value={item.value}>
                        {item.label}
                      </Options>
                    ))}
                  </TextField>
                  <ErrorMsg>{error && error?.message}</ErrorMsg>
                </DateFieldWrapper>
              )}
            />
            <Controller
              control={control}
              name={FieldNames.endTime}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <DateFieldWrapper>
                  <TextField
                    select
                    sx={textField}
                    label={
                      <span>
                        {t('jobManagement:endTime')}
                        <span className="mandatory-field">*</span>
                      </span>
                    }
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setEndTimeSelectOpen(!isEndTimeSelectOpen)} style={{ padding: 0 }}>
                            <img src={Icons.DropdownUpIcon} alt="up" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    SelectProps={{
                      open: isEndTimeSelectOpen,
                      IconComponent: () => null,
                      onOpen: () => setEndTimeSelectOpen(true),
                      onClose: () => setEndTimeSelectOpen(false),
                      MenuProps: {
                        PaperProps: {
                          style: {
                            boxShadow: '0px 16px 20px 0px rgba(58, 42, 0, 0.12)',
                          },
                        },
                      },
                    }}>
                    {hoursList.map((item) => (
                      <Options key={'endTime' + item.value} value={item.value}>
                        {item.label}
                      </Options>
                    ))}
                  </TextField>
                  <ErrorMsg>{error && error?.message}</ErrorMsg>
                </DateFieldWrapper>
              )}
            />
          </EditableOptionContainer>
        </form>
      </Box>
      <DialogActions>
        <Button type={ButtonType.Secondary} label={t('dashboard:cancel')} onClick={onClose} />
        <Button
          type={ButtonType.Primary}
          label={t('dashboard:confirm')}
          onClick={() => {
            setIsDeletePopupVisible(true);
          }}
          disabled={disableConfirmButton}
        />
      </DialogActions>
      <DeletePopup
        open={isDeletePopupVisible}
        onClose={() => setIsDeletePopupVisible(false)}
        message={t('jobManagement:confirmTheChanges')}
        primaryButtonLabel={t('homePage:yes')}
        name={''}
        isPrimaryButtonLoading={isLoading}
        primaryAction={handleSubmit(handleFormSubmit)}
        secondaryButtonLabel={t('homePage:no')}
        secondaryAction={() => {
          setIsDeletePopupVisible(false);
        }}></DeletePopup>
    </DialogWrapper>
  );
};

export default ModifySchedulePopup;
