/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Box, CircularProgress, Tabs, Typography } from '@mui/material';
import { useOTJCommHelper } from 'otj-client-lib';

import {
  convertToObject,
  getAuthData,
  getAuthDetails,
  getPlaceholderText,
  getStoredCustomerDetails,
  NUMBER_OF_ITEMS_PER_PAGE,
} from '../../Utilities/utils';
import { ConversationTabs, UserType } from '../../Constants/App';

import PageTitle from '../Common/PageTitle/PageTitle';
import MessageScreen from './MessagesScreen/MessageScreen';
import CreateConversations from './CreateConversations/CreateConversations';

import { TabPanelProps } from '../../../Modules/Jobs/Models/JobsView.Model';

import { TabSection, TabsWrapper } from '../../../Modules/Jobs/Components/JobDetailsOverView/JobDetailsOverview.styles';
import { DirectChat, GroupChat, Meta } from './Conversations.model';
import {
  AddIconWrapper,
  ConversationCardMainDiv,
  ConversationsContainer,
  ConversationsContainerOne,
  ConversationsContainerTwo,
  CustomerOuterContainer,
  NoConversationFound,
  TabPanelDiv,
  TabTitle,
} from './DashboardConversations.Style';
import { Icons } from '../../Constants/Icons';
import DashboardConversationCard from './ConversationsScreen/DashboardConversationCard';
import JobCreateApiService from '../../../Modules/Jobs/Services/JobCreateService';
import PreJobAwardConversationCard from '../../../Modules/Jobs/Components/Conversations/PreJobAwardConversationCard';
import { ConversationInput, MessageType } from '../../../API';
import theme from '../../Themes/theme';
import { useTranslation } from 'react-i18next';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps & { style?: React.CSSProperties }) {
  const { children, value, index, style, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={style}
      {...other}>
      {value === index && children}
    </Box>
  );
}

const DashboardConversations = (): JSX.Element => {
  const location = useLocation();

  const { t } = useTranslation(['conversations']);

  const [tabSelected, setTabSelected] = useState(
    location?.pathname.includes('group') ? 1 : location?.pathname.includes('job') ? 2 : 0
  );

  const [directData, setDirectData] = useState<DirectChat[]>([]);
  const [groupedData, setGroupedData] = useState<GroupChat[]>([]);
  const [selectedConversation, setSelectedConversation] = useState<DirectChat>();

  const [createDirectConversation, setCreateDirectConversation] = useState<boolean>();
  const [createGroupConversation, setCreateGroupConversation] = useState<boolean>();

  const [placeHolder, setPlaceHolder] = useState<string>('');

  const [conversationDetails, setConversationDetails] = useState<any>();

  const [conversationName, setConversationName] = useState<string>('');

  const [recentDirectConversation, setRecentDirectConversation] = useState<DirectChat>();
  const [recentGroupConversation, setRecentGroupConversation] = useState<GroupChat>();

  const [conversationsData, setConversationsData] = useState<any>([]);

  const [selectedConversationId, setSelectedConversationId] = useState<string>('');
  const [currentlySelectedConversation, setCurrentlySelectedConversation] = useState<any>();

  const { customerId = '', organizationName } = getStoredCustomerDetails();
  const storedAuthDetails = getAuthDetails();

  const [replyMsgContent, setReplyMsgContent] = useState<string>('');

  const [isSendButtonLoading, setIsSendButtonLoading] = useState<boolean>(false);
  const [isConversationsDataLoading, setIsConversationsDataLoading] = useState<boolean>(false);

  const [isSelectedConversationsDataLoading, setIsSelectedConversationsDataLoading] = useState<boolean>(false);

  const loggedInUserType = storedAuthDetails['custom:Role'] as UserType;
  const loggedInUserName = storedAuthDetails['name'];
  const loggedInUserInternalId = storedAuthDetails['custom:UserId'];

  const { chatHooks } = useOTJCommHelper();
  const { useConversations } = chatHooks;

  const { data, isSuccess } = useConversations();

  const { role } = getAuthData();

  const isInitialLoading = useRef(true);

  const handleChangeTabs = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setTabSelected(newValue);
      if (newValue === 0) {
        setSelectedConversation(recentDirectConversation);
      } else if (newValue === 1) {
        setSelectedConversation(recentGroupConversation);
      } else if (newValue === 2) {
        getConversationsData();
      }
      window.history.pushState(
        null,
        'Peazy',
        `/normal-conversations/${newValue === 0 ? 'direct' : newValue === 1 ? 'group' : 'job'}`
      );
    },
    [recentDirectConversation, recentGroupConversation]
  );

  const getDisplayNameForDirectChat = useCallback((meta: any, isPlaceholderTextRequired: boolean = false) => {
    if (isPlaceholderTextRequired) {
      const senderInformation =
        meta?.userInformation?.find((item: { id: any }) => item?.id !== loggedInUserInternalId) ?? {};
      const senderName = senderInformation?.name ?? '';
      const senderCompanyName = senderInformation?.companyName ?? '';
      return getPlaceholderText(senderName, senderCompanyName);
    }
    return meta?.userInformation?.find((item: { id: any }) => item?.id !== loggedInUserInternalId)?.name ?? '';
  }, []);

  const getConversationsData = useCallback(async (): Promise<void> => {
    setIsConversationsDataLoading(true);
    try {
      const payload: any = {};

      if (role === UserType.Customer) {
        payload.customerId = customerId;
      } else {
        payload.vendorId = customerId;
      }
      let conversationsResponse = await JobCreateApiService.getConversations(payload);
      if (conversationsResponse.data && isEmpty(conversationsResponse.errors)) {
        const totalCount = conversationsResponse?.metaData?.totalCount;
        if (totalCount > NUMBER_OF_ITEMS_PER_PAGE) {
          payload.limit = totalCount;
          conversationsResponse = await JobCreateApiService.getConversations(payload);
        }
        setConversationsData(conversationsResponse.data);
      }
    } catch (error: any) {
      console.log('get conversations error: ', error);
    }
    setIsConversationsDataLoading(false);
    isInitialLoading.current = false;
  }, []);

  const extractMeta = useCallback(
    (meta: Meta) => ({
      associateId: meta?.associateId || '',
      associateName: meta?.associateName || '',
      customerOrDmOrSubVendorCompanyName: meta?.customerOrDmOrSubVendorCompanyName || '',
      customerOrDmOrSubVendorUserName: meta?.customerOrDmOrSubVendorUserName || '',
      customerOrDmSubVendorId: meta?.customerOrDmSubVendorId || '',
      vendorCompanyName: meta?.vendorCompanyName || '',
      vendorId: meta?.vendorId || '',
      vendorUserName: meta?.vendorUserName || '',
    }),
    []
  );

  const determineVendorDetails = useCallback((meta: Meta) => {
    const {
      vendorId,
      vendorCompanyName,
      vendorUserName,
      customerOrDmSubVendorId,
      customerOrDmOrSubVendorCompanyName,
      customerOrDmOrSubVendorUserName,
      associateId,
      associateName,
    } = meta;

    if (vendorId === loggedInUserInternalId) {
      return {
        userId1: vendorId,
        userId1Name: vendorCompanyName,
        userId1UserName: vendorUserName,
        userId2: customerOrDmSubVendorId || associateId,
        userId2Name: customerOrDmOrSubVendorCompanyName || associateName,
        userId2UserName: customerOrDmOrSubVendorUserName || '',
      };
    }

    return {
      userId1: customerOrDmSubVendorId,
      userId1Name: customerOrDmOrSubVendorCompanyName,
      userId1UserName: customerOrDmOrSubVendorUserName,
      userId2: vendorId || associateId,
      userId2Name: vendorCompanyName || associateName,
      userId2UserName: vendorUserName || '',
    };
  }, []);

  const getVendorPlaceholder = useCallback((meta: Meta, details: any, conversation: DirectChat) => {
    const { userId2UserName, userId2Name } = details;
    const { associateName } = meta;

    if (userId2UserName || userId2Name || associateName) {
      return getPlaceholderText(userId2UserName || '', userId2Name || associateName);
    }

    return getDisplayNameForDirectChat(conversation?.meta, true) || '';
  }, []);

  const handleVendorConversation = useCallback((meta: Meta, conversation: DirectChat) => {
    const conversationDetails = determineVendorDetails(meta);

    setConversationDetails(conversationDetails);
    setPlaceHolder(getVendorPlaceholder(meta, conversationDetails, conversation));
  }, []);

  const handleCustomerConversation = useCallback((meta: any) => {
    const {
      vendorId,
      vendorCompanyName,
      vendorUserName,
      customerOrDmSubVendorId,
      customerOrDmOrSubVendorCompanyName,
      customerOrDmOrSubVendorUserName,
    } = meta;
    const conversationDetails = {
      userType: UserType.Customer,
      userId2: !isEmpty(customerOrDmSubVendorId) ? customerOrDmSubVendorId : '',
      userId2Name: !isEmpty(customerOrDmOrSubVendorCompanyName) ? customerOrDmOrSubVendorCompanyName : '',
      userId2UserName: !isEmpty(customerOrDmOrSubVendorUserName) ? customerOrDmOrSubVendorUserName : '',
      userId1: !isEmpty(vendorId) ? vendorId : '',
      userId1Name: !isEmpty(vendorCompanyName) ? vendorCompanyName : '',
      userId1UserName: !isEmpty(vendorUserName) ? vendorUserName : '',
    };
    setConversationDetails(conversationDetails);

    setPlaceHolder(
      !isEmpty(vendorUserName) || !isEmpty(vendorCompanyName)
        ? getPlaceholderText(
            !isEmpty(vendorUserName) ? vendorUserName : '',
            !isEmpty(vendorCompanyName) ? vendorCompanyName : ''
          )
        : getDisplayNameForDirectChat(meta, true) ?? ''
    );
  }, []);

  const onSelectConversationCard = useCallback(
    (conversation: DirectChat): void => {
      setSelectedConversation(conversation);
      if (tabSelected === 0) {
        setRecentDirectConversation(conversation);
      } else if (tabSelected === 1) {
        setRecentGroupConversation(conversation as GroupChat);
      }

      const meta = extractMeta(conversation?.meta);

      if (loggedInUserType === UserType.Vendor) {
        handleVendorConversation(meta, conversation);
      } else if (loggedInUserType === UserType.Customer) {
        handleCustomerConversation(conversation?.meta);
      }
    },
    [tabSelected]
  );

  const handleAddAction = useCallback((conversationType: ConversationTabs) => {
    if (conversationType === ConversationTabs.DIRECT_CHAT) {
      setCreateDirectConversation(true);
    } else if (conversationType === ConversationTabs.GROUP_CHAT) {
      setCreateGroupConversation(true);
    }
  }, []);

  const handleDirectConversation = useCallback((newConversation: DirectChat, existing: boolean) => {
    if (!existing) {
      setDirectData((prevData: any) => [...prevData, newConversation]);
    }

    setSelectedConversation(newConversation);
  }, []);

  const handleGroupedConversation = useCallback((newConversation: GroupChat) => {
    setGroupedData((prevData: any) => [...prevData, newConversation]);

    setSelectedConversation(newConversation);
  }, []);

  const renderConversationCard = useCallback(
    (conversation: DirectChat, index: number, dataLength: number) => {
      const isLast = index === dataLength - 1;
      return (
        <ConversationCardMainDiv onClick={() => onSelectConversationCard(conversation)}>
          <DashboardConversationCard
            key={conversation?.id}
            conversationId={conversation?.id}
            meta={conversation?.meta}
            mode={conversation?.mode}
            name={conversation?.name}
            updatedAt={conversation?.updatedAt}
            selectedConversation={selectedConversation}
            conversationName={conversationName}
            isLast={isLast}
          />
        </ConversationCardMainDiv>
      );
    },
    [selectedConversation, conversationName]
  );

  const onJobsTabSelectConversationCard = useCallback(async (conversation: any) => {
    setIsSelectedConversationsDataLoading(true);
    setSelectedConversation(conversation);
    setConversationName(renderConversationName(conversation));
    try {
      if (conversation?.conversationId) {
        const payload: any = {};
        payload.customerId = conversation?.customerId;
        payload.jobId = conversation?.jobId;
        payload.vendorId = conversation?.vendorId;

        const conversationsResponse = await JobCreateApiService.getConversations(payload);
        if (conversationsResponse.data && isEmpty(conversationsResponse.errors)) {
          setSelectedConversation(conversationsResponse.data?.[0]);
          setCurrentlySelectedConversation(conversationsResponse.data?.[0]);
          setConversationName(renderConversationName(conversationsResponse.data?.[0]));
          setSelectedConversationId(conversationsResponse.data?.[0]?.conversationId);
        }
      } else {
        setSelectedConversation(conversation);
      }
    } catch (error: any) {
      console.log('get conversations error: ', error);
    }

    setIsSelectedConversationsDataLoading(false);
  }, []);

  const renderConversationName = useCallback((conversation: any) => {
    if (customerId === conversation?.customerId) {
      return conversation?.vendorName;
    } else if (customerId === conversation?.vendorId) {
      return conversation?.customerName;
    }
  }, []);

  const renderJobsTabConversationCard = useCallback(
    (conversation: any, index: number, dataLength: number) => {
      const isLast = index === dataLength - 1;
      const conversationName = renderConversationName(conversation);
      return (
        <ConversationCardMainDiv onClick={() => onJobsTabSelectConversationCard(conversation)}>
          <PreJobAwardConversationCard
            conversationId={
              conversation?.conversationId ||
              conversation?.vendorBidId ||
              conversation?.customerId ||
              conversation?.vendorId
            }
            selectedConversation={selectedConversation}
            isLast={isLast}
            countOfMessages={conversation?.countOfMessages}
            messages={conversation?.messages}
            preJobAward={false}
            conversation={conversation}
            jobName={conversation?.jobName}
            conversationName={conversationName}
          />
        </ConversationCardMainDiv>
      );
    },
    [selectedConversation, conversationName]
  );

  const getCreateConversationPayload = useCallback((): ConversationInput => {
    const { customerId = '' } = getStoredCustomerDetails();

    const payLoad: ConversationInput = {
      message: replyMsgContent,
      messageType: MessageType.InApp,
      organizationId: customerId,
    };
    payLoad.conversationId = selectedConversationId;
    payLoad.customerId = currentlySelectedConversation?.customerId;
    payLoad.vendorId = currentlySelectedConversation?.vendorId;
    payLoad.jobId = currentlySelectedConversation?.jobId;

    return payLoad;
  }, [replyMsgContent, selectedConversationId, currentlySelectedConversation]);

  const handleSendButton = useCallback(async (): Promise<void> => {
    setIsSendButtonLoading(true);
    try {
      const payload = getCreateConversationPayload();

      const response = await JobCreateApiService.createConversation(payload);

      if (response.data && isEmpty(response.errors)) {
        const conversationResponse = await JobCreateApiService.getConversations({
          conversationId: selectedConversationId,
        });
        if (conversationResponse.data && isEmpty(conversationResponse.errors) && conversationsData.length > 0) {
          setSelectedConversation(conversationResponse.data[0]);
        }
        setReplyMsgContent('');
        await getConversationsData();
      } else {
        console.log('response error', response.errors);
      }
    } catch (error: any) {
      console.log('send reply error: ', error);
    }
    setIsSendButtonLoading(false);
  }, [selectedConversationId, replyMsgContent, currentlySelectedConversation]);

  useEffect(() => {
    const groupData: GroupChat[] = [];
    const directDataList: DirectChat[] = [];

    if (isSuccess && data) {
      data?.forEach((item: GroupChat) => {
        if (item.mode === 'GROUP' && isEmpty(convertToObject(item?.meta)?.jobId)) {
          groupData?.push(item);
        } else if (item.mode === 'NORMAL' || !isEmpty(convertToObject(item?.meta)?.jobId)) {
          directDataList?.push(item);
        }
      });

      const sortedDirectConversations = directDataList?.sort(
        (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );

      const sortedGroupedConversations = groupData?.sort(
        (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );
      setGroupedData(sortedGroupedConversations);
      setDirectData(sortedDirectConversations);
    }
  }, [data]);

  useEffect(() => {
    getConversationsData();
  }, []);
  return (
    <>
      <PageTitle title={t('conversations:conversations')} />
      <CustomerOuterContainer>
        <ConversationsContainer>
          <ConversationsContainerOne style={{ paddingTop: '20px' }}>
            <TabSection sx={{ height: '100%', position: 'relative' }}>
              <TabsWrapper>
                <Tabs value={tabSelected} onChange={handleChangeTabs} aria-label="basic tabs">
                  <TabTitle label={'Direct'} {...a11yProps(0)} />
                  <TabTitle label={'Group'} {...a11yProps(1)} />
                  <TabTitle label={'Job'} {...a11yProps(2)} />
                </Tabs>
              </TabsWrapper>
              <TabPanel value={tabSelected} index={0}>
                <TabPanelDiv>
                  {directData &&
                    directData.length > 0 &&
                    directData?.map((item: any, index: number) =>
                      renderConversationCard(item, index, directData.length)
                    )}
                  {isEmpty(directData) && (
                    <NoConversationFound>
                      <Typography>{t('conversations:noConversationsFound')}</Typography>
                    </NoConversationFound>
                  )}
                </TabPanelDiv>

                {tabSelected === 0 ? (
                  <>
                    <AddIconWrapper onClick={() => handleAddAction(ConversationTabs.DIRECT_CHAT)}>
                      <img src={Icons.PlusWhiteIcon} />
                    </AddIconWrapper>
                  </>
                ) : null}
              </TabPanel>
              <TabPanel value={tabSelected} index={1}>
                <TabPanelDiv>
                  {groupedData &&
                    groupedData.length > 0 &&
                    groupedData.map((item: any, index: number) =>
                      renderConversationCard(item, index, groupedData.length)
                    )}
                  {isEmpty(groupedData) && (
                    <NoConversationFound>
                      <Typography>{t('conversations:noConversationsFound')}</Typography>
                    </NoConversationFound>
                  )}
                </TabPanelDiv>

                {tabSelected === 1 ? (
                  <>
                    <AddIconWrapper onClick={() => handleAddAction(ConversationTabs.GROUP_CHAT)}>
                      <img src={Icons.PlusWhiteIcon} />
                    </AddIconWrapper>
                  </>
                ) : null}
              </TabPanel>
              <TabPanel value={tabSelected} index={2}>
                {isInitialLoading.current && isConversationsDataLoading ? (
                  <CircularProgress size={30} sx={{ color: theme.palette.primary.dark, margin: '20% 45%' }} />
                ) : (
                  <TabPanelDiv>
                    {conversationsData &&
                      conversationsData.length > 0 &&
                      conversationsData.map((item: any, index: number) =>
                        renderJobsTabConversationCard(item, index, conversationsData.length)
                      )}
                    {isEmpty(conversationsData) && (
                      <NoConversationFound>
                        <Typography>{t('conversations:noConversationsFound')}</Typography>
                      </NoConversationFound>
                    )}
                  </TabPanelDiv>
                )}
              </TabPanel>
            </TabSection>
          </ConversationsContainerOne>
          <ConversationsContainerTwo
            style={{
              borderBottomRightRadius: '10px',
              borderTopRightRadius: '20px',
            }}>
            <MessageScreen
              selectedConversation={selectedConversation}
              placeHolder={tabSelected === 2 ? t('conversations:giveYourReplyHere') : placeHolder}
              conversationDetails={conversationDetails}
              conversationName={conversationName}
              userName={loggedInUserName}
              companyName={organizationName}
              loggedInUserInternalId={loggedInUserInternalId}
              replyMsgContent={replyMsgContent}
              setReplyMsgContent={setReplyMsgContent}
              handleJobTabSendButton={handleSendButton}
              tabSelected={tabSelected}
              isSendButtonLoading={isSendButtonLoading}
              selectedConversationLoading={isSelectedConversationsDataLoading}
            />
          </ConversationsContainerTwo>
        </ConversationsContainer>
      </CustomerOuterContainer>
      {createDirectConversation && (
        <CreateConversations
          loggedInUserType={loggedInUserType}
          loggedInUserInternalId={loggedInUserInternalId}
          customerId={customerId}
          createNewConversation={createDirectConversation}
          setCreateConversation={setCreateDirectConversation}
          conversationMode={ConversationTabs.DIRECT_CHAT}
          organizationName={organizationName}
          loggedInUserName={loggedInUserName}
          onNewConversation={handleDirectConversation}
          conversationData={directData}
          setPlaceHolder={setPlaceHolder}
          setConversationName={setConversationName}
        />
      )}

      {createGroupConversation && (
        <CreateConversations
          loggedInUserType={loggedInUserType}
          loggedInUserInternalId={loggedInUserInternalId}
          customerId={customerId}
          createNewConversation={createGroupConversation}
          setCreateConversation={setCreateGroupConversation}
          conversationMode={ConversationTabs.GROUP_CHAT}
          organizationName={organizationName}
          loggedInUserName={loggedInUserName}
          onNewConversation={handleGroupedConversation}
          conversationData={groupedData}
          setPlaceHolder={setPlaceHolder}
          setConversationName={setConversationName}
        />
      )}
    </>
  );
};

export default DashboardConversations;
