/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useCallback, useState } from 'react';
import { useOTJCommHelper } from 'otj-client-lib';
import { isEmpty } from 'lodash';

import { convertToObject, getAcronym, getAuthDetails } from '../../../Utilities/utils';
import { UserType } from '../../../Constants/App';

import ReplyBar from './SendMessages';

import { ConversationName } from '../ConversationsScreen/Conversations.Style';
import { LogoWrapper } from '../../../../Modules/Jobs/Components/JobDetails/OneTimeJobDetails.Style';
import { HeaderText, MessageBody, MessageFooter, MessagesContainer, SelectConversation } from './MessagesScreen.Style';
import { Box, CircularProgress } from '@mui/material';
import theme from '../../../Themes/theme';
import PreJobAwardMessageCard from '../../../../Modules/Jobs/Components/Conversations/PreJobAwardMessageCard';
import MessageCardContainer from './MessageCardContainer';
import { useTranslation } from 'react-i18next';

const MessageScreen = (props: any): JSX.Element => {
  const {
    selectedConversation,
    placeHolder,
    conversationDetails,
    conversationName,
    userName,
    companyName,
    loggedInUserInternalId,
    preJobAward,
    handlePreJobAwardSendButton,
    isSendButtonLoading,
    isDataLoading,
    replyMsgContent,
    setReplyMsgContent,
    hideConversationTab,
    isDistrictManager,
    handleJobTabSendButton,
    tabSelected,
    selectedConversationLoading,
  } = props;

  const { t } = useTranslation(['conversations']);

  const [isConversationsDataLoading, setIsConversationsDataLoading] = useState<boolean>(false);

  const { chatHooks } = useOTJCommHelper();
  const { sendMessage } = chatHooks;

  const storedAuthDetails = getAuthDetails();
  const loggedInUserType = storedAuthDetails['custom:Role'] as UserType;

  const renderName = useCallback((): string => {
    if (!isEmpty(selectedConversation?.name)) return selectedConversation?.name;

    const { meta } = selectedConversation || {};

    if (!isEmpty(convertToObject(meta))) {
      const { vendorId, vendorCompanyName, associateName, customerOrDmOrSubVendorCompanyName } = convertToObject(meta);

      if (loggedInUserType === UserType.Vendor) {
        const isNotVendor = vendorId !== loggedInUserInternalId;
        if (isNotVendor) {
          return vendorCompanyName || associateName || '';
        }
        return customerOrDmOrSubVendorCompanyName || associateName || '';
      }

      if ([UserType.Associate, UserType.Customer].includes(loggedInUserType)) {
        return vendorCompanyName || '';
      }
    }

    return '';
  }, [selectedConversation]);

  const getDisplayNameForDirectChat = useCallback(() => {
    return (
      selectedConversation?.meta?.userInformation?.find((item: { id: any }) => item?.id !== loggedInUserInternalId)
        ?.name ?? ''
    );
  }, [selectedConversation]);

  const getDisplayNameForPreJobAward = useCallback(() => {
    if (loggedInUserType === UserType.Customer) {
      return selectedConversation?.vendorName;
    } else if (loggedInUserType === UserType.Vendor) {
      if (isDistrictManager) {
        return selectedConversation?.vendorName || selectedConversation?.customerName;
      }
      return selectedConversation?.customerName || selectedConversation?.vendorCompanyName;
    }
  }, [selectedConversation]);

  const handleSendButton = useCallback(() => {
    setIsConversationsDataLoading(true);
    try {
      if (!isEmpty(selectedConversation?.id)) {
        sendMessage?.(replyMsgContent, selectedConversation?.id, undefined, undefined, {
          senderName: userName ?? '',
          senderId: loggedInUserInternalId ?? '',
          senderCompanyName: companyName ?? '',
        });
        setReplyMsgContent('');
      }
      setIsConversationsDataLoading(false);
    } catch (e: any) {
      console.log('conversation create error', e);
    }
  }, [selectedConversation, replyMsgContent, loggedInUserInternalId, companyName, userName]);

  const displayName = useCallback(() => {
    if (preJobAward) return getDisplayNameForPreJobAward();
    if (selectedConversation?.mode === 'GROUP') return renderName();
    return getDisplayNameForDirectChat() || conversationName;
  }, [selectedConversation, conversationName]);

  const renderMessageFooter = useCallback(() => {
    return (
      <MessageFooter>
        <ReplyBar
          replyMsgContent={replyMsgContent}
          setReplyMsgContent={setReplyMsgContent}
          isConversationsDataLoading={isConversationsDataLoading || isSendButtonLoading}
          selectedConversationLoading={selectedConversationLoading}
          handleSendButton={
            !preJobAward ? (tabSelected === 2 ? handleJobTabSendButton : handleSendButton) : handlePreJobAwardSendButton
          }
          selectedConversation={selectedConversation}
          placeHolder={placeHolder}
        />
      </MessageFooter>
    );
  }, [
    replyMsgContent,
    setReplyMsgContent,
    isConversationsDataLoading,
    isSendButtonLoading,
    selectedConversationLoading,
    tabSelected,
    selectedConversation,
    placeHolder,
  ]);

  return (
    <>
      {isDataLoading ? (
        <CircularProgress size={30} sx={{ color: theme.palette.primary.dark, margin: '20% 45%' }} />
      ) : isEmpty(selectedConversation) ? (
        hideConversationTab ? (
          <SelectConversation>{t('conversations:thereAreNoMessagesAvailable')}</SelectConversation>
        ) : (
          <SelectConversation>{t('conversations:pleaseSelectTheConversation')}</SelectConversation>
        )
      ) : (
        <>
          <HeaderText>
            <Box
              style={{
                display: 'flex',
                gap: '1rem',
                alignItems: 'center',
                margin: '1.5rem',
              }}>
              <LogoWrapper
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '50%',
                }}>
                <Box>{getAcronym(displayName() || '').toUpperCase()}</Box>
              </LogoWrapper>
              <ConversationName>{displayName()}</ConversationName>
            </Box>
          </HeaderText>
          <MessageBody>
            <MessagesContainer>
              {isConversationsDataLoading || isSendButtonLoading || selectedConversationLoading ? (
                <CircularProgress size={30} sx={{ color: theme.palette.primary.dark, margin: '20% 45%' }} />
              ) : selectedConversation?.messages ? (
                <PreJobAwardMessageCard
                  selectedConversation={selectedConversation}
                  conversationDetails={conversationDetails}
                />
              ) : (
                <MessageCardContainer
                  selectedConversation={selectedConversation}
                  conversationDetails={conversationDetails}
                />
              )}
            </MessagesContainer>
          </MessageBody>
          {renderMessageFooter()}
        </>
      )}
    </>
  );
};

export default MessageScreen;
