/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled, Dialog } from '@mui/material';

export const DeleteWrapper = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '1.5rem',
    overflowY: 'unset',
    padding: '2rem',
    width: '41.25rem',
  },
}));

export const ButtonsContainer = styled('div')(() => ({
  padding: '2rem 0 1rem 0',
  display: 'flex',
  flexDirection: 'row',
  gap: '1.5rem',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
}));

export const MessageText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.375rem',
  color: theme.palette.text.secondary,
  textAlign: 'center',
}));

export const NameText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem',
  color: theme.palette.text.secondary,
  textAlign: 'center',
}));

export const MessageBox = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  margin: 0,
  gap: '0.5rem',
  flexWrap: 'wrap',
}));
