/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useState } from 'react';
import { Box, CircularProgress, List, Typography } from '@mui/material';
import { FilterOptions } from '../../../../Shared/Components/Common/Dropdown/Dropdown.styles';
import { SearchInput } from '../../../../Shared/Components/Layout/styles';
import { Icons } from '../../../../Shared/Constants/Icons';
import { useTranslation } from 'react-i18next';
import theme from '../../../../Shared/Themes/theme';

interface DropdownOption {
  label: string;
  value: string;
}

interface FilterDropDownOptionsProps {
  options: DropdownOption[];
  handleFilterChange: (value: string, event?: React.MouseEvent<HTMLInputElement>) => void;
  filterLoader: boolean;
}

const FilterDropDownOptions: React.FC<FilterDropDownOptionsProps> = ({ options, handleFilterChange, filterLoader }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options.slice(0, 6)); // Show top 6 by default

  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const { t } = useTranslation(['tableHeader']);

  const handleSearch = (term: string) => {
    setSearchTerm(term);
    const filtered = options.filter((option) => option.label.toLowerCase().includes(term.toLowerCase()));
    setFilteredOptions(filtered.slice(0, 6)); // Update displayed options
  };

  const handleOptionSelect = (value: string, label: string, e?: React.MouseEvent<HTMLInputElement>) => {
    setSelectedValue(value);
    setSearchTerm(label);
    handleFilterChange(value, e);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setSelectedValue('');
    setFilteredOptions(options.slice(0, 6)); // Reset to default options
    handleFilterChange('');
  };

  return (
    <Box
      sx={{
        width: 250,

        borderRadius: '1rem 1rem 0.5rem 0.5rem',
        backgroundColor: '#fff',
        boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
        paddingTop: '1rem',
      }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.palette.common.white,
            borderRadius: '1rem',
            width: '90%',
            flexDirection: 'row',
            paddingRight: '1rem',
            border: `0.0625rem solid ${theme.palette.divider}`,
          }}>
          <SearchInput
            placeholder={t('oneTimeJob:search')}
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            sx={{
              flex: 1,
            }}
          />
          {(searchTerm || selectedValue) && (
            <img src={Icons.CancelIcon} alt={t('altTexts:clear')} onClick={handleClearSearch} />
          )}
          <img src={Icons.SearchIcon} alt={t('altTexts:search')} />
        </Box>
      </Box>

      <List sx={{ maxHeight: 150, overflowY: 'auto' }}>
        {filterLoader ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0.5rem',
            }}>
            <CircularProgress size={24} style={{ color: '#006B6B' }} />
          </Box>
        ) : filteredOptions.length > 0 ? (
          filteredOptions.map((option: DropdownOption) => (
            <FilterOptions
              key={option.value}
              selected={selectedValue === option.value}
              onClick={() => handleOptionSelect(option.value, option.label)}>
              {option.label}
            </FilterOptions>
          ))
        ) : (
          <Box
            sx={{
              padding: '1rem',
              fontSize: theme.typography.h4?.fontSize,
            }}>
            <Typography color="textSecondary" align="center">
              No options found
            </Typography>
          </Box>
        )}
      </List>
    </Box>
  );
};

export default FilterDropDownOptions;
