/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { getAcronym } from '../../../../Shared/Utilities/utils';
import ConversationCardUI from '../../../../Shared/Components/Conversations/ConversationsScreen/ConversationCardUI';
import moment from 'moment';

const PreJobAwardConversationCard = (props: any): JSX.Element => {
  const {
    conversationId,
    selectedConversation,
    isLast,
    messages,
    conversation,
    isDistrictManager,
    isSubVendorInSidePeazy,
    jobName,
    conversationName,
  } = props;

  const recentMessage = messages?.slice()?.reverse()?.[0]?.message;

  const modifiedOn = messages?.slice()?.reverse()?.[0]?.modifiedOn;

  const updatedAt =
    messages?.length && Array.isArray(messages)
      ? moment.utc(modifiedOn).local().format('MMM DD, YYYY hh:mm A')
      : undefined;

  const isSelected =
    conversationId ===
    (selectedConversation?.conversationId || selectedConversation?.vendorBidId || selectedConversation?.customerId);

  const nameToRender = conversationName
    ? conversationName
    : isSubVendorInSidePeazy
      ? isDistrictManager
        ? conversation?.vendorName
        : conversation?.customerName || conversation?.vendorCompanyName
      : conversation?.vendorName || conversation?.customerName;

  return (
    <ConversationCardUI
      conversationId={conversationId}
      isSelected={isSelected}
      isLast={isLast}
      name={nameToRender}
      recentMessage={recentMessage}
      jobName={jobName ?? ''}
      unreadCount={0}
      updatedAt={updatedAt ?? ''}
      isGroupMode={false}
      acronymName={getAcronym(nameToRender)}
    />
  );
};

export default PreJobAwardConversationCard;
