/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MRT_SortingState } from 'material-react-table';
import { Divider, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import { isEmpty } from 'lodash';

import { getServiceVolume } from '../../Utilities/Jobs.Utils';
import {
  deepClone,
  getDurationInDaysOrMonths,
  getStoredCustomerDetails,
  handleFilter,
  handleSort,
  NUMBER_OF_ITEMS_PER_PAGE,
  fetchDataFromS3Bucket,
} from '../../../../Shared/Utilities/utils';
import { getTotalServiceArea } from '../../Utilities/MarketplacePage.Utils';

import AsyncStorageKeys from '../../../../Shared/Constants/StorageKeys';
import { ActionTypes, SortByType } from '../../../../Shared/Constants/App';

import { Facility, JobDueDate, JobInterestsActionType, JobRecommendation, ServiceCategoryV3 } from '../../../../API';
import MarketplaceApiService from '../../Services/marketplaceServices';
import BidCreationApiService from '../../Services/BidCreationApiService';
import JobCreateApiService from '../../../Jobs/Services/JobCreateService';
import MasterDataApiService from '../../../../Shared/Services/MasterDataService';

import { IJobRecommendationsType, JobType, MarketplaceTabs, SortingItem } from '../../Models/Marketplace.model';
import { JobServices } from '../../../../Shared/Models/Bid';
import { FilterItem } from '../../../Jobs/Components/JobsListView/JobsListView';

import DataGridComponent from './BaseTable';
import useColumns from './useColumns';
import { fetchFacilityTypes } from '../../../Customer/Components/ViewFacilityDetails/FacilityType';
import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';

import { Icons } from '../../../../Shared/Constants/Icons';
import theme from '../../../../Shared/Themes/theme';
import {
  ActionsColumn,
  Linking,
  Menus,
  MenuWrapper,
} from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import WhiteTooltip from '../../../../Shared/Components/Common/CommonStyle/Tooltip.Style';

const RecommendedJobs = (): JSX.Element => {
  const { t } = useTranslation(['vendor', 'tableHeader', 'altTexts', 'vendorBidCreation', 'jobAward']);

  const [sortBy, setSortBy] = useState('');
  const [clickedRow, setClickedRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [rowInfo, setRowInfo] = useState<any>({});
  const [jobs, setJobs] = useState<any[]>([]);
  const [jobInterests, setJobInterests] = useState<any[]>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [newSortOrder, setNewSortOrder] = useState('');
  const [newSort, setNewSort] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [columnFilters, setColumnFilters] = useState<{ id: string; value: string }[]>([]);

  const navigate = useNavigate();
  const hasFetchedDataRef = useRef(false);

  const myCustomSortingFn = (): void => {
    setSortBy(SortByType.DESC);
  };

  const onSortChange = async (sortingObj: SortingItem[]): Promise<void> => {
    await handleSort(
      sortingObj,
      newSortOrder,
      setSorting,
      setNewSortOrder,
      setNewSort,
      getJobsData,
      hasFetchedDataRef,
      newSort,
      currentPage,
      itemsPerPage
    );
  };

  const onFilterChange = async (filtersObj: FilterItem[]): Promise<void> => {
    await handleFilter(
      filtersObj,
      setColumnFilters,
      getJobsData,
      currentPage,
      itemsPerPage,
      newSortOrder,
      hasFetchedDataRef,
      newSort
    );
  };

  const handleChange = (event: any, value: number) => {
    setCurrentPage(value);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const getServiceCategories = async (customerId: string) => {
    try {
      const masterData = await MasterDataApiService.getAllServiceCategoryV3(customerId);
      return masterData.data ?? [];
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  const getJobDueDate = (jobItem: JobRecommendation) =>
    jobItem?.jobDueDates?.find((item) => item?.mdDueType === 'Bid') ?? null;

  const mapJobServices = async (jobItem: JobRecommendation, customerId: string) => {
    const serviceCategories = await getServiceCategories(customerId);

    const jobServices: JobServices[] = (jobItem.jobServices || []).map((bidEstimationItem: any, index: number) => {
      const selectedService: any = serviceCategories.find(
        (serviceCategoryItem: ServiceCategoryV3) =>
          serviceCategoryItem?.serviceCategoryId === bidEstimationItem?.mdServiceId
      );
      return {
        service: selectedService?.serviceCategoryName ?? '',
        id: index + 1,
        mdServiceId: bidEstimationItem?.mdServiceId,
        unit: bidEstimationItem?.actualMdServiceUnits,
        totalJobEstimation: bidEstimationItem?.totalCost,
        totalServices: 1,
        serviceMeasure: bidEstimationItem?.actualServiceMeasure,
      };
    });
    return jobServices;
  };

  const getAttachments = async (attachments: any[] = []) => {
    let photos: any[] = [];
    let videos: any[] = [];
    if (attachments?.length) {
      const attachmentsList = await Promise.all(
        attachments.map(async (attachment: any) => {
          if (attachment?.url && !attachment.url.includes('https')) {
            attachment.url = await fetchDataFromS3Bucket(navigate, attachment?.url ?? '');
          }
          return attachment;
        })
      );
      const imagesList: any[] = [];
      const videosList: any[] = [];
      attachmentsList.forEach((attachment: any) => {
        if (attachment.mdAttachmentType.includes('image')) {
          imagesList.push(attachment);
        } else if (attachment?.mdAttachmentType?.includes('video')) {
          videosList?.push(attachment);
        }
      });
      photos = [...photos, ...imagesList];
      videos = [...videos, ...videosList];
    }

    return { photos, videos };
  };

  const mapJobData = async (
    jobItem: JobRecommendation,
    jobDueDate: JobDueDate | null,
    facilityAddress: Facility,
    jobSummaryResponse: any,
    jobServices: JobServices[],
    photos: any[],
    videos: any[]
  ) => {
    const area = getTotalServiceArea(jobSummaryResponse?.data?.jobServices ?? []);
    const services = jobServices?.map((service) => service.service).join(', ');

    const jobBids: any = [];
    return {
      ...jobItem,
      id: jobItem.jobId,
      bidDueDate: jobDueDate?.dueDate,
      createdOn: jobItem?.createdOn,
      address: facilityAddress?.address ?? '',
      location: (facilityAddress?.address?.addressLine2 || facilityAddress?.address?.addressLine1) ?? '',
      facilityAddress: (facilityAddress?.address?.addressLine2 || facilityAddress?.address?.addressLine1) ?? '',
      area: area ?? '',
      duration: getDurationInDaysOrMonths(jobItem?.startDate || '', jobItem?.stopDate || ''),
      noOfDaysLeft: getDurationInDaysOrMonths(jobDueDate?.dueDate || '', jobItem?.startDate || ''),
      volume: getServiceVolume(facilityAddress?.sqFootage as number),
      facilityType: await fetchFacilityTypes(facilityAddress?.mdFacilityType as string),
      facilityName: facilityAddress?.buildingName ?? '',
      type: JobType.RecommendedJob,
      jobServices: jobServices,
      jobBids: jobBids,
      customerId: jobSummaryResponse.data?.customerId ?? '',
      jobAttachments: jobSummaryResponse?.data?.jobAttachments,
      photosAndVideos: { photos, videos },
      mdServiceName: services,
      specialInstructions: jobItem?.specialInstructions,
      dueDate: jobDueDate?.dueDate,
      status: jobSummaryResponse?.data?.dataStatus,
    };
  };

  const transformJobsData = async (jobsData: IJobRecommendationsType) => {
    if (isEmpty(jobsData?.data?.matchedJobs)) return [];

    const facilities = jobsData?.data?.matchedFacilities ?? [];

    const jobsPromises = jobsData?.data?.matchedJobs?.map(async (jobItem: JobRecommendation) => {
      const facilityAddress = !isEmpty(facilities)
        ? facilities?.find((facilityItem: Facility) => facilityItem.facilityId === jobItem?.jobFacility?.facilityId)
        : {};
      const jobSummaryResponse = await JobCreateApiService.jobSummaryV2('123', jobItem.jobId, false);

      const jobDueDate = getJobDueDate(jobItem);
      const jobServices = await mapJobServices(jobItem, jobSummaryResponse?.data?.customerId);

      const { photos, videos } = await getAttachments(jobSummaryResponse?.data?.jobAttachments);

      return await mapJobData(jobItem, jobDueDate, facilityAddress, jobSummaryResponse, jobServices, photos, videos);
    });

    return await Promise.all(jobsPromises);
  };

  const getJobsData = async (pageNumber?: number, limit?: number, sortBy?: any, sortOrder?: boolean, filter?: any) => {
    try {
      setIsLoading(true);
      let sortOrderString;
      if (sortOrder) {
        sortOrderString = 'desc';
      } else {
        if (sortBy) sortOrderString = 'asc';
      }
      const { customerId } = getStoredCustomerDetails();
      setIsLoading(true);
      const jobsData: IJobRecommendationsType = await MarketplaceApiService.getRecommendedJobs(
        customerId,
        new Date(moment().utc().format('YYYY-MM-DDT00:00:00.000Z')).toISOString(),
        limit ?? NUMBER_OF_ITEMS_PER_PAGE,
        pageNumber ? pageNumber : currentPage,
        sortBy,
        sortOrderString,
        filter
      );

      const allJobsInterestedResponse = await BidCreationApiService.getJobInterests('1234', customerId);
      if (jobsData?.data) {
        setTotalCount(jobsData?.metadata?.totalCount);
        const modifiedData = await transformJobsData(jobsData);
        setJobs(modifiedData ?? []);
        if (allJobsInterestedResponse.data) {
          setJobInterests(allJobsInterestedResponse.data);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error:', error);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClickInterestedOrNot = async (jobId: string, actionType: JobInterestsActionType) => {
    setIsLoading(true);
    const { customerId } = getStoredCustomerDetails();
    let addedJobInterestResponse;
    if (jobInterests.findIndex((value) => value.jobId === jobId) === -1) {
      const payload = {
        customerId: customerId || '1234',
        vendorId: customerId,
        createJobInterestInput: [
          {
            jobId,
            actionType,
          },
        ],
      };
      addedJobInterestResponse = await BidCreationApiService.addJobInterest(
        payload.customerId,
        payload.vendorId,
        payload.createJobInterestInput
      );
    } else {
      const payload = {
        customerId: customerId || '1234',
        vendorId: customerId,
        createJobInterestInput: {
          jobId,
          actionType,
        },
      };
      addedJobInterestResponse = await BidCreationApiService.updateJobInterest(
        payload.customerId,
        payload.vendorId,
        payload.createJobInterestInput
      );
    }
    if (addedJobInterestResponse.data && addedJobInterestResponse.errors.length === 0) {
      getJobsData();
    } else {
      setIsLoading(false);
      setSnackbarMessage(addedJobInterestResponse.errors[0]?.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const renderActionsCell = (row: any): JSX.Element => {
    return (
      <ActionsColumn>
        <Linking
          to={{ pathname: `/create-bid` }}
          state={{
            jobDetails: { ...jobs[row.index], isVendorBid: true },
            jobId: jobs[row.index].jobId,
          }}>
          <IconButton
            size="large"
            sx={{
              '&:hover': {
                background: theme.palette.primary.main,
              },
            }}
            onClick={() => {
              localStorage.removeItem(AsyncStorageKeys.currentJob);
            }}>
            <WhiteTooltip title={t('vendor:bid')}>
              <img src={Icons.BidIcon} alt={t('altTexts:bidNow')} />
            </WhiteTooltip>
          </IconButton>
        </Linking>

        <WhiteTooltip title={t('vendor:more')}>
          <IconButton
            key={row.index}
            sx={{
              backgroundColor: clickedRow === row.index && open ? theme.palette.primary.main : 'unset',
              '&:hover': {
                background: theme.palette.primary.main,
              },
            }}
            onClick={(e) => {
              handleClick(e);
              setClickedRow(row.index);
              setRowInfo(row.original);
            }}
            size="large"
            aria-haspopup="true"
            {...(open && { 'aria-expanded': 'true', 'aria-controls': 'account-menu' })}>
            <MoreVertIcon sx={{ color: theme.palette.common.black }}></MoreVertIcon>
          </IconButton>
        </WhiteTooltip>
        <MenuWrapper anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose} onClick={handleClose}>
          <Linking
            to={{ pathname: `/onetimejobdetails`, search: `?id=${rowInfo.jobId}` }}
            state={{
              facilityItem: { ...rowInfo },
              facility: { ...rowInfo.jobFacility, address: { addressName: rowInfo.address } },
              job: rowInfo,
            }}>
            <Menus>
              <img src={Icons.EyeIcon} alt={t(ActionTypes.TextDetails)} /> {t('dashboard:viewJobDetails')}
            </Menus>
          </Linking>
          <Divider />
          <Linking
            to={{ pathname: `/conversations`, search: `?jobId=${rowInfo.jobId}` }}
            state={{
              ...rowInfo,
            }}>
            <Menus>
              <img src={Icons.ConversationIcon} alt={t('altTexts:conversation')} /> {t('altTexts:conversation')}
            </Menus>
          </Linking>
          <>
            <Divider />
            <Menus
              onClick={() => handleOnClickInterestedOrNot(rowInfo.jobId, JobInterestsActionType.NotInterested)}
              style={{ paddingLeft: '23px' }}>
              <img src={Icons.VectorIcon} alt={t('altTexts:notInterested')} /> {t('vendor:notInterested')}
            </Menus>
          </>
        </MenuWrapper>
      </ActionsColumn>
    );
  };

  const { columns, media } = useColumns(MarketplaceTabs.RecommendedJobs);

  const recommendedColumns = useMemo(() => {
    const jobColumns = deepClone(columns);
    jobColumns.splice(16, 0, {
      header: 'Actions',
      enableColumnActions: false,
      enablePinning: false,
      size: 70,
      Cell: ({ row }: any) => renderActionsCell(row),
    });
    return jobColumns;
  }, [columns]);

  useEffect(() => {
    if (!hasFetchedDataRef.current && !sorting.length && !columnFilters.length) {
      getJobsData(currentPage, itemsPerPage);
      hasFetchedDataRef.current = true;
    }
  }, [sorting, columnFilters]);

  return (
    <>
      <DataGridComponent
        columns={recommendedColumns}
        data={jobs}
        sorting={sorting}
        sortBy={sortBy}
        isLoading={isLoading}
        handleSort={onSortChange}
        myCustomSortingFn={myCustomSortingFn}
        handleChange={handleChange}
        totalCount={totalCount}
        handleApi={getJobsData}
        currentPage={currentPage}
        newSortOrder={newSortOrder}
        newSort={newSort}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        filterJobs={onFilterChange}
      />
      {media}
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default RecommendedJobs;
