/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { isEmpty } from 'lodash';
import { API } from 'aws-amplify';

import { OrganizationService } from './OrganizationService';
import { IGraphQLError, IGraphQLResponse } from '../Models';

import { UserManagers, BooleanType } from '../../API';
import { jobBidSummary, vendor } from '../../graphql/queries';
import { AuthHeader, AWSAuthMode } from '../Constants/App';
import { getWebInfo } from '../Utilities/utils';
import { AuthenticationService } from './AuthenticationService';

export default class VendorApiService {
  static async getVendorTeam(
    managerId: string,
    userId?: string,
    isAssociate?: BooleanType | '',
    searchString?: string,
    pageNumber?: number,
    limit?: number,
    sortBy?: string,
    sortOrder?: string,
    isPaginationRequired = true
  ) {
    try {
      const managersTeamsResponse = await OrganizationService.getUserManagers(
        managerId,
        userId ?? '',
        isAssociate,
        searchString,
        pageNumber,
        limit,
        sortBy,
        sortOrder,
        isPaginationRequired
      );

      const modifiedUsersData =
        managersTeamsResponse?.data?.map((userData: UserManagers) => {
          return {
            ...userData?.user,
            username: userData?.user?.userName,
            userId: userData?.userId,
          };
        }) || [];

      const usersData = modifiedUsersData;
      const usersError = managersTeamsResponse.errors;
      if (!isEmpty(usersError)) {
        return { data: [], errors: usersError, metadata: {} };
      }
      return { data: usersData || [], metadata: managersTeamsResponse?.metadata ?? {}, errors: [] };
    } catch (e: unknown) {
      const apiError: IGraphQLError = e as IGraphQLError;
      return { data: [], errors: apiError.errors, metadata: {} };
    }
  }

  static async getBidSummary(bidId: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: jobBidSummary,
          variables: { bidId },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.jobBidSummary ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async getVendorDetails(id: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const vendorResponse: IGraphQLResponse = await API.graphql(
        {
          query: vendor,
          variables: { id },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: vendorResponse.data?.vendor ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }
}
