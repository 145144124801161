/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const addTaskApproval = /* GraphQL */ `mutation AddTaskApproval(
  $taskId: String!
  $createTaskApproval: [CreateTaskApprovalInput]!
) {
  addTaskApproval(taskId: $taskId, createTaskApproval: $createTaskApproval) {
    added {
      approvalId
      approvalName
      dueDate
      startDate
      completionDate
      reason
      status
      rankingRate
      approvalStatus
      mdApproverType
      __typename
    }
    modified {
      approvalId
      approvalName
      dueDate
      startDate
      completionDate
      reason
      status
      rankingRate
      approvalStatus
      mdApproverType
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddTaskApprovalMutationVariables,
  APITypes.AddTaskApprovalMutation
>;
export const addTaskAttachment = /* GraphQL */ `mutation AddTaskAttachment(
  $taskId: String!
  $createTaskAttachment: [CreateTaskAttachmentInput]!
) {
  addTaskAttachment(
    taskId: $taskId
    createTaskAttachment: $createTaskAttachment
  ) {
    added {
      attachmentId
      attachmentStatus
      mdAttachmentType
      fileName
      mdFileContext
      fileExtension
      url
      fileHash
      comments
      createdById
      createdByName
      __typename
    }
    deleted {
      attachmentId
      attachmentStatus
      mdAttachmentType
      fileName
      mdFileContext
      fileExtension
      url
      fileHash
      comments
      createdById
      createdByName
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddTaskAttachmentMutationVariables,
  APITypes.AddTaskAttachmentMutation
>;
export const updateTaskAttachment = /* GraphQL */ `mutation UpdateTaskAttachment(
  $taskId: String!
  $updateTaskAttachmentInput: [UpdateTaskAttachmentInput]
) {
  updateTaskAttachment(
    taskId: $taskId
    updateTaskAttachmentInput: $updateTaskAttachmentInput
  ) {
    attachmentId
    attachmentStatus
    mdAttachmentType
    fileName
    mdFileContext
    fileExtension
    url
    fileHash
    comments
    createdById
    createdByName
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTaskAttachmentMutationVariables,
  APITypes.UpdateTaskAttachmentMutation
>;
export const createIssue = /* GraphQL */ `mutation CreateIssue($createIssueInput: CreateIssueInput) {
  createIssue(createIssueInput: $createIssueInput) {
    issueId
    title
    shortDescription
    issueRequestor
    issueResolver
    issuePriority
    templateId
    entityId
    templateValue
    attachments {
      comments
      attachmentId
      attachmentStatus
      mdAttachmentType
      fileName
      mdFileContext
      fileExtension
      url
      fileHash
      dataStatus
      fileType
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIssueMutationVariables,
  APITypes.CreateIssueMutation
>;
export const updateIssue = /* GraphQL */ `mutation UpdateIssue($updateIssueInput: UpdateIssueInput) {
  updateIssue(updateIssueInput: $updateIssueInput) {
    data {
      issueId
      title
      shortDescription
      issueRequestor
      issueResolver
      issuePriority
      templateId
      entityId
      templateValue
      attachments {
        comments
        attachmentId
        attachmentStatus
        mdAttachmentType
        fileName
        mdFileContext
        fileExtension
        url
        fileHash
        dataStatus
        fileType
        __typename
      }
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIssueMutationVariables,
  APITypes.UpdateIssueMutation
>;
export const addPreShiftChecklistTemplate = /* GraphQL */ `mutation AddPreShiftChecklistTemplate(
  $workOrderId: String!
  $createPreShiftChecklistTemplateInput: PreShiftChecklistTemplateInput!
) {
  addPreShiftChecklistTemplate(
    workOrderId: $workOrderId
    createPreShiftChecklistTemplateInput: $createPreShiftChecklistTemplateInput
  ) {
    preShiftChecklistTemplateId
    checkListName
    checkListIconUrl
    mdPreShiftCheckListCategoryId
    buildingAccessConfirmation
    securityAccessConfirmation
    safetyConfirmation
    msdsChemicalSafety
    workStatus
    workOrderId
    workOrderRecSeq
    preShiftChecklistTemplateItems {
      itemName
      preShiftChecklistTemplateItemId
      mdItemActionType
      quantity
      mdQuantityType
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddPreShiftChecklistTemplateMutationVariables,
  APITypes.AddPreShiftChecklistTemplateMutation
>;
export const updatePreShiftChecklistTemplate = /* GraphQL */ `mutation UpdatePreShiftChecklistTemplate(
  $preShiftChecklistTemplateId: String!
  $createPreShiftChecklistTemplateInput: PreShiftChecklistTemplateInput!
) {
  updatePreShiftChecklistTemplate(
    preShiftChecklistTemplateId: $preShiftChecklistTemplateId
    createPreShiftChecklistTemplateInput: $createPreShiftChecklistTemplateInput
  ) {
    preShiftChecklistTemplateId
    checkListName
    checkListIconUrl
    mdPreShiftCheckListCategoryId
    buildingAccessConfirmation
    securityAccessConfirmation
    safetyConfirmation
    msdsChemicalSafety
    workStatus
    workOrderId
    workOrderRecSeq
    updatedPreShiftChecklistTemplateItems {
      added {
        itemName
        preShiftChecklistTemplateItemId
        mdItemActionType
        quantity
        mdQuantityType
        __typename
      }
      deleted {
        itemName
        preShiftChecklistTemplateItemId
        mdItemActionType
        quantity
        mdQuantityType
        __typename
      }
      modified {
        itemName
        preShiftChecklistTemplateItemId
        mdItemActionType
        quantity
        mdQuantityType
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePreShiftChecklistTemplateMutationVariables,
  APITypes.UpdatePreShiftChecklistTemplateMutation
>;
export const addPreShiftChecklist = /* GraphQL */ `mutation AddPreShiftChecklist(
  $preShiftChecklistTemplateId: String!
  $createPreShiftChecklistInput: [PreShiftChecklistInput]!
) {
  addPreShiftChecklist(
    preShiftChecklistTemplateId: $preShiftChecklistTemplateId
    createPreShiftChecklistInput: $createPreShiftChecklistInput
  ) {
    executionDate
    workDayId
    preShiftChecklistId
    checkListName
    checkListIconUrl
    mdPreShiftCheckListCategoryId
    preShiftChecklistTemplateId
    preShiftChecklistItems {
      itemName
      mdItemActionType
      quantity
      mdQuantityType
      workOrderServiceId
      markedAsDone
      markedBy
      markedOn
      preShiftChecklistId
      preShiftChecklistItemId
      preShiftChecklistTemplateItemId
      preShiftChecklistIssueId
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddPreShiftChecklistMutationVariables,
  APITypes.AddPreShiftChecklistMutation
>;
export const updatePreShiftChecklist = /* GraphQL */ `mutation UpdatePreShiftChecklist(
  $preShiftChecklistId: String!
  $createPreShiftChecklistInput: PreShiftChecklistInput!
) {
  updatePreShiftChecklist(
    preShiftChecklistId: $preShiftChecklistId
    createPreShiftChecklistInput: $createPreShiftChecklistInput
  ) {
    executionDate
    workDayId
    preShiftChecklistId
    checkListName
    checkListIconUrl
    mdPreShiftCheckListCategoryId
    preShiftChecklistTemplateId
    preShiftChecklistItems {
      itemName
      mdItemActionType
      quantity
      mdQuantityType
      workOrderServiceId
      markedAsDone
      markedBy
      markedOn
      preShiftChecklistId
      preShiftChecklistItemId
      preShiftChecklistTemplateItemId
      preShiftChecklistIssueId
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePreShiftChecklistMutationVariables,
  APITypes.UpdatePreShiftChecklistMutation
>;
export const createPreShiftChecklistTemplate = /* GraphQL */ `mutation CreatePreShiftChecklistTemplate(
  $workOrderId: String!
  $woScheduleMasterId: String!
  $createPreShiftChecklistTemplateInput: PreShiftChecklistTemplateInput!
) {
  createPreShiftChecklistTemplate(
    workOrderId: $workOrderId
    woScheduleMasterId: $woScheduleMasterId
    createPreShiftChecklistTemplateInput: $createPreShiftChecklistTemplateInput
  ) {
    preShiftChecklistTemplateId
    woScheduleMasterId
    checkListName
    checkListIconUrl
    mdPreShiftCheckListCategoryId
    buildingAccessConfirmation
    securityAccessConfirmation
    safetyConfirmation
    msdsChemicalSafety
    workStatus
    workOrderId
    workOrderRecSeq
    preShiftChecklistTemplateItems {
      itemName
      preShiftChecklistTemplateItemId
      mdItemActionType
      quantity
      mdQuantityType
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePreShiftChecklistTemplateMutationVariables,
  APITypes.CreatePreShiftChecklistTemplateMutation
>;
export const updateShiftDetails = /* GraphQL */ `mutation UpdateShiftDetails(
  $updateShiftDetailsInput: [UpdateShiftDetailsInput]
) {
  updateShiftDetails(updateShiftDetailsInput: $updateShiftDetailsInput) {
    shiftDetailId
    startTime
    endTime
    workOrderId
    punchInGraceMinutes
    punchOutGraceMinutes
    scheduleName
    woScheduleMasterId
    scheduleStartDate
    scheduleEndDate
    endDateOffset
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateShiftDetailsMutationVariables,
  APITypes.UpdateShiftDetailsMutation
>;
export const updateScheduleMasters = /* GraphQL */ `mutation UpdateScheduleMasters(
  $updateScheduleMasterInput: [UpdateScheduleMasterInput]
) {
  updateScheduleMasters(updateScheduleMasterInput: $updateScheduleMasterInput) {
    woScheduleMasterId
    startTime
    endTime
    workOrderId
    punchInGraceMinutes
    punchOutGraceMinutes
    scheduleName
    scheduleStartDate
    scheduleEndDate
    endDateOffset
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateScheduleMastersMutationVariables,
  APITypes.UpdateScheduleMastersMutation
>;
export const addPunchCard = /* GraphQL */ `mutation AddPunchCard(
  $workDayId: String!
  $workOrderId: String!
  $createPunchCard: PunchCardInput
) {
  addPunchCard(
    workDayId: $workDayId
    workOrderId: $workOrderId
    createPunchCard: $createPunchCard
  ) {
    punchCardId
    workDayId
    userId
    punchTime
    punchType
    locationLatitude
    locationLongitude
    punchComments
    status
    errorCode
    punchNotifications
    punchMealPeriodAttestation
    punchWorkersCompConfirmation
    createdBy
    modifiedBy
    punchInTime
    punchOutTime
    totalWorkMinutes
    totalMinutesOnSite
    facilityDistance
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddPunchCardMutationVariables,
  APITypes.AddPunchCardMutation
>;
export const addPunchInOut = /* GraphQL */ `mutation AddPunchInOut($workDayId: String!, $createPunchCard: PunchCardInput) {
  addPunchInOut(workDayId: $workDayId, createPunchCard: $createPunchCard) {
    punchCardId
    workDayId
    userId
    punchTime
    punchType
    locationLatitude
    locationLongitude
    punchComments
    status
    errorCode
    punchNotifications
    punchMealPeriodAttestation
    punchWorkersCompConfirmation
    createdBy
    modifiedBy
    punchInTime
    punchOutTime
    totalWorkMinutes
    totalMinutesOnSite
    facilityDistance
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddPunchInOutMutationVariables,
  APITypes.AddPunchInOutMutation
>;
export const addPunchInOutBySupervisor = /* GraphQL */ `mutation AddPunchInOutBySupervisor(
  $workDayId: String!
  $createPunchCard: [PunchCardInput]!
) {
  addPunchInOutBySupervisor(
    workDayId: $workDayId
    createPunchCard: $createPunchCard
  ) {
    punchCardId
    workDayId
    userId
    punchTime
    punchType
    locationLatitude
    locationLongitude
    punchComments
    status
    errorCode
    punchNotifications
    punchMealPeriodAttestation
    punchWorkersCompConfirmation
    createdBy
    modifiedBy
    punchInTime
    punchOutTime
    totalWorkMinutes
    totalMinutesOnSite
    facilityDistance
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddPunchInOutBySupervisorMutationVariables,
  APITypes.AddPunchInOutBySupervisorMutation
>;
export const updatePunchInOut = /* GraphQL */ `mutation UpdatePunchInOut($updatePunchCard: [UpdatePunchCardInput]) {
  updatePunchInOut(updatePunchCard: $updatePunchCard) {
    punchCardId
    workDayId
    userId
    punchTime
    punchType
    locationLatitude
    locationLongitude
    punchComments
    status
    errorCode
    punchNotifications
    punchMealPeriodAttestation
    punchWorkersCompConfirmation
    createdBy
    modifiedBy
    punchInTime
    punchOutTime
    totalWorkMinutes
    totalMinutesOnSite
    facilityDistance
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePunchInOutMutationVariables,
  APITypes.UpdatePunchInOutMutation
>;
export const removeOrUpdateNoShowPunchCard = /* GraphQL */ `mutation RemoveOrUpdateNoShowPunchCard(
  $workDayId: String!
  $removeOrUpdatePunchCardInput: [RemoveOrUpdatePunchCardInput]
) {
  removeOrUpdateNoShowPunchCard(
    workDayId: $workDayId
    removeOrUpdatePunchCardInput: $removeOrUpdatePunchCardInput
  ) {
    punchCardId
    workDayId
    userId
    punchTime
    punchType
    locationLatitude
    locationLongitude
    punchComments
    status
    errorCode
    punchNotifications
    punchMealPeriodAttestation
    punchWorkersCompConfirmation
    createdBy
    modifiedBy
    punchInTime
    punchOutTime
    totalWorkMinutes
    totalMinutesOnSite
    facilityDistance
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveOrUpdateNoShowPunchCardMutationVariables,
  APITypes.RemoveOrUpdateNoShowPunchCardMutation
>;
export const addShiftBreak = /* GraphQL */ `mutation AddShiftBreak(
  $workDayId: String!
  $createShiftBreak: ShiftBreakInput!
) {
  addShiftBreak(workDayId: $workDayId, createShiftBreak: $createShiftBreak) {
    recordId
    punchType
    facilityId
    userId
    startTime
    endTime
    comments
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddShiftBreakMutationVariables,
  APITypes.AddShiftBreakMutation
>;
export const updateShiftBreak = /* GraphQL */ `mutation UpdateShiftBreak(
  $workDayId: String!
  $updateShiftBreak: ShiftBreakInput!
) {
  updateShiftBreak(workDayId: $workDayId, updateShiftBreak: $updateShiftBreak) {
    recordId
    punchType
    facilityId
    userId
    startTime
    endTime
    comments
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateShiftBreakMutationVariables,
  APITypes.UpdateShiftBreakMutation
>;
export const addShiftDetails = /* GraphQL */ `mutation AddShiftDetails(
  $workOrderId: String!
  $createShiftDetailsInput: [ShiftDetailsInput]!
) {
  addShiftDetails(
    workOrderId: $workOrderId
    createShiftDetailsInput: $createShiftDetailsInput
  ) {
    added {
      shiftDetailId
      startTime
      endTime
      workOrderId
      punchInGraceMinutes
      punchOutGraceMinutes
      scheduleName
      __typename
    }
    deleted {
      shiftDetailId
      startTime
      endTime
      workOrderId
      punchInGraceMinutes
      punchOutGraceMinutes
      scheduleName
      __typename
    }
    modified {
      shiftDetailId
      startTime
      endTime
      workOrderId
      punchInGraceMinutes
      punchOutGraceMinutes
      scheduleName
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddShiftDetailsMutationVariables,
  APITypes.AddShiftDetailsMutation
>;
export const generateTaskWithAssignments = /* GraphQL */ `mutation GenerateTaskWithAssignments($generateTasksInput: GenerateTaskInput) {
  generateTaskWithAssignments(generateTasksInput: $generateTasksInput) {
    errorCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GenerateTaskWithAssignmentsMutationVariables,
  APITypes.GenerateTaskWithAssignmentsMutation
>;
export const updateMultipleTask = /* GraphQL */ `mutation UpdateMultipleTask($updateTaskInput: [UpdateTaskInput!]) {
  updateMultipleTask(updateTaskInput: $updateTaskInput) {
    taskId
    occurrenceDate
    suggestedReworkDate
    suggestedScheduleType
    taskNumber
    markedAsDone
    markedBy
    markedOn
    taskStatus
    workOrderId
    workDayId
    taskScheduleId
    mdServiceDescription
    plannedStartTime
    plannedEndTime
    plannedEffort
    actualEffort
    actualStartTime
    actualEndTime
    associateId
    taskSchedule {
      taskScheduleId
      mdShiftType
      isProofOfCompletionRequired
      schedule
      duration
      mdTaskId
      noOfOccurrences
      noOfOccurrencesCompleted
      __typename
    }
    taskAssignment {
      taskId
      taskAssignmentId
      assignedBy
      assignedOn
      associateId
      __typename
    }
    reworkTaskId
    errorCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMultipleTaskMutationVariables,
  APITypes.UpdateMultipleTaskMutation
>;
export const updateTask = /* GraphQL */ `mutation UpdateTask($taskId: String!, $updateTaskInput: UpdateTaskInput!) {
  updateTask(taskId: $taskId, updateTaskInput: $updateTaskInput) {
    taskId
    occurrenceDate
    suggestedReworkDate
    suggestedScheduleType
    taskNumber
    markedAsDone
    markedBy
    markedOn
    taskStatus
    workOrderId
    workDayId
    taskScheduleId
    mdServiceDescription
    plannedStartTime
    plannedEndTime
    plannedEffort
    actualEffort
    actualStartTime
    actualEndTime
    associateId
    taskSchedule {
      taskScheduleId
      mdShiftType
      isProofOfCompletionRequired
      schedule
      duration
      mdTaskId
      noOfOccurrences
      noOfOccurrencesCompleted
      __typename
    }
    taskAssignment {
      taskId
      taskAssignmentId
      assignedBy
      assignedOn
      associateId
      __typename
    }
    reworkTaskId
    errorCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTaskMutationVariables,
  APITypes.UpdateTaskMutation
>;
export const updateTaskAssignment = /* GraphQL */ `mutation UpdateTaskAssignment($taskAssignmentInput: AssignTaskInput) {
  updateTaskAssignment(taskAssignmentInput: $taskAssignmentInput) {
    associateId
    assignedBy
    assignedOn
    taskId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTaskAssignmentMutationVariables,
  APITypes.UpdateTaskAssignmentMutation
>;
export const updateTaskSchedule = /* GraphQL */ `mutation UpdateTaskSchedule(
  $updateTaskScheduleInput: [UpdateTaskScheduleInput]!
) {
  updateTaskSchedule(updateTaskScheduleInput: $updateTaskScheduleInput) {
    taskScheduleId
    mdShiftType
    isProofOfCompletionRequired
    schedule
    duration
    mdTaskId
    noOfOccurrences
    noOfOccurrencesCompleted
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTaskScheduleMutationVariables,
  APITypes.UpdateTaskScheduleMutation
>;
export const addJobAcceptance = /* GraphQL */ `mutation AddJobAcceptance(
  $teamId: String!
  $workOrderId: String!
  $assignTeamInput: AssignTeamInput!
) {
  addJobAcceptance(
    teamId: $teamId
    workOrderId: $workOrderId
    assignTeamInput: $assignTeamInput
  ) {
    teamId
    vendorId
    userId
    roleId
    isSupervisor
    workOrderId
    jobAccepted
    associateStartDate
    associateEndDate
    woScheduleMasterId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddJobAcceptanceMutationVariables,
  APITypes.AddJobAcceptanceMutation
>;
export const addWorkOrderTeam = /* GraphQL */ `mutation AddWorkOrderTeam(
  $workOrderId: String!
  $createWorkOrderTeamInput: [CreateWorkOrderTeam]!
) {
  addWorkOrderTeam(
    workOrderId: $workOrderId
    createWorkOrderTeamInput: $createWorkOrderTeamInput
  ) {
    added {
      teamId
      vendorId
      userId
      roleId
      isSupervisor
      workOrderId
      jobAccepted
      associateStartDate
      associateEndDate
      woScheduleMasterId
      __typename
    }
    deleted {
      teamId
      vendorId
      userId
      roleId
      isSupervisor
      workOrderId
      jobAccepted
      associateStartDate
      associateEndDate
      woScheduleMasterId
      __typename
    }
    reactivated {
      teamId
      vendorId
      userId
      roleId
      isSupervisor
      workOrderId
      jobAccepted
      associateStartDate
      associateEndDate
      woScheduleMasterId
      __typename
    }
    modified {
      teamId
      vendorId
      userId
      roleId
      isSupervisor
      workOrderId
      jobAccepted
      associateStartDate
      associateEndDate
      woScheduleMasterId
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddWorkOrderTeamMutationVariables,
  APITypes.AddWorkOrderTeamMutation
>;
export const addScheduleAssignments = /* GraphQL */ `mutation AddScheduleAssignments(
  $workOrderId: String!
  $woScheduleMasterId: String!
  $createScheduleAssignmentsInput: [CreateScheduleAssignments]
) {
  addScheduleAssignments(
    workOrderId: $workOrderId
    woScheduleMasterId: $woScheduleMasterId
    createScheduleAssignmentsInput: $createScheduleAssignmentsInput
  ) {
    teamId
    vendorId
    userId
    roleId
    isSupervisor
    workOrderId
    jobAccepted
    associateStartDate
    associateEndDate
    woScheduleMasterId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddScheduleAssignmentsMutationVariables,
  APITypes.AddScheduleAssignmentsMutation
>;
export const deleteScheduleAssignments = /* GraphQL */ `mutation DeleteScheduleAssignments(
  $workOrderId: String!
  $woScheduleMasterId: String!
  $userIds: [String]!
) {
  deleteScheduleAssignments(
    workOrderId: $workOrderId
    woScheduleMasterId: $woScheduleMasterId
    userIds: $userIds
  )
}
` as GeneratedMutation<
  APITypes.DeleteScheduleAssignmentsMutationVariables,
  APITypes.DeleteScheduleAssignmentsMutation
>;
export const updateScheduleAssignments = /* GraphQL */ `mutation UpdateScheduleAssignments(
  $workOrderId: String!
  $updateScheduleAssignmentsInput: [UpdateScheduleAssignments]
) {
  updateScheduleAssignments(
    workOrderId: $workOrderId
    updateScheduleAssignmentsInput: $updateScheduleAssignmentsInput
  ) {
    teamId
    vendorId
    userId
    roleId
    isSupervisor
    workOrderId
    jobAccepted
    associateStartDate
    associateEndDate
    woScheduleMasterId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateScheduleAssignmentsMutationVariables,
  APITypes.UpdateScheduleAssignmentsMutation
>;
export const removeAssociateFromWorkOrderTeam = /* GraphQL */ `mutation RemoveAssociateFromWorkOrderTeam(
  $removeAssociateInput: RemoveAssociateInput
) {
  removeAssociateFromWorkOrderTeam(
    removeAssociateInput: $removeAssociateInput
  ) {
    userId
    comments
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveAssociateFromWorkOrderTeamMutationVariables,
  APITypes.RemoveAssociateFromWorkOrderTeamMutation
>;
export const updateWorkOrderTeam = /* GraphQL */ `mutation UpdateWorkOrderTeam(
  $workOrderId: String!
  $updateWorkOrderTeamInput: [UpdateWorkOrderTeam]
) {
  updateWorkOrderTeam(
    workOrderId: $workOrderId
    updateWorkOrderTeamInput: $updateWorkOrderTeamInput
  ) {
    teamId
    vendorId
    userId
    roleId
    isSupervisor
    workOrderId
    jobAccepted
    associateStartDate
    associateEndDate
    woScheduleMasterId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWorkOrderTeamMutationVariables,
  APITypes.UpdateWorkOrderTeamMutation
>;
export const rescheduleWorkDays = /* GraphQL */ `mutation RescheduleWorkDays(
  $workOrderId: String!
  $rescheduleWorkDayInput: [RescheduleWorkDayInput!]
) {
  rescheduleWorkDays(
    workOrderId: $workOrderId
    rescheduleWorkDayInput: $rescheduleWorkDayInput
  ) {
    rescheduleSuccess {
      facilityId
      workDayId
      workWeeks
      proofOfCompletion
      timeSheets
      workDate
      geolocation
      geofencing
      mandatoryTimeOfPresence
      calculatedTimeToComplete
      actualTimeToComplete
      routeTracking
      taskRemindersAndAlerts
      issuePrepaidCard
      communication
      reworkTasks
      ratings
      mdRatingTypes
      preShiftChecklistId
      workOrderId
      __typename
    }
    rescheduleFailure {
      workDayId
      newDate
      errorCode
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RescheduleWorkDaysMutationVariables,
  APITypes.RescheduleWorkDaysMutation
>;
export const addWorkOrderFacilityAccess = /* GraphQL */ `mutation AddWorkOrderFacilityAccess(
  $workOrderId: String!
  $createWorkOrderFacilityAccessInput: CreateWorkOrderFacilityAccess!
) {
  addWorkOrderFacilityAccess(
    workOrderId: $workOrderId
    createWorkOrderFacilityAccessInput: $createWorkOrderFacilityAccessInput
  ) {
    facilityId
    facilityName
    facilityAddress
    accessContactPerson
    accessContactDetails
    otherInformation
    accessInfo
    accessCode
    day
    accessFrom
    accessTo
    from
    to
    workOrderFacilityAccessId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddWorkOrderFacilityAccessMutationVariables,
  APITypes.AddWorkOrderFacilityAccessMutation
>;
export const deleteWorkOrders = /* GraphQL */ `mutation DeleteWorkOrders($deleteWorkOrdersInput: DeleteWorkOrderInput!) {
  deleteWorkOrders(deleteWorkOrdersInput: $deleteWorkOrdersInput)
}
` as GeneratedMutation<
  APITypes.DeleteWorkOrdersMutationVariables,
  APITypes.DeleteWorkOrdersMutation
>;
export const updateWorkOrderStatus = /* GraphQL */ `mutation UpdateWorkOrderStatus($updateWorkOrderInput: UpdateWorkOrderInput!) {
  updateWorkOrderStatus(updateWorkOrderInput: $updateWorkOrderInput) {
    workOrderId
    jobId
    bidId
    vendorId
    customerId
    name
    status
    totalAmount
    createdBy
    createdOn
    modifiedBy
    modifiedOn
    jobStartDate
    jobType
    jobEndDate
    actualStartDate
    actualEndDate
    jobCustodian
    jobOrderAcceptedOn
    jobOrderAcceptedBy
    jobOrderFilePath
    paymentMethod
    paymentFrequency
    paymentDay
    facilities {
      facilityId
      facilityName
      facilityAddress
      accessContactPerson
      accessContactDetails
      otherInformation
      accessInfo
      accessCode
      day
      accessFrom
      accessTo
      from
      to
      workOrderFacilityAccessId
      __typename
    }
    services {
      workOrderServiceId
      mdServiceId
      serviceMeasure
      mdServiceUnits
      taskSchedule {
        taskScheduleId
        mdTaskId
        isProofOfCompletionRequired
        __typename
      }
      __typename
    }
    jobBidSummary {
      jobBidSummaryId
      jobDetails
      bidDetails
      __typename
    }
    workOrderTeams {
      teamId
      vendorId
      userId
      roleId
      isSupervisor
      workOrderId
      jobAccepted
      associateStartDate
      associateEndDate
      woScheduleMasterId
      __typename
    }
    shiftDetails {
      shiftDetailId
      startTime
      endTime
      punchInGraceMinutes
      punchOutGraceMinutes
      __typename
    }
    subWorkOrders {
      workOrderId
      jobId
      bidId
      vendorId
      customerId
      name
      status
      totalAmount
      createdBy
      createdOn
      modifiedBy
      modifiedOn
      jobStartDate
      jobType
      jobEndDate
      actualStartDate
      actualEndDate
      jobCustodian
      jobOrderAcceptedOn
      jobOrderAcceptedBy
      jobOrderFilePath
      paymentMethod
      paymentFrequency
      paymentDay
      hasChild
      parentWorkOrderId
      services {
        workOrderServiceId
        mdServiceId
        serviceMeasure
        mdServiceUnits
        taskSchedule {
          taskScheduleId
          mdTaskId
          isProofOfCompletionRequired
          __typename
        }
        __typename
      }
      shiftDetails {
        shiftDetailId
        startTime
        endTime
        punchInGraceMinutes
        punchOutGraceMinutes
        __typename
      }
      jobBidSummary {
        jobBidSummaryId
        jobDetails
        bidDetails
        __typename
      }
      bidApprovalStatus
      supervisorIds
      __typename
    }
    hasChild
    parentWorkOrderId
    jobCreator
    bidApprovalStatus
    allowVendorToReschedule
    isPaid
    workOrderRating
    isInspectionRequiredForParentJob
    supervisorIds
    workOrderIssueCount
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWorkOrderStatusMutationVariables,
  APITypes.UpdateWorkOrderStatusMutation
>;
export const deleteTaskAttachments = /* GraphQL */ `mutation DeleteTaskAttachments(
  $deleteTaskAttachmentInput: DeleteTaskAttachmentInput!
) {
  deleteTaskAttachments(deleteTaskAttachmentInput: $deleteTaskAttachmentInput)
}
` as GeneratedMutation<
  APITypes.DeleteTaskAttachmentsMutationVariables,
  APITypes.DeleteTaskAttachmentsMutation
>;
export const updateWorkDay = /* GraphQL */ `mutation UpdateWorkDay(
  $workDayId: String
  $updateWorkDayInput: UpdateWorkDayInput
) {
  updateWorkDay(
    workDayId: $workDayId
    updateWorkDayInput: $updateWorkDayInput
  ) {
    isReworkDay
    isShiftRescheduled
    facilityId
    workDayId
    workWeeks
    proofOfCompletion
    timeSheets
    workDate
    geolocation
    geofencing
    mandatoryTimeOfPresence
    calculatedTimeToComplete
    actualTimeToComplete
    routeTracking
    taskRemindersAndAlerts
    issuePrepaidCard
    communication
    reworkTasks
    ratings
    mdRatingTypes
    preShiftChecklistId
    workOrderId
    startTime
    endTime
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWorkDayMutationVariables,
  APITypes.UpdateWorkDayMutation
>;
export const markNoShow = /* GraphQL */ `mutation MarkNoShow($markNoShowInput: MarkNoShowInput!) {
  markNoShow(markNoShowInput: $markNoShowInput) {
    workOrderId
    userId
    workOrderScheduleId
    noShowReason
    noShowDescription
    notifiedEarlier
    markedBySupervisor
    dataStatus
    recordId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.MarkNoShowMutationVariables,
  APITypes.MarkNoShowMutation
>;
export const cancelSchedules = /* GraphQL */ `mutation CancelSchedules($cancelSchedulesInput: [CancelSchedulesInput]!) {
  cancelSchedules(cancelSchedulesInput: $cancelSchedulesInput) {
    workOrderId
    cancelWorkOrderSchedules {
      success {
        type
        lastDateOfService
        closureStartDate
        shiftRestartDate
        shiftCancellationDate
        workOrderScheduleId
        woScheduleMasterId
        __typename
      }
      failed {
        type
        lastDateOfService
        closureStartDate
        shiftRestartDate
        shiftCancellationDate
        workOrderScheduleId
        woScheduleMasterId
        error
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CancelSchedulesMutationVariables,
  APITypes.CancelSchedulesMutation
>;
export const createAssociateProfile = /* GraphQL */ `mutation CreateAssociateProfile(
  $associateProfile: AssociateProfileInput
  $associateId: String
) {
  createAssociateProfile(
    associateProfile: $associateProfile
    associateId: $associateId
  ) {
    associateProfileRecId
    associateId
    cognitoUserId
    title
    linkedInURL
    dateOfBirth
    name
    emergencyContactName
    emergencyContactNumber
    eeoInfo
    primaryLanguage
    languagesSpoken
    source
    logoUrl
    emailId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAssociateProfileMutationVariables,
  APITypes.CreateAssociateProfileMutation
>;
export const updateAssociateProfile = /* GraphQL */ `mutation UpdateAssociateProfile(
  $associateProfile: AssociateProfileUpdate
  $associateId: String
) {
  updateAssociateProfile(
    associateProfile: $associateProfile
    associateId: $associateId
  ) {
    associateProfileRecId
    associateId
    cognitoUserId
    title
    linkedInURL
    dateOfBirth
    name
    emergencyContactName
    emergencyContactNumber
    eeoInfo
    primaryLanguage
    languagesSpoken
    source
    logoUrl
    emailId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAssociateProfileMutationVariables,
  APITypes.UpdateAssociateProfileMutation
>;
export const createAssociateAddress = /* GraphQL */ `mutation CreateAssociateAddress(
  $associateAddress: AssociateAddressInput
  $associateId: String
) {
  createAssociateAddress(
    associateAddress: $associateAddress
    associateId: $associateId
  ) {
    addressId
    associateId
    addressName
    addressLine1
    addressLine2
    addressLine3
    city
    stateCode
    county
    postalCode
    mdCountryCode
    landmark
    googlePlaceId
    latitude
    longitude
    regionId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAssociateAddressMutationVariables,
  APITypes.CreateAssociateAddressMutation
>;
export const updateAssociateAddress = /* GraphQL */ `mutation UpdateAssociateAddress(
  $associateAddress: AssociateAddressUpdate
  $associateId: String
) {
  updateAssociateAddress(
    associateAddress: $associateAddress
    associateId: $associateId
  ) {
    addressId
    associateId
    addressName
    addressLine1
    addressLine2
    addressLine3
    city
    stateCode
    county
    postalCode
    mdCountryCode
    landmark
    googlePlaceId
    latitude
    longitude
    regionId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAssociateAddressMutationVariables,
  APITypes.UpdateAssociateAddressMutation
>;
export const updateAssociateServiceLocation = /* GraphQL */ `mutation UpdateAssociateServiceLocation(
  $associateServiceLocationInput: [AssociateServiceLocationInput]
  $associateId: String
) {
  updateAssociateServiceLocation(
    associateServiceLocationInput: $associateServiceLocationInput
    associateId: $associateId
  ) {
    serviceLocationsDeleted {
      associateServiceLocationId
      associateId
      mdAreaName
      mdAreaType
      mdAreaCategory
      locationId
      country
      __typename
    }
    serviceLocationsAdded {
      associateServiceLocationId
      associateId
      mdAreaName
      mdAreaType
      mdAreaCategory
      locationId
      country
      __typename
    }
    serviceLocationsReactivated {
      associateServiceLocationId
      associateId
      mdAreaName
      mdAreaType
      mdAreaCategory
      locationId
      country
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAssociateServiceLocationMutationVariables,
  APITypes.UpdateAssociateServiceLocationMutation
>;
export const updateAssociateShiftPreferences = /* GraphQL */ `mutation UpdateAssociateShiftPreferences(
  $associateShiftPreferencesInput: [AssociateShiftPreferenceInput]
  $associateId: String
) {
  updateAssociateShiftPreferences(
    associateShiftPreferencesInput: $associateShiftPreferencesInput
    associateId: $associateId
  ) {
    shiftPreferenceId
    day
    fromTime
    toTime
    isFlexibleShiftPreference
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAssociateShiftPreferencesMutationVariables,
  APITypes.UpdateAssociateShiftPreferencesMutation
>;
export const updateAssociateSkills = /* GraphQL */ `mutation UpdateAssociateSkills(
  $associateSkillsInput: [AssociateSkillsInput]
  $associateId: String
) {
  updateAssociateSkills(
    associateSkillsInput: $associateSkillsInput
    associateId: $associateId
  ) {
    added
    deleted
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAssociateSkillsMutationVariables,
  APITypes.UpdateAssociateSkillsMutation
>;
export const addAddress = /* GraphQL */ `mutation AddAddress($addressInput: AddressInput!) {
  addAddress(addressInput: $addressInput) {
    addressId
    customerId
    addressName
    noOfBuildings
    addressLine1
    addressLine2
    addressLine3
    city
    stateCode
    county
    postalCode
    mdCountryCode
    landmark
    googlePlaceId
    latitude
    longitude
    mdMsaId
    logoUrl
    intId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddAddressMutationVariables,
  APITypes.AddAddressMutation
>;
export const updateAddress = /* GraphQL */ `mutation UpdateAddress($addressData: AddressData!) {
  updateAddress(addressData: $addressData) {
    addressId
    customerId
    addressName
    noOfBuildings
    addressLine1
    addressLine2
    addressLine3
    city
    stateCode
    county
    postalCode
    mdCountryCode
    landmark
    googlePlaceId
    latitude
    longitude
    mdMsaId
    logoUrl
    intId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAddressMutationVariables,
  APITypes.UpdateAddressMutation
>;
export const addCustomer = /* GraphQL */ `mutation AddCustomer($customerInput: CustomerInput!) {
  addCustomer(customerInput: $customerInput) {
    customerId
    legalName
    doingBusinessAs
    websiteUrl
    federalEmployerId
    SICCode
    taxId
    logo
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddCustomerMutationVariables,
  APITypes.AddCustomerMutation
>;
export const updateCustomer = /* GraphQL */ `mutation UpdateCustomer($customerId: String!, $customerData: CustomerData!) {
  updateCustomer(customerId: $customerId, customerData: $customerData) {
    customerId
    legalName
    doingBusinessAs
    websiteUrl
    federalEmployerId
    SICCode
    taxId
    logo
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomerMutationVariables,
  APITypes.UpdateCustomerMutation
>;
export const addCustomerDetails = /* GraphQL */ `mutation AddCustomerDetails($customerDetailsInput: CustomerDetailsInput!) {
  addCustomerDetails(customerDetailsInput: $customerDetailsInput) {
    customerDetailsId
    customerId
    noOfEmployees
    annualRevenueEstimate
    mdCustomerType
    referralSource
    phone
    fax
    email
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddCustomerDetailsMutationVariables,
  APITypes.AddCustomerDetailsMutation
>;
export const updateCustomerDetails = /* GraphQL */ `mutation UpdateCustomerDetails($customerDetailsData: CustomerDetailsData!) {
  updateCustomerDetails(customerDetailsData: $customerDetailsData) {
    customerDetailsId
    customerId
    noOfEmployees
    annualRevenueEstimate
    mdCustomerType
    referralSource
    phone
    fax
    email
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomerDetailsMutationVariables,
  APITypes.UpdateCustomerDetailsMutation
>;
export const addFacility = /* GraphQL */ `mutation AddFacility($facilityInput: FacilityInput!) {
  addFacility(facilityInput: $facilityInput) {
    callHours
    transportationName
    storageName
    callStartTime
    callEndTime
    facilityId
    customerId
    buildingName
    buildingMakeup
    noOfFloors
    sqFootage
    mdFacilityType
    serviceableSqFootage
    occupancy
    supplyStorageAvailability
    transportRequiredWithinLocation
    contactId
    facilityTrafficLevel
    emergencyContactName
    emergencyContactPhone
    cleaningInstructions
    geoFenceRadius
    addressId
    address {
      addressId
      customerId
      addressName
      noOfBuildings
      addressLine1
      addressLine2
      addressLine3
      city
      stateCode
      county
      postalCode
      mdCountryCode
      landmark
      googlePlaceId
      latitude
      longitude
      mdMsaId
      logoUrl
      intId
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddFacilityMutationVariables,
  APITypes.AddFacilityMutation
>;
export const updateFacility = /* GraphQL */ `mutation UpdateFacility($facilityData: FacilityData!) {
  updateFacility(facilityData: $facilityData) {
    callHours
    transportationName
    storageName
    callStartTime
    callEndTime
    facilityId
    customerId
    buildingName
    buildingMakeup
    noOfFloors
    sqFootage
    mdFacilityType
    serviceableSqFootage
    occupancy
    supplyStorageAvailability
    transportRequiredWithinLocation
    contactId
    facilityTrafficLevel
    emergencyContactName
    emergencyContactPhone
    cleaningInstructions
    geoFenceRadius
    addressId
    address {
      addressId
      customerId
      addressName
      noOfBuildings
      addressLine1
      addressLine2
      addressLine3
      city
      stateCode
      county
      postalCode
      mdCountryCode
      landmark
      googlePlaceId
      latitude
      longitude
      mdMsaId
      logoUrl
      intId
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFacilityMutationVariables,
  APITypes.UpdateFacilityMutation
>;
export const deleteFacility = /* GraphQL */ `mutation DeleteFacility($deleteFacilityInput: DeleteFacilityInput!) {
  deleteFacility(deleteFacilityInput: $deleteFacilityInput)
}
` as GeneratedMutation<
  APITypes.DeleteFacilityMutationVariables,
  APITypes.DeleteFacilityMutation
>;
export const addCustomerFacilityAccess = /* GraphQL */ `mutation AddCustomerFacilityAccess(
  $customerId: String!
  $facilityId: String!
  $createCustomerFacilityAccessInput: CreateFacilityAccessDetails!
) {
  addCustomerFacilityAccess(
    customerId: $customerId
    facilityId: $facilityId
    createCustomerFacilityAccessInput: $createCustomerFacilityAccessInput
  ) {
    facilityAccessDetailId
    facilityId
    jobId
    accessContactPerson
    roleName
    accessContactDetails
    otherInformation
    accessInfo
    accessCode
    day
    from
    to
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddCustomerFacilityAccessMutationVariables,
  APITypes.AddCustomerFacilityAccessMutation
>;
export const updateCustomerFacilityAccess = /* GraphQL */ `mutation UpdateCustomerFacilityAccess(
  $customerId: String!
  $facilityId: String!
  $updateCustomerFacilityAccessInput: UpdateFacilityAccessDetails!
) {
  updateCustomerFacilityAccess(
    customerId: $customerId
    facilityId: $facilityId
    updateCustomerFacilityAccessInput: $updateCustomerFacilityAccessInput
  ) {
    facilityAccessDetailId
    facilityId
    jobId
    accessContactPerson
    roleName
    accessContactDetails
    otherInformation
    accessInfo
    accessCode
    day
    from
    to
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomerFacilityAccessMutationVariables,
  APITypes.UpdateCustomerFacilityAccessMutation
>;
export const addFacilityServicesDetails = /* GraphQL */ `mutation AddFacilityServicesDetails(
  $facilityServicesInput: FacilityServicesInput!
) {
  addFacilityServicesDetails(facilityServicesInput: $facilityServicesInput) {
    facilityDetailsId
    facilityId
    floorNumber
    mdAreaType
    mdFlooringType
    quantity
    mdUnitType
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddFacilityServicesDetailsMutationVariables,
  APITypes.AddFacilityServicesDetailsMutation
>;
export const updateFacilityServicesDetails = /* GraphQL */ `mutation UpdateFacilityServicesDetails(
  $facilityServicesInput: UpdateFacilityServicesInput!
) {
  updateFacilityServicesDetails(facilityServicesInput: $facilityServicesInput) {
    facilityDetailsId
    facilityId
    floorNumber
    mdAreaType
    mdFlooringType
    quantity
    mdUnitType
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFacilityServicesDetailsMutationVariables,
  APITypes.UpdateFacilityServicesDetailsMutation
>;
export const addFacilityServiceSpecification = /* GraphQL */ `mutation AddFacilityServiceSpecification(
  $customerId: String!
  $facilityId: String!
  $facilityServiceSpecificationInput: [CreateFacilityServiceSpecificationInput]
) {
  addFacilityServiceSpecification(
    customerId: $customerId
    facilityId: $facilityId
    facilityServiceSpecificationInput: $facilityServiceSpecificationInput
  ) {
    added {
      facilityId
      floorNumber
      mdServiceId
      serviceQuantity
      mdServiceUnitType
      __typename
    }
    deleted {
      facilityId
      floorNumber
      mdServiceId
      serviceQuantity
      mdServiceUnitType
      __typename
    }
    reactivated {
      facilityId
      floorNumber
      mdServiceId
      serviceQuantity
      mdServiceUnitType
      __typename
    }
    modified {
      facilityId
      floorNumber
      mdServiceId
      serviceQuantity
      mdServiceUnitType
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddFacilityServiceSpecificationMutationVariables,
  APITypes.AddFacilityServiceSpecificationMutation
>;
export const addBeneficiaries = /* GraphQL */ `mutation AddBeneficiaries(
  $vendorId: String
  $beneficiaryInput: [BeneficiaryInput]
) {
  addBeneficiaries(vendorId: $vendorId, beneficiaryInput: $beneficiaryInput) {
    dataStatus
    beneficiaryId
    name
    TIN
    percentageHolding
    addressLine1
    addressLine2
    city
    state
    zipCode
    vendorId
    modifiedOn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddBeneficiariesMutationVariables,
  APITypes.AddBeneficiariesMutation
>;
export const removeBeneficiaries = /* GraphQL */ `mutation RemoveBeneficiaries($beneficiaryIds: [String]) {
  removeBeneficiaries(beneficiaryIds: $beneficiaryIds) {
    dataStatus
    beneficiaryId
    name
    TIN
    percentageHolding
    addressLine1
    addressLine2
    city
    state
    zipCode
    vendorId
    modifiedOn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveBeneficiariesMutationVariables,
  APITypes.RemoveBeneficiariesMutation
>;
export const updateBeneficiaries = /* GraphQL */ `mutation UpdateBeneficiaries($beneficiaryUpdate: [BeneficiaryUpdate]) {
  updateBeneficiaries(beneficiaryUpdate: $beneficiaryUpdate) {
    dataStatus
    beneficiaryId
    name
    TIN
    percentageHolding
    addressLine1
    addressLine2
    city
    state
    zipCode
    vendorId
    modifiedOn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBeneficiariesMutationVariables,
  APITypes.UpdateBeneficiariesMutation
>;
export const addInsurances = /* GraphQL */ `mutation AddInsurances($vendorId: String, $insuranceInput: [InsuranceInput]) {
  addInsurances(vendorId: $vendorId, insuranceInput: $insuranceInput) {
    fileName
    insuranceName
    validTill
    producer
    insured
    contactName
    contactPhone
    address
    fax
    insuranceId
    dataStatus
    vendorId
    createdOn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddInsurancesMutationVariables,
  APITypes.AddInsurancesMutation
>;
export const updateInsurances = /* GraphQL */ `mutation UpdateInsurances($insuranceUpdate: [InsuranceUpdate]) {
  updateInsurances(insuranceUpdate: $insuranceUpdate) {
    fileName
    insuranceName
    validTill
    producer
    insured
    contactName
    contactPhone
    address
    fax
    insuranceId
    dataStatus
    vendorId
    createdOn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInsurancesMutationVariables,
  APITypes.UpdateInsurancesMutation
>;
export const removeInsurances = /* GraphQL */ `mutation RemoveInsurances($insuranceIds: [String]) {
  removeInsurances(insuranceIds: $insuranceIds) {
    fileName
    insuranceName
    validTill
    producer
    insured
    contactName
    contactPhone
    address
    fax
    insuranceId
    dataStatus
    vendorId
    createdOn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveInsurancesMutationVariables,
  APITypes.RemoveInsurancesMutation
>;
export const addInsuranceAttachment = /* GraphQL */ `mutation AddInsuranceAttachment(
  $insuranceId: String!
  $insuranceAttachmentInput: [VendorAttachmentInput]
) {
  addInsuranceAttachment(
    insuranceId: $insuranceId
    insuranceAttachmentInput: $insuranceAttachmentInput
  ) {
    attachmentId
    refType
    refId
    fileName
    mdFileContext
    fileExtension
    url
    dataStatus
    date
    createdOn
    modifiedOn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddInsuranceAttachmentMutationVariables,
  APITypes.AddInsuranceAttachmentMutation
>;
export const deleteInsuranceAttachment = /* GraphQL */ `mutation DeleteInsuranceAttachment(
  $insuranceId: String!
  $deleteInsuranceAttachmentInput: [String!]
) {
  deleteInsuranceAttachment(
    insuranceId: $insuranceId
    deleteInsuranceAttachmentInput: $deleteInsuranceAttachmentInput
  ) {
    attachmentId
    refType
    refId
    fileName
    mdFileContext
    fileExtension
    url
    dataStatus
    date
    createdOn
    modifiedOn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInsuranceAttachmentMutationVariables,
  APITypes.DeleteInsuranceAttachmentMutation
>;
export const updateInsuranceAttachment = /* GraphQL */ `mutation UpdateInsuranceAttachment(
  $insuranceId: String!
  $updateInsuranceAttachmentInput: [UpdateVendorAttachmentInput]
) {
  updateInsuranceAttachment(
    insuranceId: $insuranceId
    updateInsuranceAttachmentInput: $updateInsuranceAttachmentInput
  ) {
    attachmentId
    refType
    refId
    fileName
    mdFileContext
    fileExtension
    url
    dataStatus
    date
    createdOn
    modifiedOn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInsuranceAttachmentMutationVariables,
  APITypes.UpdateInsuranceAttachmentMutation
>;
export const updateVendorAddress = /* GraphQL */ `mutation UpdateVendorAddress(
  $addressId: String!
  $vendorAddressUpdate: VendorAddressUpdate
) {
  updateVendorAddress(
    addressId: $addressId
    vendorAddressUpdate: $vendorAddressUpdate
  ) {
    addressId
    vendorId
    addressName
    addressLine1
    addressLine2
    addressLine3
    city
    stateCode
    county
    postalCode
    mdCountryCode
    landmark
    googlePlaceId
    latitude
    longitude
    mdMsaId
    modifiedOn
    regionId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVendorAddressMutationVariables,
  APITypes.UpdateVendorAddressMutation
>;
export const createManyVendorAttachments = /* GraphQL */ `mutation CreateManyVendorAttachments(
  $vendorId: String!
  $vendorAttachmentInput: [VendorAttachmentInput!]
) {
  createManyVendorAttachments(
    vendorId: $vendorId
    vendorAttachmentInput: $vendorAttachmentInput
  ) {
    attachmentId
    refType
    refId
    fileName
    mdFileContext
    fileExtension
    url
    dataStatus
    date
    createdOn
    modifiedOn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateManyVendorAttachmentsMutationVariables,
  APITypes.CreateManyVendorAttachmentsMutation
>;
export const updateManyVendorAttachments = /* GraphQL */ `mutation UpdateManyVendorAttachments(
  $vendorId: String!
  $vendorAttachmentInput: [VendorAttachmentInput!]
) {
  updateManyVendorAttachments(
    vendorId: $vendorId
    vendorAttachmentInput: $vendorAttachmentInput
  ) {
    attachmentId
    refType
    refId
    fileName
    mdFileContext
    fileExtension
    url
    dataStatus
    date
    createdOn
    modifiedOn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateManyVendorAttachmentsMutationVariables,
  APITypes.UpdateManyVendorAttachmentsMutation
>;
export const deleteManyVendorAttachments = /* GraphQL */ `mutation DeleteManyVendorAttachments(
  $vendorId: String!
  $attachmentIds: [String!]
) {
  deleteManyVendorAttachments(
    vendorId: $vendorId
    attachmentIds: $attachmentIds
  ) {
    vendorAttachmentId
    vendorId
    attachmentId
    dataStatus
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteManyVendorAttachmentsMutationVariables,
  APITypes.DeleteManyVendorAttachmentsMutation
>;
export const addVendorAddress = /* GraphQL */ `mutation AddVendorAddress(
  $vendorAddressInput: VendorAddressInput
  $vendorId: String
) {
  addVendorAddress(
    vendorAddressInput: $vendorAddressInput
    vendorId: $vendorId
  ) {
    addressId
    vendorId
    addressName
    addressLine1
    addressLine2
    addressLine3
    city
    stateCode
    county
    postalCode
    mdCountryCode
    landmark
    googlePlaceId
    latitude
    longitude
    mdMsaId
    modifiedOn
    regionId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddVendorAddressMutationVariables,
  APITypes.AddVendorAddressMutation
>;
export const addVendorDetails = /* GraphQL */ `mutation AddVendorDetails(
  $vendorDetailsInput: VendorDetailsInput
  $vendorId: String
) {
  addVendorDetails(
    vendorDetailsInput: $vendorDetailsInput
    vendorId: $vendorId
  ) {
    vendorId
    annualRevenueEstimate
    fax
    coi
    noOfEmployees
    workWeekDefinition
    w9FormUrl
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddVendorDetailsMutationVariables,
  APITypes.AddVendorDetailsMutation
>;
export const addVendorTeam = /* GraphQL */ `mutation AddVendorTeam($vendorTeamInput: VendorTeamInput) {
  addVendorTeam(vendorTeamInput: $vendorTeamInput) {
    vendorId
    roleId
    email
    name
    userId
    phoneNumber
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddVendorTeamMutationVariables,
  APITypes.AddVendorTeamMutation
>;
export const addVendor = /* GraphQL */ `mutation AddVendor($vendorInput: VendorInput!) {
  addVendor(vendorInput: $vendorInput) {
    vendorId
    vendorCompanyName
    vendorCompanyType
    doingBusinessAs
    SSID
    taxId
    websiteUrl
    logoUrl
    referralSource
    phone
    email
    dataStatus
    modifiedOn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddVendorMutationVariables,
  APITypes.AddVendorMutation
>;
export const addRoleToVendorTeam = /* GraphQL */ `mutation AddRoleToVendorTeam($vendorRoleInput: AddVendorTeamRoleInput) {
  addRoleToVendorTeam(vendorRoleInput: $vendorRoleInput) {
    roleId
    roleName
    vendorId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddRoleToVendorTeamMutationVariables,
  APITypes.AddRoleToVendorTeamMutation
>;
export const updateVendorDetails = /* GraphQL */ `mutation UpdateVendorDetails(
  $vendorId: String!
  $updateVendorDetailsInput: VendorDetailsUpdate
) {
  updateVendorDetails(
    vendorId: $vendorId
    updateVendorDetailsInput: $updateVendorDetailsInput
  ) {
    vendorId
    annualRevenueEstimate
    fax
    coi
    noOfEmployees
    workWeekDefinition
    w9FormUrl
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVendorDetailsMutationVariables,
  APITypes.UpdateVendorDetailsMutation
>;
export const updateVendorServiceLocation = /* GraphQL */ `mutation UpdateVendorServiceLocation(
  $vendorId: String
  $vendorServiceLocationInput: [VendorServiceLocationInput]
) {
  updateVendorServiceLocation(
    vendorId: $vendorId
    vendorServiceLocationInput: $vendorServiceLocationInput
  ) {
    serviceLocationsDeleted {
      vendorServiceLocationId
      vendorId
      mdAreaName
      mdAreaType
      mdAreaCode
      mdAreaCategory
      country
      locationId
      __typename
    }
    serviceLocationsAdded {
      vendorServiceLocationId
      vendorId
      mdAreaName
      mdAreaType
      mdAreaCode
      mdAreaCategory
      country
      locationId
      __typename
    }
    serviceLocationsReactivated {
      vendorServiceLocationId
      vendorId
      mdAreaName
      mdAreaType
      mdAreaCode
      mdAreaCategory
      country
      locationId
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVendorServiceLocationMutationVariables,
  APITypes.UpdateVendorServiceLocationMutation
>;
export const updateVendorServicesOffered = /* GraphQL */ `mutation UpdateVendorServicesOffered(
  $vendorServicesOfferedInput: [VendorServiceInput]
  $vendorId: String
) {
  updateVendorServicesOffered(
    vendorServicesOfferedInput: $vendorServicesOfferedInput
    vendorId: $vendorId
  ) {
    added
    deleted
    reactivated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVendorServicesOfferedMutationVariables,
  APITypes.UpdateVendorServicesOfferedMutation
>;
export const updateVendorStatus = /* GraphQL */ `mutation UpdateVendorStatus(
  $vendorProfileStatusUpdateInput: VendorProfileStatusUpdateInput!
) {
  updateVendorStatus(
    vendorProfileStatusUpdateInput: $vendorProfileStatusUpdateInput
  ) {
    vendorId
    vendorCompanyName
    vendorCompanyType
    doingBusinessAs
    SSID
    taxId
    websiteUrl
    logoUrl
    referralSource
    phone
    email
    dataStatus
    modifiedOn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVendorStatusMutationVariables,
  APITypes.UpdateVendorStatusMutation
>;
export const updateVendor = /* GraphQL */ `mutation UpdateVendor($vendorId: String!, $vendorUpdate: VendorUpdate) {
  updateVendor(vendorId: $vendorId, vendorUpdate: $vendorUpdate) {
    vendorId
    vendorCompanyName
    vendorCompanyType
    doingBusinessAs
    SSID
    taxId
    websiteUrl
    logoUrl
    referralSource
    phone
    email
    dataStatus
    modifiedOn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVendorMutationVariables,
  APITypes.UpdateVendorMutation
>;
export const createManyVendorAccreditation = /* GraphQL */ `mutation CreateManyVendorAccreditation(
  $vendorId: String!
  $createVendorAccreditationInput: [CreateVendorAccreditationInput]
) {
  createManyVendorAccreditation(
    vendorId: $vendorId
    createVendorAccreditationInput: $createVendorAccreditationInput
  ) {
    recordId
    recStatus
    labels
    certificateId
    accreditationType
    issuingAuthority
    effectiveFromDate
    effectiveEndDate
    accreditationSubType {
      accreditationSubTypeId
      recSeq
      recStatus
      subType
      value
      dataStatus
      vendorAccreditationId
      vendorAccreditationRecSeq
      fromDate
      endDate
      createdBy
      createdOn
      modifiedBy
      modifiedOn
      __typename
    }
    msaCustomer
    type
    status
    dataStatus
    vendorId
    attachmentId
    fromDate
    endDate
    createdBy
    createdOn
    modifiedBy
    modifiedOn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateManyVendorAccreditationMutationVariables,
  APITypes.CreateManyVendorAccreditationMutation
>;
export const deleteManyVendorAccreditation = /* GraphQL */ `mutation DeleteManyVendorAccreditation($recordIds: [String]!) {
  deleteManyVendorAccreditation(recordIds: $recordIds) {
    recordId
    attachmentId
    certificateId
    accreditationType
    labels
    issuingAuthority
    effectiveFromDate
    effectiveEndDate
    accreditationSubType {
      accreditationSubTypeId
      recSeq
      recStatus
      subType
      value
      dataStatus
      vendorAccreditationId
      vendorAccreditationRecSeq
      fromDate
      endDate
      createdBy
      createdOn
      modifiedBy
      modifiedOn
      __typename
    }
    msaCustomer
    type
    status
    dataStatus
    createdBy
    modifiedBy
    createdOn
    modifiedOn
    fromDate
    vendorId
    attachments {
      attachmentId
      refType
      refId
      fileName
      mdFileContext
      fileExtension
      url
      dataStatus
      date
      createdOn
      modifiedOn
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteManyVendorAccreditationMutationVariables,
  APITypes.DeleteManyVendorAccreditationMutation
>;
export const addUser = /* GraphQL */ `mutation AddUser($userInput: UserInput!) {
  addUser(userInput: $userInput) {
    id
    name
    email
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddUserMutationVariables,
  APITypes.AddUserMutation
>;
export const deleteCognitoUser = /* GraphQL */ `mutation DeleteCognitoUser($userData: UserData!) {
  deleteCognitoUser(userData: $userData)
}
` as GeneratedMutation<
  APITypes.DeleteCognitoUserMutationVariables,
  APITypes.DeleteCognitoUserMutation
>;
export const updateCognitoUser = /* GraphQL */ `mutation UpdateCognitoUser($userData: UserData!) {
  updateCognitoUser(userData: $userData)
}
` as GeneratedMutation<
  APITypes.UpdateCognitoUserMutationVariables,
  APITypes.UpdateCognitoUserMutation
>;
export const addMasterData = /* GraphQL */ `mutation AddMasterData($masterDataInput: MasterDataInput!) {
  addMasterData(masterDataInput: $masterDataInput) {
    code
    keyCode
    value
    subKey1Value
    subKey2Value
    canCustomize
    userOrg
    processId
    defaultCode
    keyId
    keyType
    displayName
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddMasterDataMutationVariables,
  APITypes.AddMasterDataMutation
>;
export const addOrgMasterData = /* GraphQL */ `mutation AddOrgMasterData($orgMasterDataInput: OrgMasterDataInput!) {
  addOrgMasterData(orgMasterDataInput: $orgMasterDataInput) {
    keyId
    keyType
    code
    keyCode
    displayName
    value
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddOrgMasterDataMutationVariables,
  APITypes.AddOrgMasterDataMutation
>;
export const updateMasterData = /* GraphQL */ `mutation UpdateMasterData($updateMasterDataInput: UpdateMasterDataInput!) {
  updateMasterData(updateMasterDataInput: $updateMasterDataInput) {
    code
    keyCode
    value
    subKey1Value
    subKey2Value
    canCustomize
    userOrg
    processId
    defaultCode
    keyId
    keyType
    displayName
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMasterDataMutationVariables,
  APITypes.UpdateMasterDataMutation
>;
export const addFeatureFlag = /* GraphQL */ `mutation AddFeatureFlag($featureFlagInput: FeatureFlagInput!) {
  addFeatureFlag(featureFlagInput: $featureFlagInput) {
    keyId
    keyType
    featureName
    featureKey
    description
    isMobileEnabled
    isWebEnabled
    isAdminEnabled
    createdBy
    createdOn
    modifiedBy
    modifiedOn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddFeatureFlagMutationVariables,
  APITypes.AddFeatureFlagMutation
>;
export const openJobForBid = /* GraphQL */ `mutation OpenJobForBid(
  $customerId: String!
  $jobId: String!
  $jobCustomerInput: JobCustomerInput
) {
  openJobForBid(
    customerId: $customerId
    jobId: $jobId
    jobCustomerInput: $jobCustomerInput
  ) {
    jobId
    jobName
    startDate
    stopDate
    dataStatus
    specialInstructions
    draftStep
    draftStatus
    jobType
    __typename
  }
}
` as GeneratedMutation<
  APITypes.OpenJobForBidMutationVariables,
  APITypes.OpenJobForBidMutation
>;
export const generateW9 = /* GraphQL */ `mutation GenerateW9($w9Input: W9Input) {
  generateW9(w9Input: $w9Input) {
    embedURL {
      embeddedSigningURL
      __typename
    }
    requestId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GenerateW9MutationVariables,
  APITypes.GenerateW9Mutation
>;
export const updateUserDetails = /* GraphQL */ `mutation UpdateUserDetails($updateUserDetailsInput: UpdateUserInput) {
  updateUserDetails(updateUserDetailsInput: $updateUserDetailsInput) {
    userId
    email
    phoneNumber
    dataStatus
    salutation
    username
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserDetailsMutationVariables,
  APITypes.UpdateUserDetailsMutation
>;
export const addUserToTeam = /* GraphQL */ `mutation AddUserToTeam($userId: String!, $teamId: String!) {
  addUserToTeam(userId: $userId, teamId: $teamId) {
    userId
    teamId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddUserToTeamMutationVariables,
  APITypes.AddUserToTeamMutation
>;
export const addRoleToUser = /* GraphQL */ `mutation AddRoleToUser($assignRoleToUserInput: AssignRoleToUserInput) {
  addRoleToUser(assignRoleToUserInput: $assignRoleToUserInput) {
    roleId
    userId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddRoleToUserMutationVariables,
  APITypes.AddRoleToUserMutation
>;
export const addRoleToTeam = /* GraphQL */ `mutation AddRoleToTeam($roleId: String!, $teamId: String!) {
  addRoleToTeam(roleId: $roleId, teamId: $teamId) {
    roleId
    teamId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddRoleToTeamMutationVariables,
  APITypes.AddRoleToTeamMutation
>;
export const removeUserFromTeam = /* GraphQL */ `mutation RemoveUserFromTeam($userId: String!, $teamId: String!) {
  removeUserFromTeam(userId: $userId, teamId: $teamId) {
    userId
    teamId
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveUserFromTeamMutationVariables,
  APITypes.RemoveUserFromTeamMutation
>;
export const createOrganization = /* GraphQL */ `mutation CreateOrganization($organizationInput: CreateOrganizationInput) {
  createOrganization(organizationInput: $organizationInput) {
    organizationId
    organizationName
    dataStatus
    teams {
      teamId
      name
      __typename
    }
    users {
      userId
      __typename
    }
    errorCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationMutationVariables,
  APITypes.CreateOrganizationMutation
>;
export const addPermissionToRole = /* GraphQL */ `mutation AddPermissionToRole($inputRolePermission: CreateRolePermissionInput!) {
  addPermissionToRole(inputRolePermission: $inputRolePermission) {
    permissionId
    roleId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddPermissionToRoleMutationVariables,
  APITypes.AddPermissionToRoleMutation
>;
export const createRole = /* GraphQL */ `mutation CreateRole($roleInput: CreateRole!) {
  createRole(roleInput: $roleInput) {
    roleName
    roleId
    organizationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRoleMutationVariables,
  APITypes.CreateRoleMutation
>;
export const createTeam = /* GraphQL */ `mutation CreateTeam($teamInput: CreateTeam!) {
  createTeam(teamInput: $teamInput) {
    organizationId
    name
    teamId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTeamMutationVariables,
  APITypes.CreateTeamMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser($userInput: CreateUser!) {
  createUser(userInput: $userInput) {
    username
    organizationId
    userId
    errorCode
    salutation
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const removeUser = /* GraphQL */ `mutation RemoveUser($removeUserInput: RemoveUser!) {
  removeUser(removeUserInput: $removeUserInput) {
    roleId
    featureId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveUserMutationVariables,
  APITypes.RemoveUserMutation
>;
export const addServiceCategoryV3 = /* GraphQL */ `mutation AddServiceCategoryV3($createServiceCategory: ServiceCategoryV3Input) {
  addServiceCategoryV3(createServiceCategory: $createServiceCategory) {
    serviceCategoryId
    taskId
    mdAreaType
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddServiceCategoryV3MutationVariables,
  APITypes.AddServiceCategoryV3Mutation
>;
export const addOrUpdateOrganizationUser = /* GraphQL */ `mutation AddOrUpdateOrganizationUser(
  $addOrUpdateOrganizationUserInput: AddOrganizationUserInput
) {
  addOrUpdateOrganizationUser(
    addOrUpdateOrganizationUserInput: $addOrUpdateOrganizationUserInput
  ) {
    userId
    username
    salutation
    email
    phoneNumber
    dataStatus
    teamId
    organizationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddOrUpdateOrganizationUserMutationVariables,
  APITypes.AddOrUpdateOrganizationUserMutation
>;
export const createRegion = /* GraphQL */ `mutation CreateRegion($regionInput: RegionInput!) {
  createRegion(regionInput: $regionInput) {
    regionId
    regionName
    organizationId
    parentRegionId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRegionMutationVariables,
  APITypes.CreateRegionMutation
>;
export const updateRegion = /* GraphQL */ `mutation UpdateRegion($regionId: String!, $regionInput: RegionInput!) {
  updateRegion(regionId: $regionId, regionInput: $regionInput) {
    regionId
    regionName
    organizationId
    parentRegionId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRegionMutationVariables,
  APITypes.UpdateRegionMutation
>;
export const createUserRoleScope = /* GraphQL */ `mutation CreateUserRoleScope(
  $userRoleId: String!
  $userRoleScopeInput: UserRoleScopeInput!
) {
  createUserRoleScope(
    userRoleId: $userRoleId
    userRoleScopeInput: $userRoleScopeInput
  ) {
    userRoleScopeId
    keyId
    keyType
    userId
    organizationId
    roleId
    customerId
    mdCountryCode
    regionId
    subRegionId
    facilityId
    userRoleId
    keyName
    userName
    organizationName
    roleName
    customerName
    regionName
    subRegionName
    facilityName
    createdByName
    modifiedByName
    intAddressId
    jobDescription
    jobNumber
    customerNumber
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserRoleScopeMutationVariables,
  APITypes.CreateUserRoleScopeMutation
>;
export const updateUserRoleScope = /* GraphQL */ `mutation UpdateUserRoleScope(
  $userRoleScopeId: String!
  $userRoleScopeInput: UserRoleScopeInput!
) {
  updateUserRoleScope(
    userRoleScopeId: $userRoleScopeId
    userRoleScopeInput: $userRoleScopeInput
  ) {
    userRoleScopeId
    keyId
    keyType
    userId
    organizationId
    roleId
    customerId
    mdCountryCode
    regionId
    subRegionId
    facilityId
    userRoleId
    keyName
    userName
    organizationName
    roleName
    customerName
    regionName
    subRegionName
    facilityName
    createdByName
    modifiedByName
    intAddressId
    jobDescription
    jobNumber
    customerNumber
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserRoleScopeMutationVariables,
  APITypes.UpdateUserRoleScopeMutation
>;
export const deleteUserRoleScope = /* GraphQL */ `mutation DeleteUserRoleScope($userRoleScopeId: String!) {
  deleteUserRoleScope(userRoleScopeId: $userRoleScopeId) {
    userRoleScopeId
    keyId
    keyType
    userId
    organizationId
    roleId
    customerId
    mdCountryCode
    regionId
    subRegionId
    facilityId
    userRoleId
    keyName
    userName
    organizationName
    roleName
    customerName
    regionName
    subRegionName
    facilityName
    createdByName
    modifiedByName
    intAddressId
    jobDescription
    jobNumber
    customerNumber
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserRoleScopeMutationVariables,
  APITypes.DeleteUserRoleScopeMutation
>;
export const deleteUsers = /* GraphQL */ `mutation DeleteUsers($deleteUserInput: DeleteUserInput) {
  deleteUsers(deleteUserInput: $deleteUserInput) {
    userId
    username
    email
    signedUp
    phoneNumber
    dataStatus
    organizationId
    teamId
    errorCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUsersMutationVariables,
  APITypes.DeleteUsersMutation
>;
export const setCognitoUserPassword = /* GraphQL */ `mutation SetCognitoUserPassword(
  $userId: String
  $isPermanentPassword: Boolean
  $shouldNotNotifyUser: Boolean
) {
  setCognitoUserPassword(
    userId: $userId
    isPermanentPassword: $isPermanentPassword
    shouldNotNotifyUser: $shouldNotNotifyUser
  )
}
` as GeneratedMutation<
  APITypes.SetCognitoUserPasswordMutationVariables,
  APITypes.SetCognitoUserPasswordMutation
>;
export const createUserAuthEvent = /* GraphQL */ `mutation CreateUserAuthEvent($userAuthEventInput: UserAuthEventInput) {
  createUserAuthEvent(userAuthEventInput: $userAuthEventInput) {
    userAuthEventId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserAuthEventMutationVariables,
  APITypes.CreateUserAuthEventMutation
>;
export const createInspection = /* GraphQL */ `mutation CreateInspection($createInspectionInput: CreateInspectionInput!) {
  createInspection(createInspectionInput: $createInspectionInput) {
    inspectionId
    recSeq
    recStatus
    orgId
    facilityId
    jobId
    inspectorId
    inspectionName
    inspectionDescription
    inspectionEmailRecipient
    inspectionReportUrl
    frequencyType
    inspectionStartDate
    inspectionEndDate
    plannedInspectionStartDate
    plannedInspectionEndDate
    facilityName
    facilityAddress
    facilityType
    vendorName
    jobName
    intId
    latitude
    longitude
    dataStatus
    createdBy
    createdOn
    modifiedBy
    modifiedOn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInspectionMutationVariables,
  APITypes.CreateInspectionMutation
>;
export const createInspectionFromCsv = /* GraphQL */ `mutation CreateInspectionFromCsv(
  $createInspectionInput: CreateInspectionInput!
) {
  createInspectionFromCsv(createInspectionInput: $createInspectionInput) {
    inspection {
      status
      data {
        inspectionId
        recSeq
        recStatus
        orgId
        facilityId
        jobId
        inspectorId
        inspectionName
        inspectionDescription
        inspectionEmailRecipient
        inspectionReportUrl
        frequencyType
        inspectionStartDate
        inspectionEndDate
        plannedInspectionStartDate
        plannedInspectionEndDate
        facilityName
        facilityAddress
        facilityType
        vendorName
        jobName
        intId
        latitude
        longitude
        dataStatus
        createdBy
        createdOn
        modifiedBy
        modifiedOn
        __typename
      }
      __typename
    }
    sections {
      status
      data {
        sectionId
        recSeq
        recStatus
        inspectionId
        sectionName
        sectionDescription
        displaySeq
        isProofRequired
        proofDescription
        questions {
          questionId
          recSeq
          recStatus
          inspectionId
          sectionId
          questionText
          questionDescription
          questionNumber
          answerType
          isMandatory
          answerPattern
          parentQuestionId
          optionId
          parentOptionId
          answerOptions {
            answerId
            recSeq
            recStatus
            questionId
            displayText
            displaySeq
            answerDescription
            dataStatus
            createdBy
            createdOn
            modifiedBy
            modifiedOn
            __typename
          }
          dataStatus
          createdBy
          createdOn
          modifiedBy
          modifiedOn
          __typename
        }
        mandatoryQuestionCount
        nonMandatoryQuestionCount
        answeredMandatoryQuestionCount
        answeredNonMandatoryQuestionCount
        dataStatus
        createdBy
        createdOn
        modifiedBy
        modifiedOn
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInspectionFromCsvMutationVariables,
  APITypes.CreateInspectionFromCsvMutation
>;
export const updateInspections = /* GraphQL */ `mutation UpdateInspections($updateInspectionsInput: [UpdateInspectionInput]!) {
  updateInspections(updateInspectionsInput: $updateInspectionsInput) {
    inspectionId
    recSeq
    recStatus
    orgId
    facilityId
    jobId
    inspectorId
    inspectionName
    inspectionDescription
    inspectionEmailRecipient
    inspectionReportUrl
    frequencyType
    inspectionStartDate
    inspectionEndDate
    plannedInspectionStartDate
    plannedInspectionEndDate
    facilityName
    facilityAddress
    facilityType
    vendorName
    jobName
    intId
    latitude
    longitude
    dataStatus
    createdBy
    createdOn
    modifiedBy
    modifiedOn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInspectionsMutationVariables,
  APITypes.UpdateInspectionsMutation
>;
export const createInspectionSections = /* GraphQL */ `mutation CreateInspectionSections(
  $inspectionId: String!
  $createInspectionSectionInput: [CreateInspectionSectionInput]!
) {
  createInspectionSections(
    inspectionId: $inspectionId
    createInspectionSectionInput: $createInspectionSectionInput
  ) {
    sectionId
    recSeq
    recStatus
    inspectionId
    sectionName
    sectionDescription
    displaySeq
    isProofRequired
    proofDescription
    questions {
      questionId
      recSeq
      recStatus
      inspectionId
      sectionId
      questionText
      questionDescription
      questionNumber
      answerType
      isMandatory
      answerPattern
      parentQuestionId
      optionId
      parentOptionId
      answerOptions {
        answerId
        recSeq
        recStatus
        questionId
        displayText
        displaySeq
        answerDescription
        dataStatus
        createdBy
        createdOn
        modifiedBy
        modifiedOn
        __typename
      }
      dataStatus
      createdBy
      createdOn
      modifiedBy
      modifiedOn
      __typename
    }
    mandatoryQuestionCount
    nonMandatoryQuestionCount
    answeredMandatoryQuestionCount
    answeredNonMandatoryQuestionCount
    dataStatus
    createdBy
    createdOn
    modifiedBy
    modifiedOn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInspectionSectionsMutationVariables,
  APITypes.CreateInspectionSectionsMutation
>;
export const updateInspectionSections = /* GraphQL */ `mutation UpdateInspectionSections(
  $updateInspectionSectionsInput: [UpdateInspectionSectionInput]!
) {
  updateInspectionSections(
    updateInspectionSectionsInput: $updateInspectionSectionsInput
  ) {
    sectionId
    recSeq
    recStatus
    inspectionId
    sectionName
    sectionDescription
    displaySeq
    isProofRequired
    proofDescription
    questions {
      questionId
      recSeq
      recStatus
      inspectionId
      sectionId
      questionText
      questionDescription
      questionNumber
      answerType
      isMandatory
      answerPattern
      parentQuestionId
      optionId
      parentOptionId
      answerOptions {
        answerId
        recSeq
        recStatus
        questionId
        displayText
        displaySeq
        answerDescription
        dataStatus
        createdBy
        createdOn
        modifiedBy
        modifiedOn
        __typename
      }
      dataStatus
      createdBy
      createdOn
      modifiedBy
      modifiedOn
      __typename
    }
    mandatoryQuestionCount
    nonMandatoryQuestionCount
    answeredMandatoryQuestionCount
    answeredNonMandatoryQuestionCount
    dataStatus
    createdBy
    createdOn
    modifiedBy
    modifiedOn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInspectionSectionsMutationVariables,
  APITypes.UpdateInspectionSectionsMutation
>;
export const createInspectionAnswers = /* GraphQL */ `mutation CreateInspectionAnswers(
  $sectionId: String!
  $createInspectionFormInput: CreateInspectionFormInput!
) {
  createInspectionAnswers(
    sectionId: $sectionId
    createInspectionFormInput: $createInspectionFormInput
  ) {
    recordId
    recSeq
    recStatus
    answer
    questionId
    inspectionId
    inspectorId
    answerId
    dataStatus
    createdBy
    createdOn
    modifiedBy
    modifiedOn
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInspectionAnswersMutationVariables,
  APITypes.CreateInspectionAnswersMutation
>;
export const updateInspectionAnswers = /* GraphQL */ `mutation UpdateInspectionAnswers(
  $sectionId: String!
  $updateInspectionFormInput: UpdateInspectionFormInput!
) {
  updateInspectionAnswers(
    sectionId: $sectionId
    updateInspectionFormInput: $updateInspectionFormInput
  ) {
    answers {
      recordId
      recSeq
      recStatus
      answer
      questionId
      inspectionId
      inspectorId
      answerId
      dataStatus
      createdBy
      createdOn
      modifiedBy
      modifiedOn
      __typename
    }
    attachments {
      recordId
      recSeq
      recStatus
      inspectionId
      sectionId
      fileName
      url
      dataStatus
      createdBy
      createdOn
      modifiedBy
      modifiedOn
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInspectionAnswersMutationVariables,
  APITypes.UpdateInspectionAnswersMutation
>;
export const createBidAttachments = /* GraphQL */ `mutation CreateBidAttachments(
  $bidId: String!
  $createBidAttachmentInput: [CreateBidAttachmentInput]
) {
  createBidAttachments(
    bidId: $bidId
    createBidAttachmentInput: $createBidAttachmentInput
  ) {
    attachmentId
    mdAttachmentType
    fileName
    mdFileContext
    fileExtension
    url
    dataStatus
    comments
    tags
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBidAttachmentsMutationVariables,
  APITypes.CreateBidAttachmentsMutation
>;
export const updateBidAttachments = /* GraphQL */ `mutation UpdateBidAttachments(
  $updateBidAttachmentInput: [CreateBidAttachmentInput]
) {
  updateBidAttachments(updateBidAttachmentInput: $updateBidAttachmentInput) {
    attachmentId
    mdAttachmentType
    fileName
    mdFileContext
    fileExtension
    url
    dataStatus
    comments
    tags
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBidAttachmentsMutationVariables,
  APITypes.UpdateBidAttachmentsMutation
>;
export const deleteBidAttachments = /* GraphQL */ `mutation DeleteBidAttachments($bidId: String!, $attachmentIds: [String]) {
  deleteBidAttachments(bidId: $bidId, attachmentIds: $attachmentIds) {
    attachmentId
    mdAttachmentType
    fileName
    mdFileContext
    fileExtension
    url
    dataStatus
    comments
    tags
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBidAttachmentsMutationVariables,
  APITypes.DeleteBidAttachmentsMutation
>;
export const addComments = /* GraphQL */ `mutation AddComments($keyId: String!, $commentsInput: CommentsInput) {
  addComments(keyId: $keyId, commentsInput: $commentsInput) {
    commentId
    keyId
    keyType
    comment
    commenterType
    dataStatus
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddCommentsMutationVariables,
  APITypes.AddCommentsMutation
>;
export const createConversation = /* GraphQL */ `mutation CreateConversation($conversationInput: ConversationInput!) {
  createConversation(conversationInput: $conversationInput) {
    data {
      conversationId
      customerId
      vendorId
      bidId
      bidVersion
      jobId
      jobName
      customerName
      vendorName
      countOfMessages
      messages {
        messageId
        message
        messageType
        isRead
        organizationId
        userName
        userRoleId
        userRoleName
        modifiedOn
        conversationContext
        __typename
      }
      __typename
    }
    metadata {
      totalCount
      pageIndex
      limit
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateConversationMutationVariables,
  APITypes.CreateConversationMutation
>;
export const createJobAttachments = /* GraphQL */ `mutation CreateJobAttachments(
  $jobId: String!
  $facilityIds: String!
  $createOrUpdateJobAttachmentInput: [CreateOrUpdateJobAttachmentInput]
) {
  createJobAttachments(
    jobId: $jobId
    facilityIds: $facilityIds
    createOrUpdateJobAttachmentInput: $createOrUpdateJobAttachmentInput
  ) {
    attachmentId
    mdAttachmentType
    fileName
    mdFileContext
    fileExtension
    url
    dataStatus
    comments
    jobId
    facilityId
    tags
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateJobAttachmentsMutationVariables,
  APITypes.CreateJobAttachmentsMutation
>;
export const deleteJobAttachments = /* GraphQL */ `mutation DeleteJobAttachments($jobId: String!, $attachmentIds: [String]!) {
  deleteJobAttachments(jobId: $jobId, attachmentIds: $attachmentIds) {
    attachmentId
    mdAttachmentType
    fileName
    mdFileContext
    fileExtension
    url
    dataStatus
    comments
    jobId
    facilityId
    tags
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteJobAttachmentsMutationVariables,
  APITypes.DeleteJobAttachmentsMutation
>;
export const updateJobAttachments = /* GraphQL */ `mutation UpdateJobAttachments(
  $createOrUpdateJobAttachmentInput: [CreateOrUpdateJobAttachmentInput]
) {
  updateJobAttachments(
    createOrUpdateJobAttachmentInput: $createOrUpdateJobAttachmentInput
  ) {
    attachmentId
    mdAttachmentType
    fileName
    mdFileContext
    fileExtension
    url
    dataStatus
    comments
    jobId
    facilityId
    tags
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateJobAttachmentsMutationVariables,
  APITypes.UpdateJobAttachmentsMutation
>;
export const addJobBid = /* GraphQL */ `mutation AddJobBid(
  $jobId: String!
  $vendorId: String!
  $createJobBidInput: JobBidInput!
) {
  addJobBid(
    jobId: $jobId
    vendorId: $vendorId
    createJobBidInput: $createJobBidInput
  ) {
    bidId
    jobId
    bidName
    totalCost
    profitMargin
    finalQuote
    bidEstimationType
    bidCreationDate
    draftStep
    isFlatMargin
    draftStatus
    dataStatus
    bidTemplate
    bidVersion
    bidVersionCreatedDate
    submittedBy
    bidSubmissionDate
    mdFrequencyType
    comments
    bidCreatedFromSample
    errorCode
    vendorId
    vendorName
    vendorEmail
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddJobBidMutationVariables,
  APITypes.AddJobBidMutation
>;
export const submitBid = /* GraphQL */ `mutation SubmitBid($bidId: String!, $bidVendorInput: BidVendorInput) {
  submitBid(bidId: $bidId, bidVendorInput: $bidVendorInput) {
    bidId
    jobId
    bidName
    totalCost
    profitMargin
    finalQuote
    bidEstimationType
    bidCreationDate
    draftStep
    isFlatMargin
    draftStatus
    dataStatus
    bidTemplate
    bidVersion
    bidVersionCreatedDate
    submittedBy
    bidSubmissionDate
    mdFrequencyType
    comments
    bidCreatedFromSample
    errorCode
    vendorId
    vendorName
    vendorEmail
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SubmitBidMutationVariables,
  APITypes.SubmitBidMutation
>;
export const updateBid = /* GraphQL */ `mutation UpdateBid(
  $vendorId: String
  $bidId: String!
  $updateJobInput: UpdateJobBidInput
) {
  updateBid(
    vendorId: $vendorId
    bidId: $bidId
    updateJobInput: $updateJobInput
  ) {
    bidId
    jobId
    bidName
    totalCost
    profitMargin
    finalQuote
    bidEstimationType
    bidCreationDate
    draftStep
    isFlatMargin
    draftStatus
    dataStatus
    bidTemplate
    bidVersion
    bidVersionCreatedDate
    submittedBy
    bidSubmissionDate
    mdFrequencyType
    comments
    bidCreatedFromSample
    errorCode
    vendorId
    vendorName
    vendorEmail
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBidMutationVariables,
  APITypes.UpdateBidMutation
>;
export const resendBidEmail = /* GraphQL */ `mutation ResendBidEmail($bidId: String!) {
  resendBidEmail(bidId: $bidId) {
    bidId
    jobId
    bidName
    totalCost
    profitMargin
    finalQuote
    bidEstimationType
    bidCreationDate
    draftStep
    isFlatMargin
    draftStatus
    dataStatus
    bidTemplate
    bidVersion
    bidVersionCreatedDate
    submittedBy
    bidSubmissionDate
    mdFrequencyType
    comments
    bidCreatedFromSample
    errorCode
    vendorId
    vendorName
    vendorEmail
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ResendBidEmailMutationVariables,
  APITypes.ResendBidEmailMutation
>;
export const generateInvoice = /* GraphQL */ `mutation GenerateInvoice(
  $bidId: String!
  $jobId: String!
  $generateInvoiceInput: generateInvoiceInput!
) {
  generateInvoice(
    bidId: $bidId
    jobId: $jobId
    generateInvoiceInput: $generateInvoiceInput
  ) {
    responseCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GenerateInvoiceMutationVariables,
  APITypes.GenerateInvoiceMutation
>;
export const deleteJobBids = /* GraphQL */ `mutation DeleteJobBids($deleteJobBidsInput: DeleteJobBidInput!) {
  deleteJobBids(deleteJobBidsInput: $deleteJobBidsInput)
}
` as GeneratedMutation<
  APITypes.DeleteJobBidsMutationVariables,
  APITypes.DeleteJobBidsMutation
>;
export const addJobBidAdditionalExpenses = /* GraphQL */ `mutation AddJobBidAdditionalExpenses(
  $jobId: String!
  $bidId: String!
  $createJobBidAdditionalExpensesInput: [CreateJobBidAdditionalExpensesInput]!
) {
  addJobBidAdditionalExpenses(
    jobId: $jobId
    bidId: $bidId
    createJobBidAdditionalExpensesInput: $createJobBidAdditionalExpensesInput
  ) {
    added {
      bidId
      bidAdditionalExpenseId
      name
      description
      bidSimpleEstimationEstimationId
      rate
      quantity
      totalCost
      mdUnits
      mdServiceCategoryId
      mdAdditionalExpenseCategory
      draftStatus
      __typename
    }
    deleted {
      bidId
      bidAdditionalExpenseId
      name
      description
      bidSimpleEstimationEstimationId
      rate
      quantity
      totalCost
      mdUnits
      mdServiceCategoryId
      mdAdditionalExpenseCategory
      draftStatus
      __typename
    }
    modified {
      bidId
      bidAdditionalExpenseId
      name
      description
      bidSimpleEstimationEstimationId
      rate
      quantity
      totalCost
      mdUnits
      mdServiceCategoryId
      mdAdditionalExpenseCategory
      draftStatus
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddJobBidAdditionalExpensesMutationVariables,
  APITypes.AddJobBidAdditionalExpensesMutation
>;
export const addJobBidEstimation = /* GraphQL */ `mutation AddJobBidEstimation(
  $jobId: String!
  $bidId: String!
  $createJobBidEstimationInput: [CreateJobBidEstimationInput]!
) {
  addJobBidEstimation(
    jobId: $jobId
    bidId: $bidId
    createJobBidEstimationInput: $createJobBidEstimationInput
  ) {
    added {
      jobId
      estimationId
      bidId
      mdCategoryId
      mdTaskId
      mdMeasureType
      rate
      quantity
      isOverTime
      totalCost
      isVendorAdded
      isBillable
      numberOfOccurrences
      mdUnits
      tasks {
        taskId
        taskName
        __typename
      }
      __typename
    }
    deleted {
      jobId
      estimationId
      bidId
      mdCategoryId
      mdTaskId
      mdMeasureType
      rate
      quantity
      isOverTime
      totalCost
      isVendorAdded
      isBillable
      numberOfOccurrences
      mdUnits
      tasks {
        taskId
        taskName
        __typename
      }
      __typename
    }
    modified {
      jobId
      estimationId
      bidId
      mdCategoryId
      mdTaskId
      mdMeasureType
      rate
      quantity
      isOverTime
      totalCost
      isVendorAdded
      isBillable
      numberOfOccurrences
      mdUnits
      tasks {
        taskId
        taskName
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddJobBidEstimationMutationVariables,
  APITypes.AddJobBidEstimationMutation
>;
export const updateJobBidEstimation = /* GraphQL */ `mutation UpdateJobBidEstimation(
  $estimationId: String!
  $updateJobBidEstimationInput: UpdateJobBidEstimationInput!
) {
  updateJobBidEstimation(
    estimationId: $estimationId
    updateJobBidEstimationInput: $updateJobBidEstimationInput
  ) {
    jobId
    estimationId
    bidId
    mdCategoryId
    mdTaskId
    mdMeasureType
    rate
    quantity
    isOverTime
    totalCost
    isVendorAdded
    isBillable
    numberOfOccurrences
    mdUnits
    tasks {
      taskId
      taskName
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateJobBidEstimationMutationVariables,
  APITypes.UpdateJobBidEstimationMutation
>;
export const addJobDueDates = /* GraphQL */ `mutation AddJobDueDates(
  $customerId: String!
  $jobId: String!
  $createJobDueDatesInput: [CreateJobDueDateInput]!
) {
  addJobDueDates(
    customerId: $customerId
    jobId: $jobId
    createJobDueDatesInput: $createJobDueDatesInput
  ) {
    added {
      jobId
      mdDueType
      dueDescription
      dueDate
      __typename
    }
    deleted {
      jobId
      mdDueType
      dueDescription
      dueDate
      __typename
    }
    reactivated {
      jobId
      mdDueType
      dueDescription
      dueDate
      __typename
    }
    modified {
      jobId
      mdDueType
      dueDescription
      dueDate
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddJobDueDatesMutationVariables,
  APITypes.AddJobDueDatesMutation
>;
export const addJobFacility = /* GraphQL */ `mutation AddJobFacility(
  $customerId: String!
  $jobId: String!
  $jobFacilityInput: [CreateJobFacilityInput]
) {
  addJobFacility(
    customerId: $customerId
    jobId: $jobId
    jobFacilityInput: $jobFacilityInput
  ) {
    added {
      jobId
      facilityId
      mdFacilityType
      facilityAddress
      remarks
      status
      jobStartDate
      jobEndData
      __typename
    }
    deleted {
      jobId
      facilityId
      mdFacilityType
      facilityAddress
      remarks
      status
      jobStartDate
      jobEndData
      __typename
    }
    reactivated {
      jobId
      facilityId
      mdFacilityType
      facilityAddress
      remarks
      status
      jobStartDate
      jobEndData
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddJobFacilityMutationVariables,
  APITypes.AddJobFacilityMutation
>;
export const addJobFrequency = /* GraphQL */ `mutation AddJobFrequency(
  $customerId: String!
  $jobId: String!
  $jobFrequencyInput: [CreateJobFrequencyInput]!
) {
  addJobFrequency(
    customerId: $customerId
    jobId: $jobId
    jobFrequencyInput: $jobFrequencyInput
  ) {
    added {
      mdShiftType
      mdScheduleType
      mdServiceId
      scheduleDays
      fromTime
      toTime
      cronExpression
      repeatitions
      repetition
      __typename
    }
    deleted {
      mdShiftType
      mdScheduleType
      mdServiceId
      scheduleDays
      fromTime
      toTime
      cronExpression
      repeatitions
      repetition
      __typename
    }
    reactivated {
      mdShiftType
      mdScheduleType
      mdServiceId
      scheduleDays
      fromTime
      toTime
      cronExpression
      repeatitions
      repetition
      __typename
    }
    modified {
      mdShiftType
      mdScheduleType
      mdServiceId
      scheduleDays
      fromTime
      toTime
      cronExpression
      repeatitions
      repetition
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddJobFrequencyMutationVariables,
  APITypes.AddJobFrequencyMutation
>;
export const addJobInterest = /* GraphQL */ `mutation AddJobInterest(
  $customerId: String!
  $vendorId: String
  $createJobInterestInput: [CreateInputJobInterest]!
) {
  addJobInterest(
    customerId: $customerId
    vendorId: $vendorId
    createJobInterestInput: $createJobInterestInput
  ) {
    vendorId
    jobId
    actionType
    actionTime
    actionUser
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddJobInterestMutationVariables,
  APITypes.AddJobInterestMutation
>;
export const updateJobInterest = /* GraphQL */ `mutation UpdateJobInterest(
  $customerId: String!
  $vendorId: String
  $createJobInterestInput: CreateInputJobInterest!
) {
  updateJobInterest(
    customerId: $customerId
    vendorId: $vendorId
    createJobInterestInput: $createJobInterestInput
  ) {
    vendorId
    jobId
    actionType
    actionTime
    actionUser
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateJobInterestMutationVariables,
  APITypes.UpdateJobInterestMutation
>;
export const markJobAsAwarded = /* GraphQL */ `mutation MarkJobAsAwarded(
  $jobId: String!
  $bidId: String!
  $facilityAddress: facilityAddressInput
) {
  markJobAsAwarded(
    jobId: $jobId
    bidId: $bidId
    facilityAddress: $facilityAddress
  ) {
    jobId
    jobName
    specialInstructions
    startDate
    stopDate
    jobType
    customerId
    customerName
    jobCustodian
    dataStatus
    jobFacilities {
      jobId
      facilityId
      mdFacilityType
      facilityAddress
      remarks
      status
      jobStartDate
      jobEndData
      __typename
    }
    jobServices {
      jobId
      mdServiceId
      serviceMeasure
      mdServiceUnits
      tasks {
        taskId
        taskName
        __typename
      }
      actualMdServiceUnits
      actualServiceMeasure
      __typename
    }
    jobFrequencies {
      mdShiftType
      mdScheduleType
      mdServiceId
      scheduleDays
      fromTime
      toTime
      cronExpression
      repeatitions
      repetition
      __typename
    }
    jobVendors {
      jobId
      vendorId
      mdVendorType
      __typename
    }
    jobDueDates {
      jobId
      mdDueType
      dueDescription
      dueDate
      __typename
    }
    jobAttachments {
      attachmentId
      mdAttachmentType
      fileName
      mdFileContext
      fileExtension
      url
      dataStatus
      comments
      jobId
      facilityId
      tags
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.MarkJobAsAwardedMutationVariables,
  APITypes.MarkJobAsAwardedMutation
>;
export const processBidReview = /* GraphQL */ `mutation ProcessBidReview(
  $transactionId: String!
  $bidStatus: String!
  $processBidReviewInput: processBidReviewInput
) {
  processBidReview(
    transactionId: $transactionId
    bidStatus: $bidStatus
    processBidReviewInput: $processBidReviewInput
  ) {
    responseCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ProcessBidReviewMutationVariables,
  APITypes.ProcessBidReviewMutation
>;
export const addJob = /* GraphQL */ `mutation AddJob($customerId: String!, $jobsInput: CreateJobInput) {
  addJob(customerId: $customerId, jobsInput: $jobsInput) {
    jobId
    jobName
    startDate
    stopDate
    dataStatus
    specialInstructions
    draftStep
    draftStatus
    jobType
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddJobMutationVariables,
  APITypes.AddJobMutation
>;
export const deleteJobs = /* GraphQL */ `mutation DeleteJobs($deleteJobsInput: DeleteJobInput!) {
  deleteJobs(deleteJobsInput: $deleteJobsInput)
}
` as GeneratedMutation<
  APITypes.DeleteJobsMutationVariables,
  APITypes.DeleteJobsMutation
>;
export const updateJob = /* GraphQL */ `mutation UpdateJob(
  $customerId: String!
  $jobId: String!
  $jobsInput: UpdateJobInput
) {
  updateJob(customerId: $customerId, jobId: $jobId, jobsInput: $jobsInput) {
    jobId
    jobName
    startDate
    stopDate
    dataStatus
    specialInstructions
    draftStep
    draftStatus
    jobType
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateJobMutationVariables,
  APITypes.UpdateJobMutation
>;
export const createSubJob = /* GraphQL */ `mutation CreateSubJob($createSubJobInput: CreateSubJobInput) {
  createSubJob(createSubJobInput: $createSubJobInput) {
    jobId
    jobName
    startDate
    stopDate
    dataStatus
    specialInstructions
    draftStep
    draftStatus
    jobType
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSubJobMutationVariables,
  APITypes.CreateSubJobMutation
>;
export const addJobServices = /* GraphQL */ `mutation AddJobServices(
  $customerId: String!
  $jobId: String!
  $jobServicesInput: [CreateJobServiceInput!]
) {
  addJobServices(
    customerId: $customerId
    jobId: $jobId
    jobServicesInput: $jobServicesInput
  ) {
    added {
      jobId
      mdServiceId
      serviceMeasure
      mdServiceUnits
      tasks {
        taskId
        taskName
        __typename
      }
      actualMdServiceUnits
      actualServiceMeasure
      __typename
    }
    deleted {
      jobId
      mdServiceId
      serviceMeasure
      mdServiceUnits
      tasks {
        taskId
        taskName
        __typename
      }
      actualMdServiceUnits
      actualServiceMeasure
      __typename
    }
    reactivated {
      jobId
      mdServiceId
      serviceMeasure
      mdServiceUnits
      tasks {
        taskId
        taskName
        __typename
      }
      actualMdServiceUnits
      actualServiceMeasure
      __typename
    }
    modified {
      jobId
      mdServiceId
      serviceMeasure
      mdServiceUnits
      tasks {
        taskId
        taskName
        __typename
      }
      actualMdServiceUnits
      actualServiceMeasure
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddJobServicesMutationVariables,
  APITypes.AddJobServicesMutation
>;
export const addJobVendors = /* GraphQL */ `mutation AddJobVendors(
  $customerId: String!
  $jobId: String!
  $createJobVendorInput: [CreateJobVendorInput]!
) {
  addJobVendors(
    customerId: $customerId
    jobId: $jobId
    createJobVendorInput: $createJobVendorInput
  ) {
    added {
      jobId
      vendorId
      mdVendorType
      __typename
    }
    deleted {
      jobId
      vendorId
      mdVendorType
      __typename
    }
    reactivated {
      jobId
      vendorId
      mdVendorType
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddJobVendorsMutationVariables,
  APITypes.AddJobVendorsMutation
>;
