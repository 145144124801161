/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { Menu, MenuItem, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import theme from '../../../Themes/theme';
import { StyleConstants } from '../../../Constants/Style.Constants';

export const ReadMore = styled(Link)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.5rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  textDecoration: 'none',
  color: theme.palette.primary.dark,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.125rem',
  },
}));

export const JobLink = styled(Link)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '1.5rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.primary.dark,
  textUnderlineOffset: '0.3125rem',
  textDecoration: 'none',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.125rem',
  },
}));

export const ActionsColumn = styled('div')(() => ({
  display: 'flex',
  // justifyContent: 'space-between',
}));

export const ActionIcon = styled('img')(() => ({
  cursor: 'pointer',
}));

export const MenuWrapper = styled(Menu)(() => ({
  '& .MuiMenu-paper': {
    boxShadow: '0 0.25rem 0.375rem -0.375rem #777',
  },
}));

export const Menus = styled(MenuItem)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.375rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.text.secondary,
  '&:hover': {
    backgroundColor: theme.palette.warning.contrastText,
  },
  '& img': {
    marginRight: '0.625rem',
  },
}));

export const Linking = styled(Link)(() => ({
  color: theme.palette.text.secondary,
  textDecoration: 'none',
}));

export const configureColumnStyles = {
  columnsVisible: {
    cursor: 'pointer',
    color: theme.palette.primary.dark,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4?.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1.5rem',
    letterSpacing: '0.009375rem',
  },
};
