/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useOTJCommHelper } from 'otj-client-lib';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { getAcronym, getAuthDetails } from '../../../Utilities/utils';
import { UserType } from '../../../Constants/App';
import ConversationCardUI from './ConversationCardUI';

const DashboardConversationCard = (props: any): JSX.Element => {
  const { conversationId, meta, mode, name, selectedConversation, isLast, conversation } = props;

  const storedAuthDetails = getAuthDetails();
  const loggedInUserType = storedAuthDetails['custom:Role'] as UserType;
  const loggedInUserInternalId = storedAuthDetails['custom:UserId'];

  const { chatHooks } = useOTJCommHelper();
  const { useMessages, useConversationStatus } = chatHooks;
  const { data: currentMessages } = useMessages(conversationId ?? '');
  const { data: conversationStatus } = useConversationStatus(conversationId ?? '');

  const recentMessage = Array.isArray(currentMessages) ? currentMessages[0]?.text : undefined;

  const updatedAt =
    currentMessages?.length && Array.isArray(currentMessages)
      ? moment.utc(currentMessages?.[0]?.UpdatedAt).local().format('MMM DD, YYYY hh:mm A')
      : undefined;

  const renderName = (): string => {
    if (!isEmpty(name)) return name;

    if (!isEmpty(meta)) {
      const { vendorId, vendorCompanyName, associateName, customerOrDmOrSubVendorCompanyName } = meta;
      const isVendor = loggedInUserType === UserType.Vendor;
      const isAssociate = loggedInUserType === UserType.Associate;
      const isCustomer = loggedInUserType === UserType.Customer;

      if (isVendor) {
        if (vendorId !== (loggedInUserInternalId ?? '')) {
          return vendorCompanyName || associateName || '';
        }
        return customerOrDmOrSubVendorCompanyName || associateName || '';
      }

      if (isAssociate || isCustomer) {
        return vendorCompanyName || '';
      }
    }

    return '';
  };

  const getDisplayNameForDirectChat = (): string => {
    return meta?.userInformation?.find((item: { id: any }) => item?.id !== loggedInUserInternalId)?.name ?? '';
  };

  const renderJobName = (): string => (!isEmpty(meta?.jobName) ? meta?.jobName : '');

  const isSelected = conversationId === selectedConversation?.id;

  const nameToRender = mode === 'GROUP' ? renderName() : getDisplayNameForDirectChat() || conversation?.name;

  return (
    <ConversationCardUI
      conversationId={conversationId}
      isSelected={isSelected}
      isLast={isLast}
      name={nameToRender}
      recentMessage={recentMessage}
      jobName={renderJobName()}
      unreadCount={conversationStatus?.unReadCount || 0}
      updatedAt={updatedAt}
      isGroupMode={mode === 'GROUP'}
      acronymName={getAcronym(nameToRender)}
    />
  );
};

export default DashboardConversationCard;
