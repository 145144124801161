/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_Icons,
  MRT_RowSelectionState,
  MRT_SortingState,
  MRT_TableInstance,
  useMaterialReactTable,
  MRT_ColumnFiltersState,
  MRT_VisibilityState,
} from 'material-react-table';
import { Box, Modal, Typography, Divider, List, MenuItem, ListItemText } from '@mui/material';
import { t } from 'i18next';

import { TaskData } from '../../../../Modules/Jobs/Components/WorkTasks/WorkTasks';
import { IJobOverview } from '../../../../Modules/Jobs/Components/JobsListView/JobsListView';
import { IJobs } from '../../../../Modules/Jobs/Components/JobView/JobsView';
import { IBidList } from '../../../../Modules/Jobs/Components/JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../../Modules/Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import theme from '../../../Themes/theme';
import { Icons } from '../../../Constants/Icons';
import { StyleConstants } from '../../../Constants/Style.Constants';
import { IUpcomingShift } from '../../../../Modules/Jobs/Components/UpcomingShifts/UpcomingShifts.Model';
import Button, { ButtonType } from '../Buttons/Button';
import { dropdownStyles } from '../MultiSelectDropdownWithCheckboxes/MultiSelectDropdownWithCheckboxes.Style';
import { configureColumnStyles } from './DataGrid.styles';
import { ButtonContainer, ModifyButtonContainer } from '../../../../Modules/Vendor/Components/AllJobs/AllJobs.Style';
import DropdownWithButton from '../../../../Modules/Vendor/Components/AllJobs/AllJobsModifyDropDown';
import { JobType } from '../../../Constants/App';
import { VendorType } from '../../../Models/Vendors.Models';
import { isEmpty } from 'lodash';

interface IDataGridProps {
  vendorTypes?: any;
  columns: MRT_ColumnDef<TaskData | IJobOverview | IJobs | IBidList | IProfileCardProps>[];
  data: TaskData[] | IJobOverview[] | IJobs[] | IBidList[] | IProfileCardProps[] | IUpcomingShift[];
  onRowChange?: (rowSelection: object, data?: TaskData[] | IJobOverview[]) => void;
  enableRowSelect?: boolean;
  enableColumnPinning?: boolean;
  expanded?: boolean | number;
  leftPinArr: string[];
  rightPinArr: string[];
  enableEditing?: boolean;
  sortingObj?: MRT_SortingState;
  sort?: (sorting: any) => void;
  filter?: (sorting: any) => void;
  sortingFns?: any;
  loader?: boolean;
  errorMessageTitle?: string;
  errorMessageDescription?: string;
  handleSaveCell?: (cell: any, value: string) => void;
  editingMode: 'cell' | 'table' | 'modal' | 'row' | undefined;
  jobType?: string;
  configureColumns?: boolean;
  onMultipleRowsSelected?: boolean;
  selectActions?: boolean;
  setSelectedRows?: (data: any[]) => void;
  selectedRows?: any[];
  isRescheduleShift?: boolean;
}

const CustomIcons: Partial<MRT_Icons> = {
  MoreVertIcon: () => <img src={Icons.SortingIcon} alt={t('altTexts:more')} />,
};

/**
 *
 * @returns DataGrid for Given Data
 */
const DataGrid = ({
  columns,
  data,
  onRowChange,
  expanded,
  enableEditing,
  sort,
  filter,
  loader,
  editingMode,
  configureColumns,
  enableRowSelect,
  selectActions,
  setSelectedRows,
  selectedRows,
  isRescheduleShift,
}: IDataGridProps) => {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);

  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>({
    jobName: true,
    location: true,
    jobStatus: true,
    createdOn: true,
  });

  const [showConfig, setShowConfig] = useState(false);

  const { checkBox, listItemStyles } = dropdownStyles;
  const { columnsVisible } = configureColumnStyles;

  const tableInstanceRef = useRef<MRT_TableInstance<IJobOverview | TaskData | IJobs>>(null);

  useEffect(() => {
    setRowSelection({});
  }, [data, expanded]);

  useEffect(() => {
    const selectedData = table.getSelectedRowModel().rows.map((row) => row.original);

    if (selectedData) {
      setSelectedRows?.(selectedData);
    }
  }, [rowSelection]);

  useEffect(() => {
    // rowSelection having current selected state value through that we triggered onRowChange
    if (onRowChange && Object.keys(rowSelection)?.length) {
      onRowChange(rowSelection);
    }
  }, [rowSelection]);

  useEffect(() => {
    filter?.(columnFilters);
  }, [columnFilters]);

  useEffect(() => {
    sort?.(sorting);
  }, [sorting]);

  const handleRow = (value: any) => {
    setRowSelection(value);
    // ref instance having previous selected state value through that we triggered onRowChange
    // reason: once we unchecked select all option then rowSelection obj is empty so we can't hide approve and reject button
    const rowSelect = tableInstanceRef?.current?.getState().rowSelection;
    const PreviouslySelectedRowLen = rowSelect && Object.keys(rowSelect)?.length;
    const currentSelectedRowLen = Object.keys(value())?.length;
    if (
      onRowChange &&
      ((PreviouslySelectedRowLen && currentSelectedRowLen > 1) ||
        ((PreviouslySelectedRowLen === 1 || PreviouslySelectedRowLen === data?.length) && currentSelectedRowLen === 0))
    ) {
      onRowChange(value);
    }
  };

  const isMultipleRowsSelected = useMemo(() => {
    return Object.keys(rowSelection)?.length > 0;
  }, [rowSelection]);

  const handleShowConfig = useCallback(() => {
    setShowConfig(true);
  }, []);

  const handleJobType = (row: any) => {
    const isOtjJob =
      row?.original?.jobCustodian &&
      (row?.original?.jobType === JobType.OneTime || row?.original?.jobType === JobType.Emergency);
    const isFlow3Job = isOtjJob && row?.original?.mdVendorType === VendorType.SubVendor; // For 3rd flow
    const isFlow2Job =
      isOtjJob &&
      (row?.original?.mdVendorType === VendorType.All || row?.original?.mdVendorType === VendorType.SearchVendors); // OTJ 2nd flow

    const isSubVendor = isOtjJob && (isFlow3Job || isFlow2Job) && !isEmpty(row?.original?.parentWorkOrderId);

    const isSelfPerform = isOtjJob && row?.original?.mdVendorType === VendorType.SelfPerform;
    return { isFlow3Job, isFlow2Job, isSubVendor, isSelfPerform };
  };

  const table = useMaterialReactTable({
    columns,
    data,
    // enableRowSelection: true, //table options as options to this hook
    enablePagination: false,
    manualPagination: true,
    enableTopToolbar: configureColumns ? true : false,
    enableToolbarInternalActions: configureColumns ? true : false,
    enableBottomToolbar: false,
    editDisplayMode: editingMode,
    enableEditing: enableEditing,
    enableColumnActions: false,
    columnFilterDisplayMode: 'popover',
    enableStickyHeader: true,
    manualFiltering: true,
    enableRowSelection: enableRowSelect
      ? (row) => {
          if (enableRowSelect) {
            if (isRescheduleShift) {
              const { isFlow3Job, isFlow2Job, isSubVendor, isSelfPerform } = handleJobType(row);

              if (isFlow2Job) {
                return !!isSubVendor;
              } else if (isFlow3Job) {
                return !isSubVendor;
              } else if (isSelfPerform) {
                return false;
              }
              return row.original.dataStatus === 'A';
            }
            return true;
          }
          return false;
        }
      : false,
    positionToolbarAlertBanner: 'none',
    onColumnFiltersChange: (filters) => {
      setColumnFilters(filters);
    },
    state: { sorting, rowSelection, isLoading: loader, columnFilters, columnVisibility },
    onColumnVisibilityChange: (visibilityState) => {
      setColumnVisibility(visibilityState);
    },

    renderToolbarInternalActions: () => (
      <ButtonContainer>
        <ModifyButtonContainer>
          {isMultipleRowsSelected && selectActions && <DropdownWithButton selectedRows={selectedRows} />}
        </ModifyButtonContainer>
        <Button
          type={ButtonType.Primary}
          label={t('altTexts:configureColumns')}
          onClick={handleShowConfig}
          disabled={loader}
        />
      </ButtonContainer>
    ),
    renderBottomToolbarCustomActions: () => (
      <Box sx={{ border: '2px solid red' }}>
        <Button
          type={ButtonType.Primary}
          label={t('altTexts:configureColumns')}
          onClick={handleShowConfig}
          disabled={loader}
        />
      </Box>
    ),

    muiTableHeadCellProps: {
      sx: {
        border: `0.03125rem solid ${theme.palette.divider}`,
        padding: '0 0.75rem',
        paddingTop: 0,
        paddingBottom: 0,
        boxSizing: 'border-box',
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.h4?.fontSize,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary,
        lineHeight: '1.5rem',
        letterSpacing: StyleConstants.LetterSpacingTight,
        height: '4.5rem',
        verticalAlign: 'middle',
        [theme.breakpoints.down('laptop')]: {
          fontSize: theme.typography.h5?.fontSize,
          lineHeight: '1.375rem',
          letterSpacing: '0.015625rem',
        },
        '& .MuiCheckbox-root:hover': {
          backgroundColor: theme.palette.secondary.dark,
        },
        '& .Mui-TableHeadCell-Content': {
          justifyContent: 'space-between',
          '& .MuiIconButton-root': {
            opacity: 1,
          },
          '& .MuiCheckbox-indeterminate': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: `0.03125rem solid ${theme.palette.divider}`,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.h4?.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '1.5rem',
        letterSpacing: StyleConstants.LetterSpacingTight,
        color: theme.palette.text.secondary,
        height: '4rem',
        boxSizing: 'border-box',
        padding: '0 0.75rem',
        zIndex: 0,
        [theme.breakpoints.down('laptop')]: {
          fontSize: theme.typography.h5?.fontSize,
          lineHeight: '1.375rem',
          letterSpacing: '0.015625rem',
          height: '3rem',
        },
        '& .MuiCheckbox-root:hover': {
          backgroundColor: theme.palette.secondary.dark,
        },
        '& .MuiCheckbox-root.Mui-disabled': {
          filter: 'opacity(0.3)',
          cursor: 'not-allowed',
        },
      },
    },
    muiTableContainerProps: { sx: { maxHeight: 'calc(100vh - 320px)' } },
    muiTableBodyRowProps: {
      hover: false,
      sx: {
        '&.Mui-selected': {
          '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
          },
          backgroundColor: theme.palette.secondary.dark,
        },
      },
    },
    muiSelectCheckboxProps: {
      checkedIcon: <img src={Icons.CheckedIcon} alt={t('altTexts:checked')} />,
      icon: <img src={Icons.Unchecked} alt={t('altTexts:unchecked')} />,
    },

    muiSelectAllCheckboxProps: {
      checkedIcon: <img src={Icons.CheckedIcon} alt={t('altTexts:checked')} />,
      icon: <img src={Icons.Unchecked} alt={t('altTexts:unchecked')} />,
    },
    muiSearchTextFieldProps: {
      placeholder: 'Search all users',
      sx: { minWidth: '300px' },
      variant: 'outlined',
    },

    muiFilterTextFieldProps: {
      sx: {
        color: 'red',
        '& .MuiInputBase-root': {
          borderRadius: '1rem',
          border: `0.0625rem solid ${theme.palette.primary.dark}`,
          '&:hover:not(.Mui-disabled, .Mui-error)': {
            '&::before': {
              borderBottom: 'none',
            },
          },
          '&::before,&::after': {
            borderBottom: 'none',
          },
        },
        '& .MuiInputBase-input': {
          paddingLeft: '0.625rem',
          '&::placeholder': {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.h4?.fontSize,
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: '1.5rem',
            letterSpacing: StyleConstants.LetterSpacingTight,
            color: theme.palette.text.secondary,
          },
        },
        '& .MuiIconButton-root': {
          color: theme.palette.primary.dark,
          marginRight: '0.25rem',
          '&.Mui-disabled': {
            color: theme.palette.primary.dark,
          },
        },
      },
      onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault();
          (event.target as HTMLInputElement).blur();
        }
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    muiTopToolbarProps: {
      sx: {
        background: theme.palette.secondary.main,
        minHeight: '5.5rem',
        [theme.breakpoints.down('laptop')]: {
          minHeight: '5rem',
        },
      },
    },
    sortDescFirst: false,
    onSortingChange: (ar) => {
      setSorting(ar);
    },
    muiCircularProgressProps: {
      sx: {
        color: theme.palette.primary.dark,
      },
    },
    onRowSelectionChange: handleRow,
    icons: CustomIcons,
    enableFilterMatchHighlighting: false,
  });

  const handleToggleAll = (visible: boolean) => {
    table?.getAllColumns()?.forEach((column) => {
      if (column?.getCanHide()) {
        column?.toggleVisibility(visible);
      }
    });
  };

  const handleClose = useCallback(() => {
    setShowConfig(false);
  }, []);

  const showAllColumns = useCallback(() => {
    handleToggleAll(true);
  }, []);

  const hideAllColumns = useCallback(() => {
    handleToggleAll(false);
  }, []);

  const allColumnsHidden = useMemo(
    () => table?.getAllColumns()?.every((col) => !col?.getIsVisible()),
    [table?.getAllColumns()]
  );

  const allColumnsVisible = useMemo(
    () => table?.getAllColumns()?.every((col) => col?.getIsVisible()),
    [table?.getAllColumns()]
  );

  return (
    <>
      <Modal open={showConfig} onClose={handleClose}>
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            padding: '1.5rem 0rem',
            width: '300px',
            margin: 'auto',
            marginTop: '10%',
            borderRadius: '1rem',
            boxShadow: 24,
            maxHeight: '37.5rem',
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '1rem',
              padding: '0rem 1.5rem',
            }}>
            <Typography
              style={{
                ...columnsVisible,
                opacity: allColumnsHidden ? 0.5 : 1,
              }}
              onClick={hideAllColumns}>
              {t('altTexts:hideAll')}
            </Typography>

            <Typography
              onClick={showAllColumns}
              style={{
                ...columnsVisible,
                opacity: allColumnsVisible ? 0.5 : 1,
              }}>
              {t('altTexts:showAll')}
            </Typography>
          </Box>
          <Divider sx={{ marginBottom: '1rem' }} />

          <Box sx={{ maxHeight: '25rem', overflowY: 'auto', marginBottom: '1rem' }}>
            <List>
              {table.getAllColumns().map((column) => (
                <MenuItem
                  key={column.id}
                  value={column.id}
                  sx={{
                    maxWidth: '22.5rem',
                    background: column.getIsVisible() ? theme.palette.warning.contrastText : 'white',

                    '&:hover': {
                      background: column.getIsVisible() ? theme.palette.warning.contrastText : 'white',
                    },
                  }}
                  onClick={column.getToggleVisibilityHandler()}>
                  {column.getIsVisible() ? (
                    <img src={Icons.CheckedIcon} alt={t('altTexts:checked')} style={{ ...checkBox }} />
                  ) : (
                    <img src={Icons.Unchecked} alt={t('altTexts:unchecked')} style={{ ...checkBox }} />
                  )}
                  <ListItemText primary={column.columnDef.header} sx={{ ...listItemStyles }} />
                </MenuItem>
              ))}
            </List>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Button type={ButtonType.Secondary} label={t('altTexts:cancel')} onClick={handleClose} />
          </Box>
        </Box>
      </Modal>

      <Box sx={{ position: 'relative' }}>
        <Box style={{ position: 'relative', opacity: loader ? 0.5 : 1 }}>
          <MaterialReactTable table={table} />
        </Box>
      </Box>
    </>
  );
};

DataGrid.defaultProps = {
  enableRowSelect: true,
  enableColumnPinning: true,
  leftPinArr: '',
  rightPinArr: 'Action',
  enableEditing: false,
  editingMode: 'table',
  enableHiding: true,
  enableCellActions: true,
};

export default DataGrid;
