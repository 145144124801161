/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';

import AllJobsApiService from '../../../Jobs/Services/AllJobsService';
import { IJobOverview } from '../../../Jobs/Components/JobsListView/JobsListView';
import { IJobs } from '../../../Jobs/Models/JobsView.Model';
import { getTimeFormat } from '../../../../Shared/Utilities/utils';
import { IBidList } from '../../../Jobs/Components/JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import { TaskData } from '../../../Jobs/Components/WorkTasks/WorkTasks';
import { CurrencySymbol, ScheduleTypes } from '../../../../Shared/Constants/App';
import DataGrid from '../../../../Shared/Components/Common/DataGrid/DataGrid';
import ModifySchedulePopup from './ModifySchedulePopup';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';
import { getDayName } from './AllJobsColumns';
import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';

import { Icons } from '../../../../Shared/Constants/Icons';
import theme from '../../../../Shared/Themes/theme';

import WhiteTooltip from '../../../../Shared/Components/Common/CommonStyle/Tooltip.Style';
import { CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import { ActionsColumn, Linking } from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import { ModifyButtonContainer } from './AllJobs.Style';

const ModifySchedule = (): JSX.Element => {
  const location = useLocation();
  const jobsData = location?.state?.selectedRows;
  const [tableData, setTableData] = useState<any[]>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const { t } = useTranslation(['jobManagement']);
  const navigate = useNavigate();

  const renderCell = useCallback((renderedCellValue: React.ReactNode): string | number => {
    if (typeof renderedCellValue === 'string' || typeof renderedCellValue === 'number') {
      return renderedCellValue ? renderedCellValue : '-';
    }
    if (renderedCellValue && Array.isArray(renderedCellValue)) {
      return renderedCellValue[0]?.finalQuote || renderedCellValue[0]?.finalQuote === 0
        ? `${CurrencySymbol.Dollar}${Number(renderedCellValue[0]?.finalQuote).toFixed(2)}`
        : '-';
    }
    return '-';
  }, []);

  const handleOpenPopup = (rowData: any) => {
    setOpenPopup(true);
    setSelectedRows([rowData]);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleConfirmModification = async (data: {
    woScheduleMasterId: string;
    scheduleDays: string;
    startTime: string;
    endTime: string;
  }) => {
    try {
      const updateScheduleInput = {
        woScheduleMasterId: data.woScheduleMasterId,
        scheduleDays: data.scheduleDays,
        scheduleStartTime: data.startTime,
        scheduleEndTime: data.endTime,
      };
      const response = await AllJobsApiService.updateScheduleMaster([updateScheduleInput]);
      if (response.data) {
        setSnackbarMessage(t('jobManagement:updatedTheSchedule'));
        setSnackbarOpen(true);
        setSnackbarSeverity('success');
        setTimeout(() => {
          setOpenPopup(false);
          navigate('/all-jobs');
        }, 1000);
      } else {
        setSnackbarMessage(response?.errors[0]?.message);
        setSnackbarOpen(true);
        setSnackbarSeverity('error');
      }
    } catch (error) {
      console.error(t('jobManagement:errorUpdatingSchedule'), error);
    }
  };

  const renderActionsCell = (row: any): JSX.Element => {
    return (
      <ActionsColumn>
        <Linking to="#" onClick={() => handleOpenPopup(tableData[row.index])}>
          <IconButton
            size="large"
            sx={{
              '&:hover': {
                background: theme.palette.primary.main,
              },
            }}>
            <WhiteTooltip title={t('jobManagement:modifySchedule')}>
              <img src={Icons.EditBlackIcon} />
            </WhiteTooltip>
          </IconButton>
        </Linking>
      </ActionsColumn>
    );
  };

  useEffect(() => {
    if (jobsData) {
      setTableData(jobsData);
    }
  }, []);

  const columns = useMemo<MRT_ColumnDef<IJobOverview | TaskData | IJobs | IBidList | IProfileCardProps>[]>(
    () => [
      {
        accessorKey: 'jobNumber',
        header: 'Job Number',
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
      },
      {
        accessorKey: 'jobName',
        header: 'Job Name',
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
      },
      {
        accessorKey: 'jobAddress',
        header: 'Job Address',
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
      },
      {
        accessorKey: 'scheduleName',
        header: 'Schedule Name',
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
      },
      {
        accessorKey: 'scheduleStartTime',
        header: 'Schedule Start Time',
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue }) => {
          const time = String(renderedCellValue);
          return time ? getTimeFormat(time) : '';
        },
      },
      {
        accessorKey: 'scheduleEndTime',
        header: 'Schedule End Time',
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue }) => {
          const time = String(renderedCellValue);
          return time ? getTimeFormat(time) : '';
        },
      },
      {
        accessorKey: 'mdScheduleType',
        header: 'Schedule Type',
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
      },
      {
        accessorKey: 'scheduleDays',
        header: 'Schedule Days',
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          const scheduleDays = cell.getValue() as number[];
          const scheduleType = cell.row.original.mdScheduleType;
          if (scheduleType === ScheduleTypes.Weekly) {
            return scheduleDays?.map((dayIndex) => getDayName(dayIndex)).join(', ') || '-';
          } else if (scheduleType === ScheduleTypes.Monthly) {
            return scheduleDays?.join(', ') || '-';
          } else if (scheduleType === ScheduleTypes.Daily) {
            return ScheduleTypes.Daily;
          } else {
            return '-';
          }
        },
      },
      {
        accessorKey: 'mdShiftType',
        header: 'Shift Type',
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
      },
      {
        header: 'Actions',
        enableColumnActions: false,
        enablePinning: false,
        size: 90,
        Cell: ({ row }) => renderActionsCell(row),
      },
    ],
    []
  );

  return (
    <>
      <PageTitle title={t('jobManagement:modifySchedule')} />
      <CustomerContainer>
        <DataGrid
          columns={columns}
          data={tableData || []}
          enableRowSelect={false}
          enableColumnPinning={false}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          configureColumns={true}
        />
        <ModifyButtonContainer sx={{ marginTop: '2rem' }}>
          <Button
            type={ButtonType.Primary}
            label={t('jobManagement:modifySchedule')}
            onClick={() => handleOpenPopup(tableData)}
          />
        </ModifyButtonContainer>
        <ModifySchedulePopup
          selectedJobData={tableData}
          open={openPopup}
          onClose={handleClosePopup}
          onConfirm={handleConfirmModification}
          setOpenPopup={setOpenPopup}
          selectedRows={selectedRows}
        />
        <SnackbarMessage
          open={snackbarOpen}
          successMessage={snackbarMessage}
          errorMessage={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        />
      </CustomerContainer>
    </>
  );
};

export default ModifySchedule;
