/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonType } from '../Buttons/Button';
import { Icons } from '../../../Constants/Icons';

import { CloseIconStyle } from '../SuccessMessagePopup/SuccessMessagePopup.Style';
import { DeleteWrapper, ButtonsContainer, MessageBox, MessageText, NameText } from './DeletePopup.Style';

interface DeleteProps {
  open: boolean;
  onClose: () => void;
  message: string;
  name: string;
  primaryAction: () => void;
  secondaryAction?: () => void;
  primaryButtonLabel: string;
  secondaryButtonLabel?: string;
  isPrimaryButtonLoading?: boolean;
  isSecondaryButtonLoading?: boolean;
}

const DeletePopup: React.FC<DeleteProps> = ({
  open,
  onClose,
  message,
  name,
  primaryButtonLabel,
  secondaryButtonLabel,
  primaryAction,
  secondaryAction,
  isPrimaryButtonLoading,
  isSecondaryButtonLoading,
}) => {
  const { t } = useTranslation(['vendor', 'oneTimeJob', 'dashboard']);

  return (
    <DeleteWrapper open={open} onClose={onClose}>
      <CloseIconStyle onClick={onClose}>
        <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
      </CloseIconStyle>
      <MessageBox>
        <MessageText>{message}</MessageText>
        <NameText>{name}</NameText>
      </MessageBox>
      {secondaryButtonLabel && (
        <ButtonsContainer>
          <Button
            type={ButtonType.Secondary}
            label={secondaryButtonLabel}
            onClick={secondaryAction}
            isLoading={isSecondaryButtonLoading}
          />
          <Button
            type={ButtonType.Primary}
            label={primaryButtonLabel}
            onClick={primaryAction}
            isLoading={isPrimaryButtonLoading}
          />
        </ButtonsContainer>
      )}
      {!secondaryButtonLabel && (
        <ButtonsContainer>
          <Button type={ButtonType.Primary} label={primaryButtonLabel} onClick={primaryAction} />
        </ButtonsContainer>
      )}
    </DeleteWrapper>
  );
};

export default DeletePopup;
