/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MRT_SortingState } from 'material-react-table';
import { IconButton } from '@mui/material';
import { isEmpty } from 'lodash';

import {
  deepClone,
  getBidAddress,
  getBidFacility,
  getStoredCustomerDetails,
  getVendorBids,
  handleFilter,
  handleSort,
  NUMBER_OF_ITEMS_PER_PAGE,
} from '../../../../Shared/Utilities/utils';

import MarketplaceApiService from '../../Services/marketplaceServices';
import { BidQueryType } from '../../../../API';

import { IJobRow } from '../../../Jobs/Models/JobsView.Model';
import { GetAllBids, JobType, MarketplaceTabs, SortingItem } from '../../Models/Marketplace.model';
import { FilterItem } from '../../../Jobs/Components/JobsListView/JobsListView';

import DataGridComponent from './BaseTable';
import ModifyPopUp from '../../../Jobs/Components/JobDetails/ModifyPopUp';
import useColumns from './useColumns';
import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';

import { Icons } from '../../../../Shared/Constants/Icons';
import theme from '../../../../Shared/Themes/theme';
import { ActionsColumn, Linking } from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import WhiteTooltip from '../../../../Shared/Components/Common/CommonStyle/Tooltip.Style';
import { SortByType } from '../../../../Shared/Constants/App';

const BidsInProgress = (): JSX.Element => {
  const { t } = useTranslation(['vendor', 'tableHeader', 'altTexts', 'vendorBidCreation', 'jobAward']);

  const [sortBy, setSortBy] = useState('');
  const [bidsInProgressJobs, setBidsInProgressJobs] = useState<any[]>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [newSortOrder, setNewSortOrder] = useState('');
  const [newSort, setNewSort] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModifyBidPopupOpen, setIsModifyBidPopupOpen] = useState(false);
  const [InProgressBidDetails, setInProgressBidDetails] = useState<any>({});
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [columnFilters, setColumnFilters] = useState<{ id: string; value: string }[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const hasFetchedDataRef = useRef(false);

  const navigate = useNavigate();

  const myCustomSortingFn = (): void => {
    setSortBy(SortByType.DESC);
  };

  const onSortChange = async (sortingObj: SortingItem[]): Promise<void> => {
    await handleSort(
      sortingObj,
      newSortOrder,
      setSorting,
      setNewSortOrder,
      setNewSort,
      fetchData,
      hasFetchedDataRef,
      newSort,
      currentPage,
      itemsPerPage
    );
  };

  const onFilterChange = async (filtersObj: FilterItem[]) => {
    await handleFilter(
      filtersObj,
      setColumnFilters,
      fetchData,
      currentPage,
      itemsPerPage,
      newSortOrder,
      hasFetchedDataRef,
      newSort
    );
  };

  const handleChange = (event: any, value: number) => {
    setCurrentPage(value);
  };

  const handleModifyBidPopup = (): void => {
    setIsModifyBidPopupOpen(!isModifyBidPopupOpen);
  };

  const transformBidsData = (data: GetAllBids[], customerId: string) => {
    return data.map((selectedBid: GetAllBids) => {
      const jobBids = getVendorBids(selectedBid, customerId);
      const jobFacility = getBidFacility(selectedBid);
      const address = getBidAddress(selectedBid);
      const services = selectedBid?.jobServices?.map((service) => service?.mdServiceName).join(', ');
      return {
        ...selectedBid,
        id: selectedBid?.bidId ?? '',
        bidDueDate: selectedBid?.bidDueDate,
        createdOn: selectedBid?.bidCreatedOn,
        area: selectedBid?.totalSqftArea ?? selectedBid?.totalUnitsArea,
        location: selectedBid?.jobFacilities?.[0]?.facilityAddress ?? '',
        facilityAddress: selectedBid?.jobFacilities?.[0]?.facilityAddress ?? '',
        facilityName: selectedBid?.facilityBuildingName ?? '',
        type: JobType.BidsInProgress,
        bidStatus: selectedBid?.bidDataStatus,
        bidId: selectedBid?.bidId,
        bidVersion: selectedBid?.bidVersion,
        vendorId: customerId,
        jobFacility: jobFacility,
        jobBids: jobBids,
        address: address,
        status: selectedBid?.jobDataStatus,
        mdServiceName: services,
      };
    });
  };

  const fetchData = async (
    pageNumber?: number,
    limit?: number,
    sortBy?: string,
    sortOrder?: boolean,
    filterBy?: string,
    filter?: string
  ) => {
    setIsLoading(true);
    try {
      const { customerId } = getStoredCustomerDetails();
      const sortOrderString = sortOrder ? 'desc' : sortBy ? 'asc' : undefined;

      const bidsInProgress = await MarketplaceApiService.getAllBids(
        customerId,
        BidQueryType.InProgressBids,
        pageNumber,
        limit ?? NUMBER_OF_ITEMS_PER_PAGE,
        sortBy,
        sortOrderString,
        filterBy,
        filter
      );

      if (bidsInProgress?.errors?.length) {
        setSnackbarMessage(bidsInProgress.errors[0]?.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }

      if (bidsInProgress?.data && bidsInProgress?.errors?.length === 0) {
        const modifiedData = transformBidsData(bidsInProgress?.data, customerId);

        const filteredModifiedData = modifiedData.filter(Boolean);
        if (!isEmpty(filteredModifiedData)) {
          setBidsInProgressJobs(filteredModifiedData);
          setTotalCount(bidsInProgress?.metadata?.totalCount);
          setIsLoading(false);
        } else {
          setBidsInProgressJobs([]);
          setIsLoading(false);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error:', error);
    }
  };

  const renderBidsInProgressActionsCell = (row: any): JSX.Element => {
    const { jobId } = row.original as unknown as IJobRow;
    return (
      <ActionsColumn>
        <IconButton
          size="large"
          sx={{
            '&:hover': {
              background: theme.palette.primary.main,
            },
          }}
          onClick={() => {
            setIsModifyBidPopupOpen(true);
            setInProgressBidDetails({
              jobDetails: { ...bidsInProgressJobs[row.index], isVendorBid: true },
              jobId: bidsInProgressJobs[row.index].jobId,
            });
          }}>
          <WhiteTooltip title={t('vendor:continueBid')}>
            <img src={Icons.EditBlackIcon} alt={t('altTexts:modify')} />
          </WhiteTooltip>
        </IconButton>
        <Linking
          to={{ pathname: `/conversations`, search: `?jobId=${jobId}` }}
          state={{ ...row.original, tag: 'BID_RELATED' }}
          key={0}>
          <IconButton
            size="large"
            sx={{
              '&:hover': {
                background: theme.palette.primary.main,
              },
            }}>
            <WhiteTooltip title={t('vendor:message')}>
              <img
                src={Icons.ConversationIcon}
                alt={t('altTexts:conversation')}
                style={{ width: '1.3rem', marginTop: '2px' }}
              />
            </WhiteTooltip>
          </IconButton>
        </Linking>
        <Linking
          to={{
            pathname: `/onetimejobdetails`,
            search: `?id=${jobId}`,
          }}
          state={{
            facilityItem: {
              ...row.original,
              address: row.original?.jobFacility?.facilityAddress || {},
            },
            facility: { ...row.original.jobFacility, address: row.original?.jobFacility?.facilityAddress || {} },
            job: {
              ...row.original,
              address: row.original?.jobFacility?.facilityAddress || {},
            },
          }}
          key={2}>
          <IconButton
            size="large"
            sx={{
              '&:hover': {
                background: theme.palette.primary.main,
              },
            }}>
            {' '}
            <WhiteTooltip title={t('homePage:viewDetails')}>
              <img src={Icons.EyeIcon} alt={t('altTexts:viewDetails')} />
            </WhiteTooltip>
          </IconButton>
        </Linking>
      </ActionsColumn>
    );
  };

  const { columns } = useColumns(MarketplaceTabs.BidsInProgress);

  const bidsInProgressColumns = useMemo(() => {
    const jobColumns = deepClone(columns);
    jobColumns.splice(12, 0, {
      header: 'Actions',
      enableColumnActions: false,
      enablePinning: false,
      size: 70,
      Cell: ({ row }: any) => renderBidsInProgressActionsCell(row),
    });
    return jobColumns;
  }, [columns]);

  const navigateToCreateBid = (bidDetails: any) => {
    setTimeout(() => {
      navigate('/create-bid', { state: { ...bidDetails } });
    }, 500);
  };

  useEffect(() => {
    if (!hasFetchedDataRef.current && !sorting.length && !columnFilters.length) {
      fetchData(currentPage, itemsPerPage);
      hasFetchedDataRef.current = true;
    }
  }, [sorting, columnFilters]);

  return (
    <>
      <DataGridComponent
        columns={bidsInProgressColumns}
        data={bidsInProgressJobs}
        sorting={sorting}
        sortBy={sortBy}
        isLoading={isLoading}
        handleSort={onSortChange}
        myCustomSortingFn={myCustomSortingFn}
        handleChange={handleChange}
        totalCount={totalCount}
        handleApi={fetchData}
        currentPage={currentPage}
        newSortOrder={newSortOrder}
        newSort={newSort}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        filterJobs={onFilterChange}
      />
      <ModifyPopUp
        open={isModifyBidPopupOpen}
        handleClose={handleModifyBidPopup}
        handleOnCloseJob={() => navigateToCreateBid(InProgressBidDetails)}
        heading={t('assignJob:continueBid')}
        text={t('assignJob:confirmContinueBid')}
      />
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default BidsInProgress;
