/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled, Tab } from '@mui/material';
import { StyleConstants } from '../../Constants/Style.Constants';
import theme from '../../Themes/theme';

const wrapperWidth = 1024 + 80; // 1024 -> max width and 80 is navbar width

export const CustomerOuterContainer = styled('div')(({ theme }) => ({
  margin: '0 auto',
  padding: '9rem 4rem 2rem 4.75rem',
  height: '100%',
  [theme.breakpoints.down(wrapperWidth)]: {
    boxSizing: 'border-box',
    padding: '6.5rem 1.5rem 2rem 1.5rem',
  },
}));

export const ConversationsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  height: '100%',
  boxShadow: '0rem 0.625rem 1.25rem 0.375rem rgba(121, 87, 0, 0.10)',
  borderRadius: '10px',
}));

export const ConversationsContainerOne = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexBasis: '25%',
  maxWidth: '25%',
  height: '100%',
  background: 'white',
  borderTopLeftRadius: '10px',
  borderBottomLeftRadius: '10px',
  overflow: 'hidden',
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.12)',
}));

export const TabTitle = styled(Tab)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '1.75rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  textTransform: 'capitalize',
  '&.Mui-selected': {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
  },
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.375rem',
  },
}));

export const ChatTitle = styled('div')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '1.75rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  textTransform: 'capitalize',
  '&.Mui-selected': {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
  },
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.375rem',
  },
}));

export const NoConversationFound = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '10px',
  justifyContent: 'flex-start',
  fontSize: '1rem',
  width: '100%',
}));

export const ConversationsContainerTwo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexBasis: '75%',
  flexDirection: 'column',
  overflow: 'hidden',
  height: '100%',
  background: theme.palette.secondary.main,
  boxShadow: 'inset 0px 2px 4px rgba(0, 0, 0, 0.1)',
}));

export const AddIconWrapper = styled('div')(() => ({
  position: 'absolute',
  bottom: '16px',
  right: '26px',
  width: '55px',
  height: '55px',
  backgroundColor: theme.palette.primary.dark,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#fff',
  cursor: 'pointer',
  zIndex: 1000,
  [theme.breakpoints.down('laptop')]: {
    bottom: '18px',
  },
}));

export const ConversationCardMainDiv = styled('div')(() => ({
  cursor: 'pointer',
}));

export const TabPanelStyles = {
  tabPanel: {
    overflowY: 'auto',
    height: 'calc(100vh - 14rem)',
    background: theme.palette.secondary.main,
    borderBottomLeftRadius: '10px',
    '@media (max-width: 64rem)': {
      height: 'calc(100vh - 12.8rem)',
    },
  },
  containerOne: {
    overflowY: 'auto',
    height: 'calc(100vh - 15rem)',

    background: theme.palette.secondary.main,
    borderBottomLeftRadius: '10px',
    '@media (max-width: 69rem)': {
      height: 'calc(100vh - 12.8rem)',
    },
  },
};

export const TabPanelDiv = styled('div')(() => ({
  overflowY: 'auto',
  height: 'calc(100vh - 15rem)',

  background: theme.palette.secondary.main,
  borderBottomLeftRadius: '10px',
  '@media (max-width: 69rem)': {
    height: 'calc(100vh - 12.8rem)',
  },
}));
