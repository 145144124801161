/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useState } from 'react';
import { Select, Box } from '@mui/material'; // Updated import
import { useTranslation } from 'react-i18next';

import { DropdownOptions, IDropdownWithButtonProps } from './AllJobs.Model';
import { Labels } from '../../../../Shared/Components/Common/GraphContainer/GraphContainer';

import { Icons } from '../../../../Shared/Constants/Icons';

import { Options } from '../../../../Shared/Components/Common/Dropdown/Dropdown.styles';
import { SelectInput } from '../../../../Shared/Components/Common/GraphContainer/GraphContainer.styles';
import { useNavigate } from 'react-router-dom';

const DropdownWithButton = ({ selectedRows }: IDropdownWithButtonProps): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState<DropdownOptions | ''>('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { t } = useTranslation(['jobManagement']);

  const options = [
    { value: DropdownOptions.RescheduleShift, label: t('jobManagement:rescheduleShift') },
    { value: DropdownOptions.CancelShift, label: t('jobManagement:cancelShift') },
    ...(selectedRows?.length === 1
      ? [{ value: DropdownOptions.ModifySchedule, label: t('jobManagement:modifySchedule') }]
      : []),
  ];

  const navigate = useNavigate();

  const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);

  const handleValueChange = (event: any) => {
    const value = event.target.value;
    setSelectedValue(value);

    if (value === DropdownOptions.RescheduleShift) {
      navigate('/all-jobs/reschedule-shift', {
        state: {
          selectedRows: selectedRows,
        },
      });
    } else if (value === DropdownOptions.CancelShift) {
      navigate('/all-jobs/cancel-shift', {
        state: {
          selectedRows: selectedRows,
        },
      });
    } else if (value === DropdownOptions.ModifySchedule) {
      navigate('/modify-schedule', {
        state: {
          selectedRows: selectedRows,
        },
      });
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Select
        value={selectedValue || ''}
        onChange={handleValueChange}
        open={isDropdownOpen}
        onClose={toggleDropdown}
        onOpen={toggleDropdown}
        displayEmpty
        renderValue={(selected) => {
          if (!selected) {
            return t('jobManagement:selectAction');
          }
          const selectedOption = options.find((option) => option.value === selected);
          return selectedOption ? selectedOption.label : t('jobManagement:selectAction');
        }}
        input={
          <SelectInput
            sx={{
              '& .MuiSelect-select': { width: '6.25rem' },
            }}
          />
        }
        IconComponent={() => (
          <img
            src={Icons.DropdownIcon}
            alt="dropdown-icon"
            style={{
              transform: isDropdownOpen ? Labels.Rotate : 'none',
              transition: 'transform 0.3s',
              position: 'absolute',
              right: '0.75rem',
            }}
          />
        )}>
        {options.map((option) => (
          <Options key={option.value} value={option.value}>
            {option.label}
          </Options>
        ))}
      </Select>
    </Box>
  );
};

export default DropdownWithButton;
