/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useState, useEffect, useReducer, useCallback } from 'react';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { Box } from '@mui/material';

import { Auth } from 'aws-amplify';
import { PermissionGate } from '@abrightlab/client-rbac';
import { INITIALSTATE, postReducer } from '../../../../Shared/Store/reducer/postReducer';
import { ACTION_TYPES } from '../../../../Shared/Store/actions/postActions';
import { GraphTemplateV2 } from '../../../../Shared/Components/Common/GraphContainer/index';
import { GraphType } from '../../../../Shared/Components/Common/Graphs/Graphs.Model';
import FacilityApiService from '../../../Facility/Services/FacilityApiService';
import CustomerApiService from '../../Services/CustomerService';
import JobCreateApiService from '../../../Jobs/Services/JobCreateService';
import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import { AuthenticationService } from '../../../../Shared/Services/AuthenticationService';
import WorkOrdersApiService from '../../../Jobs/Services/WorkOrdersService';
import { BooleanType } from '../../../../API';
import {
  getStoredCustomerDetails,
  getAuthDetails,
  removeJobLocalData,
  getAuthData,
} from '../../../../Shared/Utilities/utils';
import {
  Role,
  GraphTypes,
  DefaultOptions,
  DateFormat,
  DateRangeOptionType,
  FormFieldType,
  FilterName,
  UserType,
} from '../../../../Shared/Constants/App';
import AsyncStorageKeys from '../../../../Shared/Constants/StorageKeys';
import GoogleMap from '../../../../Shared/Components/Common/GoogleMap/GoogleMap';
import Button, { ButtonType, IconDirection } from '../../../../Shared/Components/Common/Buttons/Button';
import ApproveDialog from '../../../Jobs/Components/TaskDetails/logoutDialog';
import AnalyticsApiService from '../../../../Shared/Services/AnalyticsService';
import theme from '../../../../Shared/Themes/theme';
import { Icons } from '../../../../Shared/Constants/Icons';
import AllJobs from '../../../Vendor/Components/Marketplace/AllJobs';
import {
  HeaderWrapper,
  Title,
  Wrapper,
} from '../../../../Shared/Components/Common/GraphContainer/GraphContainer.styles';

import { HomePageContainer, SearchFieldContainer, SearchInput } from '../../../../Shared/Components/Layout/styles';
import { ButtonTitle } from './Dashboard.style';

const HomePage = () => {
  const [showNoDataScreen, setShowNoDataScreen] = useState(false);
  const [state, dispatch] = useReducer(postReducer, INITIALSTATE);
  const [noShowGraphData, setNoShowGraphData] = useState({});
  const [isNoShowsDataLoading, setIsNoShowsDataLoading] = useState(false);
  const [isShowSessionExpirePopUp, setIsShowSessionExpirePopUp] = useState(false);
  const [isShowSessionExpirePopUpLoading, setIsShowSessionExpirePopUpLoading] = useState(false);
  const { loading, error, data } = state;
  const [allData, setAllData] = useState({});
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedGraph, setSelectedGraph] = useState('');
  const [jobCompletionTrendData, setJobCompletionTrendData] = useState([]);
  const [isJobCompletionTrendDataLoading, setIsJobCompletionTrendDataLoading] = useState(false);
  const [selectedAssociateId, setSelectedAssociateId] = useState();
  const [selectedFacilityId, setSelectedFacilityId] = useState();
  const [allAssociates, setAllAssociates] = useState([]);
  const [selectedAssociate, setSelectedAssociate] = useState('');
  const [selectedFacility, setSelectedFacility] = useState();
  const [facility, setFacility] = useState([]);
  const [isAssociateLoading, setIsAssociateLoading] = useState();
  const [isFacilityLoading, setIsFacilityLoading] = useState();
  const [selectedRoleBasedId, setSelectedRoleBasedId] = useState();
  const [customersAndVendors, setCustomersAndVendors] = useState([]);
  const [selectedCustomerVendor, setSelectedCustomerVendor] = useState('');
  const [isCustomerVendorLoading, setIsCustomerVendorLoading] = useState();

  const { t } = useTranslation(['homePage', 'oneTimeJob']);
  const navigate = useNavigate();
  const storedAuthDetails = getAuthDetails();
  const { role, isDistrictManager } = getAuthData();
  const locationData = { lat: 33.753746, long: -84.38633 }; //This is needed to set the initial center.
  const customRole = 'custom:Role';
  const roles = storedAuthDetails[customRole];
  const defaultDateRangeOption = '-7';
  const defaultRoleBasedOption = DefaultOptions.All;
  const { customerId = '', userId } = getStoredCustomerDetails() || {};

  const dateRangeOptions = [
    { id: 1, label: t('dashboard:today'), value: '1', type: DateRangeOptionType.Days },
    { id: 2, label: t('dashboard:lastWeek'), value: '-7', type: DateRangeOptionType.Days },
    { id: 3, label: t('dashboard:lastMonth'), value: '-30', type: DateRangeOptionType.Days },
    { id: 4, label: t('dashboard:last45Days'), value: '-45', type: DateRangeOptionType.Days },
    { id: 5, label: t('dashboard:customDateRange'), value: 'custom', type: DateRangeOptionType.CustomDateRange },
  ];
  const labels = [
    { dataKey: 'missed', fill: theme.palette.secondary.customColor12, name: t('dashboard:missed') },
    { dataKey: 'scheduled', fill: theme.palette.secondary.customColor13, name: t('dashboard:scheduled') },
    { dataKey: 'completed', fill: theme.palette.secondary.customColor14, name: t('dashboard:completed') },
  ];

  const filters = [
    {
      title: storedAuthDetails[customRole] === UserType.Customer ? t('dashboard:vendors') : t('dashboard:associates'),
      type: FormFieldType.DropDown,
      name: FilterName.Associates,
      options: allAssociates.map((associateAndVendor) => ({
        label: associateAndVendor.name,
        value: associateAndVendor.id,
      })),
    },
    {
      title: t('dashboard:location'),
      type: FormFieldType.DropDown,
      name: FilterName.Location,
      options: facility.map((facility) => ({
        label: facility.name,
        value: facility.id,
      })),
    },
    {
      title: storedAuthDetails['custom:Role'] === UserType.Customer ? t('dashboard:vendors') : t('dashboard:customers'),
      type: FormFieldType.DropDown,
      name: FilterName.CustomerAndVendor,
      options: customersAndVendors.map((customersAndVendor) => ({
        label: customersAndVendor.name,
        value: customersAndVendor.id,
      })),
    },
  ];

  const getJobCompletionTrendData = async (
    startDate,
    endDate,
    selectedAssociateId = userId,
    selectedFacilityId = DefaultOptions.All,
    selectedRoleBasedId = ''
  ) => {
    try {
      setIsJobCompletionTrendDataLoading(true);
      const storedAuthDetails = getAuthDetails();

      const jobCompletionData = await AnalyticsApiService.shiftTrend(
        customerId,
        startDate,
        endDate,
        storedAuthDetails[customRole] === UserType.Customer ? UserType.Customer : UserType.Vendor,
        userId,
        selectedAssociateId,
        selectedFacilityId,
        selectedRoleBasedId
      );
      if (jobCompletionData?.data) {
        const modifiedData = jobCompletionData.data.map((dataItem) => {
          return {
            name: moment(dataItem.shiftDate).format(DateFormat.StandardDate),
            scheduled: dataItem.scheduled,
            missed: dataItem.missed,
            completed: dataItem.completed,
          };
        });

        setJobCompletionTrendData({
          title: t('dashboard:jobCompletionTrendByStatus'),
          xAxisLabel: t('dashboard:shiftDate'),
          yAxisLabel: t('dashboard:numberOfShifts'),
          data: modifiedData,
        });
      }
    } catch (e) {
      setIsJobCompletionTrendDataLoading(false);
      console.error(t('dashboard:ErrorFetchingJobCompletionTrendData'));
    } finally {
      setIsJobCompletionTrendDataLoading(false);
    }
  };

  const getNoShowGraphData = async (startDate, endDate) => {
    try {
      setIsNoShowsDataLoading(true);
      const storedAuthDetails = getAuthDetails();
      const { customerId = '', userId } = getStoredCustomerDetails() || {};
      const noShowFacilities = await AnalyticsApiService.noShowFacilities(
        customerId,
        startDate,
        endDate,
        storedAuthDetails[customRole] === UserType.Customer ? UserType.Customer : UserType.Vendor,
        userId
      );

      if (noShowFacilities?.data) {
        const modifiedData = noShowFacilities?.data?.map((dataItem) => {
          return {
            name: dataItem.facilityName,
            value: dataItem.count,
          };
        });

        setNoShowGraphData({
          title: 'Missed Jobs',
          graphView: 'vertical',
          graphColor: theme.palette.secondary.customColor3,
          data: modifiedData,
        });
      }
      setIsNoShowsDataLoading(false);
    } catch (e) {
      setIsNoShowsDataLoading(false);
      console.log('e', e);
    }
  };

  const fetchData = async (date) => {
    try {
      dispatch({ type: ACTION_TYPES.FETCH_START });
      const storedAuthDetails = getAuthDetails();
      const { customerId = '', userId } = getStoredCustomerDetails() || {};
      const allIdsResponse = await AnalyticsApiService.taskSummary(
        customerId,
        date,
        storedAuthDetails[customRole] === UserType.Customer ? UserType.Customer : UserType.Vendor,
        userId
      );

      if (allIdsResponse?.errors?.length) {
        dispatch({ type: ACTION_TYPES.FETCH_ERROR });
      } else if (typeof allIdsResponse?.data === 'object') {
        if (!Object.keys(allIdsResponse?.data)?.length) {
          dispatch({ type: ACTION_TYPES.FETCH_ERROR, payload: data });
          setShowNoDataScreen(true);
        } else if (Object.keys(allIdsResponse?.data)?.length) {
          setAllData(allIdsResponse?.data);
          dispatch({ type: ACTION_TYPES.FETCH_SUCCESS, payload: data });
        }
      }
    } catch (e) {
      console.log('error in catch', e);
      dispatch({ type: ACTION_TYPES.FETCH_ERROR });
    }
  };

  const handleFetchData = (date) => {
    void fetchData(date);
  };

  const onDateRangeSelect = (selectedRange, id, associatesId, facilityId, roleBasedId) => {
    let startDate = selectedRange.fromDate;
    let endDate = selectedRange.toDate;

    setFromDate(startDate);
    setToDate(endDate);
    setSelectedGraph(id);
    setSelectedAssociateId(associatesId ?? selectedAssociateId);
    setSelectedFacilityId(facilityId ?? selectedFacilityId);
    setSelectedRoleBasedId(roleBasedId ?? selectedRoleBasedId);
  };

  const handleAssociateChange = (associatesId, startDate, endDate) => {
    setSelectedAssociateId(associatesId);
    getJobCompletionTrendData(startDate, endDate, associatesId, selectedFacilityId, selectedRoleBasedId);
  };

  const handleCustomerVendorChange = (roleBasedId, startDate, endDate) => {
    setSelectedRoleBasedId(roleBasedId);
    void getJobCompletionTrendData(startDate, endDate, selectedAssociateId, selectedFacilityId, roleBasedId);
  };

  const handleLocationChange = (facilityId, startDate, endDate) => {
    setSelectedFacilityId(facilityId);
    void getJobCompletionTrendData(startDate, endDate, selectedAssociateId, facilityId, selectedRoleBasedId);
  };

  useEffect(() => {
    // const storedAuthDetails = getAuthDetails();
    localStorage.removeItem(AsyncStorageKeys.viewJob);
    localStorage.removeItem(AsyncStorageKeys.currentJob);
    removeJobLocalData();
  }, []);

  const signOut = useCallback(async () => {
    try {
      setIsShowSessionExpirePopUpLoading(true);
      await Auth.signOut();
      localStorage.removeItem('auth');
      localStorage.removeItem('customerDetails');
      navigate('/login');
    } catch (error) {
      console.error('An error occurred during sign out:', error);
    } finally {
      setIsShowSessionExpirePopUpLoading(false);
    }
  }, []);

  const LogoutUI = () => {
    return (
      <div>
        {t('logout:logoutConfirm')} <br /> <strong>{t('logout:peazyApplication')}</strong>
      </div>
    );
  };

  const fetchAllAssociates = async () => {
    setIsAssociateLoading(true);
    try {
      const customerDetails = getStoredCustomerDetails();
      const vendorId = customerDetails?.customerId;
      const loggedInUser = await MasterDataApiService.getCurrentUserInfo();
      const userDetails = await AuthenticationService.getUserByCognitoUserId(loggedInUser.username);
      const userId = userDetails?.data?.userId;
      const response = isDistrictManager
        ? await WorkOrdersApiService.getVendorTeam(userId, '', BooleanType.True, '', 1, 10, false)
        : await CustomerApiService.getAllUsers(vendorId, '', 0, 10, BooleanType.True, false);
      const associateData = response.data.map((associate) => ({
        id: associate.userId,
        name: associate.username,
      }));
      setAllAssociates([{ id: DefaultOptions.All, name: DefaultOptions.All }, ...associateData]);
    } catch (error) {
      console.error(t('dashboard:errorFetchingUsers'));
    } finally {
      setIsAssociateLoading(false);
    }
  };

  const fetchCustomersAndVendors = async () => {
    setIsCustomerVendorLoading(true);
    try {
      const customerDetails = getStoredCustomerDetails();
      let customerVendorData = [];
      let response;
      if (roles === UserType.Vendor) {
        const vendorId = customerDetails?.customerId;
        response = await JobCreateApiService.getVendorAffiliations(vendorId);
      } else if (roles === UserType.Customer) {
        const customerId = customerDetails?.customerId;
        response = await JobCreateApiService.getCustomerAffiliations(customerId);
      }

      customerVendorData = response?.data.map((vendor) => ({
        id: vendor.orgId,
        name: vendor.orgName,
      }));
      setCustomersAndVendors([{ id: DefaultOptions.All, name: DefaultOptions.All }, ...customerVendorData]);
    } catch (error) {
      console.error(t('dashboard:errorFetchingUsers'), error);
    } finally {
      setIsCustomerVendorLoading(false);
    }
  };

  const handleAssociateChanges = (event) => {
    const value = event.target.value;
    setSelectedAssociate(value);
    handleAssociateChange(value);
  };

  const handleCustomerVendorChanges = (event) => {
    const value = event.target.value;
    setSelectedCustomerVendor(value);
    const id = value === DefaultOptions.All ? '' : value;
    handleCustomerVendorChange(id);
  };

  const fetchFacilities = async () => {
    setIsFacilityLoading(true);
    try {
      let facilitiesData = [];
      const { customerId = '' } = getStoredCustomerDetails() || {};
      const response = await FacilityApiService.getAllFacilities(customerId);
      facilitiesData = response.data.map((facility) => ({
        id: facility.facilityId,
        name: facility.buildingName,
      }));

      setFacility([{ id: DefaultOptions.All, name: DefaultOptions.All }, ...facilitiesData]);
    } catch (error) {
      console.error(t('dashboard:errorFetchingFacility'));
    } finally {
      setIsFacilityLoading(false);
    }
  };

  const handleFacilityChanges = (event) => {
    const value = event.target.value;
    setSelectedFacility(value);
    handleLocationChange(value);
  };

  useEffect(() => {
    fetchAllAssociates();
  }, [role]);

  useEffect(() => {
    fetchCustomersAndVendors();
  }, []);

  useEffect(() => {
    void fetchFacilities();
  }, []);

  useEffect(() => {
    const startDate = moment().subtract(6, DateRangeOptionType.Days).format(DateFormat.ISODate);
    const endDate = moment().format(DateFormat.ISODate);
    void getNoShowGraphData(startDate, endDate);
    getJobCompletionTrendData(startDate, endDate, selectedAssociateId, selectedFacilityId, selectedRoleBasedId);
  }, []);

  useEffect(() => {
    if (fromDate && toDate) {
      if (selectedGraph === GraphTypes.NoShows) {
        void getNoShowGraphData(fromDate, toDate);
      } else if (selectedGraph === GraphTypes.CompletionTrend) {
        void getJobCompletionTrendData(fromDate, toDate, selectedAssociateId, selectedFacilityId, selectedRoleBasedId);
      }
    }
  }, [fromDate, toDate, selectedGraph]);

  useEffect(() => {
    if (fromDate && toDate && (selectedAssociateId || selectedRoleBasedId || selectedFacilityId)) {
      getJobCompletionTrendData(fromDate, toDate, selectedAssociateId, selectedFacilityId, selectedRoleBasedId);
    }
  }, [fromDate, toDate, selectedAssociateId, selectedRoleBasedId, selectedFacilityId]);

  return (
    <div>
      {/* <Box
        sx={{
          padding: '16px 240px',
          textAlign: 'center',
          color: 'white',
          fontFamily: 'Manrope',
          backgroundColor: '#006B6B',
        }}>
        <Typography>
          Important Update: Peazy Upgrade Scheduled Thursday, September 19th 8:30am-2pm Eastern. System will be down for
          20 minutes and have reduced functionality during this window of time.
        </Typography>
      </Box> */}
      <HomePageContainer>
        <Box
          sx={{
            display: 'flex',
            padding: '2rem 0rem 1.5rem 0',
            gap: '1.5rem',
            width: '100%',
          }}>
          <SearchFieldContainer>
            <SearchInput type="text" placeholder={t('oneTimeJob:search')} />
            <img src={Icons.SearchIcon} alt={t('altTexts:search')} style={{ width: '1.5rem', height: '1.5rem' }} />
          </SearchFieldContainer>
          <PermissionGate
            roles={[
              Role.AccountHolder,
              Role.Supervisor,
              Role.DistrictManager,
              Role.RegionalManager,
              Role.Customer,
              Role.OTJAdmin,
              Role.Admin,
            ]}
            permissions={['']}>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison */}
            {isDistrictManager || role === UserType.Customer ? (
              // <Link to="/createOneTimeJob" style={{ textDecoration: 'none' }}>
              <Button
                onClick={() => {
                  const authInfo = localStorage.getItem('auth');
                  const { signedInTime } = JSON.parse(authInfo);
                  const isPreviousSignedSessionMoreThan_55_minutes =
                    new Date().getTime() - signedInTime > 1000 * 60 * 55; //checking 55 minutes difference from singed in time.
                  if (isPreviousSignedSessionMoreThan_55_minutes) {
                    setIsShowSessionExpirePopUp(true);
                  } else {
                    localStorage.removeItem(AsyncStorageKeys.currentJob);
                    navigate('/createOneTimeJob');
                  }
                }}
                icon={<img src={Icons.PlusWhiteIcon} alt={t('altTexts:add')} />}
                type={ButtonType.Primary}
                label={t('homePage:oneTimeJob')}
                iconDirection={IconDirection.Right}
              />
            ) : // </Link>
            null}
          </PermissionGate>
        </Box>

        <Box
          sx={{
            boxShadow: '0rem 0.625rem 1.25rem 0.375rem rgba(121, 87, 0, 0.10)',
            backgroundColor: 'white',
            overflow: 'hidden',
            borderRadius: 3,
            marginBottom: 3,
            borderColor: '10px solid green',
          }}>
          <GoogleMap
            allData={allData}
            loading={loading}
            error={error}
            fetchData={handleFetchData}
            showNoDataScreen={showNoDataScreen}
            locationData={locationData}
          />
        </Box>

        <Box
          sx={{
            marginBottom: 3,
            borderColor: '10px solid red',
          }}>
          <Box sx={{ marginBottom: '3rem' }}>
            <GraphTemplateV2
              graphData={jobCompletionTrendData}
              id={GraphTypes.CompletionTrend}
              type={GraphType.MultiBarGraph}
              loading={isJobCompletionTrendDataLoading}
              onDateRangeSelect={onDateRangeSelect}
              handleAssociateChanges={handleAssociateChanges}
              handleCustomerVendorChanges={handleCustomerVendorChanges}
              handleLocationChanges={handleFacilityChanges}
              selectedAssociate={selectedAssociate}
              selectedCustomerVendor={selectedCustomerVendor}
              defaultOption={defaultRoleBasedOption}
              selectedFacility={selectedFacility}
              isAssociateLoading={isAssociateLoading}
              isCustomerVendorLoading={isCustomerVendorLoading}
              isFacilityLoading={isFacilityLoading}
              labels={labels}
              dateRangeOptions={dateRangeOptions}
              defaultDateRangeOption={defaultDateRangeOption}
              filters={filters}
            />
          </Box>
          {/* <Box>
            <GraphTemplateV2
              graphData={noShowGraphData}
              id={GraphTypes.NoShows}
              type={GraphType.BarGraph}
              loading={isNoShowsDataLoading}
              onDateRangeSelect={onDateRangeSelect}
              dateRangeOptions={dateRangeOptions}
              defaultDateRangeOption={defaultDateRangeOption}
            />
          </Box> */}
        </Box>

        {role === UserType.Vendor && (
          <Box>
            <Wrapper>
              <HeaderWrapper>
                <Title>{t('dashboard:jobsToBidOn')}</Title>
                <Link to="/marketplace/all-jobs" style={{ textDecoration: 'none' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', padding: 0, textDecoration: 'none' }}>
                    <ButtonTitle>{t('dashboard:viewAll')}</ButtonTitle>
                    <img src={Icons.ArrowForwardIcon} sx={{ paddingLeft: '0.5rem' }} />
                  </Box>
                </Link>
              </HeaderWrapper>
              <Box
                sx={{
                  margin: 4,
                  paddingBottom: 3,
                  borderColor: '10px solid green',
                }}>
                <AllJobs />
              </Box>
            </Wrapper>
          </Box>
        )}

        <ApproveDialog
          title={t('logout:sessionTimedOutTitle')}
          loading={isShowSessionExpirePopUpLoading}
          ChildComponent={<LogoutUI />}
          isApproveWindowOpen={isShowSessionExpirePopUp}
          showCancelButton={false}
          isShowCloseIcon={false}
          handleCloseApproveWindow={() => setIsShowSessionExpirePopUp(false)}
          handleApprove={() => signOut()}
        />
      </HomePageContainer>
    </div>
  );
};

export default HomePage;
