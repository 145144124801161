/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useOTJCommHelper } from 'otj-client-lib';
import { isEmpty } from 'lodash';

import MessageCardUI from './MessageCard';
import { getAcronym, getAuthDetails } from '../../../Utilities/utils';
import { MessageCardProps } from '../../../../Modules/Jobs/Components/Conversations/PreJobAwardMessageCard';

const MessageCardContainer = ({ selectedConversation, conversationDetails }: MessageCardProps): JSX.Element => {
  const { chatHooks } = useOTJCommHelper();
  const { useMessages, UpdateLastSeenInConversation } = chatHooks;
  const { data: currentMessages } = useMessages(selectedConversation?.id ?? '');

  const conversationMessages = Array.isArray(currentMessages) ? currentMessages?.slice()?.reverse() : [];

  const chatBoxReference = useRef<null | HTMLDivElement>(null);

  const hasSameOrganizationId = (organizationId: string): boolean => {
    const storedAuthDetails = getAuthDetails();
    const loggedInUserInternalId = storedAuthDetails['custom:UserId'];
    return loggedInUserInternalId === organizationId;
  };

  const getSenderName = useCallback((message: any) => {
    let individualUserName;
    let username;
    if (!isEmpty(message?.meta)) {
      individualUserName = message?.meta?.senderName as string;
      username = message?.meta?.senderCompanyName as string;
    } else {
      if (message?.from !== conversationDetails?.userId1) {
        username = conversationDetails?.userId2Name as string;
        individualUserName = conversationDetails?.userId2UserName ?? '';
      } else {
        username = conversationDetails?.userId1Name as string;
        individualUserName = conversationDetails?.userId1UserName ?? '';
      }
    }
    return { individualUserName, username };
  }, []);

  const processedMessages = useMemo(() => {
    return conversationMessages?.map((message: any) => {
      const { individualUserName, username } = getSenderName(message);
      return { ...message, individualUserName, username };
    });
  }, [conversationMessages]);

  useEffect(() => {
    const updateLastSeen = async () => {
      if (!isEmpty(currentMessages) && !isEmpty(selectedConversation?.id)) {
        try {
          await UpdateLastSeenInConversation(selectedConversation?.id);
        } catch (error) {
          console.error('Error updating last seen:', error);
        }
      }
    };

    updateLastSeen();
  }, [selectedConversation?.id, currentMessages, UpdateLastSeenInConversation]);

  useEffect(() => {
    if (chatBoxReference?.current) {
      chatBoxReference.current.scrollTop = chatBoxReference.current.scrollHeight;
    }
  }, [conversationMessages]);

  return (
    <MessageCardUI
      messages={processedMessages}
      chatBoxRef={chatBoxReference}
      getAcronym={getAcronym}
      hasSameOrganizationId={hasSameOrganizationId}
    />
  );
};

export default MessageCardContainer;
