/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React from 'react';
import { Box } from '@mui/material';
import {
  ConversationCardDiv,
  ConversationCardOuterDiv,
  ConversationName,
  ConversationsDiv,
  IndividualUnreadContainer,
  LastSeenText,
  MessageText,
  SubText,
  UnreadCount,
} from './Conversations.Style';
import { LogoWrapper } from '../../../../Modules/Jobs/Components/JobDetails/OneTimeJobDetails.Style';
import { useTranslation } from 'react-i18next';

interface ConversationCardUIProps {
  conversationId: string;
  isSelected: boolean;
  isLast: boolean;
  name: string;
  recentMessage: string | undefined;
  jobName: string;
  unreadCount: number;
  updatedAt: string | undefined;
  isGroupMode: boolean;
  acronymName: string;
}

const ConversationCardUI: React.FC<ConversationCardUIProps> = ({
  conversationId,
  isSelected,
  isLast,
  name,
  recentMessage,
  jobName,
  unreadCount,
  updatedAt,
  acronymName,
}) => {
  const { t } = useTranslation('conversations');

  return (
    <ConversationCardOuterDiv
      className={`conversation-card ${isSelected ? 'selected' : ''} ${isLast ? 'last-card' : ''}`}>
      <ConversationCardDiv style={{ background: isSelected ? '#E6F0F0' : 'white' }} key={conversationId}>
        <ConversationsDiv>
          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <LogoWrapper>
              <Box>{acronymName?.toUpperCase()}</Box>
            </LogoWrapper>
            <ConversationsDiv>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '3rem' }}>
                <ConversationName>{name}</ConversationName>
                {unreadCount > 0 && (
                  <IndividualUnreadContainer>
                    <UnreadCount>{unreadCount}</UnreadCount>
                  </IndividualUnreadContainer>
                )}
              </Box>
              {!recentMessage || jobName ? (
                <SubText>{jobName || t('conversations:noMessagesAvailable')}</SubText>
              ) : null}
            </ConversationsDiv>
          </Box>
          {recentMessage ? (
            <MessageText style={{ padding: '0 0 0 3.75rem' }}>{recentMessage}</MessageText>
          ) : (
            jobName && <SubText style={{ padding: '0 0 0 3.75rem' }}>{t('conversations:noMessagesAvailable')}</SubText>
          )}
          {updatedAt && <LastSeenText style={{ margin: '0 0 0 3.75rem' }}>{updatedAt}</LastSeenText>}
        </ConversationsDiv>
      </ConversationCardDiv>
    </ConversationCardOuterDiv>
  );
};

export default ConversationCardUI;
