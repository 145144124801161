/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AlertColor, Snackbar } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { isEmpty } from 'lodash';

import { getRescheduleShiftsErrorMessage } from '../../../../../Shared/Utilities/utils';

import DataGrid from '../../../../../Shared/Components/Common/DataGrid/DataGrid';
import PageTitle from '../../../../../Shared/Components/Common/PageTitle/PageTitle';
import UpdateShiftDateCard from '../../../../Jobs/Components/UpdateShiftDate/UpdateShiftDate';
import Button, { ButtonType } from '../../../../../Shared/Components/Common/Buttons/Button';
import DateSelection from '../../../../../Shared/Components/Common/DatePicker/DateSelection';
import ModifyPopUp from '../../../../Jobs/Components/JobDetails/ModifyPopUp';
import SnackbarMessage from '../../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';
import ScheduleColumns from '../CancelShift/ScheduleColumns';

import WorkOrdersApiService from '../../../../Jobs/Services/WorkOrdersService';
import { RescheduleWorkDayInput } from '../../../../../API';
import { DropdownOptions } from '../AllJobs.Model';

import { CustomerContainer } from '../../../../../Shared/Components/Layout/styles';
import { SnackBarWrapper } from '../../../../Jobs/Components/JobDetailsOverView/JobDetailsOverview.styles';
import { DropdownWrapper } from '../../../../Jobs/Components/WorkTasks/JobDetailsTasks.styles';
import { DatePickerWrapper } from '../../../../../Shared/Components/Common/GoogleMap/GoogleMapChips.Style';
import theme from '../../../../../Shared/Themes/theme';

export type RescheduleWorkDaysNewInput = {
  workOrderId?: string | null;
  workDayId: string;
  newDate: string;
  startTime?: { hours: number; minutes: number } | null;
  endTime?: { hours: number; minutes: number } | null;
  endDateOffset?: number | null;
};

const RescheduleShift = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [schedules, setSchedules] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const [openUpdateShiftDate, setOpenUpdateShiftDate] = useState<boolean>(false);
  const [isStartTimeSelectOpen, setStartTimeSelectOpen] = useState(false);
  const [isEndTimeSelectOpen, setEndTimeSelectOpen] = useState(false);
  const [shiftData, setShiftData] = useState<any>({});
  const [isMultiSelectedRows, setIsMultiSelectedRows] = useState<boolean>(false);

  const [isRescheduleShiftPopUpOpen, setIsRescheduleShiftPopUp] = useState(false);

  const [isRescheduleShiftLoading, setIRescheduleShiftLoading] = useState<boolean>(false);

  const [rescheduleMultipleShiftData, setRescheduleMultipleShiftData] = useState<RescheduleWorkDaysNewInput[]>([]);
  const [rescheduleShiftData, setRescheduleShiftData] = useState<RescheduleWorkDaysNewInput>();

  const [workOrderScheduleIds, setWorkOrderScheduleIds] = useState<string>();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');

  const { t } = useTranslation([
    'jobManagement',
    'messages',
    'altTexts',
    'taskRejectionAndRework',
    'noData',
    'calendar',
  ]);

  const location = useLocation();

  const { control } = useForm({
    defaultValues: {
      specificDate: new Date().toISOString(),
    },
  });

  const specificDate = useWatch({
    control,
    name: 'specificDate',
  });

  const fetchData = useCallback(
    async (workOrderScheduleIds: string) => {
      setIsLoading(true);
      try {
        const formattedDate = new Date(specificDate).toISOString().split('T')[0];

        const schedulesByDateResponse = await WorkOrdersApiService.getSchedulesByDate(
          formattedDate,
          '',
          workOrderScheduleIds
        );

        setSchedules(schedulesByDateResponse?.data);
        handleMatchScheduleData(schedulesByDateResponse?.data ?? []);
      } catch (error) {
        console.error(t('messages:errorFetchingJobs'), error);
      } finally {
        setIsLoading(false);
      }
    },
    [specificDate, workOrderScheduleIds]
  );

  const handleMatchScheduleData = useCallback(
    (schedules = []) => {
      const updatedSchedulesData = schedules.map((schedule: { workOrderScheduleId: any }) => {
        const matchedSchedule = location?.state?.selectedRows?.find(
          (job: { workOrderScheduleId: any }) => job.workOrderScheduleId === schedule.workOrderScheduleId
        );

        if (matchedSchedule) {
          return {
            ...schedule,
            jobType: matchedSchedule?.jobType,
            jobCustodian: matchedSchedule?.jobCustodian,
            mdVendorType: matchedSchedule?.mdVendorType,
            parentWorkOrderId: matchedSchedule?.parentWorkOrderId,
          };
        }

        return schedule;
      });

      setSchedules(updatedSchedulesData);
    },
    [location?.state?.selectedRows, setSchedules]
  );

  const handleOnUpdateShiftDate = (rescheduleWorkDayInput: any) => {
    setIsRescheduleShiftPopUp(true);
    if (rescheduleWorkDayInput?.rescheduleWorkDayInput) {
      setRescheduleMultipleShiftData(rescheduleWorkDayInput?.rescheduleWorkDayInput);
    } else {
      setRescheduleShiftData(rescheduleWorkDayInput);
    }
  };

  const handleShiftTimings = (cell: any) => {
    setOpenUpdateShiftDate(true);
    setShiftData(cell.row.original);
    setIsMultiSelectedRows(false);
    setRescheduleMultipleShiftData([]);
    setRescheduleShiftData({
      workOrderId: null,
      workDayId: '',
      newDate: '',
      startTime: null,
      endTime: null,
      endDateOffset: null,
    });
  };

  const columns = ScheduleColumns({
    type: DropdownOptions.RescheduleShift,
    handleShiftTimings: handleShiftTimings,
  });

  const handleRescheduleShiftPopup = () => {
    setIsRescheduleShiftPopUp(!isRescheduleShiftPopUpOpen);
  };

  const showSnackbar = (severity: AlertColor, message: string) => {
    setSnackbarOpen(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  };

  const handleRescheduleWorkDayResponse = (rescheduleWorkDaysResponse: any): void => {
    if (
      !isEmpty(rescheduleWorkDaysResponse?.data?.rescheduleFailure ?? []) &&
      isEmpty(rescheduleWorkDaysResponse?.errors ?? [])
    ) {
      const errorMsg = `${t('rescheduleShiftDate:failedToRescheduleShifts')}. ${t(getRescheduleShiftsErrorMessage(rescheduleWorkDaysResponse?.data?.rescheduleFailure?.[0]?.errorCode ?? ''))}`;
      showSnackbar('error', errorMsg);
    } else if (
      !isEmpty(rescheduleWorkDaysResponse?.data?.rescheduleSuccess ?? []) &&
      isEmpty(rescheduleWorkDaysResponse?.data?.rescheduleFailure ?? []) &&
      isEmpty(rescheduleWorkDaysResponse?.errors ?? [])
    ) {
      showSnackbar('success', t('rescheduleShiftDate:shiftsReschedulingSuccessMsg'));
    } else if (!isEmpty(rescheduleWorkDaysResponse?.errors ?? [])) {
      showSnackbar('error', rescheduleWorkDaysResponse?.errors[0]?.message);
    }
  };

  const handleRescheduleShift = async () => {
    try {
      setIRescheduleShiftLoading(true);
      const rescheduleMultipleWorkDays = rescheduleMultipleShiftData.map((shift) => ({
        ...shift,
        workDayId: shift.workDayId ?? '',
        newDate: shift.newDate ?? '',
        startTime: !isEmpty(shift?.startTime) ? `${shift?.startTime?.hours}:${shift?.startTime?.minutes}` : '',
        endTime: !isEmpty(shift?.endTime) ? `${shift?.endTime?.hours}:${shift?.endTime?.minutes}` : '',
        endDateOffset: Number(shift?.endDateOffset),
      }));

      const rescheduleWorkDay = [
        {
          ...rescheduleShiftData,
          workDayId: rescheduleShiftData?.workDayId ?? '',
          newDate: rescheduleShiftData?.newDate ?? '',
          startTime: !isEmpty(rescheduleShiftData?.startTime)
            ? `${rescheduleShiftData?.startTime?.hours}:${rescheduleShiftData?.startTime?.minutes}`
            : '',
          endTime: !isEmpty(rescheduleShiftData?.endTime)
            ? `${rescheduleShiftData?.endTime?.hours}:${rescheduleShiftData?.endTime?.minutes}`
            : '',
          endDateOffset: Number(rescheduleShiftData?.endDateOffset),
        },
      ];

      const rescheduleWorkDayInput: RescheduleWorkDayInput[] = !isEmpty(rescheduleMultipleShiftData)
        ? rescheduleMultipleWorkDays
        : rescheduleWorkDay;

      const workOrderId = isEmpty(rescheduleMultipleShiftData)
        ? rescheduleShiftData?.workOrderId
        : rescheduleMultipleShiftData?.[0]?.workOrderId;
      const rescheduleWorkDaysResponse = await WorkOrdersApiService.rescheduleWorkDays(
        workOrderId as string,
        rescheduleWorkDayInput
      );

      handleRescheduleWorkDayResponse(rescheduleWorkDaysResponse);
      setIRescheduleShiftLoading(false);
      setIsRescheduleShiftPopUp(false);
      setOpenUpdateShiftDate(false);
    } catch (error) {
      console.error(t('messages:errorFetchingJobs'), error);
      showSnackbar('error', 'Error occurred while rescheduling the shifts');
    }
  };

  useEffect(() => {
    const workOrderScheduleIds: string = location.state?.selectedRows
      ?.map((row: { workOrderScheduleId: any }) => row.workOrderScheduleId)
      ?.join(',');
    setWorkOrderScheduleIds(workOrderScheduleIds);
    fetchData(workOrderScheduleIds);
  }, [specificDate]);

  return (
    <>
      <PageTitle title={t('jobManagement:rescheduleShift')} />
      <CustomerContainer>
        <DropdownWrapper sx={{ justifyContent: 'flex-end', marginTop: '2rem', marginBottom: '2rem' }}>
          <DatePickerWrapper
            sx={{
              maxWidth: '13rem',
              '& .MuiOutlinedInput-root': {
                '& .MuiInputBase-input': {
                  padding: '0.5625rem 0 0.5625rem 1rem',
                  color: theme.palette.primary.dark,
                },
              },
            }}>
            <DateSelection
              requiredText={t('calendar:dateFieldIsRequired')}
              control={control}
              fieldName={'specificDate'}
              label={t('taskRejectionAndRework:selectDate')}
              isExcludePastDate={false}
              textFieldStyles={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
                    borderWidth: 1,
                    borderColor: theme.palette.primary.dark,
                    borderRadius: '1rem',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: theme.palette.primary.dark,
                  '&.Mui-focused': {
                    color: theme.palette.primary.dark,
                  },
                },
              }}
            />
          </DatePickerWrapper>
        </DropdownWrapper>
        <DataGrid
          columns={columns}
          data={schedules}
          enableRowSelect={true}
          enableColumnPinning={false}
          loader={isLoading}
          errorMessageTitle={t('noData:noDataYet')}
          errorMessageDescription={t('noData:comeBackLater')}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          isRescheduleShift={true}
        />

        {selectedRows?.length > 0 && (
          <SnackBarWrapper>
            <Snackbar
              open
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              action={
                <Button
                  type={ButtonType.Primary}
                  label={t('jobManagement:rescheduleShift')}
                  onClick={() => {
                    if (selectedRows?.length === 1) {
                      setShiftData(selectedRows?.[0]);
                    } else {
                      setShiftData({ scheduleDate: specificDate });
                    }
                    setOpenUpdateShiftDate(true);
                    setIsMultiSelectedRows(true);
                    setRescheduleMultipleShiftData([]);
                    setRescheduleShiftData({
                      workOrderId: null,
                      workDayId: '',
                      newDate: '',
                      startTime: null,
                      endTime: null,
                      endDateOffset: null,
                    });
                  }}
                />
              }
            />
          </SnackBarWrapper>
        )}

        <UpdateShiftDateCard
          openUpdateShift={openUpdateShiftDate}
          handleShiftDateClose={() => setOpenUpdateShiftDate(false)}
          handleOnConfirm={handleOnUpdateShiftDate}
          shiftData={shiftData}
          setStartTimeSelectOpen={setStartTimeSelectOpen}
          setEndTimeSelectOpen={setEndTimeSelectOpen}
          isStartTimeSelectOpen={isStartTimeSelectOpen}
          isEndTimeSelectOpen={isEndTimeSelectOpen}
          isMultiSelectedRows={isMultiSelectedRows}
          isMultipleRescheduleShift={true}
          selectedRows={selectedRows}
        />
        <ModifyPopUp
          open={isRescheduleShiftPopUpOpen}
          handleClose={handleRescheduleShiftPopup}
          handleOnCloseJob={handleRescheduleShift}
          loading={isRescheduleShiftLoading}
          heading={t('jobManagement:rescheduleShift')}
          text={t('jobManagement:rescheduleShiftPopupText')}
        />
        <SnackbarMessage
          open={snackbarOpen}
          successMessage={snackbarMessage}
          errorMessage={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        />
      </CustomerContainer>
    </>
  );
};

export default RescheduleShift;
