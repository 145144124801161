/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { styled } from '@mui/material';
import { StyleConstants } from '../../../Constants/Style.Constants';

export const HeadingText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  color: theme.palette.text.primary,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: '1.5rem',
  },
}));

export const CheckIcon = styled('img')(() => ({
  cursor: 'pointer',
  width: '2rem !important',
  height: '2rem !important',
}));

export const CardContainerWrapper = styled('div')(() => ({
  height: '35vh',
  overflowY: 'auto',
  paddingLeft: '1em',
  paddingTop: '0.5em',
}));

export const ConversationNameWrapper = styled('div')(() => ({
  overflowY: 'auto',
  paddingLeft: '1em',
  paddingTop: '0.5em',
}));
