/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React from 'react';
import { Button, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { BottomBarMessageContainer, buttonStyles, MessageTextField } from './MessagesScreen.Style';
import theme from '../../../Themes/theme';

interface IReplyBarProps {
  replyMsgContent: string;
  setReplyMsgContent: (msg: string) => void;
  isConversationsDataLoading: boolean;
  handleSendButton: () => void;
  selectedConversation: any;
  placeHolder: string;
  selectedConversationLoading?: boolean;
}

const ReplyBar = ({
  replyMsgContent,
  setReplyMsgContent,
  isConversationsDataLoading,
  handleSendButton,
  placeHolder,
  selectedConversationLoading,
}: IReplyBarProps): JSX.Element => {
  const { t } = useTranslation(['conversations']);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    if (event.key === 'Enter' && !event.shiftKey && !isEmpty(replyMsgContent.trim())) {
      event.preventDefault();
      handleSendButton();
    }
  };

  const { buttonText, sendButton } = buttonStyles;

  return (
    <BottomBarMessageContainer>
      <MessageTextField
        id="message-reply"
        placeholder={placeHolder}
        autoComplete="off"
        value={replyMsgContent}
        sx={{ pointerEvents: isConversationsDataLoading || selectedConversationLoading ? 'none' : 'auto' }}
        onChange={(event) => setReplyMsgContent(event.target.value ? event.target.value : '')}
        disabled={isConversationsDataLoading || selectedConversationLoading}
        onKeyDown={handleKeyDown}
      />
      <Button
        variant="text"
        disabled={isConversationsDataLoading || isEmpty(replyMsgContent) || selectedConversationLoading}
        sx={{
          ...sendButton,
          opacity: isConversationsDataLoading || isEmpty(replyMsgContent || selectedConversationLoading) ? 0.5 : 1,
        }}
        onClick={() => {
          handleSendButton();
        }}>
        {isConversationsDataLoading ? (
          <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
        ) : (
          <Typography sx={{ ...buttonText }}>{t('conversations:send')}</Typography>
        )}
      </Button>
    </BottomBarMessageContainer>
  );
};

export default ReplyBar;
