/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import moment from 'moment';
import { MRT_ColumnDef } from 'material-react-table';

import { DateFormat, DayOfWeek, FiltersOfAllJobs, ScheduleTypes } from '../../../../Shared/Constants/App';
import { formatDateCell } from '../../Utilities/Jobs.Utils';
import { IJobs } from '../../../Jobs/Models/JobsView.Model';
import { IJobOverview } from '../../../Jobs/Components/JobsListView/JobsListView';
import { TaskData } from '../../../Jobs/Components/WorkTasks/WorkTasks';
import { IBidList } from '../../../Jobs/Components/JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import { getTimeFormat } from '../../../../Shared/Utilities/utils';
import FilterDropDownOptions from './FilterDropDownOptions';

export const getDayName = (dayIndex: number): string => {
  return DayOfWeek[dayIndex] ?? '-';
};

export const getAllJobsColumns = (
  renderCell: (value: React.ReactNode) => string | number,
  renderActionsCell: (row: any) => JSX.Element,
  filters: any[],
  handleJobNumberChanges: (filterValue: string) => void,
  isFiltersLoading: boolean,
  handleJobNameChanges: (filterValue: string) => void,
  handleLocationChanges: (filterValue: string) => void
): MRT_ColumnDef<IJobOverview | TaskData | IJobs | IBidList | IProfileCardProps>[] => [
  {
    accessorKey: 'jobNumber',
    header: 'Job Number',
    enableColumnFilter: true,
    enableSorting: true,
    Filter: () => (
      <FilterDropDownOptions
        options={filters.find((filter: { name: string }) => filter.name === FiltersOfAllJobs.JobNumber)?.options || []}
        handleFilterChange={handleJobNumberChanges}
        filterLoader={isFiltersLoading}
      />
    ),
    Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
  },
  {
    accessorKey: 'jobName',
    header: 'Job Name',
    enableSorting: true,
    enableColumnFilter: true,
    Filter: () => (
      <FilterDropDownOptions
        options={filters.find((filter: { name: string }) => filter.name === FiltersOfAllJobs.JobName)?.options || []}
        handleFilterChange={handleJobNameChanges}
        filterLoader={isFiltersLoading}
      />
    ),
    size: 180,
    Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
    enableColumnActions: false,
    sortingFn: 'alphanumericCaseSensitive',
  },
  {
    accessorKey: 'scheduleName',
    header: 'Schedule Name',
    enableSorting: true,
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
  },
  {
    accessorKey: 'scheduleStartDate',
    header: 'Schedule Start Date',
    enableSorting: true,
    enableColumnFilter: false,
    filterFn: (row, _columnIds, filterValue) => {
      const formattedValue = moment(row.getValue('scheduleStartDate')).format(DateFormat.StandardDate);
      return formattedValue.toLowerCase().includes(filterValue.toLowerCase());
    },
    Cell: ({ cell }) => formatDateCell(cell),
  },
  {
    accessorKey: 'scheduleEndDate',
    header: 'Schedule End Date',
    enableSorting: true,
    enableColumnFilter: false,
    filterFn: (row, _columnIds, filterValue) => {
      const formattedValue = moment(row.getValue('scheduleEndDate')).format(DateFormat.StandardDate);
      return formattedValue.toLowerCase().includes(filterValue.toLowerCase());
    },
    Cell: ({ cell }) => formatDateCell(cell),
  },
  {
    accessorKey: 'scheduleStartTime',
    header: 'Schedule Start Time',
    enableSorting: true,
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }) => {
      const time = String(renderedCellValue);
      return time ? getTimeFormat(time) : '';
    },
  },
  {
    accessorKey: 'scheduleEndTime',
    header: 'Schedule End Time',
    enableSorting: true,
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }) => {
      const time = String(renderedCellValue);
      return time ? getTimeFormat(time) : '';
    },
  },
  {
    accessorKey: 'workTicketNumber',
    header: 'Work Ticket Number',
    enableHiding: true,
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
  },
  {
    accessorKey: 'scheduleDays',
    header: 'Schedule Days',
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ cell }) => {
      const scheduleDays = cell.getValue() as number[];
      const scheduleType = cell.row.original.mdScheduleType;
      if (scheduleType === ScheduleTypes.Weekly) {
        return scheduleDays?.map((dayIndex) => getDayName(dayIndex)).join(', ') || '-';
      } else if (scheduleType === ScheduleTypes.Monthly) {
        return scheduleDays?.join(', ') || '-';
      } else if (scheduleType === ScheduleTypes.Daily) {
        return ScheduleTypes.Daily;
      } else {
        return '-';
      }
    },
  },
  {
    accessorKey: 'jobStatus',
    header: 'Job Status',
    enableHiding: true,
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
  },
  {
    accessorKey: 'jobAddress',
    header: 'Job Address',
    enableHiding: true,
    enableSorting: false,
    enableColumnFilter: true,
    Filter: () => (
      <FilterDropDownOptions
        options={filters.find((filter: { name: string }) => filter.name === FiltersOfAllJobs.Location)?.options || []}
        handleFilterChange={handleLocationChanges}
        filterLoader={isFiltersLoading}
      />
    ),
    Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
  },
  {
    accessorKey: 'jobStartDate',
    header: 'Job Start Date',
    enableHiding: true,
    enableSorting: true,
    enableColumnFilter: false,
    filterFn: (row, _columnIds, filterValue) => {
      const formattedValue = moment(row.getValue('jobStartDate')).format(DateFormat.StandardDate);
      return formattedValue.toLowerCase().includes(filterValue.toLowerCase());
    },
    Cell: ({ cell }) => formatDateCell(cell),
  },
  {
    accessorKey: 'jobEndDate',
    header: 'Job End Date',
    enableHiding: true,
    enableSorting: true,
    enableColumnFilter: false,
    filterFn: (row, _columnIds, filterValue) => {
      const formattedValue = moment(row.getValue('jobEndDate')).format(DateFormat.StandardDate);
      return formattedValue.toLowerCase().includes(filterValue.toLowerCase());
    },
    Cell: ({ cell }) => formatDateCell(cell),
  },
  {
    accessorKey: 'vendorName',
    header: 'Vendor Name',
    enableHiding: true,
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
  },
  {
    accessorKey: 'vendorAdminName',
    header: 'vendor Admin Name',
    enableHiding: true,
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
  },
  {
    accessorKey: 'vendorAdminEmail',
    header: 'Vendor Admin Email',
    size: 170,
    enableColumnActions: false,
    sortingFn: 'alphanumericCaseSensitive',
    enableHiding: true,
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
  },
  {
    accessorKey: 'supervisorName',
    header: 'Supervisor Name',
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row }) => {
      const supervisors = row.original.supervisors as { name: string | null | undefined }[];
      return (
        supervisors
          ?.filter((supervisor) => supervisor.name)
          .map((supervisor) => supervisor.name)
          .join(', ') || '-'
      );
    },
  },
  {
    accessorKey: 'supervisorEmail',
    header: 'Supervisor Email',
    enableSorting: false,
    enableColumnFilter: false,
    size: 400,
    Cell: ({ row }) => {
      const supervisors = row.original.supervisors as { email: string | null | undefined }[];
      return (
        supervisors
          ?.filter((supervisor) => supervisor.email)
          .map((supervisor) => supervisor.email)
          .join(', ') || '-'
      );
    },
  },
  {
    accessorKey: 'associates',
    header: 'Associate Name',
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row }) => {
      const associates = row.original.associates as { name: string | null | undefined }[];
      return (
        associates
          ?.filter((associate) => associate.name)
          .map((associate) => associate.name)
          .join(', ') || '-'
      );
    },
  },
  {
    accessorKey: 'createdOn',
    header: 'Created On',
    size: 100,
    filterFn: (row, _columnIds, filterValue) => {
      const formattedValue = moment(row.getValue('createdOn')).format(DateFormat.StandardDate);
      return formattedValue.toLowerCase().includes(filterValue.toLowerCase());
    },
    Cell: ({ cell }) => formatDateCell(cell),
    enableColumnActions: false,
    enableHiding: true,
    enableSorting: true,
    enableColumnFilter: false,
  },
  {
    header: 'Actions',
    enableColumnActions: false,
    enablePinning: false,
    size: 90,
    Cell: ({ row }) => renderActionsCell(row),
  },
];
