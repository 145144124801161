/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { styled } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';
import { StyleConstants } from '../../../../Shared/Constants/Style.Constants';

export const PaginationStyle = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
}));

export const ModifyButtonContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '1rem',
}));

export const ModifyScheduleText = styled('span')(() => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h2?.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '1.5rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  marginBottom: '0.5rem',
}));

export const ScheduleDaysText = styled('span')(() => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4?.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '1.5rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  display: 'flex',
  marginBottom: '0.75rem',
}));

export const wrapperStyles = {
  daysStyle: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '3rem',
    height: '3rem',
    border: '1px solid #006B6B',
    borderRadius: '50%',
    fontFamily: theme.typography.fontFamily,
    lineHeight: '1.5rem',
    fontSize: theme.typography.h5?.fontSize,
  },
  frequencyOptions: {
    borderRadius: '1rem',
    width: '100%',
    border: '1px solid #006B6B',
    padding: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: theme.typography.fontFamily,
    cursor: 'pointer',
  },
};

export const NoteText = styled('span')(() => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4?.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '1.5rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  marginBottom: '0.75rem',
}));

export const EditableOptionContainer = styled('div')(() => ({
  gap: '1rem',
  display: 'flex',
  marginBottom: '1rem',
  flexDirection: 'row',
}));

export const ScheduleDaysContainer = styled('div')(() => ({
  gap: '0.3rem',
  marginBottom: '0.5rem',
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
}));
