/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, CircularProgress, IconButton, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table';
import { useForm, useWatch } from 'react-hook-form';

import { DateRangeSelector } from '../../../../Shared/Components/Common/DateRangeSelection/DateRangeSelection.Model';
import { Labels } from '../../../../Shared/Components/Common/GraphContainer/GraphContainer';
import DataGrid from '../../../../Shared/Components/Common/DataGrid/DataGrid';
import {
  getStoredCustomerDetails,
  getAuthDetails,
  JobDetails,
  getAuthData,
  JobExecution,
} from '../../../../Shared/Utilities/utils';
import { TaskData } from '../WorkTasks/WorkTasks';
import { IJobs } from '../JobView/JobsView';
import AnalyticsApiService, { UserType } from '../../../../Shared/Services/AnalyticsService';
import {
  DateRangeOptionType,
  DefaultOptions,
  FilterName,
  FormFieldType,
  HeaderLegalName,
  JobType,
  LegalName,
  NumberOfRowsOptions,
} from '../../../../Shared/Constants/App';
import CustomerApiService from '../../../Customer/Services/CustomerService';
import { ISupervisorAssociate } from '../../../Vendor/Components/AllJobs/AllJobs.Model';
import { BidStatus, BooleanType, JobStatus } from '../../../../API';
import { IBidList } from '../JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import JobCreateApiService from '../../Services/JobCreateService';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import DateRangeSelection from '../../../../Shared/Components/Common/DateRangeSelection/DateRangeSelection';
import CustomDropDown from '../../../../Shared/Components/Common/CustomDropDown/CustomDropdown';

import { Icons } from '../../../../Shared/Constants/Icons';

import { Options } from '../../../../Shared/Components/Common/Dropdown/Dropdown.styles';
import { DropDown, SelectInput } from '../../../../Shared/Components/Common/GraphContainer/GraphContainer.styles';
import { ActionsColumn, ReadMore } from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import { TitleWrapper, TableWrapper, ListViewStyles } from './JobsListView.styles';
import { JobsTitle } from '../IssuesRaised/JobsIssuesRaised.styles';
import { CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import { StyledPagination } from '../../../../Shared/Components/Common/CommonStyle/Pagination.Style';
import { OrganizationService } from '../../../../Shared/Services/OrganizationService';
import { isEmpty } from 'lodash';
import VendorApiService from '../../../../Shared/Services/VendorService';
import { IConversationContext } from '../PostJobAwardConversations/PostJobAwardConversations';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import { VendorType } from '../../../../Shared/Models/Vendors.Models';
import theme from '../../../../Shared/Themes/theme';
import WhiteTooltip from '../../../../Shared/Components/Common/CommonStyle/Tooltip.Style';
import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import { AuthenticationService } from '../../../../Shared/Services/AuthenticationService';

export interface IJobOverview {
  facility: string;
  facilityId: string;
  jobName: string;
  approvedByCustomer?: string | number;
  totalTasks?: number;
  status: string;
  totalCompleted: string;
  totalInProgress: string;
  totalApprovedBySupervisor: string;
  totalApprovedByCustomer: string;
  jobId?: string;
  sortingFn?: any;
  executionType?: string;
  customerId?: string;
  supervisors?: ISupervisorAssociate[];
  associates?: ISupervisorAssociate[];
  primeVendorForRecurringJobs?: boolean;
  isSubVendorPerformingActionForRegularJobs?: boolean;
  isSubVendorInSidePeazy?: boolean;
  dataStatus?: string;
  mdScheduleType?: string;
  parentWorkOrderId?: string;
}

export type FilterItem = {
  id: string;
  value: string;
};

export type StatusItem = {
  label: string;
  value: string;
};

export type JobTypeItem = {
  label: string;
  value: string;
};

const JobsListView = (): JSX.Element => {
  const { t } = useTranslation(['Dashboard', 'homePage', 'calendar']);
  const [jobs, setJobs] = useState<IJobOverview[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [newSort, setNewSort] = useState<boolean>(false);
  const [newSortOrder, setNewSortOrder] = useState('');
  const [isValueOpen, setValueOpen] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedAssociateId, setSelectedAssociateId] = useState<string>();
  const [columnFilters, setColumnFilters] = useState<{ id: string; value: string }[]>([
    { id: 'workDayStatus', value: 'All' },
    { id: 'jobType', value: 'All' },
  ]);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeSelector>({
    fromDate: null,
    toDate: null,
  });
  const [allAssociates, setAllAssociates] = useState<{ id: string; name: string }[]>([]);
  const [selectedAssociate, setSelectedAssociate] = useState<string>(DefaultOptions.All);
  const [isAssociateAndVendorLoading, setIsAssociateAndVendorLoading] = useState(false);

  const [loadingMembers, setLoadingMembers] = useState<Record<string, boolean>>({});

  const location = useLocation();
  const selectedDate = location.state?.selectedDate;
  const defaultDateRangeOption = '1';
  const defaultRoleBasedOption = DefaultOptions.All;
  const storedAuthDetails = getAuthDetails();
  const { isDistrictManager } = getAuthData() || {};
  const customRole = 'custom:Role';
  const role = storedAuthDetails[customRole] as UserType;

  const loggedInUserInternalId = storedAuthDetails['custom:UserId'];
  const loggedInUserName = storedAuthDetails['name'];

  const { actionsColumn } = ListViewStyles;
  const navigate = useNavigate();

  const toggleValue = () => {
    setValueOpen(!isValueOpen);
  };

  const { control } = useForm({
    defaultValues: {
      specificDate: selectedDate ?? new Date().toISOString(),
    },
  });

  const specificDate = useWatch({
    control,
    name: 'specificDate',
  });

  const dateRangeOptions = [
    { id: 1, label: t('dashboard:today'), value: '1', type: DateRangeOptionType.Days },
    { id: 2, label: t('dashboard:lastWeek'), value: '-7', type: DateRangeOptionType.Days },
    { id: 3, label: t('dashboard:lastMonth'), value: '-30', type: DateRangeOptionType.Days },
    { id: 4, label: t('dashboard:customDateRange'), value: 'custom', type: DateRangeOptionType.CustomDateRange },
  ];

  const shiftStatuses: StatusItem[] = [
    { label: 'All', value: '' },
    { label: 'Scheduled', value: 'A' },
    { label: 'In Progress', value: 'P' },
    { label: 'Completed', value: 'S' },
    { label: 'Missed', value: 'N' },
    { label: 'Incomplete', value: 'I' },
  ];
  const jobTypes: JobTypeItem[] = [
    { label: 'All', value: '' },
    { label: 'Recurring', value: 'Recurring' },
    { label: 'One time', value: 'OneTime' },
    { label: 'Emergency', value: 'Emergency' },
  ];

  const filters = [
    {
      title: storedAuthDetails[customRole] === UserType.Customer ? t('dashboard:vendors') : t('dashboard:associates'),
      type: FormFieldType.DropDown,
      name: FilterName.Associates,
      options: allAssociates.map((associateAndVendor) => ({
        label: associateAndVendor.name,
        value: associateAndVendor.id,
      })),
    },
  ];

  const handleDateRangeChange = (dateRange: DateRangeSelector) => {
    setSelectedDateRange({
      fromDate: dateRange.fromDate || new Date(),
      toDate: dateRange.toDate || new Date(),
    });
  };

  const handleItemsPerPageChange = (event: SelectChangeEvent<number>) => {
    const newItemsPerPage = Number(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
    void fetchData(
      selectedDateRange?.fromDate ?? new Date(),
      selectedDateRange?.toDate ?? new Date(),
      currentPage,
      selectedAssociateId,
      newSortOrder,
      newSort,
      columnFilters
    );
  };

  const handlePaginationChange = (event: any, value: number) => {
    handleChange(event, value);
    setCurrentPage(value);
    void fetchData(
      selectedDateRange?.fromDate ?? new Date(),
      selectedDateRange?.toDate ?? new Date(),
      value,
      selectedAssociateId,
      newSortOrder,
      newSort,
      columnFilters
    );
  };

  const fetchAllAssociates = async () => {
    setIsAssociateAndVendorLoading(true);
    try {
      const customerDetails = getStoredCustomerDetails();
      const vendorId = customerDetails?.customerId;
      const loggedInUser = await MasterDataApiService.getCurrentUserInfo();
      const userDetails = await AuthenticationService.getUserByCognitoUserId(loggedInUser.username);
      const userId = userDetails?.data?.userId;
      const response = isDistrictManager
        ? await WorkOrdersApiService.getVendorTeam(userId, '', BooleanType.True, '', 1, 10, false)
        : await CustomerApiService.getAllUsers(vendorId, '', 0, 10, BooleanType.True, false);
      const associateData = response.data.map((associate: { userId: any; username: any }) => ({
        id: associate.userId,
        name: associate.username,
      }));
      setAllAssociates([{ id: DefaultOptions.All, name: DefaultOptions.All }, ...associateData]);
    } catch (error) {
      console.error('Error fetching users', error);
    } finally {
      setIsAssociateAndVendorLoading(false);
    }
  };

  const handleAssociateChanges = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setSelectedAssociate(value);
    handleAssociateChange(value);
  };

  const handleAssociateChange = (selectedAssociateId: string) => {
    setSelectedAssociateId(selectedAssociateId);
    void fetchData(
      selectedDateRange?.fromDate ?? new Date(),
      selectedDateRange?.toDate ?? new Date(),
      currentPage,
      selectedAssociateId,
      newSortOrder,
      newSort,
      columnFilters
    );
  };

  const getSortOrderString = (sortBy?: any, sortOrder?: any) => {
    let sortOrderString = sortOrder;
    if (sortOrder) {
      sortOrderString = 'desc';
    } else {
      if (sortBy) sortOrderString = 'asc';
    }
    return sortOrderString;
  };

  const fetchData = async (
    fromDate: Date,
    toDate: Date,
    currentPage: any,
    selectedAssociateId?: string,
    sortBy?: any,
    sortOrder?: any,
    columnFilters: FilterItem[] = []
  ) => {
    try {
      setIsLoading(true);
      const { customerId = '', userId } = getStoredCustomerDetails() || {};
      const sortOrderString = getSortOrderString(sortBy, sortOrder);
      selectedAssociateId = selectedAssociateId ?? userId;

      const updatedColumnFilters = columnFilters.map((filter) => {
        if (filter.id === 'customerLegalName') {
          return {
            ...filter,
            id: 'customerName',
          };
        }
        if (filter.id === 'vendorLegalName') {
          return {
            ...filter,
            id: 'vendorName',
          };
        }
        return filter;
      });

      const queryParams = updatedColumnFilters
        .map((filter) => {
          const statusMatch = shiftStatuses.find((s) => s.label.toLowerCase() === filter.value.toLowerCase());
          const value = statusMatch ? statusMatch.value : filter.value;
          return `${encodeURIComponent(filter.id)}=${encodeURIComponent(value)}`;
        })
        .join('&');

      const allIdsResponse = await AnalyticsApiService.paginatedTaskSummary(
        customerId,
        fromDate,
        toDate,
        storedAuthDetails[customRole] === 'Customer' ? UserType.Customer : UserType.Vendor,
        userId,
        currentPage,
        itemsPerPage,
        sortBy,
        sortOrderString,
        queryParams,
        selectedAssociateId
      );

      if (allIdsResponse?.errors?.length) {
        // Handle errors if needed
        // setIsError(true);
      } else if (allIdsResponse?.data?.data?.length > 0) {
        const modifiedJobs = await processJobsData(allIdsResponse.data.data, customerId);
        setJobs(modifiedJobs);
        setTotalCount(allIdsResponse.data.metadata.totalCount);
      } else {
        setJobs([]);
        setTotalCount(allIdsResponse.data.metadata.totalCount);
      }
    } catch (e) {
      console.log('Error', e);
      // Handle error state
    } finally {
      setIsLoading(false);
    }
  };

  const processJobsData = async (jobsData: any[], customerId: string) => {
    return await Promise.all(
      jobsData.map(async (item: any) => {
        const modifiedJob: any = { ...item };
        const jobSummaryResponse = await JobCreateApiService.jobSummaryV2(customerId, item.jobId);
        const workOrderResponse = await WorkOrdersApiService.getAllWorkOrders({ jobId: item.jobId });

        const isOtjJob =
          jobSummaryResponse?.data?.jobCustodian &&
          (jobSummaryResponse?.data?.jobType === JobType.OneTime ||
            jobSummaryResponse?.data?.jobType === JobType.Emergency);

        modifiedJob.customerId = jobSummaryResponse?.data?.customerId;
        modifiedJob.executionType =
          isOtjJob &&
          (jobSummaryResponse?.data?.jobVendors?.[0]?.mdVendorType === JobExecution.SubVendor ||
          jobSummaryResponse?.data?.jobVendors?.[0]?.mdVendorType === JobExecution.All
            ? JobDetails.SubVendor
            : JobDetails.Self);
        modifiedJob.estimationType =
          isOtjJob &&
          (jobSummaryResponse?.data?.jobVendors?.[0]?.mdVendorType === JobExecution.All
            ? JobDetails.EstimateWithinPeazy
            : JobDetails.EstimateOutsidePeazy);
        modifiedJob.jobName = jobSummaryResponse?.data?.jobName;
        modifiedJob.jobCustodian = jobSummaryResponse?.data?.jobCustodian ?? workOrderResponse?.data?.[0]?.jobCustodian;
        modifiedJob.subJobs = jobSummaryResponse?.data?.subJobs;
        modifiedJob.parentWorkOrderId = workOrderResponse?.data?.[0]?.parentWorkOrderId;
        modifiedJob.vendorId = workOrderResponse?.data?.[0]?.vendorId;
        modifiedJob.isSelfPerformJob =
          jobSummaryResponse?.data?.jobVendors?.[0]?.mdVendorType === VendorType.SelfPerform;

        return modifiedJob;
      })
    );
  };

  const handleChange = (event: any, value: number): void => {
    setCurrentPage(value);
  };

  const filterJobs = (filtersObj: FilterItem[]): void => {
    setColumnFilters(filtersObj);
    if (filtersObj?.length) {
      void fetchData(
        selectedDateRange?.fromDate ?? new Date(),
        selectedDateRange?.toDate ?? new Date(),
        currentPage,
        selectedAssociateId,
        newSortOrder,
        newSort,
        filtersObj
      );
    } else {
      void fetchData(
        selectedDateRange?.fromDate ?? new Date(),
        selectedDateRange?.toDate ?? new Date(),
        currentPage,
        selectedAssociateId,
        newSortOrder,
        newSort,
        []
      );
    }
  };

  const sortingJobs = (sortingObj: any): void => {
    setSorting(sortingObj);
    if (sortingObj.length) {
      setNewSortOrder(sortingObj[0].id);
      setNewSort(sortingObj[0].desc);
      void fetchData(
        selectedDateRange?.fromDate ?? new Date(),
        selectedDateRange?.toDate ?? new Date(),
        currentPage,
        selectedAssociateId,
        sortingObj[0].id,
        sortingObj[0].desc
      );
    } else {
      void fetchData(
        selectedDateRange?.fromDate ?? new Date(),
        selectedDateRange?.toDate ?? new Date(),
        currentPage,
        selectedAssociateId,
        '',
        newSort,
        columnFilters
      );
    }
  };

  useEffect(() => {
    const fromDate = selectedDateRange?.fromDate ?? new Date();
    const toDate = selectedDateRange?.toDate ?? new Date();
    void fetchData(fromDate, toDate, currentPage, selectedAssociateId, newSortOrder, newSort, columnFilters);
  }, [selectedDateRange, itemsPerPage, currentPage, newSortOrder, newSort, columnFilters]);

  useEffect(() => {
    void fetchAllAssociates();
  }, [role]);

  const myCustomSortingFn = (): void => {
    setSortBy('DESC');
  };

  const getBaseColumnProps = () => ({
    state: { sortBy, columnFilters },
    onSortingChange: setSortBy,
    onColumnFiltersChange: setColumnFilters,
  });

  const columns = useMemo<MRT_ColumnDef<IJobOverview | TaskData | IJobs | IBidList | IProfileCardProps>[]>(
    () => [
      {
        accessorKey: 'facilityName',
        header: 'Facility',
        enableSorting: true,
        enableColumnFilter: true,
        size: 300,
        Cell: ({ renderedCellValue }) => renderedCellValue || '-',
        enableHiding: false,
        enableColumnActions: true,
        ...getBaseColumnProps(),
      },
      {
        accessorKey:
          storedAuthDetails[customRole] === UserType.Customer ? LegalName.vendorLegalName : LegalName.customerLegalName,
        header:
          storedAuthDetails[customRole] === UserType.Customer
            ? HeaderLegalName.VendorName
            : HeaderLegalName.CustomerName,
        enableSorting: true,
        enableColumnFilter: true,
        size: 300,
        Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : '-'),
        enableHiding: false,
        enableColumnActions: true,
        ...getBaseColumnProps(),
      },
      {
        accessorKey: 'workOrderName',
        header: 'Job name',
        enableSorting: true,
        enableColumnFilter: true,
        size: 112,
        Cell: ({ renderedCellValue }) => renderedCellValue ?? '-',
        enableColumnActions: true,
        ...getBaseColumnProps(),
      },
      {
        accessorKey: 'scheduleName',
        header: 'Schedule Name',
        enableSorting: false,
        enableColumnFilter: false,
        size: 112,
        Cell: ({ renderedCellValue }) => renderedCellValue ?? '-',
        enableColumnActions: false,
      },
      {
        accessorKey: 'jobType',
        header: 'Job type',
        enableSorting: true,
        filterSelectOptions: jobTypes.map((jobTypeItem) => jobTypeItem.label),
        enableColumnFilter: true,
        size: 112,
        Cell: ({ renderedCellValue }) => jobTypes.find((jt) => jt.value === renderedCellValue)?.label ?? '-',
        enableColumnActions: true,
        filterVariant: 'select',
        ...getBaseColumnProps(),
      },
      {
        accessorKey: 'workDayStatus',
        header: 'Job status',
        enableSorting: true,
        filterSelectOptions: shiftStatuses.map((shiftStatusItem) => shiftStatusItem.label),
        enableColumnFilter: true,
        enableColumnActions: true,
        size: 162,
        filterVariant: 'select',
        ...getBaseColumnProps(),
      },
      {
        header: 'Tasks done',
        enableSorting: false,
        enableColumnFilter: false,
        size: 112,
        Cell: ({ cell }) => {
          const { totalTasks, approvedByCustomer } = cell.row.original;

          return `${approvedByCustomer}/${totalTasks}`;
        },
        enableColumnActions: false,
      },
      {
        accessorKey: 'punchedInBySupervisor',
        header: t('homePage:punchedInBySupervisor'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 112,
        Cell: ({ renderedCellValue }) => {
          return renderedCellValue === 'true' ? t('homePage:yes') : t('homePage:no');
        },
        enableColumnActions: false,
      },
      {
        accessorKey: 'punchedOutBySupervisor',
        header: t('homePage:punchedOutBySupervisor'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 112,
        Cell: ({ renderedCellValue }) => {
          return renderedCellValue === 'true' ? t('homePage:yes') : t('homePage:no');
        },
        enableColumnActions: false,
      },
      {
        header: 'Inspection score',
        enableSorting: false,
        enableColumnFilter: false,
        size: 112,
        Cell: ({ cell }) => {
          const { totalTasks, approvedByCustomer } = cell.row.original;
          const perc =
            approvedByCustomer && totalTasks
              ? `${((Number(approvedByCustomer) / Number(totalTasks)) * 100).toFixed(0)}%`
              : '0.00%';
          return `${perc}`;
        },
        enableColumnActions: false,
      },
      {
        header: 'Actions',
        enableColumnActions: false,
        enablePinning: false,
        size: 162,
        Cell: ({ cell }) => {
          const row = cell.row.original;

          return (
            <ActionsColumn style={{ ...actionsColumn }}>
              <ReadMore
                to="/job-detail/worktasks"
                state={{
                  facilityItem: cell.row.original,
                  jobId: getParentJobId(cell.row.original),
                  executionType: cell.row.original?.executionType,
                  customerId: cell.row.original?.customerId,
                  selectedDate: specificDate,
                }}>
                {t('dashboard:viewDetails')}
              </ReadMore>
              {row.jobId && loadingMembers[row.jobId] ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: theme.palette.primary.dark,
                    margin: '12px',
                  }}
                />
              ) : (
                <IconButton
                  size="large"
                  sx={{
                    '&:hover': {
                      background: theme.palette.primary.main,
                    },
                  }}
                  onClick={() => {
                    setDataForChat(cell.row.original);
                  }}>
                  <WhiteTooltip title={t('vendor:message')}>
                    <img
                      src={Icons.GreenConversationIcon}
                      alt={t('altTexts:conversation')}
                      style={{ width: '1.3rem', marginTop: '2px' }}
                    />
                  </WhiteTooltip>
                </IconButton>
              )}
            </ActionsColumn>
          );
        },
      },
    ],
    [specificDate, loadingMembers]
  );

  const getParentJobId = (data: any) => {
    if (isDistrictManager) {
      return data.parentJobId ?? data.jobId;
    } else {
      return data.jobId;
    }
  };

  const fetchJobSummary = async (customerId: string, jobId: string) => {
    return await JobCreateApiService.jobSummaryV2(customerId, jobId);
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const setDataForChat = useCallback(async (jobDetails: any) => {
    try {
      setLoadingMembers((prev) => ({
        ...prev,
        [jobDetails.jobId as string]: true,
      }));
      const { customerId = '' } = getStoredCustomerDetails() || {};
      const getUserNameForCustomerAndDm = await OrganizationService.getUserNames({ jobId: jobDetails?.jobId });
      const isSubVendorPerformingActionForRegularJobs =
        jobDetails?.jobType === JobType.Recurring &&
        !isEmpty(jobDetails?.parentWorkOrderId ?? '') &&
        !isEmpty(jobDetails?.jobCustodian);

      const primeVendorForRecurringJobs =
        jobDetails?.jobType === JobType.Recurring &&
        isEmpty(jobDetails?.parentWorkOrderId ?? '') &&
        !isEmpty(jobDetails?.jobCustodian);

      const isOTJFlow =
        !isEmpty(jobDetails?.jobCustodian) &&
        (jobDetails?.jobType === JobType.OneTime || jobDetails?.jobType === JobType.Emergency);

      const isSubVendorInSidePeazy =
        isOTJFlow &&
        jobDetails?.executionType === JobDetails.SubVendor &&
        jobDetails?.estimationType === JobExecution.EstimateWithinPeazy;

      const isSubVendorOutSidePeazy =
        isOTJFlow &&
        jobDetails?.executionType === JobDetails.SubVendor &&
        jobDetails?.estimationType === JobExecution.EstimateOutsidePeazy;

      const response = await VendorApiService.getVendorDetails(customerId);
      let contextData: IConversationContext = {} as IConversationContext;
      if (role === UserType.Vendor) {
        if (
          (!isDistrictManager && (isSubVendorOutSidePeazy || isSubVendorInSidePeazy)) ||
          isSubVendorPerformingActionForRegularJobs
        ) {
          if (isSubVendorOutSidePeazy) {
            return;
          }

          contextData = {
            userType: UserType.Vendor,
            userId1: loggedInUserInternalId ?? '',
            userId1Name: response?.data?.doingBusinessAs ?? '',
            userId1UserName: loggedInUserName ?? '',
            userId2: getUserNameForCustomerAndDm?.data?.[0]?.vendorUserId ?? '',
            userId2Name: getUserNameForCustomerAndDm?.data?.[0]?.vendorName ?? '',
            userId2UserName: getUserNameForCustomerAndDm?.data?.[0]?.vendorUserName ?? '',
            jobId: jobDetails?.jobId,

            userAndJobDetails: {
              jobName: jobDetails?.workOrderName ?? jobDetails?.jobName ?? '',
              vendorId: loggedInUserInternalId ?? '',
              jobId: jobDetails?.jobId,
              vendorUserName: loggedInUserName ?? '',
              vendorCompanyName: response?.data?.doingBusinessAs ?? '',
              userType: UserType.Vendor,
              facilityName: '',
              conversationGroupName: '',
              associateId: '',
              associateName: '',
              customerOrDmSubVendorId: getUserNameForCustomerAndDm?.data?.[0]?.vendorUserId ?? '',
              customerOrDmOrSubVendorUserName: getUserNameForCustomerAndDm?.data?.[0]?.vendorUserName ?? '',
              customerOrDmOrSubVendorCompanyName: getUserNameForCustomerAndDm?.data?.[0]?.vendorName ?? '',
            },
            primaryContactName: t('common:chatWithDM'),
          };
        } else if (
          isDistrictManager &&
          (isSubVendorOutSidePeazy || isSubVendorInSidePeazy || primeVendorForRecurringJobs)
        ) {
          if (isSubVendorOutSidePeazy) {
            return;
          }

          const getUserNameForCustomerAndDm = await OrganizationService.getUserNames({
            jobId: jobDetails?.subJobs?.[0]?.jobId ?? '',
          });

          contextData = {
            userType: UserType.Vendor,
            userId1: loggedInUserInternalId ?? '',
            userId1Name: response?.data?.doingBusinessAs ?? '',
            userId1UserName: loggedInUserName ?? '',
            userId2: getUserNameForCustomerAndDm?.data?.[0]?.subVendorUserId ?? '',
            userId2Name: getUserNameForCustomerAndDm?.data?.[0]?.subVendorName ?? '',
            userId2UserName: getUserNameForCustomerAndDm?.data?.[0]?.subVendorUserName ?? '',
            jobId: jobDetails?.subJobs?.[0]?.jobId,

            userAndJobDetails: {
              jobName: jobDetails?.workOrderName ?? jobDetails?.workOrderName ?? '',
              vendorId: loggedInUserInternalId ?? '',
              jobId: jobDetails?.subJobs?.[0]?.jobId,
              vendorUserName: loggedInUserName ?? '',
              vendorCompanyName: response?.data?.doingBusinessAs ?? '',
              userType: UserType.Vendor,
              facilityName: '',
              conversationGroupName: '',
              associateId: '',
              associateName: '',
              customerOrDmSubVendorId: getUserNameForCustomerAndDm?.data?.[0]?.subVendorUserId ?? '',
              customerOrDmOrSubVendorUserName: getUserNameForCustomerAndDm?.data?.[0]?.subVendorUserName ?? '',
              customerOrDmOrSubVendorCompanyName: getUserNameForCustomerAndDm?.data?.[0]?.subVendorName ?? '',
            },
            primaryContactName: t('common:chatWithSubVendor'),
          };
        } else {
          if (jobDetails?.isSelfPerformJob) return;

          const customerDetailsResponse = await CustomerApiService.getCustomerDetailsResponse(
            jobDetails?.customerId as string
          );

          contextData = {
            userType: UserType.Vendor,
            userId1: loggedInUserInternalId ?? '',
            userId1Name: response?.data?.doingBusinessAs ?? '',
            userId1UserName: loggedInUserName ?? '',
            userId2: getUserNameForCustomerAndDm?.data?.[0]?.jobCreatorUserId ?? '',
            userId2Name: customerDetailsResponse?.data?.customer?.doingBusinessAs ?? '',
            userId2UserName: getUserNameForCustomerAndDm?.data?.[0]?.jobCreatorUserName ?? '',
            jobId: jobDetails?.jobId ?? '',

            userAndJobDetails: {
              jobName: jobDetails?.workOrderName ?? jobDetails?.jobName ?? '',
              vendorId: loggedInUserInternalId ?? '',
              jobId: jobDetails?.jobId ?? '',
              vendorUserName: loggedInUserName ?? '',
              vendorCompanyName: response?.data?.doingBusinessAs ?? '',
              userType: UserType.Vendor,
              facilityName: '',
              conversationGroupName: '',
              associateId: '',
              associateName: '',
              customerOrDmSubVendorId: getUserNameForCustomerAndDm?.data?.[0]?.jobCreatorUserId ?? '',
              customerOrDmOrSubVendorUserName: getUserNameForCustomerAndDm?.data?.[0]?.jobCreatorUserName ?? '',
              customerOrDmOrSubVendorCompanyName: customerDetailsResponse?.data?.customer?.doingBusinessAs ?? '',
            },
            primaryContactName: t('common:chatWithCustomer'),
          };
        }
      } else {
        const jobSummaryResponse = await fetchJobSummary(customerId, jobDetails?.jobId);
        if (
          role === UserType.Customer &&
          !isEmpty(jobSummaryResponse) &&
          (jobSummaryResponse?.data?.dataStatus === JobStatus.Awarded ||
            jobSummaryResponse?.data?.dataStatus === JobStatus.Completed ||
            jobSummaryResponse?.data?.dataStatus === JobStatus.InProgress)
        ) {
          const jobBidsResponse = await JobCreateApiService.getJobBids(jobDetails?.jobId);

          const jobBids = jobBidsResponse?.data?.map((bid: { dataStatus: any; vendorId: any; vendorName: any }) => ({
            dataStatus: bid?.dataStatus,
            vendorId: bid?.vendorId,
            vendorName: bid?.vendorName,
          }));

          const vendorUserName = await OrganizationService.getUserNames({
            jobId: jobDetails?.jobId ?? '',
          });
          const vendorId = jobDetails?.vendorId;
          const awardedBids =
            jobSummaryResponse?.data?.dataStatus === JobStatus.Completed
              ? {
                  vendorId,
                  vendorUserName: vendorUserName?.data?.[0]?.vendorUserName ?? '',
                  vendorName: vendorUserName?.data?.[0]?.vendorName ?? '',
                }
              : jobBids?.find((item: { dataStatus: BidStatus }) => item?.dataStatus === BidStatus.C);

          contextData = {
            userId1: vendorUserName?.data?.[0]?.vendorUserId ?? '',
            userId1Name: awardedBids?.vendorName ?? '',
            userId2: loggedInUserInternalId ?? '',
            userId2Name: jobSummaryResponse?.data?.customerName ?? '',
            userId2UserName: loggedInUserName ?? '',
            userId1UserName: vendorUserName?.data?.[0]?.vendorUserName ?? '',
            jobId: jobSummaryResponse?.data?.jobId ?? '',
            userType: UserType.Customer,

            userAndJobDetails: {
              jobName: jobSummaryResponse?.data?.jobName ?? '',
              vendorId: vendorUserName?.data?.[0]?.vendorUserId ?? '',
              jobId: jobSummaryResponse?.data?.jobId ?? '',
              vendorUserName: vendorUserName?.data?.[0]?.vendorUserName ?? '',
              vendorCompanyName: awardedBids?.vendorName ?? '',
              userType: UserType.Customer,
              facilityName: '',
              conversationGroupName: '',
              associateId: '',
              associateName: '',
              customerOrDmSubVendorId: loggedInUserInternalId ?? '',
              customerOrDmOrSubVendorUserName: loggedInUserName ?? '',
              customerOrDmOrSubVendorCompanyName: jobSummaryResponse?.data?.customerName ?? '',
            },
            primaryContactName: t('common:chatWithVendor'),
          };
        }
      }

      setLoadingMembers((prev) => ({
        ...prev,
        [jobDetails?.jobId as string]: false,
      }));
      handleConversationIconClick(jobDetails, contextData);
    } catch (error) {
      console.error('Error fetching vendor details or setting conversation context:', error);
    }
  }, []);

  const handleConversationIconClick = useCallback((jobDetails: any, contextData: IConversationContext) => {
    const queryParams = new URLSearchParams({
      jobId: jobDetails?.jobId,
    });

    navigate(`/post-job-award-conversations?${queryParams?.toString()}`, {
      state: {
        ...jobDetails,
        conversationContext: contextData,
      },
    });
  }, []);

  return (
    <>
      <PageTitle title={t('homePage:listView')} />
      <CustomerContainer>
        <TitleWrapper>
          <JobsTitle>{t('homePage:jobOverView')}</JobsTitle>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <DateRangeSelection
              onDateRangeChange={handleDateRangeChange}
              defaultOption={defaultDateRangeOption}
              options={dateRangeOptions}
              isExcludePastDate={false}
            />
            {storedAuthDetails[customRole] === UserType.Vendor && (
              <CustomDropDown
                title={filters.find((filter) => filter.name === FilterName.Associates)?.title}
                options={
                  filters.find((filter: { name: string }) => filter.name === FilterName.Associates)?.options || []
                }
                selectedValue={selectedAssociate}
                onChange={handleAssociateChanges}
                isLoading={isAssociateAndVendorLoading}
                defaultOption={defaultRoleBasedOption}
              />
            )}
          </Box>
        </TitleWrapper>
        <TableWrapper>
          <>
            <DataGrid
              columns={columns}
              data={jobs}
              enableRowSelect={false}
              enableColumnPinning={false}
              loader={isLoading}
              sortingObj={sorting}
              sort={sortingJobs}
              filter={filterJobs}
              errorMessageTitle={''}
              errorMessageDescription={t('homePage:shiftNotFound')}
              sortingFns={{
                myCustomSortingFn: myCustomSortingFn,
              }}
            />
            <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
              {!isLoading && totalCount > 10 && (
                <Stack spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
                  <StyledPagination
                    count={Math.ceil(totalCount / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    color="primary"
                  />
                </Stack>
              )}
              {!isLoading && (
                <Stack
                  spacing={2}
                  justifyContent="center"
                  sx={{
                    marginTop: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '3rem',
                  }}>
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: '1rem',
                    }}>
                    {t('dashboard:totalItems')}: {totalCount}
                  </Typography>
                  {totalCount > 10 && (
                    <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                      <Typography>{t('dashboard:recordsPerPage')}:</Typography>
                      <DropDown>
                        <Box onClick={toggleValue}>
                          <Select
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                            label="Records"
                            input={<SelectInput />}
                            IconComponent={() => (
                              <img
                                src={Icons.DropdownIcon}
                                style={{
                                  transform: isValueOpen ? Labels.Rotate : 'none',
                                  position: 'absolute',
                                  right: '0.75rem',
                                }}
                              />
                            )}
                            open={isValueOpen}
                            onClose={toggleValue}
                            onOpen={toggleValue}>
                            {NumberOfRowsOptions.map((dataItem) => (
                              <Options key={dataItem.id} value={dataItem.value}>
                                {dataItem.label}
                              </Options>
                            ))}
                          </Select>
                        </Box>
                      </DropDown>
                    </Box>
                  )}
                </Stack>
              )}
            </Box>
          </>
        </TableWrapper>
      </CustomerContainer>
    </>
  );
};

export default JobsListView;
