/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AlertColor,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Icons } from '../../../../Shared/Constants/Icons';
import {
  getAcronymName,
  formatDate,
  hours,
  getTimeFormat,
  getTimeFromGivenDate,
  parseTime,
  ErrorCodesForPunChIn,
  getStoredCustomerDetails,
  getAuthData,
  fetchDataFromS3Bucket,
  RolesEnum,
  isDateBefore,
  formattingDate,
  convertToReadableFormat,
  getDaySuffix,
  getAuthDetails,
  removeUsersFromConversation,
  ITimeFormateResponse,
} from '../../../../Shared/Utilities/utils';
import { ITeamMemberData, IWorkOrderTeam } from '../JobDetails/JobAssignmentCard';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import { BooleanType, CreateWorkOrderTeam, PunchType, UserForUserManagement } from '../../../../API';
import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';
import {
  buttonStyles,
  DateRange,
  HeadingText,
  LogoWrapper,
  NewDatePickerContainer,
  PunchCardContainerWrapper,
  PunchInButton,
  PunchInHeader,
  PunchInHeading,
  PunchTeamContainer,
  TagWrapper,
  TagWrapperText,
  TeamCard,
  TeamCardOuterDiv,
  TeamDisplayDiv,
} from '../JobDetails/OneTimeJobDetails.Style';
import theme from '../../../../Shared/Themes/theme';
import { FieldNames } from '../../Models/AddEditOneTimeJob.Model';
import { Controller, useForm } from 'react-hook-form';
import DateSelection from '../../../../Shared/Components/Common/DatePicker/DateSelection';
import { DateFieldWrapper, ErrorMsg } from '../AddEditOneTimeJob/AddEditOneTimeJob.Style';
import { Options } from '../../../../Shared/Components/Common/Dropdown/Dropdown.styles';
import Modal from '../../Utilities/Shared/Components/Modal';
import moment, { Moment, MomentInput } from 'moment';
import AssociateApiService from '../../../../Shared/Services/AssociateService';
import _, { isEmpty } from 'lodash';
import { ConversationTabs, DateFormat, IUser, Role, UserType } from '../../../../Shared/Constants/App';
import { AuthenticationService } from '../../../../Shared/Services/AuthenticationService';
import CustomerApiService from '../../../Customer/Services/CustomerService';
import { useNavigate } from 'react-router-dom';
import { LoaderTag } from '../ActionItems/ActionItems.Style';
import { OrganizationService } from '../../../../Shared/Services/OrganizationService';
import AddTeamMember from './AddTeamMember';
import { AllShiftDetailsQuery, SchedulesByDateQuery } from './AssociateCard';
import { IConversationContext } from '../PostJobAwardConversations/PostJobAwardConversations';
import VendorApiService from '../../../../Shared/Services/VendorService';
import { ConversationName } from '../../../../Shared/Components/Conversations/ConversationsScreen/Conversations.Style';
import CreateConversations from '../../../../Shared/Components/Conversations/CreateConversations/CreateConversations';
import { GroupChat } from '../../../../Shared/Components/Conversations/Conversations.model';
import { useOTJCommHelper } from 'otj-client-lib';
import { addButtonStyles, AssignedTeamHeader, ChatWithTeam, ConversationIcon } from './SupervisorPunchIn.Style';

interface SupervisorPunchIn {
  teamData: ITeamMemberData[];
  associateTeamData: ITeamMemberData[];
  isTeamDataLoading: boolean;
  workOrderId: string;
  workOrderData: any;
  address: any;
  isAssociatePunchedIn: any;
  workDayId: string;
  isFutureShiftSelected: boolean;
  specificDate: any;
  jobEndDate: any;
  getWorkOrderDetails: () => Promise<void>;
  woScheduleMasterId: string;
  workOrderScheduleId: string;
  scheduleData: SchedulesByDateQuery;
  shiftData: AllShiftDetailsQuery[];
  checkedAssociates: ITeamMemberData[];
}

export interface AssociateShiftDateProps {
  associateStartDate: string;
  associateEndDate: string;
  userId: string;
}

export interface punchTime {
  punchInOrOutDate: string;
  punchInOrOutTime: { formattedMins: string; hours: number; meridiem: string; sec: number };
}

const SupervisorPunchIn = ({
  teamData,
  associateTeamData,
  isTeamDataLoading,
  shiftData,
  workOrderId,
  workOrderData,
  isFutureShiftSelected,
  specificDate,
  getWorkOrderDetails,
  workOrderScheduleId,
  woScheduleMasterId,
  scheduleData,
  checkedAssociates,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: SupervisorPunchIn) => {
  const [punchInTeamData, setPunchInTeamData] = useState<ITeamMemberData[]>([]);
  const [punchOutTeamData, setPunchOutTeamData] = useState<ITeamMemberData[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');
  const [isStartTimeSelectOpen, setStartTimeSelectOpen] = useState(false);
  const [hoursList, setHoursList] = useState<any[]>([]);
  const [isPunchedInModalOpen, setIsPunchedInModalOpen] = useState<boolean>(false);
  const [isPunchedOutModalOpen, setIsPunchedOutModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddSupervisorsModalOpen, setIsAddSupervisorsModalOpen] = useState<boolean>(false);
  const [isAddAssociatesModalOpen, setIsAddAssociatesModalOpen] = useState<boolean>(false);
  const [selectedSupervisors, setSelectedSupervisors] = useState<any>([]);
  const [selectedAssociates, setSelectedAssociates] = useState<any>([]);
  const [associatesTeam, setAssociatesTeam] = useState<ITeamMemberData[]>([]);
  const [originalAssociatesTeam, setOriginalAssociatesTeam] = useState<ITeamMemberData[]>([]);
  const [supervisorsTeam, setSupervisorsTeam] = useState<ITeamMemberData[]>([]);
  const [originalSupervisorsTeam, setOriginalSupervisorsTeam] = useState<ITeamMemberData[]>([]);
  const [isAssociateDataLoading, setIsAssociateDataLoading] = useState<boolean>(false);
  const [isSupervisorDataLoading, setIsSupervisorDataLoading] = useState<boolean>(false);
  const [isAssignButtonLoading, setIsAssignButtonLoading] = useState<boolean>(false);
  const [isConfirmButtonLoading, setIsConfirmButtonLoading] = useState<boolean>(false);
  const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false);
  const [isDeleteModelOpen, setDeleteModalOpen] = useState(false);
  const [editAssociateShiftDate, setEditAssociateShiftDate] = useState<AssociateShiftDateProps>({
    userId: '',
    associateStartDate: '',
    associateEndDate: '',
  });
  const [currentLocation, setCurrentLocation] = useState({ lat: 0, lng: 0 });

  const [conversationContext, setConversationContext] = useState<IConversationContext>({} as IConversationContext);

  const [loadingMembers, setLoadingMembers] = useState<Record<string, boolean>>({});

  const [chatWithSupervisors, setChatWithSupervisors] = useState<boolean>(false);
  const [chatWithAssociates, setChatWithAssociates] = useState<boolean>(false);
  const [conversationName, setConversationName] = useState<string>('');

  const { t } = useTranslation([
    'supervisorPunchIn',
    'assignJob',
    'oneTimeJob',
    'calendar',
    'messages',
    'altTexts',
    'conversations',
  ]);
  const { textField, PunchInButtonStyles } = buttonStyles;

  const { addIconButton, plusIcon, conversationIcon, noDataFound } = addButtonStyles;
  const { control, handleSubmit, watch, getValues, setValue } = useForm({
    defaultValues: {
      punchInDate: specificDate ? formatDate(specificDate as string | undefined) : null,
      punchOutDate: null,
      punchInTime: '',
      punchOutTime: '',
    },
  });

  const watchFields = watch();
  const { isDistrictManager, role } = getAuthData();
  const navigate = useNavigate();

  const { customerId = '', organizationName } = getStoredCustomerDetails();

  const storedAuthDetails = getAuthDetails();

  const loggedInUserInternalId = storedAuthDetails['custom:UserId'];
  const loggedInUserName = storedAuthDetails['name'];
  const loggedInUserType = storedAuthDetails['custom:Role'] as UserType;

  const { chatHooks } = useOTJCommHelper();
  const { useConversations, RemoveUserFromConversation, deleteConversation } = chatHooks;
  const { data } = useConversations();
  const punchInLabel = t('supervisorPunchIn:punchIn');
  const punchOutLabel = t('supervisorPunchIn:punchOut');
  const errorOccurredWhilePunchIn = t('supervisorPunchIn:ErrorOccurredWhilePunchIn');
  const errorOccurredWhilePunchOut = t('supervisorPunchIn:ErrorOccurredWhilePunchOut');

  const disablePunchInButton = () => {
    const { punchInDate, punchInTime } = getValues();
    return !(punchInDate && punchInTime);
  };

  const disablePunchOutButton = () => {
    const { punchOutDate, punchOutTime } = getValues();
    return !(punchOutDate && punchOutTime);
  };

  const handlePunchIn = (id: any) => {
    setIsPunchedInModalOpen(true);
    const updatedTeamData = associateTeamData.filter((member) => member?.id === id);
    setPunchInTeamData(updatedTeamData);
    return true;
  };

  const handlePunchOut = (id: any) => {
    setIsPunchedOutModalOpen(true);
    const updatedTeamData = associateTeamData.filter((member) => member?.id === id);
    setPunchOutTeamData(updatedTeamData);
    return true;
  };

  const showSnackbar = (severity: AlertColor, message: string) => {
    setSnackbarOpen(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  };

  const renderDuration = (member: ITeamMemberData) => {
    if (!member?.isSupervisor && member?.associateStartDate && member?.associateEndDate) {
      const associateStartDate = new Date(member.associateStartDate);
      const associateEndDate = new Date(member.associateEndDate);
      const formattedStartDate = formatDate(associateStartDate.toISOString());
      const formattedEndDate = formatDate(associateEndDate.toISOString());
      return (
        <PunchInButton>
          {t('dashboard:duration')}
          <DateRange>
            {formattedStartDate} - {formattedEndDate}
          </DateRange>
        </PunchInButton>
      );
    }
  };

  const renderTag = (member: ITeamMemberData) => {
    if (!member?.isSupervisor && member.isJobNotAccepted) {
      return (
        <TagWrapper>
          <TagWrapperText>{t('assignJob:jobNotYetAccepted')}</TagWrapperText>
        </TagWrapper>
      );
    }
    if (!member?.isSupervisor && member?.isCannotMakeJob) {
      return (
        <TagWrapper>
          <TagWrapperText>{t('assignJob:canNotMakeIt')}</TagWrapperText>
        </TagWrapper>
      );
    }
  };

  const renderPunchInOrOutTime = (
    member: ITeamMemberData,
    time: {
      punchInTime: { punchInOrOutDate: string; punchInOrOutTime: ITimeFormateResponse } | undefined;
      punchOutTime: { punchInOrOutDate: string; punchInOrOutTime: ITimeFormateResponse } | undefined;
    }
  ) => {
    if (!member?.isSupervisor) {
      if (!isEmpty(member?.punchInTime) && member?.punchType === PunchType.PunchIn) {
        return (
          <PunchInButton>
            {`${t('supervisorPunchIn:punchInTime')} : `}
            {`${time?.punchInTime?.punchInOrOutDate}, ${time?.punchInTime?.punchInOrOutTime?.hours}:${time?.punchInTime?.punchInOrOutTime?.formattedMins}${time?.punchInTime?.punchInOrOutTime?.meridiem} `}
          </PunchInButton>
        );
      }
      if (!isEmpty(member?.punchOutTime)) {
        return (
          <PunchInButton sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              {`${t('supervisorPunchIn:punchInTime')} : `}
              {`${time?.punchInTime?.punchInOrOutDate}, ${time?.punchInTime?.punchInOrOutTime?.hours}:${time?.punchInTime?.punchInOrOutTime?.formattedMins}${time.punchInTime?.punchInOrOutTime?.meridiem} `}
            </Box>
            <Box>
              {`${t('supervisorPunchIn:punchOutTime')} : `}
              {`${time?.punchOutTime?.punchInOrOutDate}, ${time?.punchOutTime?.punchInOrOutTime?.hours}:${time?.punchOutTime?.punchInOrOutTime?.formattedMins}${time?.punchOutTime?.punchInOrOutTime?.meridiem} `}
            </Box>
          </PunchInButton>
        );
      }
    }
  };

  const convertPunchInAndOutTimeToLocal = (punchDate: string) => {
    if (punchDate) {
      const date = moment(punchDate);
      const day = date.date();
      const daySuffix = getDaySuffix(day);
      const punchInOrOutDate = ` ${date.format('MMM')} ${day}${daySuffix} , ${date.format('YYYY')}`;

      const formattedTime = moment(punchDate).toISOString();
      const punchInOrOutTime = getTimeFromGivenDate(formattedTime, false);
      return { punchInOrOutDate, punchInOrOutTime };
    }
  };

  const renderTime = (member: ITeamMemberData) => {
    const punchInTime = convertPunchInAndOutTimeToLocal(member?.punchInTime as string);
    const punchOutTime = convertPunchInAndOutTimeToLocal(member?.punchOutTime as string);

    return { punchInTime, punchOutTime };
  };

  const handleGroupChat = useCallback((newConversation: GroupChat) => {
    const queryParams = new URLSearchParams({
      jobId: workOrderData?.jobId,
    });

    navigate(`/post-job-award-conversations?${queryParams?.toString()}`, {
      state: {
        ...newConversation,
        jobId: workOrderData?.jobId,
        conversationName: conversationName,
        selectedConversationId: newConversation?.id,
        jobName: workOrderData?.name ?? workOrderData?.jobName ?? '',
      },
    });
  }, []);

  const handleConversationIconClick = useCallback(() => {
    const queryParams = new URLSearchParams({
      jobId: workOrderData?.jobId,
    });

    navigate(`/post-job-award-conversations?${queryParams?.toString()}`, {
      state: {
        ...workOrderData,
        conversationContext: conversationContext,
      },
    });
  }, [conversationContext, workOrderData]);

  const setDataForChat = useCallback(
    async (selectedChat: ITeamMemberData) => {
      if (!selectedChat.id) {
        return;
      }
      try {
        setLoadingMembers((prev) => ({
          ...prev,
          [selectedChat.id as string]: true,
        }));
        const { customerId = '' } = getStoredCustomerDetails() || {};
        const response = await VendorApiService.getVendorDetails(customerId);

        setConversationContext((prevConversationContext) => {
          return {
            ...prevConversationContext,
            userType: UserType.Vendor,
            userId1: loggedInUserInternalId ?? '',
            userId1Name: response?.data?.doingBusinessAs ?? '',
            userId1UserName: loggedInUserName ?? '',
            userId2: selectedChat?.id ?? '',
            userId2Name: selectedChat?.name ?? '',
            userId2UserName: '',
            jobId: workOrderData?.jobId,

            userAndJobDetails: {
              jobName: workOrderData?.name ?? workOrderData?.jobName ?? '',
              vendorId: loggedInUserInternalId ?? '',
              jobId: workOrderData?.jobId,
              vendorUserName: loggedInUserName ?? '',
              vendorCompanyName: response?.data?.doingBusinessAs ?? '',
              userType: UserType.Vendor,
              facilityName: '',
              conversationGroupName: '',
              associateId: selectedChat?.id ?? '',
              associateName: selectedChat?.name ?? '',
              customerOrDmSubVendorId: '',
              customerOrDmOrSubVendorUserName: '',
              customerOrDmOrSubVendorCompanyName: '',
            },
            primaryContactName: t('common:chatWithDM'),
          };
        });
        setLoadingMembers((prev) => ({
          ...prev,
          [selectedChat.id as string]: false,
        }));
      } catch (error) {
        console.error('Error fetching vendor details or setting conversation context:', error);
      }
    },
    [workOrderData]
  );

  useEffect(() => {
    if (!isEmpty(conversationContext)) {
      handleConversationIconClick();
    }
  }, [conversationContext]);

  const renderConversation = (member: ITeamMemberData) => {
    if (!member.id) return null;
    return (
      <Box>
        {loggedInUserInternalId !== member?.id && member.isChecked ? (
          <ConversationIcon>
            {loadingMembers[member.id] ? (
              <CircularProgress
                size={24}
                sx={{
                  color: theme.palette.primary.dark,
                }}
              />
            ) : (
              <img
                src={Icons.GreenConversationIcon}
                alt={t('altTexts:conversation')}
                style={{ cursor: 'pointer', height: '1.5rem', width: '1.5rem' }}
                onClick={() => {
                  void setDataForChat(member);
                }}
              />
            )}
          </ConversationIcon>
        ) : null}
      </Box>
    );
  };

  const renderPunchInAndPunchOut = (member: ITeamMemberData) => {
    const isPunchInDisabled =
      member?.isJobNotAccepted || isFutureShiftSelected || member?.isCannotMakeJob || scheduleData?.isRescheduled;

    const isPunchOutDisabled =
      member?.isJobNotAccepted ||
      member?.punchType === PunchType.PunchOut ||
      isFutureShiftSelected ||
      member?.isCannotMakeJob ||
      scheduleData?.isRescheduled;

    if (!member?.isSupervisor) {
      if (isEmpty(member?.punchType) || member?.punchType === PunchType.NoShow) {
        return (
          <Button
            sx={{
              ...PunchInButtonStyles,
            }}
            onClick={() => handlePunchIn(member?.id)}
            disabled={isPunchInDisabled}>
            <PunchInButton
              style={{
                opacity: isPunchInDisabled ? 0.5 : 1,
              }}>
              {t('supervisorPunchIn:punchIn')}
            </PunchInButton>
          </Button>
        );
      }
      return (
        <Button
          sx={{
            ...PunchInButtonStyles,
          }}
          onClick={() => handlePunchOut(member.id)}
          disabled={isPunchOutDisabled}>
          <PunchInButton
            style={{
              opacity: isPunchOutDisabled ? 0.5 : 1,
            }}>
            {member?.punchType === PunchType.PunchOut
              ? t('supervisorPunchIn:punchedOut')
              : t('supervisorPunchIn:punchOut')}
          </PunchInButton>
        </Button>
      );
    }
  };

  const renderTeamContent = (teamData: ITeamMemberData[]) => {
    return (
      <>
        {teamData?.length > 0 && (
          <TeamDisplayDiv>
            {teamData?.map((member: ITeamMemberData) => {
              if (!member.id) return null;
              const time = renderTime(member);
              return (
                <>
                  <TeamCardOuterDiv>
                    {renderTag(member)}
                    <TeamCard style={{ padding: '20px' }} key={member?.id}>
                      <Box sx={{ display: 'flex' }}>
                        <LogoWrapper>
                          <Box>{getAcronymName(member?.name as string).toUpperCase()}</Box>
                        </LogoWrapper>
                        <PunchTeamContainer>
                          <Box className="Title">{member?.name}</Box>
                          {member?.isSupervisor && (
                            <Box className="Position">{convertToReadableFormat(member.role)}</Box>
                          )}
                          {renderPunchInAndPunchOut(member)}
                          {renderPunchInOrOutTime(member, time)}
                          {renderDuration(member)}
                        </PunchTeamContainer>
                      </Box>
                      <Box>{renderConversation(member)}</Box>
                    </TeamCard>
                  </TeamCardOuterDiv>
                </>
              );
            })}
          </TeamDisplayDiv>
        )}
      </>
    );
  };

  const handleClosePunchIn = () => {
    setIsPunchedInModalOpen(false);
  };

  const handleClosePunchOut = () => {
    setIsPunchedOutModalOpen(false);
  };

  const handleOnPressPunchIn = async (dateDetails: any) => {
    const { punchInDate, punchInTime } = dateDetails;

    const parsedTime = parseTime(punchInTime);

    const punchTime = moment(punchInDate as MomentInput).format(DateFormat.DateTimeFormat);
    const punchIn = moment(punchTime).set('hours', Number(parsedTime.hours)).set('minutes', Number(parsedTime.minutes));
    const punchInTimeInUst = moment(punchIn).toISOString();

    try {
      setIsLoading(true);

      const localDate = new Date();
      const formattedDate = localDate.toLocaleString();
      const formattedTime = moment(localDate).toISOString();

      const punchInTimeInFuture = moment(punchInTimeInUst).isAfter(moment(formattedTime));

      if (punchInTimeInFuture) {
        const localCurrentDate = moment(formattedDate, 'DD/MM/YYYY, HH:mm:ss').format(DateFormat.ShortMonthFormat);

        const localCurrentTime = getTimeFromGivenDate(formattedTime, false);

        showSnackbar(
          'error',
          `${t('supervisorPunchIn:punchInTimeShouldNotBeInFuture')}  (${localCurrentDate} , ${localCurrentTime.hours}:${
            localCurrentTime.formattedMins
          } ${localCurrentTime.meridiem})`
        );
      } else {
        await performPunchIn(punchIn);
      }
      setIsLoading(false);
    } catch (error) {
      showSnackbar('error', `${errorOccurredWhilePunchIn}`);
    } finally {
      setIsLoading(false);
    }
  };

  const performPunchIn = async (punchIn: Moment) => {
    const punchInInput = [
      {
        userId: punchInTeamData?.[0]?.id,
        punchTime: moment(punchIn).toISOString(),
        punchType: PunchType.PunchIn,
        locationLatitude: currentLocation.lat,
        locationLongitude: currentLocation?.lng,
      },
    ];

    if (workOrderScheduleId) {
      const punchInResponse = await AssociateApiService.addPunchInOutBySupervisor(workOrderScheduleId, punchInInput);

      if (punchInResponse?.data) {
        if (punchInResponse?.data[0]?.status === ErrorCodesForPunChIn.ALREADY_EXIST) {
          setIsPunchedInModalOpen(false);
          await getWorkOrderDetails();
        } else {
          if (!isEmpty(punchInResponse.errors)) {
            showSnackbar('error', `${errorOccurredWhilePunchIn}`);
          } else if (punchInResponse?.data?.length) {
            showSnackbar('success', `${t('supervisorPunchIn:PunchedInSuccessfully')}`);
            setIsPunchedInModalOpen(false);
            await getWorkOrderDetails();
          }
        }
      }
    } else {
      showSnackbar('error', `${errorOccurredWhilePunchIn}`);
    }
  };

  const handleOnPressPunchMeOut = async (dateDetails: any) => {
    const { punchOutDate, punchOutTime } = dateDetails;

    const punchTime = moment(punchOutDate as MomentInput).format(DateFormat.DateTimeFormat);
    const parsedTime = parseTime(punchOutTime);

    const punchOutTiming = moment(punchTime)
      .set('hours', Number(parsedTime.hours))
      .set('minutes', Number(parsedTime.minutes));
    const punchOut = moment(punchOutTiming).format(DateFormat.DateTimeFormat);

    try {
      setIsLoading(true);
      if (workOrderScheduleId) {
        const latestPunchInOutByWorkDayResponse = await WorkOrdersApiService.getLatestPunchInOutByWorkDay(
          workOrderScheduleId,
          punchOutTeamData?.[0]?.id ?? ''
        );

        const latestPunchTime = latestPunchInOutByWorkDayResponse?.data?.punchTime;

        let punchInTime;
        if (latestPunchTime?.endsWith('Z')) {
          punchInTime = latestPunchTime?.slice(0, latestPunchTime?.length - 1);
        } else {
          punchInTime = latestPunchTime ?? '';
        }

        const punchInType = latestPunchInOutByWorkDayResponse.data.punchType;

        const punchOutDateWithTime = formattingDate(moment(punchOut).toISOString());

        const isPunchInTimeIsBeforePunchOut = moment(punchOutDateWithTime).isBefore(moment(punchInTime));
        const isPunchOutNotInFuture = moment(moment(punchOut).toISOString()).isBetween(latestPunchTime, moment());

        if (isPunchInTimeIsBeforePunchOut) {
          const localPunchInDate = formatDate(latestPunchTime as string | undefined, DateFormat.ShortMonthFormat);
          const validPunchOutTime = getTimeFromGivenDate(latestPunchTime as string, true);
          showSnackbar(
            'error',
            `${t('supervisorPunchIn:punchOutTimeShouldBeGreaterThanPunchTime')} ${localPunchInDate} , ${validPunchOutTime.hours}:${
              validPunchOutTime.formattedMins
            } ${validPunchOutTime.meridiem}`
          );
          return;
        } else if (!isPunchOutNotInFuture) {
          const localPunchInDate = formatDate(latestPunchTime as string | undefined, DateFormat.ShortMonthFormat);
          const localPunchInTime = getTimeFromGivenDate(latestPunchTime as string, true);
          const localDate = new Date();
          const formattedDate = localDate.toLocaleString();
          const formattedTime = moment(localDate).toISOString();

          const localCurrentDate = moment(formattedDate, 'DD/MM/YYYY, HH:mm:ss').format(DateFormat.ShortMonthFormat);

          const localCurrentTime = getTimeFromGivenDate(formattedTime, false);

          showSnackbar(
            'error',
            `${t('supervisorPunchIn:punchOutTimeShouldBeInBetweenPunchTime')} (${localPunchInDate} , ${localPunchInTime.hours}:${
              localPunchInTime.formattedMins
            } ${localPunchInTime.meridiem}) ${t('supervisorPunchIn:currentTime')} (${localCurrentDate} , ${localCurrentTime.hours}:${
              localCurrentTime.formattedMins
            } ${localCurrentTime.meridiem})`
          );
        } else if (punchInType === PunchType.PunchOut) {
          showSnackbar('error', `${t('supervisorPunchIn:alreadyPunchedOutTitle')}`);
          setIsPunchedOutModalOpen(false);
          await getWorkOrderDetails();
          return;
        } else {
          await performPunchOut(punchOut);
        }
      } else {
        showSnackbar('error', `${errorOccurredWhilePunchOut}`);
      }
      setIsLoading(false);
    } catch (error) {
      showSnackbar('error', `${errorOccurredWhilePunchOut}`);
    } finally {
      setIsLoading(false);
    }
  };

  const performPunchOut = async (punchOut: string) => {
    const punchOutInput = [
      {
        userId: punchOutTeamData?.[0]?.id,
        punchTime: moment(punchOut).toISOString(),
        punchType: PunchType.PunchOut,
        locationLatitude: currentLocation.lat,
        locationLongitude: currentLocation?.lng,
      },
    ];

    if (workOrderScheduleId) {
      const punchInOutResponse = await AssociateApiService.addPunchInOutBySupervisor(
        workOrderScheduleId,
        punchOutInput
      );
      if (punchInOutResponse?.data) {
        setIsPunchedOutModalOpen(false);
        showSnackbar('success', `${t('supervisorPunchIn:PunchedOutSuccessfully')}`);
        await getWorkOrderDetails();
      } else {
        showSnackbar('error', `${errorOccurredWhilePunchOut}`);
      }
    } else {
      showSnackbar('error', `${errorOccurredWhilePunchOut}`);
    }
  };

  const renderPunchInHeaderContent = () => {
    return <HeadingText>{punchInLabel}</HeadingText>;
  };

  const renderPunchOutHeaderContent = () => {
    return <HeadingText>{punchOutLabel}</HeadingText>;
  };

  const renderPunchInBodyContent = () => {
    return (
      <>
        <NewDatePickerContainer>
          <DateSelection
            requiredText={t('supervisorPunchIn:punchInDateFieldReq')}
            control={control}
            isExcludePastDate={true}
            fieldName={FieldNames.punchInDate ?? null}
            label={t('supervisorPunchIn:Date')}
            iconColor="black"
            showSpecificRangeDates={{
              from: specificDate,
              to: new Date(),
            }}
          />
          <Controller
            control={control}
            name={FieldNames.punchInTime}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <DateFieldWrapper>
                  <TextField
                    select
                    sx={textField}
                    label={
                      <span>
                        {t('supervisorPunchIn:Time')}
                        <span className="mandatory-field">*</span>
                      </span>
                    }
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setStartTimeSelectOpen(!isStartTimeSelectOpen)}
                            style={{
                              padding: 0,
                            }}>
                            <img src={Icons.DropdownUpIcon} alt={'up'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    SelectProps={{
                      open: isStartTimeSelectOpen,
                      IconComponent: () => null,
                      onOpen: () => setStartTimeSelectOpen(true),
                      onClose: () => setStartTimeSelectOpen(false),
                      MenuProps: {
                        PaperProps: {
                          style: {
                            boxShadow: '0px 16px 20px 0px rgba(58, 42, 0, 0.12)',
                          },
                        },
                      },
                    }}>
                    {hoursList.map((item) => (
                      <Options key={'startTime' + item.value} value={item.value}>
                        {item.label}
                      </Options>
                    ))}
                  </TextField>

                  <ErrorMsg>{error && error?.message}</ErrorMsg>
                </DateFieldWrapper>
              );
            }}
          />
        </NewDatePickerContainer>
      </>
    );
  };

  const renderPunchOutBodyContent = () => {
    return (
      <>
        <NewDatePickerContainer>
          <DateSelection
            requiredText={t('supervisorPunchIn:punchOutDateFieldReq')}
            control={control}
            isExcludePastDate={true}
            fieldName={FieldNames.punchOutDate ?? null}
            label={t('supervisorPunchIn:Date')}
            iconColor="black"
            showSpecificRangeDates={{
              from: specificDate,
              to: undefined,
            }}
          />
          <Controller
            control={control}
            name={FieldNames.punchOutTime}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <DateFieldWrapper>
                  <TextField
                    select
                    sx={textField}
                    label={
                      <span>
                        {t('supervisorPunchIn:Time')}
                        <span className="mandatory-field">*</span>
                      </span>
                    }
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setStartTimeSelectOpen(!isStartTimeSelectOpen)}
                            style={{
                              padding: 0,
                            }}>
                            <img src={Icons.DropdownUpIcon} alt={'up'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    SelectProps={{
                      open: isStartTimeSelectOpen,
                      IconComponent: () => null,
                      onOpen: () => setStartTimeSelectOpen(true),
                      onClose: () => setStartTimeSelectOpen(false),
                      MenuProps: {
                        PaperProps: {
                          style: {
                            boxShadow: '0px 16px 20px 0px rgba(58, 42, 0, 0.12)',
                          },
                        },
                      },
                    }}>
                    {hoursList.map((item) => (
                      <Options key={'startTime' + item.value} value={item.value}>
                        {item.label}
                      </Options>
                    ))}
                  </TextField>

                  <ErrorMsg>{error && error?.message}</ErrorMsg>
                </DateFieldWrapper>
              );
            }}
          />
        </NewDatePickerContainer>
      </>
    );
  };

  const renderAddSupervisorsHeaderContent = () => {
    return <HeadingText>{t('assignJob:assignSupervisors')}</HeadingText>;
  };

  const renderAddAssociatesHeaderContent = () => {
    return <HeadingText>{t('assignJob:assignAssociates')}</HeadingText>;
  };

  const handleAddSupervisors = async () => {
    try {
      setIsAssignButtonLoading(true);
      const customerDetails = getStoredCustomerDetails();
      const vendorId = customerDetails.customerId;
      const finalMembers = selectedSupervisors.filter((data: { isAlreadyChecked: any }) => !data?.isAlreadyChecked);
      const createScheduleAssignmentsInput: CreateWorkOrderTeam[] = finalMembers.map((item: any) => {
        if (item.teamId) {
          return {
            userId: item?.id || '',
            vendorId,
            roleId: item?.roleId as string,
            isSupervisor: item?.isSupervisor,
            teamId: item?.teamId,
            associateStartDate: item?.associateStartDate,
            associateEndDate: item?.associateStartDate,
          };
        }
        return {
          userId: item?.id || '',
          vendorId,
          roleId: item?.roleId as string,
          isSupervisor: item?.isSupervisor,
          associateStartDate: item?.associateStartDate,
          associateEndDate: item?.associateStartDate,
        };
      });
      const confirmedTeamResponse = await WorkOrdersApiService.addTeamAssignmentWorkOrderTeam({
        workOrderId,
        createScheduleAssignmentsInput,
        woScheduleMasterId,
      });
      if (confirmedTeamResponse.errors?.length) {
        showSnackbar('error', confirmedTeamResponse.errors[0].message as string);
        setIsAssignButtonLoading(false);
      } else {
        showSnackbar('success', `${t('messages:successfullyAssignedSupervisor')}`);
        setIsAssignButtonLoading(false);
        handleCloseAddButton(Role.Supervisor);
        await getWorkOrderDetails();
        await fetchAllVendorTeam(workOrderId);
        await fetchAllAssociateTeam(workOrderId);
      }
    } catch (e) {
      setIsAssignButtonLoading(false);
      console.log('Error:', e);
    }
  };

  const parseDateString = (dateString: string | null): Date | null => {
    return dateString ? new Date(dateString) : null;
  };

  const toISOStringOrNull = (date: Date | null | undefined): string | null => {
    return date ? new Date(date).toISOString() : null;
  };

  const handleAddAssociates = async (startDate: string, endDate: string) => {
    setIsConfirmButtonLoading(true);
    try {
      const customerDetails = getStoredCustomerDetails();
      const vendorId = customerDetails.customerId;

      if (isDateBefore(endDate, startDate)) {
        setIsConfirmButtonLoading(false);
        showSnackbar('error', `${t('oneTimeJob:endDateShouldBeGreaterThanStartDate')}`);
        return;
      }

      const finalAssociates = selectedAssociates.filter((data: { isAlreadyChecked: any }) => !data?.isAlreadyChecked);
      const editTeamMembers = selectedAssociates.filter(
        (data: { id: string }) => data?.id === editAssociateShiftDate?.userId
      );
      let finalMembers;
      if (isEmpty(editAssociateShiftDate?.userId)) {
        finalMembers = finalAssociates;
      } else {
        finalMembers = editTeamMembers;
      }
      if (finalMembers.length) {
        const createScheduleAssignmentsInput = getTeamAssignments(finalMembers, startDate, endDate, vendorId as string);

        const confirmedTeamResponse = await saveTeamAssignments(
          createScheduleAssignmentsInput,
          editAssociateShiftDate?.userId
        );

        if (confirmedTeamResponse.errors?.length) {
          showSnackbar('error', confirmedTeamResponse.errors[0].message as string);
        } else {
          showSnackbar('success', `${t('messages:successfullyAssignedAssociate')}`);
          handleCloseAddButton(Role.Associate);
          await getWorkOrderDetails();
          await fetchAllVendorTeam(workOrderId);
          await fetchAllAssociateTeam(workOrderId);
        }
      }
      setIsConfirmButtonLoading(false);
    } catch (e) {
      setIsConfirmButtonLoading(false);
      console.error('Error', e);
    }
  };

  const getTeamAssignments = (
    finalMembers: any[],
    startDate: string,
    endDate: string,
    vendorId: string
  ): CreateWorkOrderTeam[] => {
    const parsedStartDate = parseDateString(startDate);
    const parsedEndDate = parseDateString(endDate);

    return finalMembers.map((item: any) => {
      const newMemberInput: CreateWorkOrderTeam = {
        userId: item.id || '',
        vendorId,
        roleId: item.roleId as string,
        isSupervisor: item.isSupervisor,
      };

      if (item.teamId) {
        return {
          ...newMemberInput,
          teamId: item.teamId,
          associateStartDate: toISOStringOrNull(parsedStartDate),
          associateEndDate: toISOStringOrNull(parsedEndDate),
        };
      } else if (!item.isSupervisor) {
        return {
          ...newMemberInput,
          associateStartDate: toISOStringOrNull(parsedStartDate),
          associateEndDate: toISOStringOrNull(parsedEndDate),
        };
      }
      return newMemberInput;
    });
  };

  const saveTeamAssignments = async (createScheduleAssignmentsInput: CreateWorkOrderTeam[], userId?: string) => {
    if (isEmpty(userId)) {
      return await WorkOrdersApiService.addTeamAssignmentWorkOrderTeam({
        workOrderId,
        createScheduleAssignmentsInput,
        woScheduleMasterId: woScheduleMasterId,
      });
    } else {
      return await WorkOrdersApiService.updateTeamAssignmentWorkOrderTeam({
        workOrderId,
        updateScheduleAssignmentsInput: createScheduleAssignmentsInput,
      });
    }
  };

  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const handleDeleteTeamMember = async (teamMemberDetails: ITeamMemberData | undefined) => {
    try {
      setIsDeleteButtonLoading(true);
      const userId = teamMemberDetails?.id ?? '';
      const deleteAssociateResponse = await WorkOrdersApiService.deleteTeamAssignmentWorkOrderTeam({
        workOrderId,
        userIds: [userId],
        woScheduleMasterId: woScheduleMasterId,
      });
      console.log('Team member deleted successfully', deleteAssociateResponse);
      if (teamMemberDetails?.role === Role.Associate) {
        showSnackbar('success', `${t('messages:successfullyDeletedAssociate')}`);
      } else {
        showSnackbar('success', `${t('messages:successfullyDeletedSupervisor')}`);
      }

      if (
        (teamMemberDetails?.isSupervisor && deleteAssociateResponse?.data?.[0] !== loggedInUserInternalId) ||
        !teamMemberDetails?.isSupervisor
      ) {
        removeUsersFromConversation?.(
          data,
          deleteAssociateResponse?.data,
          workOrderData?.jobId,
          teamMemberDetails?.isSupervisor ? false : true,
          workOrderId,
          woScheduleMasterId,
          RemoveUserFromConversation,
          deleteConversation,
          teamMemberDetails?.isSupervisor ? loggedInUserInternalId : ''
        );
      }

      setIsDeleteButtonLoading(false);
      handleCloseDeleteModal();
      handleCloseAddButton(teamMemberDetails?.role === Role.Associate ? Role.Associate : Role.Supervisor);
      await getWorkOrderDetails();
      await fetchAllVendorTeam(workOrderId);
      await fetchAllAssociateTeam(workOrderId);
    } catch (error) {
      setIsDeleteButtonLoading(false);
      console.error('Failed to delete team member', error);
    } finally {
      setIsDeleteButtonLoading(false);
    }
  };

  const renderAddSupervisorsBodyContent = (type: Role) => {
    return (
      <>
        <AddTeamMember
          teamMembers={supervisorsTeam}
          setTeamMembers={setSupervisorsTeam}
          originalTeam={originalSupervisorsTeam}
          setOriginalTeam={setOriginalSupervisorsTeam}
          selectedTeamMembers={selectedSupervisors}
          setSelectedTeamMembers={setSelectedSupervisors}
          isDataLoading={isSupervisorDataLoading}
          onClose={() => handleCloseAddButton(type)}
          handleAddTeamMember={handleAddSupervisors}
          assignmentType={type}
          workOrderData={workOrderData}
          isButtonLoading={isAssignButtonLoading}
          deleteTeamMember={true}
          editTeamMember={false}
          handleDeleteTeamMember={handleDeleteTeamMember}
          isDeleteModelOpen={isDeleteModelOpen}
          setDeleteModalOpen={setDeleteModalOpen}
          isDeleteButtonLoading={isDeleteButtonLoading}
          handleCloseDeleteModal={handleCloseDeleteModal}
          setEditAssociateShiftDate={setEditAssociateShiftDate}
          shiftData={shiftData}
        />
      </>
    );
  };

  const renderAddAssociatesBodyContent = (type: Role) => {
    return (
      <>
        <AddTeamMember
          teamMembers={associatesTeam}
          setTeamMembers={setAssociatesTeam}
          originalTeam={originalAssociatesTeam}
          setOriginalTeam={setOriginalAssociatesTeam}
          selectedTeamMembers={selectedAssociates}
          setSelectedTeamMembers={setSelectedAssociates}
          isDataLoading={isAssociateDataLoading}
          onClose={() => handleCloseAddButton(type)}
          handleAddTeamMember={handleAddAssociates}
          assignmentType={type}
          workOrderData={workOrderData}
          isButtonLoading={isConfirmButtonLoading}
          deleteTeamMember={true}
          editTeamMember={true}
          handleDeleteTeamMember={handleDeleteTeamMember}
          isDeleteModelOpen={isDeleteModelOpen}
          setDeleteModalOpen={setDeleteModalOpen}
          isDeleteButtonLoading={isDeleteButtonLoading}
          handleCloseDeleteModal={handleCloseDeleteModal}
          editAssociateShiftDate={editAssociateShiftDate}
          setEditAssociateShiftDate={setEditAssociateShiftDate}
          shiftData={shiftData}
        />
      </>
    );
  };

  const handleAddButton = (roleType: Role) => {
    if (roleType === Role.Supervisor) {
      setIsAddSupervisorsModalOpen(true);
    } else if (roleType === Role.Associate) {
      setIsAddAssociatesModalOpen(true);
    }
  };

  const handleCloseAddButton = (roleType: Role) => {
    if (roleType === Role.Supervisor) {
      setIsAddSupervisorsModalOpen(false);
    } else if (roleType === Role.Associate) {
      setIsAddAssociatesModalOpen(false);
    }
  };

  const fetchAllAssociateTeam = async (
    workOrderId: string,
    pageNumber?: number,
    limit?: number,
    isPaginationDataLoader?: boolean,
    searchString?: string
  ) => {
    const customerDetails = getStoredCustomerDetails();
    const vendorId = customerDetails?.customerId;
    const { userId } = getAuthData();
    const onBehalfOfAssociate = false;
    if (userId || vendorId) {
      setIsAssociateDataLoading(true);
      try {
        const userDetails = await AuthenticationService.getUserByCognitoUserId(userId);

        let associateTeamResponse;
        associateTeamResponse = await getAssociateTeamResponse(
          userDetails,
          vendorId as string,
          searchString,
          pageNumber,
          limit
        );

        if (associateTeamResponse?.metadata?.totalCount > 10) {
          associateTeamResponse = await getAssociateTeamResponse(
            userDetails,
            vendorId as string,
            searchString,
            pageNumber,
            associateTeamResponse?.metadata?.totalCount as number | undefined
          );
        }

        if (associateTeamResponse.errors.length) {
          showSnackbar('error', associateTeamResponse?.errors[0]?.message as string);
          setIsAssociateDataLoading(false);
        }
        const workOrderTeamResponse = await WorkOrdersApiService.getWorkOrderTeams({
          workOrderId,
          woScheduleMasterId: woScheduleMasterId,
        });
        if (workOrderTeamResponse.errors.length) {
          showSnackbar('error', workOrderTeamResponse?.errors[0]?.message as string);
          setIsAssociateDataLoading(false);
          return;
        }
        const modifiedData = await processAssociateTeamData(
          associateTeamResponse.data,
          workOrderTeamResponse.data,
          onBehalfOfAssociate
        );

        setAssociatesTeam(modifiedData);
        setOriginalAssociatesTeam(modifiedData);
        setIsAssociateDataLoading(false);
      } catch (e) {
        console.error('Error', e);
        setAssociatesTeam([]);
        setOriginalAssociatesTeam([]);
        setIsAssociateDataLoading(false);
      }
    }
  };

  const processAssociateTeamData = async (
    vendorTeamData: any[],
    workOrderTeamData: any[],
    onBehalfOfAssociate: boolean
  ) => {
    const tempCheckedMember: ITeamMemberData[] = [];

    const modifiedVendorTeam = await Promise.all(vendorTeamData?.map((user) => processUserData(user)));

    const filteredByRoleData = filterByRole(modifiedVendorTeam, onBehalfOfAssociate);
    const filteredByAvailableAssociate = filterAssociates(filteredByRoleData);

    const modifiedData = await Promise.all(
      filteredByAvailableAssociate.map((member) =>
        processMemberData(member, workOrderTeamData, tempCheckedMember, onBehalfOfAssociate)
      )
    );

    if (!onBehalfOfAssociate) {
      setSelectedAssociates([...tempCheckedMember]);
    }

    return modifiedData;
  };

  const processUserData = async (user: UserForUserManagement) => {
    const { roleName = '', roleId = '' } = !isEmpty(user?.roles) ? user?.roles?.[0] || {} : {};
    const associateDetails = await CustomerApiService.getAssociateDetailsByUserId(user?.cognitoUserId as string);
    const associateId = associateDetails?.data?.associateId ?? '';
    const memberProfileUrl = await fetchDataFromS3Bucket(
      navigate,
      `associate/${associateId}/profile-pictures/${associateId}`
    );

    return {
      id: user?.userId,
      userId: user?.userId,
      name: user?.username,
      isChecked: false,
      profileImg: memberProfileUrl,
      role: roleName,
      roleId,
      isSupervisor: false,
      isDisabled: false,
      isJobNotAccepted: false,
    };
  };

  const processMemberData = (
    member: any,
    workOrderTeamData: any[],
    tempCheckedMember: ITeamMemberData[],
    onBehalfOfAssociate: boolean
  ) => {
    const tempMember = { ...member };
    const workOrderTeamMember = workOrderTeamData?.find(
      (teamMember: IWorkOrderTeam) => teamMember?.userId === member?.userId
    );

    if (!workOrderTeamMember) return tempMember;

    const isChecked = true;
    const isAlreadyChecked = true;
    const teamId = workOrderTeamMember.teamId || '';
    const isSupervisor = workOrderTeamMember.isSupervisor;

    if (onBehalfOfAssociate || !isSupervisor) {
      tempCheckedMember.push({
        ...tempMember,
        isChecked,
        isAlreadyChecked,
        isDisabled: onBehalfOfAssociate,
        teamId,
        isSupervisor,
        associateStartDate: workOrderTeamMember?.associateStartDate,
        associateEndDate: workOrderTeamMember?.associateEndDate,
      });

      return {
        ...tempMember,
        isChecked,
        isAlreadyChecked,
        isDisabled: onBehalfOfAssociate,
        teamId,
        isSupervisor,
        associateStartDate: workOrderTeamMember?.associateStartDate,
        associateEndDate: workOrderTeamMember?.associateEndDate,
      };
    }

    return { ...tempMember, teamId };
  };

  const filterByRole = (team: any[], onBehalfOfAssociate: boolean) => {
    if (onBehalfOfAssociate) return team;
    return team.filter((item) => item?.role === RolesEnum.AssociateTechnician);
  };

  const getAssociateTeamResponse = async (
    userDetails: any,
    vendorId: string,
    searchString?: string,
    pageNumber?: number,
    limit?: number
  ) => {
    return isDistrictManager
      ? await WorkOrdersApiService.getVendorTeam(
          userDetails?.data?.userId as string,
          '',
          BooleanType.True,
          searchString || '',
          pageNumber,
          limit,
          true
        )
      : await OrganizationService.getAllUsers(vendorId, '', '', pageNumber, limit, BooleanType.True, true);
  };

  const getVendorTeamResponse = async (
    userDetails: any,
    vendorId: string,
    searchString?: string,
    pageNumber?: number,
    limit?: number
  ) => {
    return isDistrictManager
      ? await WorkOrdersApiService.getVendorTeam(
          userDetails?.data?.userId as string,
          '',
          BooleanType.False,
          searchString || '',
          pageNumber,
          limit,
          true
        )
      : await CustomerApiService.getAllUsers(vendorId, '', pageNumber, limit, BooleanType.False, true);
  };

  const fetchAllVendorTeam = async (
    workOrderId: string,
    pageNumber?: number,
    limit?: number,
    isPaginationDataLoader?: boolean,
    searchString?: string
  ) => {
    const customerDetails: any = getStoredCustomerDetails();
    const vendorId = customerDetails?.customerId;
    const { userId } = getAuthData();
    if (userId || vendorId) {
      setIsSupervisorDataLoading(true);
      try {
        const userDetails = await AuthenticationService.getUserByCognitoUserId(userId);

        let vendorTeamResponse;

        vendorTeamResponse = await getVendorTeamResponse(
          userDetails,
          vendorId as string,
          searchString,
          pageNumber,
          limit
        );

        if (vendorTeamResponse?.metadata?.totalCount > 10) {
          vendorTeamResponse = await getVendorTeamResponse(
            userDetails,
            vendorId as string,
            searchString,
            pageNumber,
            vendorTeamResponse?.metadata?.totalCount as number | undefined
          );
        }

        if (vendorTeamResponse.errors.length) {
          showSnackbar('error', vendorTeamResponse?.errors[0]?.message as string);
          setIsSupervisorDataLoading(false);
          return;
        }
        const workOrderTeamResponse = await WorkOrdersApiService.getWorkOrderTeams({
          workOrderId,
          woScheduleMasterId: woScheduleMasterId,
        });
        if (workOrderTeamResponse.errors.length) {
          showSnackbar('error', workOrderTeamResponse?.errors[0]?.message as string);
          return;
        }

        const vendorTeamsUsersExcludingAssociates = filterVendorTeams(vendorTeamResponse?.data, isDistrictManager);
        if (!isEmpty(vendorTeamsUsersExcludingAssociates)) {
          await processVendorTeams(vendorTeamsUsersExcludingAssociates, workOrderTeamResponse?.data);
          setIsSupervisorDataLoading(false);
        } else {
          setSupervisorsTeam([]);
          setOriginalSupervisorsTeam([]);
          setIsSupervisorDataLoading(false);
        }
      } catch (e) {
        console.error('Error', e);
        setSupervisorsTeam([]);
        setOriginalSupervisorsTeam([]);
        setIsSupervisorDataLoading(false);
      }
    }
  };

  const filterVendorTeams = (vendorTeamResponseData: any[], isDistrictManager: boolean) => {
    return isDistrictManager
      ? vendorTeamResponseData
      : vendorTeamResponseData.filter((user: any) => {
          return user?.roles[0]?.roleName !== RolesEnum.AssociateTechnician;
        });
  };

  const processVendorTeams = async (
    vendorTeamsUsersExcludingAssociates: UserForUserManagement[],
    workOrderTeamData: IWorkOrderTeam[]
  ) => {
    const tempCheckedMember: ITeamMemberData[] = [];
    const modifiedData = await Promise.all(
      vendorTeamsUsersExcludingAssociates.map(async (member: UserForUserManagement) => {
        const memberProfileUrl = await fetchDataFromS3Bucket(
          navigate,
          `associate/${member?.userId}/profile-pictures/${member?.userId}`
        );
        const tempMember = createTeamMemberData(member, memberProfileUrl);
        const workOrderTeamMember = workOrderTeamData.find(
          (teamMember: IWorkOrderTeam) => teamMember.userId === member.userId
        );

        if (workOrderTeamMember) {
          if (workOrderTeamMember.isSupervisor) {
            tempCheckedMember.push({
              ...tempMember,
              isChecked: true,
              isAlreadyChecked: true,
              teamId: workOrderTeamMember.teamId || '',
            });
            return { ...tempMember, isChecked: true, isAlreadyChecked: true, teamId: workOrderTeamMember.teamId || '' };
          }
          return { ...tempMember, teamId: workOrderTeamMember.teamId || '' };
        }
        return tempMember;
      })
    );
    updateTeamData(tempCheckedMember, modifiedData);
  };

  const createTeamMemberData = (member: UserForUserManagement, profileImg: string): ITeamMemberData => {
    const { roleName = '', roleId = '' } =
      member && !isEmpty(member?.roles) && member?.roles?.length ? member?.roles[0] || {} : {};

    return {
      id: member.userId,
      name: member.username,
      isChecked: false,
      isAlreadyChecked: false,
      profileImg,
      role: roleName,
      roleId,
      isSupervisor: true,
      teamId: '',
    };
  };
  const isOnlyPrimeVendor = workOrderData?.hasChild && !workOrderData?.parentWorkOrderId;

  const updateTeamData = (checkedMembers: ITeamMemberData[], modifiedData: ITeamMemberData[]) => {
    setSelectedSupervisors(() => {
      const combinedArray = [...checkedMembers];
      return _.uniqBy(combinedArray, 'id');
    });
    setSupervisorsTeam(modifiedData);
    setOriginalSupervisorsTeam(modifiedData);
  };

  const filterAssociates = (associateData: ITeamMemberData[]) => {
    return associateData.filter((member: IUser) => {
      const shortlisted = selectedSupervisors.some((supervisor: any) => supervisor.id === member.userId);
      return !shortlisted;
    });
  };

  useEffect(() => {
    setValue(FieldNames.punchInDate, specificDate as string | null);
  }, [specificDate]);

  useEffect(() => {
    disablePunchInButton();
    disablePunchOutButton();
  }, [watchFields]);

  useEffect(() => {
    setHoursList(
      hours.map((hour) => {
        return {
          label: getTimeFormat(hour),
          value: hour,
        };
      })
    );
    fetchAllAssociateTeam(workOrderId);
    fetchAllVendorTeam(workOrderId);
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error('Error getting the current location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  const isSupervisorsGroupDisabled =
    isLoading ||
    isEmpty(teamData) ||
    (!isEmpty(teamData) && teamData.length === 1 && teamData?.[0]?.id === loggedInUserInternalId);

  const isAssociatesGroupDisabled = isLoading || isEmpty(checkedAssociates);

  return (
    <>
      <PunchCardContainerWrapper>
        {isTeamDataLoading ? (
          <LoaderTag>
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark, marginTop: '100px' }} />
          </LoaderTag>
        ) : (
          <>
            <PunchInHeader>
              <AssignedTeamHeader>
                <PunchInHeading>{t('assignJob:assignedSupervisors')}</PunchInHeading>
                <Button
                  onClick={() => handleAddButton(Role.Supervisor)}
                  sx={{
                    ...addIconButton,
                    opacity: scheduleData?.isRescheduled ? 0.5 : 1,
                  }}
                  disabled={scheduleData?.isRescheduled}>
                  <img
                    src={Icons.AddTagIcon}
                    alt={t('altTexts:add')}
                    style={{
                      ...plusIcon,
                      opacity: scheduleData?.isRescheduled ? 0.5 : 1,
                    }}
                  />
                </Button>
              </AssignedTeamHeader>

              <ChatWithTeam
                sx={{
                  cursor: isSupervisorsGroupDisabled ? 'not-allowed' : 'pointer',
                  opacity: isSupervisorsGroupDisabled ? 0.5 : 1,
                }}
                onClick={() => {
                  if (!isSupervisorsGroupDisabled) {
                    setChatWithSupervisors(true);
                  }
                }}>
                <img
                  src={Icons.GreenConversationIcon}
                  alt={t('altTexts:conversation')}
                  style={{ ...conversationIcon }}
                />
                <ConversationName style={{ color: '#006B6B' }}>
                  {t('conversations:chatWithSupervisors')}
                </ConversationName>
              </ChatWithTeam>
            </PunchInHeader>

            {teamData.length > 0 ? (
              renderTeamContent(teamData)
            ) : (
              <Typography sx={{ ...noDataFound }}>{t('assignJob:noResultsFound')}</Typography>
            )}
            {/* not showing adding associate for dm for flow 2 */}
            {((isDistrictManager && !isOnlyPrimeVendor) || (!isDistrictManager && role === UserType.Vendor)) && (
              <>
                <PunchInHeader>
                  <AssignedTeamHeader>
                    <PunchInHeading>{t('assignJob:assignedAssociates')}</PunchInHeading>
                    <Button
                      onClick={() => handleAddButton(Role.Associate)}
                      sx={{
                        ...addIconButton,
                        opacity: scheduleData?.isRescheduled ? 0.5 : 1,
                      }}
                      disabled={scheduleData?.isRescheduled}>
                      <img
                        src={Icons.AddTagIcon}
                        alt={t('altTexts:add')}
                        style={{
                          ...plusIcon,
                          opacity: scheduleData?.isRescheduled ? 0.5 : 1,
                        }}
                      />
                    </Button>
                  </AssignedTeamHeader>
                  <ChatWithTeam
                    sx={{
                      cursor: isAssociatesGroupDisabled ? 'not-allowed' : 'pointer',
                      opacity: isAssociatesGroupDisabled ? 0.5 : 1,
                    }}
                    onClick={() => {
                      if (!isAssociatesGroupDisabled) {
                        setChatWithAssociates(true);
                      }
                    }}>
                    <img
                      src={Icons.GreenConversationIcon}
                      alt={t('altTexts:conversation')}
                      style={{ ...conversationIcon }}
                    />
                    <ConversationName style={{ color: '#006B6B' }}>
                      {t('conversations:chatWithAssociates')}
                    </ConversationName>
                  </ChatWithTeam>
                </PunchInHeader>

                {checkedAssociates.length > 0 ? (
                  renderTeamContent(checkedAssociates)
                ) : (
                  <Typography sx={{ ...noDataFound }}>{t('assignJob:noResultsFound')}</Typography>
                )}
              </>
            )}
          </>
        )}
      </PunchCardContainerWrapper>

      <Modal
        open={isPunchedInModalOpen}
        onClose={handleClosePunchIn}
        primaryButtonLoading={isLoading}
        primaryButtonLabel={punchInLabel}
        primaryButtonVisible
        secondaryButtonLabel={t('assignJob:cancel')}
        secondaryButtonVisible
        renderHeader={renderPunchInHeaderContent()}
        renderBody={renderPunchInBodyContent()}
        primaryAction={handleSubmit(handleOnPressPunchIn)}
        secondaryAction={handleClosePunchIn}
        styles={{
          '& .MuiPaper-root': {
            width: '32rem',
          },
        }}
        primaryButtonDisabling={disablePunchInButton()}
      />
      <Modal
        open={isPunchedOutModalOpen}
        onClose={handleClosePunchOut}
        primaryButtonLoading={isLoading}
        primaryButtonLabel={punchOutLabel}
        primaryButtonVisible
        secondaryButtonLabel={t('assignJob:cancel')}
        secondaryButtonVisible
        renderHeader={renderPunchOutHeaderContent()}
        renderBody={renderPunchOutBodyContent()}
        primaryAction={handleSubmit(handleOnPressPunchMeOut)}
        secondaryAction={handleClosePunchOut}
        styles={{
          '& .MuiPaper-root': {
            width: '32rem',
          },
        }}
        primaryButtonDisabling={disablePunchOutButton()}
      />
      <Modal
        open={isAddSupervisorsModalOpen}
        onClose={() => handleCloseAddButton(Role.Supervisor)}
        renderHeader={renderAddSupervisorsHeaderContent()}
        renderBody={renderAddSupervisorsBodyContent(Role.Supervisor)}
      />

      <Modal
        open={isAddAssociatesModalOpen}
        onClose={() => handleCloseAddButton(Role.Associate)}
        renderHeader={renderAddAssociatesHeaderContent()}
        renderBody={renderAddAssociatesBodyContent(Role.Associate)}
      />

      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
      {chatWithSupervisors && (
        <CreateConversations
          loggedInUserType={loggedInUserType}
          loggedInUserInternalId={loggedInUserInternalId}
          customerId={customerId}
          createNewConversation={chatWithSupervisors}
          setCreateConversation={setChatWithSupervisors}
          conversationMode={ConversationTabs.GROUP_CHAT}
          organizationName={organizationName}
          loggedInUserName={loggedInUserName}
          onNewConversation={handleGroupChat}
          users={teamData}
          setConversationName={setConversationName}
          jobId={workOrderData?.jobId}
          jobName={workOrderData?.name ?? workOrderData?.jobName ?? ''}
          isSupervisorGroup={true}
        />
      )}
      {chatWithAssociates && (
        <CreateConversations
          loggedInUserType={loggedInUserType}
          loggedInUserInternalId={loggedInUserInternalId}
          customerId={customerId}
          createNewConversation={chatWithAssociates}
          setCreateConversation={setChatWithAssociates}
          conversationMode={ConversationTabs.GROUP_CHAT}
          organizationName={organizationName}
          loggedInUserName={loggedInUserName}
          onNewConversation={handleGroupChat}
          users={checkedAssociates}
          setConversationName={setConversationName}
          jobId={workOrderData?.jobId}
          jobName={workOrderData?.name ?? workOrderData?.jobName ?? ''}
          isAssociateGroup={true}
          isAssociateGroupCreating={true}
        />
      )}
    </>
  );
};

export default SupervisorPunchIn;
