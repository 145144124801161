/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import { getAuthData, getStoredCustomerDetails } from '../../../../Shared/Utilities/utils';
import { isEmpty } from 'lodash';
import theme from '../../../../Shared/Themes/theme';
import { Box, CircularProgress, Typography } from '@mui/material';
import JobCreateApiService from '../../Services/JobCreateService';
import { ConversationInput, JobType, MessageType } from '../../../../API';
import { UserType } from '../../../../Shared/Constants/App';
import {
  ConversationCardMainDiv,
  ConversationsContainer,
  ConversationsContainerOne,
  ConversationsContainerTwo,
  CustomerOuterContainer,
  NoConversationFound,
  TabPanelStyles,
} from '../../../../Shared/Components/Conversations/DashboardConversations.Style';
import MessageScreen from '../../../../Shared/Components/Conversations/MessagesScreen/MessageScreen';
import { TabsWrapper } from '../JobDetailsOverView/JobDetailsOverview.styles';
import { HeaderText } from '../../../../Shared/Components/Conversations/MessagesScreen/MessagesScreen.Style';
import { ConversationName } from '../../../../Shared/Components/Conversations/ConversationsScreen/Conversations.Style';
import PreJobAwardConversationCard from './PreJobAwardConversationCard';
import { VendorType } from '../../../../Shared/Models/Vendors.Models';
import VendorApiService from '../../../../Shared/Services/VendorService';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line sonarjs/cognitive-complexity
const PreJobAwardConversations = (): JSX.Element => {
  const location = useLocation();
  const [selectedConversationId, setSelectedConversationId] = useState<string>('');
  const [selectedConversation, setSelectedConversation] = useState<any>({});
  const [replyMsgContent, setReplyMsgContent] = useState<string>('');
  const [conversationsData, setConversationsData] = useState<any>([]);
  const [allConversationsData, setAllConversationsData] = useState<any>([]);

  const [isConversationsDataLoading, setIsConversationsDataLoading] = useState<boolean>(false);

  const [isSelectedConversationsDataLoading, setIsSelectedConversationsDataLoading] = useState<boolean>(false);

  const [isSendButtonLoading, setIsSendButtonLoading] = useState<boolean>(false);

  const [bidSubmittedVendorsList, setBidSubmittedVendorsList] = useState<any>([]);

  const [isConversationsDataLoaded, setIsConversationsDataLoaded] = useState(false);
  const [isBidsDataLoaded, setIsBidsDataLoaded] = useState(false);

  const { t } = useTranslation(['conversations']);

  const { role } = getAuthData();
  const { customerId = '' } = getStoredCustomerDetails();

  const { containerOne } = TabPanelStyles;

  const preJobAward = true;

  const isOtjJob = !!location?.state?.jobCustodian && location?.state?.jobType !== JobType.Recurring;

  const isSubVendorInSidePeazy =
    isOtjJob &&
    location?.state?.mdVendorType !== VendorType.SelfPerform &&
    location?.state?.mdVendorType !== VendorType.SubVendor;

  const { isDistrictManager } = getAuthData() || {};

  const hideConversationTab = isSubVendorInSidePeazy
    ? preJobAward && !isDistrictManager
    : preJobAward && role === UserType.Vendor;

  const isInitialNavigation = useRef(true);

  const isInitialLoading = useRef(true);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const getConversationsData = async (): Promise<void> => {
    setIsConversationsDataLoading(true);
    try {
      const payload: any = {};
      if (!location.state?.jobCustodian) {
        payload.jobId = location.state.jobId;
      } else if (location.state?.jobCustodian) {
        if (customerId === location.state?.jobCustodian) {
          payload.jobId = location.state?.subJobId;
        } else {
          payload.jobId = location.state?.jobId;
        }
      }

      const conversationsResponse = await JobCreateApiService.getConversations(payload);
      if (conversationsResponse.data && isEmpty(conversationsResponse.errors)) {
        await getBidsData();
        setConversationsData(conversationsResponse.data);
      }
    } catch (error: any) {
      console.log('get conversations error: ', error);
    }
    setIsConversationsDataLoading(false);
    setIsConversationsDataLoaded(true);
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const getCreateConversationPayload = (): ConversationInput => {
    const { customerId = '' } = getStoredCustomerDetails();

    const payLoad: ConversationInput = {
      message: replyMsgContent,
      messageType: MessageType.InApp,
      organizationId: customerId,
    };
    if (!location.state?.jobCustodian) {
      payLoad.customerId = location.state.customerId;
      payLoad.jobId = location.state.jobId;
    } else {
      payLoad.customerId = location.state?.jobCustodian;
      if (customerId === location.state?.jobCustodian) {
        payLoad.jobId = location.state?.subJobId;
      } else {
        payLoad.jobId = location.state?.jobId;
      }
    }
    if (role !== UserType.Customer && customerId !== location.state?.jobCustodian) {
      payLoad.vendorId = customerId;
    } else {
      payLoad.vendorId = selectedConversation.vendorId ?? location.state?.vendorId;
    }

    if (selectedConversationId && !isEmpty(selectedConversationId)) {
      payLoad.conversationId = selectedConversationId;
    }
    if (hideConversationTab && isEmpty(selectedConversationId) && !isEmpty(allConversationsData)) {
      payLoad.conversationId = allConversationsData?.[0]?.conversationId;
    }
    if (location.state?.tag && location.state?.tag === 'BID_RELATED') {
      if (customerId === location.state?.jobCustodian) {
        payLoad.bidId = location.state?.subJobBidId;
      } else {
        payLoad.bidId = location.state?.bidId;
      }
    }

    return payLoad;
  };

  const handleSendButton = async (): Promise<void> => {
    setIsSendButtonLoading(true);
    try {
      const payload = getCreateConversationPayload();

      const response = await JobCreateApiService.createConversation(payload);
      if (!selectedConversationId) {
        setSelectedConversation(response?.data?.data?.[0]);
        setSelectedConversationId(response?.data?.data?.[0]?.conversationId);
      }
      if (response.data && isEmpty(response.errors)) {
        const conversationResponse = await JobCreateApiService.getConversations({
          conversationId: selectedConversationId,
        });
        if (conversationResponse.data && isEmpty(conversationResponse.errors) && conversationsData.length > 0) {
          setSelectedConversation(conversationResponse.data[0]);
        }
        setReplyMsgContent('');
        await getConversationsData();
      } else {
        console.log('response error', response.errors);
      }
    } catch (error: any) {
      console.log('send reply error: ', error);
    }
    setIsSendButtonLoading(false);
  };

  const getBidsData = async (): Promise<void> => {
    const jobId = isDistrictManager ? location.state?.subJobId : location.state?.jobId;
    const jobBidsResponse = await JobCreateApiService.getJobBids(jobId);
    if (jobBidsResponse.data && jobBidsResponse.data.length > 0 && isEmpty(jobBidsResponse.errors)) {
      setBidSubmittedVendorsList(jobBidsResponse.data);
    }
    setIsBidsDataLoaded(true);
  };

  const onSelectConversationCard = async (conversation: any) => {
    setIsSelectedConversationsDataLoading(true);
    setSelectedConversation(conversation);
    try {
      if (conversation?.conversationId) {
        const payload: any = {};
        if (!location.state?.jobCustodian) {
          payload.jobId = location.state.jobId;
          payload.customerId = location.state?.customerId;
        } else if (location.state?.jobCustodian) {
          payload.customerId = location.state?.jobCustodian;
          if (customerId === location.state?.jobCustodian) {
            payload.jobId = location.state?.subJobId;
          } else {
            payload.jobId = location.state?.jobId;
          }
        }
        payload.vendorId = conversation?.vendorId;

        const conversationsResponse = await JobCreateApiService.getConversations(payload);
        if (conversationsResponse.data && isEmpty(conversationsResponse.errors)) {
          setSelectedConversation(conversationsResponse.data?.[0]);
          setSelectedConversationId(conversationsResponse.data?.[0]?.conversationId);
        }
      } else {
        setSelectedConversation(conversation);
      }
    } catch (error: any) {
      console.log('get conversations error: ', error);
    }
    setIsSelectedConversationsDataLoading(false);
  };

  const renderConversationCard = (conversation: any, index: number, dataLength: number) => {
    const isLast = index === dataLength - 1;
    return (
      <ConversationCardMainDiv onClick={() => onSelectConversationCard(conversation)}>
        <PreJobAwardConversationCard
          conversationId={
            conversation?.conversationId ||
            conversation?.vendorBidId ||
            conversation?.customerId ||
            conversation?.vendorId
          }
          selectedConversation={selectedConversation}
          isLast={isLast}
          countOfMessages={conversation?.countOfMessages}
          messages={conversation?.messages}
          preJobAward={preJobAward}
          conversation={conversation}
          isDistrictManager={isDistrictManager}
          isSubVendorInSidePeazy={isSubVendorInSidePeazy}
        />
      </ConversationCardMainDiv>
    );
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const getAllConversationsData = async () => {
    setIsConversationsDataLoading(true);
    const newConversationsData: any[] = [];

    const addUniqueBids = (bidSubmittedVendorsList: any[], newConversationsData: any[]) => {
      bidSubmittedVendorsList.forEach((bid: { vendorId: any; jobId: any }) => {
        const { vendorId: bidVendorId, jobId: bidJobId } = bid;

        const isExistingConversation = conversationsData.some(
          (conversation: { vendorId: any; jobId: any }) =>
            conversation.vendorId === bidVendorId && conversation.jobId === bidJobId
        );

        if (!isExistingConversation) {
          newConversationsData.push(bid);
        }
      });
    };

    const handleEmptyConversations = (responseData: any) => {
      if (responseData) {
        newConversationsData.push(responseData);
      }
    };

    const handleNonEmptyConversations = (responseData: any) => {
      const matchingConversation = conversationsData.find(
        (conversation: { vendorId: any }) => conversation.vendorId === customerId
      );

      if (matchingConversation) {
        newConversationsData.push(matchingConversation);
      }

      if (isEmpty(newConversationsData) && responseData) {
        newConversationsData.push(responseData);
      }
    };

    if (role === UserType.Customer || (isDistrictManager && isSubVendorInSidePeazy)) {
      if (!conversationsData || conversationsData.length === 0) {
        newConversationsData.push(...bidSubmittedVendorsList);
      } else {
        newConversationsData.push(...conversationsData);
        addUniqueBids(bidSubmittedVendorsList, newConversationsData);
      }
      setAllConversationsData(newConversationsData);
      setIsConversationsDataLoading(false);
      isInitialLoading.current = false;
      return;
    }

    if (!isSubVendorInSidePeazy) {
      const jobSummaryResponse = await JobCreateApiService.jobSummaryV2(customerId, location.state?.jobId);

      if (!conversationsData || conversationsData.length === 0) {
        handleEmptyConversations(jobSummaryResponse?.data);
      } else {
        handleNonEmptyConversations(jobSummaryResponse?.data);
      }
      setAllConversationsData(newConversationsData);
      setIsConversationsDataLoading(false);
      isInitialLoading.current = false;
      return;
    }

    const vendorDetailsResponse = await VendorApiService.getVendorDetails(location.state?.jobCustodian);

    if (!conversationsData || conversationsData.length === 0) {
      handleEmptyConversations(vendorDetailsResponse?.data);
    } else {
      handleNonEmptyConversations(vendorDetailsResponse?.data);
    }

    setAllConversationsData(newConversationsData);
    setIsConversationsDataLoading(false);
    isInitialLoading.current = false;
  };

  useEffect(() => {
    getConversationsData();
  }, []);

  useEffect(() => {
    if (isConversationsDataLoaded && isBidsDataLoaded) {
      getAllConversationsData();
    }
  }, [isConversationsDataLoaded, isBidsDataLoaded, conversationsData]);

  useEffect(() => {
    if (isInitialNavigation.current && location?.state?.FromBidsTab && allConversationsData?.length) {
      const vendorId = location?.state?.vendorId;

      const matchingConversation = allConversationsData.find(
        (conversation: { vendorId: any }) => conversation.vendorId === vendorId
      );

      if (matchingConversation) {
        setSelectedConversation(matchingConversation);
        setSelectedConversationId(matchingConversation?.conversationId);
      }
      isInitialNavigation.current = false;
    }
  }, [allConversationsData]);

  return (
    <>
      <PageTitle title={location.state?.jobName} />
      <CustomerOuterContainer>
        <ConversationsContainer>
          {!hideConversationTab && (
            <ConversationsContainerOne>
              <TabsWrapper>
                <HeaderText style={{ alignItems: 'center', justifyContent: 'center' }}>
                  <ConversationName>
                    {role === UserType.Customer
                      ? allConversationsData?.length > 1
                        ? t('conversations:chatWithVendors')
                        : t('conversations:chatWithVendor')
                      : isDistrictManager && isSubVendorInSidePeazy
                        ? t('conversations:chatWithSubVendors')
                        : t('conversations:chatWithCustomer')}
                  </ConversationName>
                </HeaderText>
              </TabsWrapper>
              {isConversationsDataLoading ? (
                <CircularProgress size={30} sx={{ color: theme.palette.primary.dark, margin: '20% 45%' }} />
              ) : (
                <Box
                  sx={{
                    ...containerOne,
                  }}>
                  {!isEmpty(allConversationsData)
                    ? allConversationsData &&
                      allConversationsData?.length > 0 &&
                      allConversationsData?.map((item: any, index: number) =>
                        renderConversationCard(item, index, allConversationsData.length)
                      )
                    : isEmpty(allConversationsData) && (
                        <NoConversationFound>
                          <Typography>{t('conversations:noConversationsFound')}</Typography>
                        </NoConversationFound>
                      )}
                </Box>
              )}
            </ConversationsContainerOne>
          )}
          <ConversationsContainerTwo
            style={{
              flexBasis: hideConversationTab ? '100%' : '75%',
              borderRadius: hideConversationTab ? '10px' : '0px',
              borderTopRightRadius: '20px',
              borderBottomRightRadius: '10px',
            }}>
            <MessageScreen
              selectedConversation={hideConversationTab ? allConversationsData?.[0] : selectedConversation}
              placeHolder={
                (hideConversationTab ? allConversationsData?.[0]?.messages : selectedConversation?.messages)
                  ? t('conversations:giveYourReplyHere')
                  : t('conversations:startConversation')
              }
              conversationDetails={''}
              conversationName={''}
              preJobAward={true}
              handlePreJobAwardSendButton={handleSendButton}
              isSendButtonLoading={isSendButtonLoading || isConversationsDataLoading}
              isDataLoading={isInitialLoading.current ? isConversationsDataLoading : false}
              selectedConversationLoading={isSelectedConversationsDataLoading}
              replyMsgContent={replyMsgContent}
              setReplyMsgContent={setReplyMsgContent}
              hideConversationTab={hideConversationTab}
              allConversationsData={allConversationsData}
              isDistrictManager={isDistrictManager}
            />
          </ConversationsContainerTwo>
        </ConversationsContainer>
      </CustomerOuterContainer>
    </>
  );
};

export default PreJobAwardConversations;
