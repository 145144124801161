/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useTranslation } from 'react-i18next';
import { ExtendedMRT_ColumnDef } from '../../../Models/Marketplace.model';
import { IJobOverview } from '../../../../Jobs/Components/JobsListView/JobsListView';
import { TaskData } from '../../../../Jobs/Components/WorkTasks/WorkTasks';
import { IProfileCardProps } from '../../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import { IBidList } from '../../../../Jobs/Components/JobDetails/OneTimeJobDetails';
import { IJobs } from '../../../../Jobs/Components/JobView/JobsView';
import { formatDateCell } from '../../../Utilities/Jobs.Utils';
import { formatTime } from '../../../../../Shared/Utilities/utils';
import { DropdownOptions } from '../AllJobs.Model';
import { Box } from '@mui/material';
import { Icons } from '../../../../../Shared/Constants/Icons';
import { ReactNode } from 'react';
import { JobType } from '../../../../../Shared/Constants/App';
import { VendorType } from '../../../../../Shared/Models/Vendors.Models';
import { isEmpty } from 'lodash';

interface IScheduleColumns {
  type: DropdownOptions;
  handleShiftTimings?: (cell: any) => void;
  renderActionsCell?: (row: any) => ReactNode;
}
const ScheduleColumns = ({ type, handleShiftTimings, renderActionsCell }: IScheduleColumns) => {
  const { t } = useTranslation(['vendor', 'tableHeader', 'altTexts', 'vendorBidCreation', 'jobAward']);

  const getActionColumn = () => {
    if (type === DropdownOptions.CancelShift) {
      return [
        {
          header: t('tableHeader:actions'),
          enableColumnActions: false,
          enablePinning: false,
          size: 90,
          Cell: ({ row }: any) => renderActionsCell?.(row),
        },
      ];
    }
    return [];
  };

  const handleEditIcon = (row: any) => {
    const isOtjJob =
      row?.original?.jobCustodian &&
      (row?.original?.jobType === JobType.OneTime || row?.original?.jobType === JobType.Emergency);
    const isFlow3Job = isOtjJob && row?.original?.mdVendorType === VendorType.SubVendor; // For 3rd flow
    const isFlow2Job =
      isOtjJob &&
      (row?.original?.mdVendorType === VendorType.All || row?.original?.mdVendorType === VendorType.SearchVendors); // OTJ 2nd flow

    const isSubVendor = isOtjJob && (isFlow3Job || isFlow2Job) && !isEmpty(row?.original?.parentWorkOrderId);

    const isSelfPerform = isOtjJob && row?.original?.mdVendorType === VendorType.SelfPerform;
    if (isFlow2Job) {
      return !!isSubVendor;
    } else if (isFlow3Job) {
      return !isSubVendor;
    } else if (isSelfPerform) {
      return false;
    }
    row.original.dataStatus === 'A';
  };

  const columns: ExtendedMRT_ColumnDef<IJobOverview | TaskData | IJobs | IBidList | IProfileCardProps>[] = [
    {
      accessorKey: 'jobName',
      header: t('jobManagement:jobName'),
      enablePinning: false,
      enableSorting: false,
      size: 10,
      enableColumnFilter: false,
      enableColumnActions: false,
      Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : '-'),
    },
    {
      accessorKey: 'scheduleName',
      header: t('jobManagement:scheduleName'),
      enablePinning: false,
      enableSorting: false,
      size: 10,
      enableColumnFilter: false,
      enableColumnActions: false,
      Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : '-'),
    },
    {
      accessorKey: 'scheduleDate',
      header: t('jobManagement:scheduleDate'),
      enableSorting: false,
      enableColumnFilter: false,
      size: 140,
      Cell: ({ cell }) => {
        if (type === DropdownOptions.RescheduleShift) {
          const isEditable = handleEditIcon(cell.row);

          return cell ? (
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              {formatDateCell(cell)}
              <img
                src={Icons.EditBlackIcon}
                alt={t('altTexts:edit')}
                style={{
                  cursor: isEditable ? 'pointer' : 'not-allowed',
                  opacity: isEditable ? 1 : 0.3,
                  height: '1.5rem',
                  width: '1.5rem',
                }}
                onClick={() => isEditable && handleShiftTimings?.(cell)}
              />
            </Box>
          ) : (
            '-'
          );
        } else {
          return cell ? formatDateCell(cell) : '-';
        }
      },
      enableColumnActions: false,
    },
    {
      accessorKey: 'scheduleStartTime',
      header: t('jobManagement:scheduleStartTime'),
      enableSorting: false,
      enableColumnFilter: false,
      size: 100,
      Cell: ({ cell, renderedCellValue }) => {
        const time = String(renderedCellValue);

        if (type === DropdownOptions.RescheduleShift) {
          const isEditable = handleEditIcon(cell.row);
          return time ? (
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              {formatTime(time)}
              <img
                src={Icons.EditBlackIcon}
                alt={t('altTexts:edit')}
                style={{
                  cursor: isEditable ? 'pointer' : 'not-allowed',
                  opacity: isEditable ? 1 : 0.3,
                  height: '1.5rem',
                  width: '1.5rem',
                }}
                onClick={() => isEditable && handleShiftTimings?.(cell)}
              />
            </Box>
          ) : (
            '-'
          );
        } else {
          return time ? formatTime(time) : '-';
        }
      },
      enableColumnActions: false,
    },
    {
      accessorKey: 'scheduleEndTime',
      header: t('jobManagement:scheduleEndTime'),
      enableSorting: false,
      enableColumnFilter: false,
      size: 100,
      Cell: ({ cell, renderedCellValue }) => {
        const time = String(renderedCellValue);
        if (type === DropdownOptions.RescheduleShift) {
          const isEditable = handleEditIcon(cell.row);
          return time ? (
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              {formatTime(time)}
              <img
                src={Icons.EditBlackIcon}
                alt={t('altTexts:edit')}
                style={{
                  cursor: isEditable ? 'pointer' : 'not-allowed',
                  opacity: isEditable ? 1 : 0.3,
                  height: '1.5rem',
                  width: '1.5rem',
                }}
                onClick={() => isEditable && handleShiftTimings?.(cell)}
              />
            </Box>
          ) : (
            '-'
          );
        } else {
          return time ? formatTime(time) : '-';
        }
      },
    },
  ];

  const AllColumns = getActionColumn();

  columns.push(...AllColumns);
  return columns;
};

export default ScheduleColumns;
