/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import {
  AssignTeamDiv,
  buttonStyles,
  LogoWrapper,
  TeamCard,
  TeamContainer,
} from '../../../../Modules/Jobs/Components/JobDetails/OneTimeJobDetails.Style';
import theme from '../../../Themes/theme';
import { ITeamMemberData } from '../../../../Modules/Jobs/Components/JobDetails/JobAssignmentCard';
import { UsersListType } from './CreateConversations';
import { convertToReadableFormat, getAcronym } from '../../../Utilities/utils';
import { Icons } from '../../../Constants/Icons';
import { ConversationTabs } from '../../../Constants/App';
import { useState } from 'react';
import { textField } from '../../../../Modules/Vendor/Components/BidRevision/Popup.Styles';
import { CardContainerWrapper, CheckIcon, ConversationNameWrapper } from './CreateConversations.Style';

export const checkAlreadySelected = (data: ITeamMemberData[], memberId: string): boolean => {
  return data.some((prevMember) => prevMember.id === memberId);
};

interface ConversationCard {
  users: UsersListType[];
  isDataLoading: boolean;
  checkedMembers: UsersListType[];
  setCheckedMembers: (data: UsersListType[]) => void;
  handleOnPressCreateConversation: () => void;
  createGroup: (conversationName: string) => void;
  isButtonLoading: boolean;
  conversationMode: ConversationTabs;
  setGroupName: (groupName: string) => void;
  groupName: string;
  isCreateConversationLoading: boolean;
  isGroupAlreadyCreated: boolean;
  previouslySelectedUsersForGroupChat: UsersListType[];
  addAssociateLoader: boolean;
  AddNewMemberToAlreadyCreatedGroup: () => void;
  chatWithExistingGroup: () => void;
  loggedInUserInternalId: string;
}
const CreateConversationCards = ({
  users,
  isDataLoading,
  checkedMembers,
  setCheckedMembers,
  handleOnPressCreateConversation,
  createGroup,
  isButtonLoading,
  conversationMode,
  setGroupName,
  groupName,
  isCreateConversationLoading,
  isGroupAlreadyCreated,
  previouslySelectedUsersForGroupChat,
  addAssociateLoader,
  AddNewMemberToAlreadyCreatedGroup,
  chatWithExistingGroup,
  loggedInUserInternalId,
}: ConversationCard) => {
  const { t } = useTranslation(['assignJob', 'oneTimeJob', 'calendar', 'conversations']);

  const [isConversationCardVisible, setConversationCardVisibility] = useState(false);
  const { buttonText, createJobButton } = buttonStyles;

  const handleSelectMember = (member: UsersListType) => {
    const isPreviouslySelected = previouslySelectedUsersForGroupChat.some((prevMember) => prevMember.id === member.id);

    if (isGroupAlreadyCreated && isPreviouslySelected) {
      return;
    }

    if (conversationMode === ConversationTabs.DIRECT_CHAT) {
      if (checkedMembers.some((m) => m.id === member.id)) {
        setCheckedMembers([]);
      } else {
        setCheckedMembers([member]);
      }
    } else {
      if (checkedMembers.some((m) => m.id === member.id)) {
        setCheckedMembers(checkedMembers.filter((m) => m.id !== member.id));
      } else {
        setCheckedMembers([...checkedMembers, member]);
      }
    }
  };

  const renderTeamContent = (users: UsersListType[]) => {
    return (
      <>
        {users?.length > 0 && (
          <AssignTeamDiv>
            {users.map((member: UsersListType) => {
              if (loggedInUserInternalId === member?.id) {
                return;
              }
              const isSelected = checkedMembers.some((m) => m.id === member.id);
              return (
                <TeamCard
                  key={member.id}
                  onClick={() => handleSelectMember(member)}
                  style={{
                    border: isSelected ? `1px solid ${theme.palette.primary.main}` : '1px solid transparent',
                  }}>
                  <Box style={{ display: 'flex' }}>
                    <LogoWrapper>
                      {!isSelected ? (
                        <Box>{getAcronym(member.name).toUpperCase()}</Box>
                      ) : (
                        <Box>
                          <CheckIcon src={Icons.SuccessIcon} />
                        </Box>
                      )}
                    </LogoWrapper>
                    <TeamContainer>
                      <Box className="Title">{member.name}</Box>
                      <Box className="Position">{convertToReadableFormat(member.role)}</Box>
                    </TeamContainer>
                  </Box>
                </TeamCard>
              );
            })}
          </AssignTeamDiv>
        )}
      </>
    );
  };

  const handleNextButton = () => {
    if (conversationMode === ConversationTabs.GROUP_CHAT) {
      setConversationCardVisibility(true);
    } else {
      handleOnPressCreateConversation();
    }
  };

  const ConversationNameInput = () => {
    return (
      <ConversationNameWrapper>
        <Typography>
          <span>{t('conversations:enterConversationName')}</span>
          <span className="mandatory-field">*</span>
        </Typography>
        <TextField
          sx={{ ...textField, marginBottom: '1em', marginTop: '1em' }}
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          label={t('conversations:conversationName')}></TextField>
      </ConversationNameWrapper>
    );
  };

  const renderAddMembersButtons = () => {
    return (
      <>
        <Button
          disabled={previouslySelectedUsersForGroupChat?.length === checkedMembers?.length || addAssociateLoader}
          sx={{
            ...createJobButton,
            opacity:
              previouslySelectedUsersForGroupChat?.length === checkedMembers?.length || addAssociateLoader ? 0.5 : 1,
            marginTop: '2em',
            width: '100%',
            maxWidth: '350px',
            textAlign: 'center',
          }}
          onClick={() => AddNewMemberToAlreadyCreatedGroup()}>
          {addAssociateLoader ? (
            <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
          ) : (
            <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
              {t('conversations:addMembers')}
            </Typography>
          )}
        </Button>
        <Button
          disabled={addAssociateLoader ?? false}
          sx={{
            ...createJobButton,
            opacity: !addAssociateLoader ? 1 : 0.5,
            marginTop: '2em',
            width: '100%',
            maxWidth: '350px',
            textAlign: 'center',
          }}
          onClick={() => chatWithExistingGroup()}>
          {addAssociateLoader ? (
            <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
          ) : (
            <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
              {t('conversations:groupConversation')}
            </Typography>
          )}
        </Button>
      </>
    );
  };

  const handleIndividualConversationButton = () => {
    return (
      <Button
        disabled={isButtonLoading || checkedMembers.length === 0}
        sx={{
          ...createJobButton,
          opacity: checkedMembers.length > 0 && !isButtonLoading ? 1 : 0.5,
          marginTop: '2em',
          width: '100%',
          maxWidth: '350px',
          textAlign: 'center',
        }}
        onClick={() => handleNextButton()}>
        {isButtonLoading ? (
          <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
        ) : (
          <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
            {t('conversations:createConversation')}
          </Typography>
        )}
      </Button>
    );
  };

  const handleGroupConversationButton = () => {
    return (
      <Button
        disabled={isCreateConversationLoading || !groupName}
        sx={{
          ...createJobButton,
          opacity: groupName && !isCreateConversationLoading ? 1 : 0.5,
          marginTop: '2em',
          width: '100%',
          maxWidth: '350px',
          textAlign: 'center',
        }}
        onClick={() => createGroup(groupName)}>
        {isCreateConversationLoading ? (
          <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
        ) : (
          <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
            {t('conversations:createConversation')}
          </Typography>
        )}
      </Button>
    );
  };

  return (
    <>
      {!isConversationCardVisible ? (
        <CardContainerWrapper>
          {isDataLoading ? (
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark, marginLeft: '45%' }} />
          ) : users?.length > 0 ? (
            renderTeamContent(users)
          ) : (
            <Typography sx={{ display: 'flex', justifyContent: 'center' }}>{t('assignJob:noResultsFound')}</Typography>
          )}
        </CardContainerWrapper>
      ) : (
        ConversationNameInput()
      )}

      <>
        <Box style={{ display: 'flex', justifyContent: 'center', width: '100%', gap: '1.5rem' }}>
          {isGroupAlreadyCreated
            ? renderAddMembersButtons()
            : !isConversationCardVisible
              ? handleIndividualConversationButton()
              : handleGroupConversationButton()}
        </Box>
      </>
    </>
  );
};

export default CreateConversationCards;
