/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { API } from 'aws-amplify';

import { findInspectionReportUrl, masterData } from '../../../graphql/queries';

import { AuthHeader, AWSAuthMode, CustomHeaders } from '../../../Shared/Constants/App';
import { getWebInfo } from '../../../Shared/Utilities/utils';
import { IGraphQLResponse } from '../../../Shared/Models';
import { AuthenticationService } from '../../../Shared/Services/AuthenticationService';
import { ReportUrlInput } from '../../../API';

export default class ReportApiService {
  static async getMasterData(code: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: masterData,
          variables: { id: code },
          authMode: AWSAuthMode.API_KEY,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.masterData.data ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async fetchReportUrl(reportUrlInput: ReportUrlInput) {
    try {
      const response: IGraphQLResponse = await API.graphql(
        {
          query: findInspectionReportUrl,
          variables: { reportUrlInput },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: response.data?.findInspectionReportUrl ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }
}
