/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { styled } from '@mui/material';

export const ConversationIcon = styled('div')(() => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '0.5rem',
}));

export const AssignedTeamHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const addButtonStyles = {
  addIconButton: {
    backgroundColor: 'transparent',
    padding: 0,
    paddingBottom: '1rem',
    margin: 0,
    minWidth: 0,
  },

  plusIcon: {
    width: '1.75rem',
    height: '1.75rem',
    marginRight: '2em',
  },

  conversationIcon: {
    width: '1.5rem',
    height: '1.5rem',
    marginRight: '8px',
    background: 'white',
  },

  noDataFound: {
    display: 'flex',
    justifyContent: 'center',
  },
};

export const ChatWithTeam = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #006B6B',
  borderRadius: '14px',
  padding: '10px 14px',
}));
