/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

export enum ProfitOption {
  Percentage = '%',
  Dollar = '$',
}

export interface BottomBarProps {
  bidDetails: any;
  subVendorTotalEstimation: number;
  totalEstimation: number;
  setTotalEstimation: (value: number) => void;
  updateAndSubmit?: any;
  profitMargin?: any;
  setProfitMargin?: any;
  storedJob: any;
  additionalSubTotal: number;
  setAdditionalSubTotal: (value: number) => void;
  selectedProfitOption: ProfitOption;
  setSelectedProfitOption: (value: ProfitOption) => void;
  totalEstimationWithMargin: any;
  setTotalEstimationWithMargin: (value: any) => void;
  vendorQuotesAttachments: any;
  finalQuote: any;
  isFinalQuoteLoading: boolean;
  stateValue?: any;
  isServicesLoading: boolean;
  isDataLoading: boolean;
  isFacilityDetailsLoading: boolean;
}
