/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { API } from 'aws-amplify';
import { findAllWorkOrders } from '../../../graphql/queries';
import { AuthHeader, AWSAuthMode } from '../../../Shared/Constants/App';
import { IGraphQLResponse } from '../../../Shared/Models';
import { getWebInfo } from '../../../Shared/Utilities/utils';
import { AuthenticationService } from '../../../Shared/Services/AuthenticationService';
import { updateScheduleMasters } from '../../../graphql/mutations';
import { UpdateScheduleMasterInput } from '../../../API';

export default class AllJobsApiService {
  static async allJobs(
    pageNumber: number,
    limit: number,
    userId: string,
    jobId?: string,
    jobNumber?: string,
    jobAddress?: string,
    sortBy?: string,
    sortOrder?: string,
    searchText?: string
  ) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const allJobsResponse: IGraphQLResponse = await API.graphql(
        {
          query: findAllWorkOrders,
          variables: {
            pageNumber,
            limit,
            userId,
            jobId,
            jobNumber,
            jobAddress,
            sortBy,
            sortOrder,
            searchText,
          },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: allJobsResponse?.data?.findAllWorkOrders ?? {}, errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async updateScheduleMaster(updateScheduleMasterInput: UpdateScheduleMasterInput[]) {
    try {
      const authHeaders = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: updateScheduleMasters,
          variables: { updateScheduleMasterInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.updateScheduleMasters ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }
}
