/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useEffect, useRef } from 'react';
import { getAcronym, getStoredCustomerDetails } from '../../../../Shared/Utilities/utils';
import MessageCardUI from '../../../../Shared/Components/Conversations/MessagesScreen/MessageCard';

export interface MessageCardProps {
  selectedConversation: any;
  conversationDetails: any;
}

const PreJobAwardMessageCard = ({ selectedConversation }: MessageCardProps): JSX.Element => {
  const conversationMessages = selectedConversation?.messages;

  const { customerId = '' } = getStoredCustomerDetails();

  const chatBoxReference = useRef<null | HTMLDivElement>(null);

  const hasSameOrganizationId = (organizationId: string): boolean => {
    return customerId === organizationId;
  };

  const getSenderName = (message: any) => {
    const username = message?.userName;
    const individualUserName = message?.userName;

    return { individualUserName, username };
  };

  const processedMessages = conversationMessages?.map((message: any) => {
    const { individualUserName, username } = getSenderName(message);
    return { ...message, individualUserName, username };
  });

  useEffect(() => {
    if (chatBoxReference?.current) {
      chatBoxReference.current.scrollTop = chatBoxReference.current.scrollHeight;
    }
  }, [processedMessages]);

  return (
    <MessageCardUI
      messages={processedMessages}
      chatBoxRef={chatBoxReference}
      getAcronym={getAcronym}
      hasSameOrganizationId={hasSameOrganizationId}
    />
  );
};

export default PreJobAwardMessageCard;
