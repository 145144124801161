/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

export enum DashboardType {
  Inspection = 'Inspection',
  JobFulfillment = 'Job-fulfillment',
}
