/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

export interface ISupervisorAssociate {
  woScheduleMasterId: string;
  name: string;
  roleName: string;
  email: string;
}

export enum DropdownOptions {
  RescheduleShift = 'RescheduleShift',
  CancelShift = 'CancelShift',
  ModifySchedule = 'ModifySchedule',
}

export enum CancellationOptions {
  PermanentClosure = 'permanent',
  TemporaryClosure = 'temporary',
  ShiftCancellation = 'shiftCancellation',
}
export interface IModifySchedulePopupProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (data: { woScheduleMasterId: string; scheduleDays: any; startTime: string; endTime: string }) => void;
  selectedJobData: any;
  setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRows: any;
}

export interface IDropdownWithButtonProps {
  selectedRows?: any[];
}
