/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { UserType } from '../../../Shared/Constants/App';
import { Loader } from '../../Jobs/Components/JobView/JobsView.styles';
import PageTitle from '../../../Shared/Components/Common/PageTitle/PageTitle';
import { ReportsContainer } from './Reports.styles';
import { getStoredCustomerDetails } from '../../../Shared/Utilities/utils';
import Button, { ButtonType } from '../../../Shared/Components/Common/Buttons/Button';
import ReportApiService from '../Services/ReportService';
import { DashboardType } from '../Models/Reports.model';

export interface IGraphQLResponse {
  data?: any;
  errors?: any;
}

const Reports = () => {
  const { t } = useTranslation(['dashboard', 'reports']);

  const [reportUrl, setReportUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [showReportMenu, setShowReportMenu] = useState<boolean>(false);
  const [userRole, setUserRole] = useState<string>('');

  const { customerId = '', userId } = getStoredCustomerDetails() || {};

  const getDashboardNumber = async (role: string) => {
    const dashboardNumbers = await ReportApiService.getMasterData('DASHBOARD_NUMBER');
    return dashboardNumbers.data.find((item: any) => item.value === role)?.subKey1Value;
  };

  // Load initial report URL based on user role and ID
  const loadReportUrl = async () => {
    setLoading(true);
    try {
      const userRole = getUserRole();
      setUserRole(userRole);

      if (shouldShowMenu(userRole, customerId)) {
        setShowReportMenu(true);
        setLoading(false);
        return;
      }

      const dashboardNumber = await getDashboardNumber(userRole);

      const url = await ReportApiService.fetchReportUrl({
        reportType: DashboardType.JobFulfillment,
        dashboardNumber: dashboardNumber,
        orgId: customerId,
        role: userRole,
        userId,
      });
      setReportUrl(url.data);
    } catch (error) {
      console.error('Error loading report URL:', error);
    } finally {
      setLoading(false);
    }
  };

  // Helper function to retrieve user role from local storage
  const getUserRole = (): UserType => {
    const authValue = localStorage.getItem('auth');
    return authValue ? JSON.parse(authValue)?.attributes?.['custom:Role'] : UserType.NoUser;
  };

  // Determine if report menu should be shown based on role and customer ID
  const shouldShowMenu = (role: UserType, customerId: string): boolean => {
    return role === UserType.Vendor && customerId === 'a00aa016-2636-4737-b2a4-b9b0b0a62458';
  };

  // Handlers for fetching specific reports
  const handleInspectionReportFetch = async () => {
    setLoading(true);
    try {
      const dashboardNumber = await getDashboardNumber('Inspection');
      const url = await ReportApiService.fetchReportUrl({
        reportType: DashboardType.Inspection,
        dashboardNumber: dashboardNumber,
        orgId: customerId,
        role: userRole,
      });
      setReportUrl(url.data);
    } catch (error) {
      console.error('Error fetching inspection report:', error);
    } finally {
      setShowReportMenu(false);
      setLoading(false);
    }
  };

  const handleJobFulfillmentReportFetch = async () => {
    setLoading(true);
    try {
      const dashboardNumber = await getDashboardNumber(userRole);
      const url = await ReportApiService.fetchReportUrl({
        reportType: DashboardType.JobFulfillment,
        dashboardNumber: dashboardNumber,
        orgId: customerId,
        role: userRole,
        userId,
      });
      setReportUrl(url.data);
    } catch (error) {
      console.error('Error fetching job fulfillment report:', error);
    } finally {
      setShowReportMenu(false);
      setLoading(false);
    }
  };

  // Load initial report URL on component mount
  useEffect(() => {
    loadReportUrl();
  }, []);

  return (
    <>
      <PageTitle
        title={t('dashboard:Reports')}
        onCancelClick={() => setShowReportMenu(true)}
        showCancelIcon={
          !showReportMenu &&
          userRole === UserType.Vendor.toString() &&
          customerId?.toString() === 'a00aa016-2636-4737-b2a4-b9b0b0a62458'
        }
      />
      <ReportsContainer>
        {loading && (
          <Loader>
            <CircularProgress style={{ marginTop: '2rem', color: '#006B6B' }} />
          </Loader>
        )}
        {!reportUrl && !loading && !showReportMenu && <div>No report available</div>}
        {showReportMenu && !loading && (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <Button label={'Inspection Report'} onClick={handleInspectionReportFetch} type={ButtonType.Primary} />
            <Button
              label={'Job Fulfillment Report'}
              onClick={handleJobFulfillmentReportFetch}
              type={ButtonType.Primary}
            />
          </div>
        )}
        {!showReportMenu && reportUrl && (
          <iframe
            id="report-iframe"
            src={reportUrl}
            frameBorder="0"
            width="100%"
            height="100%"
            title="Metabase Dashboard"
            allowTransparency
          />
        )}
      </ReportsContainer>
    </>
  );
};

export default Reports;
